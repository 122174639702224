import {connect} from 'react-redux';
import {cancelBookService} from '../../../../core/actions/action';
import ServiceCancel from '../Components/ServiceCancel';

const mapStateToProps = state => {

    return{
        cancelSerice:state.Travel.cancelSerice,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        cancelBookService:(serviceId, statusReason) => {
            return cancelBookService(serviceId, statusReason);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCancel);
