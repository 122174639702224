import React, { Component } from 'react';
import {RadioGroup, Radio} from "@blueprintjs/core";
import GgBusOrder from './Container/GgBusOrders';
import GgFlightOrder from './Container/GgFlightOrders';
import RailBhojOrder from './Container/GgRailOrders';
import HolidaysPackage from './Components/HolidaysPackage';
import Hotel from './Components/Hotel';

export default class Travel extends Component{
    constructor(props){
        super(props);
        this.state={
            selectedOption:1
        };
    }

    changeOrders(e){
        this.setState({selectedOption:Number(e.target.value)})
    }

    componentDidMount(){
        if(this.props.location.hash.split("#")[2] !=undefined){
      this.setState({selectedOption:Number(this.props.location.hash.split("#")[2])})}else{
          this.setState({selectedOption:1})
      }
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.location.hash.split("#")[2] !=undefined){
        this.setState({selectedOption:Number(nextProps.location.hash.split("#")[2])})}else{
            this.setState({selectedOption:1})
        }
    }
    render(){
        return(
            <React.Fragment>
                <div className="megabrand">
                    <div className="megabrandOption">
                        <RadioGroup
                            onChange={(e) => this.changeOrders(e)}
                            name="type"
                            className="d-inline option-type col-12"
                            selectedValue={this.state.selectedOption}>
                            <Radio label="GG Bus" value={1} />
                            <Radio label="GG Flight" value={2} />
                            {/*<Radio label="Railbhoj" value={3} />*/}
                            <Radio label="Holidays" value={4} />
                            <Radio label="Hotel" value={5} />
                            {/*<Radio label="Hotel" value={4} />*/}
                        </RadioGroup>
                    </div>
                    {
                        this.state.selectedOption===1 ?
                            <GgBusOrder/>
                            :
                            this.state.selectedOption===2 ?
                                <GgFlightOrder/>
                                :
                                this.state.selectedOption===3 ?
                                    <RailBhojOrder/>
                                    :
                                    this.state.selectedOption===4 ?
                                        <HolidaysPackage />
                                        :
                                        this.state.selectedOption===5 ?
                                            <Hotel />
                                            : ""
                    }

                </div>
            </React.Fragment>
        );
    }
}