import React, { Component } from 'react';
import {Helper} from "gg-react-utilities";
//import {Button} from '@blueprintjs/core';

export default class IC extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isVisibleMilestone: false,
            title: "",
            milestones: {},
            error: false,
            hasMore: true,
            isLoading: false,
            data:[],
            counter:1,
            isSkeleton:true,
            total:0.00,
            last_page:1,
            scrollLoader:false
        };
    }

    componentDidMount() {

       this.props.getICList(this.state.counter)
    }

    componentWillReceiveProps(nextProps) {
        let oldData = this.state.data;
        let newDataFromApi = nextProps.icList.data;
        let newData = oldData.concat(newDataFromApi);
        this.setState({
            data:newData,
            isSkeleton:false,
            scrollLoader:false,
            total:nextProps.icList.total_icb_cashback,
            last_page:nextProps.icList.last_page
        });
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('icBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            this.loadMore()
        }
    }


    loadMore = () => {

        this.setState({
            counter: this.state.counter + 1,
            isLoading: true,
            scrollLoader:true
        }, () => {
            this.props.getICList(this.state.counter);
        })

    }


    render(){
        return(
            <React.Fragment>
                {this.props.icList!==null ?
                <div className="fifo mt-4" >
                    <div id={"fifoWrapper1"} className="fifoWrapper">
                        <div className="fifoSection w-100">
                            <div className="icon">
                                <img src={require('../../images/rupee.svg')} alt="" />
                            </div>
                            <div className="count">
                                <h3 className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>{Helper.formatMoney(this.state.total)}</h3>
                                <p>Cashback Received Through UPV</p>
                            </div>
                        </div>
                    </div>
                    <div style={{height:'100%',maxHeight:'590px',overflowY:'scroll'}} id={"icBooking"} onScroll={this.handleScroll} className="table-responsive mt-4">
						<table className="bp3-html-table bp3-html-table-bordered">
							<thead>
								<tr>
									<th>ID</th>
									<th>Order ID</th>
									<th>Cashback Amount</th>
									<th>Created Date</th>
								</tr>
							</thead>
							<tbody>
								{this.state.isSkeleton===true ?
									<tr>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>1</span></td>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>122322</span></td>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>₹ 2122</span></td>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>16 Aug 2982</span></td>
									</tr>
									:
									this.state.data.length===0 ?
										<tr>
											<td colSpan="4" className="emptyState">
												<img src={require('../../../core/images/empty-state/UPV.svg')} alt=""/>
												<p className="mt-3">No Purchase done under IC yet, Continue shopping to get 2% and 10% guaranteed instant cashback.</p>
											</td>
										</tr>
									:
									this.state.data.map((res, index) => {
									return(
										<tr key={index}>
											<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>{index+1}</span></td>
											<td>{res.order_id}</td>
											<td>₹ {res.amount}</td>
											<td>{res.cashback_date}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
                    {
                        this.state.last_page < this.state.counter ? null :
                            this.state.scrollLoader ?
                                <React.Fragment>
                                    <center>
                                        <div className="my-3">
                                            <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                            :null
                    }
                </div>
                    :null}
            </React.Fragment>
        );
    }
}