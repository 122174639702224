import React, { Component } from 'react';
import AllorderEmptyStates from "../../../core/images/empty-state/Allorder.svg";
import moment from "moment";

export default class GrangeComponent extends Component{
    constructor(props){
        super(props);
        this.state={
            loading: true
        }
    }

    componentDidMount(){
        this.setState( {
            loading: this.props.loading,
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    handleScroll=()=>{

        var grangeInquiry = document.getElementById('grangeInquiry');
        var grangeInquiryHeight = (grangeInquiry.scrollHeight)-(grangeInquiry.scrollTop+560);
        if (parseInt(grangeInquiryHeight) === 0){
            if(this.props.stopLoadMore === false){
                this.props.NextPage()
            }
        }
    }

    render(){
        console.log("this.props.GrangeInquiryList",this.props.GrangeInquiryList)
        return(
            <React.Fragment>
                <div className="grange" id={"grangeInquiry"} style={{'height':'100%','maxHeight':'575px','overflowY':'scroll'}} onScroll={this.handleScroll}>
                    <table className="bp3-html-table table-borderless">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Mobile No.</th>
                            <th>Email</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Pin-code</th>
                            <th>Created At</th>
                            {/* <th>Acp Partner Code</th>
                            <th>Start Operation Number</th> */}
                        </tr>
                        <tr className="d-none">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.loading ?
                                <tr>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                </tr>
                                :
                                this.props.GrangeInquiryList !== undefined && this.props.GrangeInquiryList.length ?
                                    this.props.GrangeInquiryList.map((inquiry, key) => {
                                        return<tr key={key}>
                                            <td>{inquiry.id}</td>
                                            <td>{inquiry.mobile}</td>
                                            <td>{inquiry.email}</td>
                                            <td>{inquiry.city}</td>
                                            <td>{inquiry.state}</td>
                                            <td>{inquiry.pincode}</td>
                                            <td>{moment(inquiry.created_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                            {/* <td>{inquiry.acp_partner_code}</td>
                                            <td>{inquiry.start_operation_number}</td> */}
                                        </tr>
                                    })
                                    :
                                    <tr>
                                        <td colSpan="8" align="center">
                                            <div className="text-center">
                                                <img src={AllorderEmptyStates} alt=""/>
                                                <p className="mt-3">No transactions for the selected option</p>
                                            </div>
                                        </td>
                                    </tr>
                        }
                        </tbody>
                   
                    </table>
                    {
                        this.state.loading ?
                            null
                            :
                            this.props.stopLoadMore ?
                                ""
                                :
                                <React.Fragment>
                                    <center>
                                        <div className="my-3">
                                            <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }
}