import React, {Component} from 'react';
import PrivateRoute from './PrivateRoute';
import { Switch } from 'react-router-dom';
import '../../node_modules/normalize.css/normalize.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "gg-react-utilities/src/css/style.css";
import Cashback from "../Cashback";
import UPV from '../Cashback/UPV';
import upvReplaceList from '../Cashback/UPV/components/upvReplaceList';
import CircleView from '../Cashback/UPV/components/circle_view';
import CircleTree from '../Cashback/UPV/container/circleContainer/CirclesContainer';
import CoinConversion from '../Cashback/UPV/components/coin_conversion';
import coinConverted from '../Cashback/UPV/components/coinConverted_history'
import FPV from '../Cashback/FPV';
import fpvReplaceList from '../Cashback/FPV/components/fpvReplaceList';
import FpvCircleTree from '../Cashback/FPV/container/circleContainer/CirclesContainer';
import FpvCoinConversion from '../Cashback/FPV/components/coin_conversion';
import FpvCoinConverted from '../Cashback/FPV/components/coinConverted_history';
import Stats from '../Stats';
import Referrals from '../Referrals';
import showImagePreviue from '../Referrals/UserReferrals/Components/ShareImage';
import Rewards from '../Rewards';
import MoneyRequest from '../MoneyRequest';
import Passbook from '../Passbook';
import Withdraw from "../Withdraw";
import OtherRewards from "../OtherRewards";
import Orders from "../Orders";
import Favourites from "../Favourites";
import UPVExchange from "../UPVExchange";
import UPVExchangeInfo from "../UPVExhangeInfo";
import UPVDetail from "../UPVExchange/UPVDetail";
import UPVSellExchange from "../UPVSellExchange";
import UPVSellDetail from "../UPVSellExchange/UPVSellDetail";
import UPVBidSuccess from "../UPVExchange/Payment/bidSuccess";
import UpvConversionSuccess from "../Cashback/UPV/components/success";
import UpvConversion from "../Cashback/UPV/Conversion";
import UpvConversionHistory from "../Cashback/UPV/ConversionHistory";
import BidPayment from "../UPVExchange/Payment";
import BidTransactions from "../UPVExchange/Transaction";
import BidTransactionDetail from "../UPVExchange/Transaction/TransactionDetail";
import Config from '../Config'
import {Notfound, Maintance} from "gg-react-utilities";


export default class Root extends Component{
    render(){
        return(
            <React.Fragment>
                {Config.IS_MAINTENANCE_MODE ?
                    <Switch>
                        <PrivateRoute path="*" component={Maintance} />
                    </Switch>
                    :
                    <Switch>
                        <PrivateRoute exact path="/" component={Stats} />
                        <PrivateRoute exact path="/cashback" component={Cashback} />
                        <PrivateRoute exact path="/upv/:cbType?" component={UPV} />
                        <PrivateRoute exact path="/upv-replace" component={upvReplaceList} />
                        <PrivateRoute exact path="/circle_old/:upvID" component={CircleView} />
                        <PrivateRoute exact path="/circle/:upvID" component={CircleTree} />
                        <PrivateRoute exact path="/circle/coinconversion/:upvID" component={CoinConversion} />
                        <PrivateRoute exact path="/circle/coinconversion/history/:upvId" component={coinConverted}/>
                        <PrivateRoute exact path="/fpv/:cbType?" component={FPV} />
                        <PrivateRoute exact path="/fpv-replace" component={fpvReplaceList} />
                        <PrivateRoute exact path="/fpv/circle/:fpvID" component={FpvCircleTree} />
                        <PrivateRoute exact path="/fpv/circle/coinconversion/:fpvID" component={FpvCoinConversion} />
                        <PrivateRoute exact path="/fpv/circle/coinconversion/history/:fpvId" component={FpvCoinConverted}/>
                        <PrivateRoute exact path="/referrals" component={Referrals} />
                        <PrivateRoute exact path="/referrals/image" component={showImagePreviue} />
                        <PrivateRoute exact path="/rewards" component={Rewards} />
                        <PrivateRoute exact path="/money-requests" component={MoneyRequest} />
                        <PrivateRoute exact path="/passbook" component={Passbook} />
                        <PrivateRoute exact path="/withdraw" component={Withdraw} />
                        <PrivateRoute exact path="/other-rewards" component={OtherRewards} />
                        <PrivateRoute exact path="/orders" component={Orders} />
                        <PrivateRoute exact path="/favourites" component={Favourites} />
                        <PrivateRoute exact path="/upv-exchange" component={UPVExchange} />
                        <PrivateRoute exact path="/sell-upv-exchange" component={UPVSellExchange} />
                        <PrivateRoute exact path="/upv-exchange-detail" component={UPVDetail} />
                        <PrivateRoute exact path="/upv-sell-exchange-detail" component={UPVSellDetail} />
                        <PrivateRoute exact path="/upv-conversion" component={UpvConversion} />
                        <PrivateRoute exact path="/upv-conversion/payment/:status" component={UpvConversionSuccess} />
                        <PrivateRoute exact path="/upv-conversion-transaction-history" component={UpvConversionHistory} />
                        <PrivateRoute exact path="/upv-exchange/payment/:status" component={UPVBidSuccess} />
                        <PrivateRoute exact path="/bid-payment" component={BidPayment} />
                        <PrivateRoute exact path="/bids-transaction" component={BidTransactions} />
                        <PrivateRoute exact path="/bid-transaction-detail" component={BidTransactionDetail} />
                        <PrivateRoute exact path="/upv-exchange-info" component={UPVExchangeInfo} />
                        <PrivateRoute path="*" component={Notfound} />
                    </Switch>
                }
            </React.Fragment>
        );
    }
}