import {connect} from 'react-redux';
import {getInsuranceInquiriesList} from '../../../core/actions/action';
import InsuranceInquiriesIndex from '../components/InsuranceInquiriesIndex';

const mapDispatchToProps = dispatch => {

    return {
        getInsuranceInquiryList:(params) => {
            return getInsuranceInquiriesList(params);
        },
    }
}

export default connect(null, mapDispatchToProps)(InsuranceInquiriesIndex);
