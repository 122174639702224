 import React from 'react';
 import {Button} from "@blueprintjs/core";


 export default class VoucherLoadMore extends React.Component {
     constructor(props){
         super(props);

         this.state={
             voucherOrderList:[],
         };
     }

     NextPage = () => {
        this.props.NextPage()
     }


     render(){
         return(
             <React.Fragment>
                 {
                     this.props.voucherOrderList.length >= 15 ?
                         this.props.stopLoadMore ?
                             ""
                             :

                             <div className="text-center my-4">
                                 <center><Button intent="primary" text="Load More" onClick={() => this.NextPage()}/></center>
                             </div>
                         :
                         ""
                 }
             </React.Fragment>
         );
     }
 }