import React, {Component} from 'react';
import AllorderEmptyStates from "../../../../core/images/empty-state/Allorder.svg";
import railBhojDefaultImage from "../../../../core/images/order/railbhoj.svg";
import {Button} from "@blueprintjs/core";

export default class RailBhojOrder extends Component{
    constructor(props){
        super(props);
        this.state={
            orderList:[],
            loading:true,
        };
    }

    componentDidMount(){
        this.setState( {
            loading: this.props.loading,
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            if(this.props.stopLoadMore === false){
                this.props.NextPage()
            }
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="railbhoj" id={"allBooking"} style={{'height':'100%','maxHeight':'590px','overflowY':'scroll'}} onScroll={this.handleScroll}>
                    <table className="bp3-html-table table-borderless">
                        <thead>
                            <tr>
                                <th>Order Details</th>
                                <th>Order ID</th>
                                <th>Order Date</th>
                                <th>Price</th>
                                <th>Cashback Type</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.loading ?
                                <tr>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                </tr>
                                :
                                this.props.orderList.length ?
                                    this.props.orderList.map((booking, key) => {
                                        return <tr key={key}>
                                        <td>
                                            <div className="orderTable">
                                                <div className="image hidden-xs hidden-sm hidden-md visible-xl visible-lg">
                                                    <img src={railBhojDefaultImage} alt="" />
                                                </div>
                                                <div className="title">
                                                    <p>{booking.outlet_details.outlet_name}</p>
                                                    <span>{booking.passenger_details.train_no} / {booking.passenger_details.journey_to_from_code}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{booking.order.id}</td>
                                        <td>{booking.order.created_at}</td>
                                        <td><b>₹ {booking.order.total}</b></td>
                                        <td>{booking.order.cb_choice_title}</td>
                                        <td><label className="label" style={{color: booking.order_status.order_status_color}}><b>{booking.order_status.order_status_label}</b></label></td>
                                        <td>
                                            {
                                                booking.is_invoice_downloadable === true ?
                                                    <a href={booking.download_invoice_url} title="Download Invoice" className="bp3-button bp3-small bp3-intent-primary btn-view" rel="noopener noreferrer" target="_blank">
                                                        <span className="bp3-icon bp3-icon-import bp3-text-medium"></span>
                                                    </a>
                                                    :
                                                    <Button small={true} intent="inform" disabled={true} className="btn-view mr-2" icon="import" title="Download Invoice" />
                                            }
                                        </td>
                                    </tr>
                                    })
                                    :
                                    <tr>
                                        <td colSpan="7" align="center">
                                            <div className="text-center">
                                                <img src={AllorderEmptyStates} alt=""/>
                                                <p className="mt-3">No transactions for the selected option</p>
                                            </div>
                                        </td>
                                    </tr>

                        }
                        </tbody>
                    </table>
                    {
                        this.state.loading ?
                            null
                            :
                            this.props.stopLoadMore ?
                                ""
                                :
                                <React.Fragment>
                                    <center>
                                        <div className="my-3">
                                            <img src={require('../../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                    }
                </div>
            </React.Fragment>
        )
    }
}