let initialState = {
    ggRewards:[],
    sendCoin:null,
    requestCoin:null,
};

const Rewards = (state = initialState, action) => {

    switch (action.type) {

        case 'GG_REWARDS':
            return {...state, ggRewards: action.sources}
        case 'SEND_GG_COIN':
            return {...state, sendCoin: action.sources}
        case 'REQUEST_GG_COIN':
            return {...state, requestCoin: action.sources}
        default:
            return state
    }
}

export default Rewards;
