import React from 'react';
import {Icon, Intent} from "@blueprintjs/core";

export default class circle extends React.Component{

    constructor(props){
        super(props)
        this.state ={
            isCircleLoding:false,
            circleClass:null,
            addUpvCircleFunction:false,
            userInformationFunction:false,
            nextLavelUpvFunction:false,
            getNextLevelLoading:false
        }
    }

    componentDidMount() {

        this.setState({
            circleClass:this.props.circleClass,
            lavel : this.props.lavel,
            upvId : this.props.upvId,
            addUpvCircleFunction : this.props.addUpvCircleFunction,
            userInformationFunction : this.props.userInformationFunction,
            nextLavelUpvFunction : this.props.nextLavelUpvFunction,
            getNextLevelLoading : this.props.getNextLevelLoading,
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            circleClass:nextProps.circleClass,
            lavel : nextProps.lavel,
            upvId : nextProps.upvId,
            addUpvCircleFunction : nextProps.addUpvCircleFunction,
            userInformationFunction : nextProps.userInformationFunction,
            nextLavelUpvFunction : nextProps.nextLavelUpvFunction,
            getNextLevelLoading : nextProps.getNextLevelLoading,
        })
        if(nextProps.getNextLevelLoading === false){
            this.setState({
                isCircleLoding:false
            })
        }
    }

    render() {

        if(this.props.addUpvCircleFunction) {
            return (
                <li>
                    <a onClick={() => {
                        this.props.openAddCircleModal(this.state.upvId, this.state.lavel);
                    }}
                       className="user font-20 add-child">
                        <Icon icon="plus" iconSize={18} intent={Intent.PRIMARY}/>
                    </a>
                </li>
            )
        }else{
            return (
                <li className={this.props.circleClass}>
                {
                    this.state.isCircleLoding ?
                        <div className="user">
                            <svg x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0"
                                 style={{display: 'inline-block', height: '50px', width: '50px'}}>
                                <path fill="white"
                                      d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                    <animateTransform
                                        attributeName="transform"
                                        attributeType="XML"
                                        type="rotate"
                                        dur="1s"
                                        from="0 50 50"
                                        to="360 50 50"
                                        repeatCount="indefinite"/>
                                </path>
                            </svg>
                        </div>
                    :
                        <a
                            onContextMenu={(e) => {
                                if (this.props.userInformationFunction) {
                                    this.props.userUpvInformation(e, this.state.upvId, this.state.lavel)
                                }
                            }}

                            onClick={() => {
                                if(this.state.getNextLevelLoading === false) {
                                    this.setState({
                                        isCircleLoding: true
                                    }, () => {
                                        if (this.props.nextLavelUpvFunction) {
                                            this.props.getNextLevel(this.state.upvId, this.state.lavel)
                                        }

                                    })
                                }
                            }}
                            className="user">
                            <Icon icon="document" iconSize={18}
                                  intent={Intent.PRIMARY}/>
                            <span className="badge">{this.state.upvId}</span>
                        </a>
                }
                </li>
            )
        }
    }

}