import React from 'react';
import {Helper} from 'gg-react-utilities';
import money from "../../core/images/rewards/Group7.svg";
import ggMoney from "../../core/images/rewards/Group6.svg";
import { Auth } from 'gg-react-utilities';

export default class Balance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ggMoneyBalance:[],
            ggCoinBalance:[],
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="row mt-3 mb-5">
                    {
                        Auth.isLoggedIn() === true ?
                            <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-2">
                                <div className="moneyBalence text-right">
                                    <img className="moneyImg" src={money} alt=""/>
                                    <p className="bill-count">{this.props.ggMoneyBalance.current_balance ? Helper.formatMoney(this.props.ggMoneyBalance.current_balance) : 'Loading...'}</p>
                                    <p className="heading">GG Money - Current Balance</p>
                                </div>
                            </div>
                            :
                            <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-2">
                                {/*<div className="moneyBalence text-right bp3-skeleton" style={{width: "100%", height: "80px"}}>*/}
                                <div className="moneyBalence text-right">
                                    <div className="bp3-skeleton" style={{width: "50px", height: "50px"}}></div>
                                    <p className="bill-count bp3-skeleton bp3SkeletonSpan"></p>
                                    <p className="heading bp3-skeleton bp3SkeletonHeading"></p>
                                </div>
                            </div>
                    }

                    {
                        Auth.isLoggedIn() === true ?
                            <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-2">
                                <div className="moneyBalence text-right">
                                    <img className="moneyImg" src={ggMoney} alt=""/>
                                    <p className="bill-count">{this.props.ggCoinBalance.current_balance ? this.props.ggCoinBalance.current_balance : 'Loading...'}</p>
                                    <p className="heading"> GG Coin - Current Balance</p>
                                </div>
                            </div>
                            :
                            <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-2">
                                <div className="moneyBalence text-right">
                                    <div className="bp3-skeleton" style={{width: "50px", height: "50px"}}></div>
                                    <p className="bill-count bp3-skeleton bp3SkeletonSpan"></p>
                                    <p className="heading bp3-skeleton bp3SkeletonHeading"></p>
                                </div>
                            </div>
                    }

                    {
                        Auth.isLoggedIn() === true ?
                            <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-2">
                                <div className="moneyBalence text-right">
                                    <img className="moneyImg" src={money} alt=""/>
                                    <p className="bill-count">{this.props.total_gg_money_cashback ? Helper.formatMoney(this.props.total_gg_money_cashback) : 'Loading...'}</p>
                                    <p className="heading">Total GG Money Earned</p>
                                </div>
                            </div>
                            :
                            <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-2">
                                {/*<div className="moneyBalence text-right bp3-skeleton" style={{width: "100%", height: "80px"}}>*/}
                                <div className="moneyBalence text-right">
                                    <div className="bp3-skeleton" style={{width: "50px", height: "50px"}}></div>
                                    <p className="bill-count bp3-skeleton bp3SkeletonSpan"></p>
                                    <p className="heading bp3-skeleton bp3SkeletonHeading"></p>
                                </div>
                            </div>
                    }
                    {
                        Auth.isLoggedIn() === true ?
                            <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-2">
                                <div className="moneyBalence text-right">
                                    <img className="moneyImg" src={ggMoney} alt=""/>
                                    <p className="bill-count">{this.props.ggCoinBalance.total_gg_coin_earned ? this.props.ggCoinBalance.total_gg_coin_earned : 'Loading...'}</p>
                                    <p className="heading"> Total GG Coin Earned</p>
                                </div>
                            </div>
                            :
                            <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-2">
                                <div className="moneyBalence text-right">
                                    <div className="bp3-skeleton" style={{width: "50px", height: "50px"}}></div>
                                    <p className="bill-count bp3-skeleton bp3SkeletonSpan"></p>
                                    <p className="heading bp3-skeleton bp3SkeletonHeading"></p>
                                </div>
                            </div>
                    }
                </div>
				<hr/>
            </React.Fragment>
        );
    }
}