import React from 'react';
import PageTitle from "../../../core/components/PageTitle";
import TopBar from "../../../core/components/TopBar";
import {connect} from 'react-redux';
import {addCircle, getUPVLevels, getCircleUPVLevelList, getUserDetailByUpvId, upvReplaceRequest} from '../../../core/actions/action';
import {Button, Classes, Dialog, Icon, Intent, Position, Toaster} from "@blueprintjs/core";
import {Child} from './circle_components/child';
import {Auth} from "gg-react-utilities";
import Select from 'react-select';
import {isNumber} from "@material-ui/core/es/styles/transitions";
import Loader from "../../../core/components/Loader";

class CircleView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            upv: {},
            upvID: props.match.params.upvID,
            targetsAndAcheivements: [],
            childLists: [],
            nextChildLists: [],
            isSkeleton: true,
            maxCirclesAllowed: null,
            isLoader:false,
            completedCircles: null,
            treeViewOpen: false,
            prevReferralID: null,
            circlesData: [],
            isLoading: false,
            isVisibleAddCircleModal: false,
            prevUPVID: [],
            upvIndex: 0,
            referralID: '',
            openCashbackTree: false,
            CashbackTree: [],
            ownUpvsWithoutTag:[],
            circleUpvInformationStatus: false,
            circleUpvInformation:[],
            isReplaceCircleUpv:false,
            ReplaceCircleStatus:false,
            newChildUpvId:null
        }
        this.openCashbackTree = this.openCashbackTree.bind(this);
        this.closeCashbackTree = this.closeCashbackTree.bind(this);

    }

    componentDidMount() {
        this.checkAuth();

        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);

    }

    async checkAuth() {
        setTimeout(() => {
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn: res,
                }, () => {
                    this.getUPVLevels(this.state.upvID)
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        }, 1);
    }

    getUPVLevels(upvID) {
        this.props.getUPVLevels(upvID)
            .then((res) => {
                const sources = res.data;
                if (sources.status === true) {
                    this.setState({
                        circlesData: [sources.data],
                        isSkeleton: false,
                        targetsAndAcheivements: sources.data.targets_and_achievements,
                        maxCirclesAllowed: sources.data.max_circles_allowed,
                        childLists: sources.data.child_list,
                        ownUpvsWithoutTag:sources.data.own_upvs_without_tag,
                        prevUPVID: [upvID]
                    })
                } else {
                    Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                }
            }).catch((error) => {
            if (error.response.status == 401) {
                Toaster.create({position: Position.TOP}).show({
                    message: "Please login to continue",
                    intent: Intent.DANGER
                });
            } else {
                if (error.response) {
                    Object.keys(error.response.data.error).forEach((key) => {
                        error.response.data.error[key].forEach((error) => {
                            Toaster.create({position: Position.TOP}).show({
                                message: error,
                                intent: Intent.DANGER
                            });
                        });
                    });
                }
            }
        })
    }

    getNextLevel(upvID, index) {
        this.setState({
            isLoading: true
        })
        this.props.getUPVLevels(upvID)
            .then((res) => {
                const sources = res.data;
                if (sources.status === true) {
                    this.setState({
                        treeViewOpen: true
                    }, () => {
                        this.state.prevUPVID.splice((index + 1), ((this.state.prevUPVID.length)))
                        this.state.prevUPVID.push(upvID);
                        this.state.circlesData.splice(index, ((this.state.circlesData.length) - index))
                        let oldData = this.state.circlesData;
                        let newDataFromApi = sources.data;
                        let newData = oldData.concat(newDataFromApi);
                        this.setState({
                            circlesData: newData,
                            ownUpvsWithoutTag:sources.data.own_upvs_without_tag
                        })
                    })
                } else {
                    Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                }
            }).catch((error) => {
            if (error.response) {
                Object.keys(error.response.data.error).forEach((key) => {
                    error.response.data.error[key].forEach((error) => {
                        Toaster.create({position: Position.TOP}).show({
                            message: error,
                            intent: Intent.DANGER
                        });
                    });
                });
            }
        })
    }

    contextMenu = (e,upvId,lavel) => {
        e.preventDefault();
        this.setState({
            circleUpvInformationStatus:true,
            upvReplaceRequestStatus:false,
            circleUpvInformation:null
        })
        this.props.getUserDetailByUpvId(upvId)
            .then((res) => {
                const sources = res.data;
                if (sources.status === true) {
                    this.setState({
                        upvInformationId:upvId,
                        circleUpvInformation:sources.data,
                        newChildUpvId:null,
                        isReplaceCircleUpv: lavel === 0 ? true :false,
                        ReplaceCircleStatus:false
                    })
                } else {
                    Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                }
            }).catch((error) => {
            if (error.response.status == 401) {
                Toaster.create({position: Position.TOP}).show({
                    message: "Please login to continue",
                    intent: Intent.DANGER
                });
            } else {
                if (error.response) {
                    Object.keys(error.response.data.error).forEach((key) => {
                        error.response.data.error[key].forEach((error) => {
                            Toaster.create({position: Position.TOP}).show({
                                message: error,
                                intent: Intent.DANGER
                            });
                        });
                    });
                }
            }
        })

    }

    upvReplaceRequest = () => {
        let request = {
            "old_child_upv_id":this.state.upvInformationId,
            "new_child_upv_id":this.state.newChildUpvId
        }
        this.props.upvReplaceRequest (this.state.upvID,request)
            .then((res) => {
                const sources = res.data;
                if (sources.status === true) {
                    Toaster.create({position: Position.TOP}).show({
                        message: sources.message,
                        intent: Intent.SUCCESS
                    });
                } else {
                    Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                }
                this.setState({
                    circleUpvInformationStatus: false,
                    circleUpvInformation:null,
                    isReplaceCircleUpv:false,
                    ReplaceCircleStatus:false,
                    upvReplaceRequestStatus:false,
                    newChildUpvId:null
                })
            }).catch((error) => {
            if (error.response.status == 401) {
                Toaster.create({position: Position.TOP}).show({
                    message: "Please login to continue",
                    intent: Intent.DANGER
                });
            } else {
                if (error.response) {
                    Object.keys(error.response.data.error).forEach((key) => {
                        error.response.data.error[key].forEach((error) => {
                            Toaster.create({position: Position.TOP}).show({
                                message: error,
                                intent: Intent.DANGER
                            });
                        });
                    });
                }
            }
            this.setState({
                circleUpvInformationStatus: false,
                circleUpvInformation:null,
                isReplaceCircleUpv:false,
                ReplaceCircleStatus:false,
                upvReplaceRequestStatus:false,
                newChildUpvId:null
            })
        })

    }

    createChildItem = (upv) => {
        let childItem = [];
        this.state.childLists.map((res, i) => {
            this.state.prevUPVID.find((e) => e == res.id) !== undefined ?
                childItem.push(<li key={i} className={"active"}>
                    <a onClick={() => {
                        this.getNextLevel(res.id, 0)
                    }}
                       className="user">
                        <Icon icon="document" iconSize={18} intent={Intent.PRIMARY}/>
                        <span className="badge">{res.id}</span>
                    </a>
                </li>)
                :
                childItem.push(<li key={i}>
                    <a onClick={(e) => {
                        this.getNextLevel(res.id, 0)
                    }}
                       onContextMenu={(e) =>{this.contextMenu(e,res.id,0)}}
                       className="user">
                        <Icon icon="document" iconSize={18} intent={Intent.PRIMARY}/>
                        <span className="badge">{res.id}</span>
                    </a>
                </li>)
        })
        let childLength = this.state.childLists.length;
        for (let i = 1; i <= (this.state.maxCirclesAllowed - childLength); i++) {
            childItem.push(<li>
                <a onClick={() => {
                    let title = "Add Circle in UPV Number : " + upv;
                    this.setState({
                        title: title,
                        addUpvID: upv,
                        upvIndex: (0),
                        isLoading:false
                    },()=>{
                        this.openAddCircleModal();
                    })
                }}
                   className="user font-20 add-child">
                    <Icon icon="plus" iconSize={18} intent={Intent.PRIMARY}/>
                </a>
            </li>)
        }
        return childItem;
    }

    addCircle(upvID, Index) {

        if (this.state.hasReferralIDError || this.state.referralID === '') {
            Toaster.create({position: Position.TOP}).show({
                message: "Please enter referral UPV ID.",
                intent: Intent.DANGER
            });
        } else {
            this.setState({
                isLoading: true
            });
            let obj = {
                "referrer_upv_id": upvID
            }
            this.props.addCircle(this.state.referralID, obj)
                .then((res) => {
                    const sources = res.data;
                    if (sources.status === true) {
                        Toaster.create({position: Position.TOP}).show({
                            message: sources.message,
                            intent: Intent.SUCCESS
                        });
                        this.setState({
                            isLoading:false,
                            counter: 1,
                            data: [],
                            isSkeleton: true,
                            total: 0.00,
                            last_page: 1,
                            referralID:'',
                            ownUpvsWithoutTag: this.state.ownUpvsWithoutTag.filter(item => item != this.state.referralID)
                        }, () => {
                            if(Index > 0) {
                                this.state.circlesData[Index - 1].child_list.push(sources.data);
                            }else{
                                this.state.circlesData[Index].child_list.push(sources.data);
                            }
                            this.getNextLevel(this.state.referralID, (Index))
                            this.closeAddCircleModal();
                        })
                    } else {
                        Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                    }

                    this.closeAddCircleModal();

                }).catch((error) => {
                    this.setState({
                        referralID: '',
                        isLoading:false
                    })
                    this.closeAddCircleModal();
                    if (error.response) {

                        Object.keys(error.response.data.error).forEach((key) => {
                            error.response.data.error[key].forEach((error) => {
                                Toaster.create({position: Position.TOP}).show({
                                    message: error,
                                    intent: Intent.DANGER
                                });
                            });
                        });

                    } else {

                        Toaster.create({position: Position.TOP}).show({
                            message: "Something went wrong.",
                            intent: Intent.DANGER
                        });
                    }
            })
        }
    }

    closeAddCircleModal() {
        this.setState({
            isVisibleAddCircleModal: false
        })
    }

    closeCircleUpvInformationModal() {
        this.setState({
            circleUpvInformationStatus: false
        })
    }

    openAddCircleModal() {
        if(this.state.ownUpvsWithoutTag && this.state.ownUpvsWithoutTag.length) {
            this.setState({
                isVisibleAddCircleModal: true
            })
        }else{
            Toaster.create({position: Position.TOP}).show({
                message: "Create your circle UPV to add here.",
                intent: Intent.WARNING
            });
        }
    }

    openCashbackTree() {
        this.props.getCircleUPVLevelList()
            .then((res) => {
                const sources = res.data;
                if (sources.status === true) {
                    this.setState({
                        CashbackTree: sources.data,
                    })
                } else {
                    Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                }
            }).catch((error) => {
            if (error.response.status == 401) {
                Toaster.create({position: Position.TOP}).show({
                    message: "Please login to continue",
                    intent: Intent.DANGER
                });
            } else {
                if (error.response) {
                    Object.keys(error.response.data.error).forEach((key) => {
                        error.response.data.error[key].forEach((error) => {
                            Toaster.create({position: Position.TOP}).show({
                                message: error,
                                intent: Intent.DANGER
                            });
                        });
                    });
                }
            }
        })
        this.setState({
            openCashbackTree: true
        });
    }

    closeCashbackTree() {
        this.setState({
            openCashbackTree: false
        });
    }

    render() {
        const upv = this.props.match.params.upvID;
        const ownUpvsWithoutTag = []
        this.state.ownUpvsWithoutTag.map((data,key)=>{
            ownUpvsWithoutTag.push({value:data,label: 'Referral UPV ID : ' + data});
        })

        return (
            <div className="container mb-5">
                <PageTitle title="UPV Exchange Info - Global Garner"/>
                <div className="exchangeWrapper">
                    <div className="exchangeTopBar">
                        <TopBar
                            title={"Circle Number : " + upv}
                            rightBar={upv}
                            openCashbackTree={()=>{this.openCashbackTree()}}
                        />
                    </div>
                    <div className="exchangeInfoContainer text-left">
                        <div className="container py-5" style={{background: '#F8FAFF'}}>
                            <ul className="progressbar">
                                {this.state.targetsAndAcheivements.length === 0 ?
                                    <React.Fragment>
                                        <li className={this.state.isSkeleton ? "bp3-skeleton" : ""}>6
                                            upv <strong>&#8377; 500</strong></li>
                                        <li className={this.state.isSkeleton ? "bp3-skeleton" : ""}>36
                                            upv <strong>&#8377; 1000</strong></li>
                                        <li className={this.state.isSkeleton ? "bp3-skeleton" : ""}>216
                                            upv <strong>&#8377; 3500</strong></li>
                                        <li className={this.state.isSkeleton ? "bp3-skeleton" : ""}>1296
                                            upv <strong>&#8377; 5000</strong></li>
                                        <li className={this.state.isSkeleton ? "bp3-skeleton" : ""}>7776
                                            upv <strong>&#8377; 18000</strong></li>
                                        <li className={this.state.isSkeleton ? "bp3-skeleton" : ""}>46656
                                            upv <strong>&#8377; 50,000</strong></li>
                                        <li className={this.state.isSkeleton ? "bp3-skeleton" : ""}>279936
                                            upv <strong>&#8377; 1,50,000</strong></li>
                                        <li className={this.state.isSkeleton ? "bp3-skeleton" : ""}>1679616
                                            upv <strong>&#8377; 6,72,000</strong></li>
                                        <li className={this.state.isSkeleton ? "bp3-skeleton" : ""}>0077696
                                            upv <strong>&#8377; 21,00,000</strong></li>
                                    </React.Fragment>
                                    :
                                    this.state.targetsAndAcheivements.map((res, i) => {
                                        return (
                                            <li className={res.is_target_achieved ? "active" : null}
                                                key={i}>{res.target} upv <strong>&#8377; {res.achievement}</strong></li>
                                        )
                                    })
                                }

                            </ul>
                        </div>
                        {
                            this.state.childLists && this.state.childLists.length ?
                                <div className="container py-5 py-s-4">
                                    {/*<Icon icon="document" iconSize={18} intent={Intent.PRIMARY} />*/}

                                    <div className="tree">
                                        <div className="pg-orgchart">
                                            <div className="org-chart">
                                                <ul>
                                                    <li>
                                                        <div className="user">
                                                            <Icon icon="document" iconSize={18}
                                                                  intent={Intent.PRIMARY}/>
                                                            <span className="badge">{upv}</span>
                                                        </div>
                                                        <ul>
                                                            <li>
                                                                {this.createChildItem(upv)}
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <Child addCircle={(upvID, index) => {
                                                    let title = "Add Circle in UPV Number : " + upvID;
                                                    this.setState({
                                                        title: title,
                                                        addUpvID: upvID,
                                                        upvIndex: index,
                                                        isLoading:false,
                                                    })
                                                    this.openAddCircleModal();
                                                }}
                                               contextMenu = { (e,upvId,lavel) => { this.contextMenu(e,upvId,lavel)}}
                                               getNextLevel={(upv_id, index) => {
                                                   this.getNextLevel(upv_id, index)
                                               }}
                                               circlesData={this.state.circlesData}
                                               prevUPVID={this.state.prevUPVID}
                                               treeViewOpen={this.state.treeViewOpen}/>
                                    </div>
                                </div>
                                : ''
                        }
                    </div>
                </div>
                <Dialog isOpen={this.state.isVisibleAddCircleModal}
                        title={this.state.title}
                        className="bidModal"
                        style={{marginTop: '250px'}}
                        onClose={this.closeAddCircleModal.bind(this)}>
                    <div className="bidForm m-3 text-center">
                        <div className="row">
                            <React.Fragment>
                                <div className="col-12">
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={this.state.referralID}
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={false}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="upv"
                                        options={ownUpvsWithoutTag}
                                        onChange={(referralID) => {
                                            if(referralID !== '') {
                                                this.setState({
                                                    referralID: referralID.value,
                                                    hasReferralIDError: false
                                                })
                                            }else{
                                                this.setState({
                                                    referralID: ''
                                                })
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-12">
                                    {
                                        this.state.isLoading ?
                                            <div className="bidFormBtn mt-2 mb-2">
                                                <img src={require('../../../core/images/loader.gif')} alt={""}
                                                     style={{width: '50px', height: '50px'}}/>
                                            </div>
                                            :
                                            <div className="bidFormBtn mt-4 mb-4">
                                                <Button
                                                    text={"Cancel"}
                                                    intent={"default"}
                                                    onClick={() => {
                                                        this.setState({
                                                            isVisibleAddCircleModal: false
                                                        })
                                                    }}
                                                />
                                                < Button
                                                    text={"Submit"}
                                                    intent={"primary"}
                                                    onClick={() => {
                                                        this.addCircle(this.state.addUpvID, this.state.upvIndex)
                                                    }
                                                    }
                                                />
                                            </div>
                                    }
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                </Dialog>
                <Dialog isOpen={this.state.circleUpvInformationStatus}
                        title={"UPV No. " + this.state.upvInformationId}
                        className="bp3-dialog-large CircleUpvInformationModal"
                        onClose={this.closeCircleUpvInformationModal.bind(this)}
                >
                    <div className={Classes.DIALOG_BODY+ ' bidForm text-center mb-1'}>
                        <div className="row">
                            {
                                this.state.circleUpvInformation ?
                                    <React.Fragment>
                                        <div className="col-12 text-center">
                                            <img
                                                src={this.state.circleUpvInformation.avatar_url}
                                                alt=""
                                                className="img-fluid img-thumbnail"
                                            />
                                            <h3>{this.state.circleUpvInformation.username}</h3>
                                            <h4>{this.state.circleUpvInformation.email}</h4>
                                            <h4>{this.state.circleUpvInformation.mobile}</h4>
                                        </div>

                                        <div className="col-12 border-top mt-2 px-0">
                                            <div className="d-sm-flex align-items-center justify-content-sm-between">
                                                <p>Generated on: {this.state.circleUpvInformation.created_at}</p>
                                                <p>Added in Circle: {this.state.circleUpvInformation.tagging_date}</p>
                                            </div>
                                        </div>
                                        {
                                            this.state.isReplaceCircleUpv && this.state.ReplaceCircleStatus === false ?
                                                <div className="col-12 border-top mt-2 px-0">
                                                    <div className="mt-4 mb-4">
                                                        <center>
                                                            <Button
                                                                className="bp3-button ml-3"
                                                                text="Replace UPV"
                                                                intent={Intent.PRIMARY}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        ReplaceCircleStatus: !this.state.ReplaceCircleStatus
                                                                    })
                                                                }}
                                                            />
                                                        </center>
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                    </React.Fragment>
                                :
                                    <div className="col-12 text-center">
                                        <Loader/>
                                    </div>
                            }
                        </div>
                    </div>
                    {
                        this.state.ReplaceCircleStatus === true ?
                            <div className={Classes.DIALOG_FOOTER} >
                                <h3>Replace UPV</h3>
                                <h5>By this option, you can replace your non performing child UPV</h5>
                                <div className="bp3-input-group">
                                    <input
                                        type="number"
                                        className="bp3-input"
                                        required
                                        min={0}
                                        onChange={(e)=>{
                                            if(isNumber(e.target.value)) {
                                                this.setState({
                                                    newChildUpvId: e.target.value
                                                })
                                            }
                                        }}
                                    />
                                    <label className="form-control-placeholder">New child UPV No.</label>
                                </div>

                                <div className="mt-5 d-flex justify-content-end">
                                {
                                    this.state.upvReplaceRequestStatus ?
                                        <div className="bidFormBtn mt-2 mb-2">
                                            <img src={require('../../../core/images/loader.gif')} alt={""}
                                                 style={{width: '50px', height: '50px'}}/>
                                        </div>
                                    :
                                        <React.Fragment>
                                            <Button
                                                className="bp3-button bp3-outline bg-white"
                                                text="Cancel"
                                                onClick={()=>{
                                                    this.setState({
                                                        ReplaceCircleStatus:!this.state.ReplaceCircleStatus
                                                    })
                                                }}
                                            />
                                            <Button
                                                className="bp3-button ml-3"
                                                text="Replace"
                                                intent={Intent.PRIMARY}
                                                onClick={()=>{
                                                    this.setState({
                                                        upvReplaceRequestStatus:true
                                                    },()=>{
                                                        this.upvReplaceRequest();
                                                    })

                                                }}
                                            />
                                        </React.Fragment>
                                }
                                </div>
                            </div>
                        :''
                    }
                </Dialog>

                <Dialog
                    isOpen={this.state.openCashbackTree}
                    onClose={this.closeCashbackTree}
                    title="Cashback tree"
                    className="bp3-dialog-large CashbackTree">
                    <div className={Classes.DIALOG_BODY+ ' mt-0'}>
                        <ul className="timeline">
                            {
                                this.state.CashbackTree ?
                                    this.state.CashbackTree.map((data,key)=>{
                                        return(
                                            <li key={key}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h4>{data.level_title}</h4>
                                                    <h4 className="float-right">{data.amount}</h4>
                                                </div>
                                                <p>{data.message}</p>
                                            </li>
                                        )
                                    })
                                :''
                            }
                        </ul>
                    </div>
                </Dialog>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getUPVLevels: (upvID) => {
            return getUPVLevels(upvID)
        },
        addCircle: (upvID, obj) => {
            return addCircle(upvID, obj)
        },
        getCircleUPVLevelList: () => {
            return getCircleUPVLevelList()
        },
        getUserDetailByUpvId : (upvId) => {
            return getUserDetailByUpvId(upvId)
        },
        upvReplaceRequest : (upvId,request) => {
            return upvReplaceRequest(upvId,request)
        }
    }
}

export default connect(null, mapDispatchToProps)(CircleView)