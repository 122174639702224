import {connect} from 'react-redux';
import {getAquashInquiryList} from '../../../core/actions/action';
import AquashInquiriesIndex from '../components/AquashInquiriesIndex';

const mapDispatchToProps = dispatch => {

    return {
        getAquashInquiryList:() => {
            return getAquashInquiryList();
        },
    }
}

export default connect(null, mapDispatchToProps)(AquashInquiriesIndex);
