import {GET_ADDRESS_LIST} from "../../Address/actions/index";

let initialState = {
    address:{}
};

const address = (state = initialState, action) => {

    switch (action.type) {

        case GET_ADDRESS_LIST:
            return {...state, address: action.sources}
        default:
            return state
    }
}

export default address;
