import React, { Component } from 'react';
import {RadioGroup, Radio} from "@blueprintjs/core";
import HrInquiry from "./container/HrInquiriesContainer.js";
import ItInquiry from "./container/ItInquiriesContainer";
import InsuranceInquiriesContainer from "./container/InsuranceInquiriesContainer";
import GrangeInquiryContainer from "./container/GrangeContainer"
import AquashInquiryContainer from "./container/AquashContainer"
import LoanInquiriesContainer from "./container/LoanInquiriesContainer";
import VehicleInquiriesContainer from "./container/VehicleInquiriesContainer";
import WebInquiryContainer from "./container/WebInquiriesContainer";
import Hotel from "../Travel/Components/Hotel";

export default class Inquiry extends Component{
    
    constructor(props){
        super(props);
        this.state={
            selectedOption:1
        };
    }

    changeOrders(e){
        this.setState({selectedOption:Number(e.target.value)})
    }
    componentDidMount(){
        if(this.props.location.hash.split("#")[2] !=undefined){
      this.setState({selectedOption:Number(this.props.location.hash.split("#")[2])})}else{
          this.setState({selectedOption:1})
      }
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.location.hash.split("#")[2] !=undefined){
        this.setState({selectedOption:Number(nextProps.location.hash.split("#")[2])})}else{
            this.setState({selectedOption:1})
        }
    }
    render(){
        return(
            <React.Fragment>
                <div className="megabrand">
                    <div className="megabrandOption">
                        <RadioGroup
                            onChange={(e) => this.changeOrders(e)}
                            name="type"
                            className="d-inline option-type col-12"
                            selectedValue={this.state.selectedOption}>
                            <Radio label="HR " value={1} />
                            <Radio label="IT Company " value={2} />
                            <Radio label="Insurance " value={3} />
                            <Radio label="Vehicle " value={4} />
                            <Radio label="Web " value={5} />
                            <Radio label="Loan " value={6} />
                             <Radio label="Aquash " value={7} />
                            <Radio label="Grange " value={8} />
                            {/*<Radio label="Hotel" value={4} />*/}
                        </RadioGroup>
                    </div>
                    {
                        this.state.selectedOption===1 ?
                            <HrInquiry/>
                            :
                            this.state.selectedOption===2 ?
                                <ItInquiry/>
                                :
                                this.state.selectedOption===3 ?
                                    <InsuranceInquiriesContainer/>
                                    :
                                    this.state.selectedOption===4 ?
                                        <VehicleInquiriesContainer />
                                        :
                                        this.state.selectedOption===5 ?
                                            <WebInquiryContainer />
                                            : 
                                            this.state.selectedOption===6 ?
                                                <LoanInquiriesContainer />
                                                  :   this.state.selectedOption===7 ?
                                                  <AquashInquiryContainer />
                                                   :  this.state.selectedOption===8 ?
                                                   <GrangeInquiryContainer />
                                                    :""                                   
                    }

                </div>
            </React.Fragment>
        );
    }
}