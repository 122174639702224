import {connect} from 'react-redux';
import {getGGVoucherOrderList} from '../../../core/actions/action';
import GGVoucherIndex from '../components/GGVoucherIndex';

const mapStateToProps = state => {

    return{
        GGVoucherOrderList:state.GGVoucherOrders.GGVoucherOrderList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getGGVoucherOrderList:(params) => {
            return getGGVoucherOrderList(params);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GGVoucherIndex);
