import React, { Component } from 'react';
import { FavoriteRounded} from '@material-ui/icons';
import {Intent, Position, Toaster} from "@blueprintjs/core";
import NobrandfoundEmptyState from "../../../core/images/svg/Nobrandfound.svg";
import Loding from './Loding';
import Config from '../../../Config';
import {Auth} from "gg-react-utilities";
import _ from 'lodash';

export default class BswFavourite extends Component{
    constructor(props){
        super(props)

        this.state = {
            favouriteList:[],
            page:1,
            stopLoadMore: false,
            showEmptyStats:false,
            loadingData : true,
            isLoggedIn:false,
        }
    }

    componentDidMount() {
        this.checkAuth();

        this.setState( {
            loading: this.props.loading,
        })
        let params={
            page:this.state.page,
        }
        this.getFavouriteList(params)
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1000);
    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    getFavouriteList=(params)=>{
        this.props.getBswFavouriteList(params)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    let oldData = this.state.favouriteList;
                    let newDataFromApi = sources.data.data;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState( {
                        favouriteList:newData,
                    });

                    if(sources.data.data === ""){
                        this.setState( {
                            showEmptyStats:true,
                        });
                    }

                    if(sources.data.current_page === sources.data.last_page){
                        this.setState( {
                            stopLoadMore: true,
                        })
                    }else{
                        this.setState( {
                            stopLoadMore: false,
                        })
                    }
                }else{

                }
            }).catch((error)=>{
            const sources = error.response;
        })
    }

    NextPage= () =>{
        let oldPageNo = this.state.page;
        this.setState({
            page: oldPageNo + 1
        },()=>{
            let params={
                page:this.state.page,
            }
            this.getFavouriteList(params)
        })

    }

    removeFromFavorite = (affiliateId) => {
        this.props.removeBswFavourite(affiliateId)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    this.setState( {
                        favouriteList:[],
                        loading:true,
                    });
                    let params={
                        page:1,
                    }
                    this.getFavouriteList(params)
                    this.stopLoading();
                    Toaster.create({position: Position.TOP}).show({message:sources.message ,intent: Intent.SUCCESS});
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.message ,intent: Intent.DANGER});
                }
            }).catch((error)=>{
            const sources = error.response;
        })
    }

    stopLoading = () => {
        setTimeout(() => {
            this.setState({
                loading: false,
            });
        }, 1000);
    }

    render() {
        /*if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }*/

        let counter = 0;
        return (
            <React.Fragment>
                <div className="col-12 pb-5 brand-search p-0 px-s-3">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-s-0 p-0">
                        <div className="row show-brands">
                            {
								this.state.loading ?
                                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 my-4">
                                        <div className={"brandsDiv"}>
                                            <a href="#" rel="noopener noreferrer">
                                                <div className="brandsImg bp3-skeleton">
                                                    <img alt="name" src={Config.DEFAULT_IMAGE_URL} className="img-fluid" />
                                                </div>
                                                <div className="px-2">
                                                    <h4 className="name bp3-skeleton">Name of Affiliate</h4>
                                                    <div className="visit-website bp3-skeleton">
                                                        Visit Website
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
								:
								this.state.favouriteList.length ?
								this.state.favouriteList.map((affiliate, key) => {
									counter++;
									if(counter > 4) counter =1;

                                    let splitColorCode = _.split(affiliate.color_code, ',');
                                    let backgroundColorCode = {backgroundImage: `linear-gradient(135deg, ${splitColorCode[0]} 0%, ${splitColorCode[1]} 100%)`};

                                    return<div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 my-4" key={key}>
										<div className={"brandsDiv"} key={key} style={backgroundColorCode}>
											<FavoriteRounded style={{'color':'#FFF'}} className="favorite float-right heart" onClick={() => this.removeFromFavorite(affiliate.affiliate_id)}/>
											<a href={Config.BSW_FAV + 'brands/'+ affiliate.slug + "/"+ counter} rel="noopener noreferrer" target="_blank">
												<div className="brandsImg">
													<img alt={affiliate.name} src={affiliate.logo} className="img-fluid" />
												</div>
												<h4 className="name">{affiliate.name}</h4>
												<div className="visit-website">
													Visit Website
												</div>
											</a>
										</div>
									</div>
								})
								:
								<div className="col-12 my-4" >
									<center>
										<img src={NobrandfoundEmptyState} alt=''/>
										<p className='mt-3 mb-2'>No favourite Brand found!</p>
									</center>
								</div>
                            }
                        </div>
                    </div>
                    {
                        this.state.loading ?
                            ""
                            :
                            <Loding
                                favouriteList={this.state.favouriteList}
                                NextPage={() => this.NextPage()}
                                stopLoadMore={this.state.stopLoadMore}/>
                    }
                </div>
            </React.Fragment>
        )
    }
}
