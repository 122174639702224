import React from 'react';
import {Button, FormGroup, Intent, Position, Toaster} from "@blueprintjs/core";
import {getConversionInvoice} from "../../../core/actions/action";
import {connect} from "react-redux";
import InsertLoader from '../../../core/images/loader.gif';

class ConversionListCard extends React.Component{
    constructor(props){
        super(props);
        this.state= {
            sort:"",
            isSkeleton:true,
            scrollLoader:true,
            downloadLoader:false,
            counter:1,
            upvConversionList:[],
            oldUpvType:'',
            newUpvType:''
        }
    }

    componentDidMount() {

    }

    downloadInvoice(upvId, paymentId){
        this.props.getConversionInvoice(upvId, paymentId)
            .then((response) => {

                const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "UPV-Conversion-Invoice.pdf");
                document.body.appendChild(link);
                link.click();
                this.setState({
                    downloadLoader:false
                })
            })
            .catch((error) =>{
                Toaster.create({position: Position.TOP}).show({message:"Something went wrong",intent: Intent.DANGER});
                this.setState({
                    downloadLoader:false
                })
            })
    }

    render() {
            return (
                <React.Fragment>
                    {
                        this.props.listData ?
                            <div className={"col-12 col-lg-6 col-md-6 col-sm-6 col-xs-12"} key={this.props.key}>
                                <div className={"bidBlock p-3 mt-3 shadowBlock"}>
                                    <div className="row">
                                        <div className="col-12 col-lg-2 col-md-2 col-sm-2">
                                            <div className="upvRight mb-2">
                                                <h5 className={"m-0"}>UPV No</h5>
                                                <h4 className={"m-0"}>{this.props.listData.upv_id}</h4>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-10 col-md-10 col-sm-10">
                                            <div className="upvLeft text-left">
                                                <div className={"topbar"}>
                                                    <h4 className="m-0 nameText">{this.props.listData.old_upv_type} to {this.props.listData.new_upv_type}</h4>
                                                    <h4 className="m-0 f-5 priceText"
                                                        style={{fontWeight: '500'}}>{this.props.listData.amount != 0 ? "₹ " + this.props.listData.amount : "Free"}</h4>
                                                </div>
                                                <h4 className="m-0 timeText">Conversion Date
                                                    : {this.props.listData.formatted_txn_datetime}</h4>
                                                <div className={"topbar"}>
                                                    <h5 className="m-0 timeText">Generated Date
                                                        :{this.props.listData.formatted_created_at}</h5>
                                                    <h4 className="m-0 f-5">
                                                        {
                                                            !this.state.downloadLoader ?
                                                                <Button
                                                                    className="bp3-small bp3-text-small bp3-intent-primary"
                                                                    text={"Download Invoice"}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            downloadLoader: true
                                                                        })
                                                                        this.downloadInvoice(this.props.listData.upv_id, this.props.listData.payment_id)
                                                                    }}
                                                                />
                                                                :
                                                                <img src={InsertLoader} alt={""}
                                                                     style={{width: '50px', height: '23px'}}/>
                                                        }
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :''
                    }
                     </React.Fragment>
            )
    }
}


const mapDispatchToProps = dispatch => {

    return {
        getConversionInvoice:(upvId,paymentId) => {
            return getConversionInvoice(upvId,paymentId)
        }
    }
}

export default connect(null, mapDispatchToProps)(ConversionListCard);

