import React from 'react';
import {Button, Classes, Dialog} from "@blueprintjs/core";

export default class OrderHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            userBill: ""
        };
        this.handleClose = this.handleClose.bind(this)
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        //this.setState({ userBill: nextProps.viewUserBill });
        console.log(this.props.viewGrangeOrderDetails)
    }


    handleClose() {
        this.setState({isOpen: false});
    }

    renderStatusHtml(status) {

        switch (status) {
            case 'pending':
                return <label className="label text-warning">Pending</label>;
            case 'pending_payment':
                return <label className="badge text-warning">Pending Payment</label>;
            case 'processing':
                return <label className="badge text-success">Processing</label>;
            case 'shipped':
                return <label className="badge text-info">Shipped</label>;
            case 'ready_for_pickup':
                return <label className="badge text-info">Ready for Pickup</label>;
            case 'delivered':
                return <label className="badge text-success">Delivered</label>;
            case 'completed':
                return <label className="badge text-success">Completed</label>;
            case 'closed':
                return <label className="badge text-success">Closed</label>;
            case 'canceled':
                return <label className="badge text-danger">Cancelled</label>;
            case 'fraud':
                return <label className="badge text-danger">Declined</label>;
            default:
                return <label className="label text-default">--</label>;
        }
    }

    renderUPVStatusHtml(status) {

        switch (status) {
            case '0':
                return <label className="label text-warning">Pending</label>;
            case '1':
                return <label className="badge text-success">Sent</label>;
            case '2':
                return <label className="badge text-danger">not applicable</label>;
            default:
                return <label className="label text-default">--</label>;
        }
    }

    cbType(type){

        switch (type) {
            case 1:
                return "FIFO";
            case 2:
                return "RPP";
            case 3:
                return "ICB";
            case 4:
                return "CIRCLE";
            default:
                return "-";
        }
    }


    render() {
        return (
            <React.Fragment>
                <Button small={true} intent="primary" title="View" icon="eye-open" className="btn-view mr-2"
                        onClick={() => {
                            this.setState({isOpen: true})
                            this.props.getGrangeOrderDetails(this.props.orderid)
                        }}/>
                <Dialog isOpen={this.state.isOpen} onClose={() => this.handleClose()} className="bp3-dialog-large store-order"
                        title={"Order Detail " + this.props.displayOrderId}>
                    {this.props.viewGrangeOrderDetails ?

                        <div className={Classes.DIALOG_BODY}>
                            <p><b>Product List</b></p>
                            <div style={{maxHeight:'219px',overflowY: 'auto',overflowX: 'hidden'}}>
                            {
                                this.props.viewGrangeOrderDetails.items && this.props.viewGrangeOrderDetails.items.map((order, key) => {
                                    return(
                                        <div className="row"  key={key}>
                                            <div className="col-md-2 col-sm-2 col-12 orderImg text-center-xs">
                                                {
                                                    order.product.images.length !== 0 ?
                                                        <img src={order.product.images[0].url}
                                                             alt="product" className="img-fluid"/>
                                                    :
                                                        <img src={order.product.base_image.small_image_url}
                                                             alt="product" className="img-fluid"/>
                                                }
                                            </div>
                                            <div className="col-md-10 col-sm-10 col-12 storeOrderDetails mt-s-4">
                                                <div className="row">
                                                    <div className="col-md-8 col-sm-8 col-8">
                                                        <p className="heading">Product Name</p>
                                                        <p className="headingTitle">{order.name}</p>
                                                    </div>
                                                    <div className="col-md-4 col-sm-4 col-4">
                                                        <p className="heading">Price</p>
                                                        <p className="headingTitle">{order.formated_total}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-8 col-sm-8 col-8">
                                                        <p className="heading">Quantity</p>
                                                        <p className="headingTitle">{order.qty_ordered}</p>
                                                    </div>
                                                    <div className="col-md-4 col-sm-4 col-4">
                                                        <p className="heading">Total</p>
                                                        <p className="headingTitle">{order.formated_total}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                        </div>
                                    );
                                })
                            }
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3 col-sm-3 col-3">
                                    <p className="heading">Platform</p>
                                    <p className="headingTitle">{this.props.viewGrangeOrderDetails.platform && this.props.viewGrangeOrderDetails.platform}</p>
                                </div>
                                <div className="col-md-3 col-sm-3 col-3">
                                    <p className="heading">Cashback Type</p>
                                    <p className="headingTitle">
                                        {
                                            this.cbType(this.props.viewGrangeOrderDetails.caseback_choice)
                                        }
                                    </p>
                                </div>
                                <div className="col-md-3 col-sm-3 col-3">
                                    <p className="heading">Order Status</p>
                                    <p className="headingTitle text-nowrap">
                                        {
                                            this.renderStatusHtml(this.props.viewGrangeOrderDetails.status)
                                        }
                                    </p>
                                </div>
                                <div className="col-md-3 col-sm-3 col-3">
                                    <p className="heading">UPV Status</p>
                                    <p className="headingTitle text-nowrap">
                                        {
                                            this.renderUPVStatusHtml(this.props.viewGrangeOrderDetails.upv_status)
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-2 mb-4">
                                <div className="col-md-12 col-12">
                                    {this.props.viewGrangeOrderDetails.shipping_address &&
                                    <div className="OrderShippingDetails">
                                        <p className="userInfo"><b>{this.props.viewGrangeOrderDetails.shipping_address.first_name} {this.props.viewGrangeOrderDetails.shipping_address.last_name}</b></p>
                                        <p className="">{this.props.viewGrangeOrderDetails.phone}</p>
                                        <p className="">{this.props.viewGrangeOrderDetails.shipping_address.address1[0]}</p>
                                        <p>{this.props.viewGrangeOrderDetails.shipping_address.city}</p>
                                        <p>{this.props.viewGrangeOrderDetails.shipping_address.state}, {this.props.viewGrangeOrderDetails.shipping_address.country}, {this.props.viewGrangeOrderDetails.shipping_address.postcode}</p>
                                    </div>}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12 col-12 orderTotal">
                                    <p><b>Payment summary</b></p>
                                        <div className="table-items">
                                            <div className="row tr-item">
                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <p className="d1 totalTitle">
                                                        Subtotal
                                                    </p>
                                                </div>
                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-2 px-s-0">
                                                    <div className="d2">&nbsp;</div>
                                                </div>
                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                    <p className="d3 t-sub-title">
                                                        {this.props.viewGrangeOrderDetails.formated_sub_total}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    <div className="table-items">
                                        <div className="row tr-item">
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                                                <p className="d1 totalTitle">
                                                    Shipping Charge
                                                </p>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-2 px-s-0">
                                                <div className="d2">&nbsp;</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <p className="d3 t-sub-title">
                                                    {this.props.viewGrangeOrderDetails.formated_shipping_amount}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-items">
                                        <div className="row tr-item">
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                                                <p className="d1 totalTitle">
                                                    Convenience Charge {this.props.viewGrangeOrderDetails.tax_percent}%
                                                </p>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-2 px-s-0">
                                                <div className="d2">&nbsp;</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <p className="d3 t-sub-title">
                                                    {this.props.viewGrangeOrderDetails.formated_tax_amount}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-items">
                                        <div className="row tr-item">
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                                                <p className="d1 totalTitle">
                                                    Grand Total
                                                </p>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-2 px-s-0">
                                                <div className="d2">&nbsp;</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <p className="d3 t-sub-title">
                                                    {this.props.viewGrangeOrderDetails.formated_grand_total}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-items">
                                        <div className="row tr-item">
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                                                <p className="d1 totalTitle">
                                                    GG Voucher Amount
                                                </p>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-2 px-s-0">
                                                <div className="d2">&nbsp;</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <p className="d3 t-sub-title">
                                                    {this.props.viewGrangeOrderDetails.formated_used_gg_pay_voucher_amount}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-items">
                                        <hr/>
                                        <div className="row tr-item">
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                                                <div className="d1 totalTitle">
                                                    Payable Total
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <div className="d3 t-sub-title">
                                                    {this.props.viewGrangeOrderDetails.formated_payable_total}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.props.viewGrangeOrderDetails.sub_total_refunded > 0 ?
                                            <div className="table-items">
                                                <div className="row tr-item">
                                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                                                        <p className="d1 totalTitle">
                                                            Total Refunded
                                                        </p>
                                                    </div>
                                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-2 px-s-0">
                                                        <div className="d2">&nbsp;</div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                        <p className="d3 t-sub-title">
                                                            {this.props.viewGrangeOrderDetails.formated_grand_total_refunded}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        :''
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className={Classes.DIALOG_BODY}>
                            <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}></div>
                        </div>
                    }
                </Dialog>
            </React.Fragment>
        );
    }
}