import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import upvReplaceSent from '../components/upvReplaceSent';
import {getUpvReplaceSentList, upvReplaceSentRequestCancel} from '../../../core/actions/action';
import { getUpvReplaceSentListAction } from '../../../core/actions';

const mapStateToProps = state => {

    return{
        upvReplaceSentList:state.cashbackReducer.upvReplaceSentList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getUpvReplaceSentList :(page) => {
            return getUpvReplaceSentList(page)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(getUpvReplaceSentListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(getUpvReplaceSentListAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
        upvReplaceSentRequestCancel:(upvID,obj) => {
            return upvReplaceSentRequestCancel(upvID,obj)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(upvReplaceSent);
