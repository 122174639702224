import {connect} from 'react-redux';

import TransactionList from '../components/transactionList';
import {transactionList} from '../../../core/actions/action';


const mapDispatchToProps = dispatch => {

    return {
        transactionList :(page) => {
            return transactionList(page)
        }
    }
}

export default connect(null, mapDispatchToProps)(TransactionList);
