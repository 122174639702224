import React from 'react';
import UserLink from './Components/User';
import Social from './Components/Social';
import UserList from './Components/UserList';
import {Auth, NoLogin} from 'gg-react-utilities';
import Advertise from "../UserReferrals/Container/Advertise";

export default class VendorRef extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            usersList:[],
            start:0,
            stopLoadMore: false,
            loadingData : true,
            isLoggedIn:false,
            loader:false
        }
    }

    async componentDidMount() {
        let params={
            start:this.state.start,
        }
        this.getVendorUsreRefList(params)

        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);

        let isLoggedIn=await Auth.isLoggedIn();
        this.setState({
            isLoggedIn:isLoggedIn
        })
    }

    getVendorUsreRefList=(params)=>{
        this.props.getRefVendorUserList(params)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    let oldAffData = this.state.usersList;
                    let newAffDataFromApi = sources.data.data;
                    let newAffData = oldAffData.concat(newAffDataFromApi);
                    this.setState( {
                        usersList:newAffData,
                        loader:false
                    });

                    if(newAffData.length >= sources.data.totalRecords){
                        this.setState( {
                            stopLoadMore: true,
                        })
                    }else{
                        this.setState( {
                            stopLoadMore: false,
                        })
                    }
                }else{

                }
            }).catch((error)=>{
            const sources = error.response;
        })
    }

    NextPage= () =>{
        let oldPageNo = this.state.start;
        this.setState({
            start: oldPageNo + 15,
            loader:true
        },()=>{
            let params={
                start:this.state.start
            }
            this.getVendorUsreRefList(params)
        })

    }

    render(){
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container">
                    <div className="top-bar"></div>
                    <NoLogin/>
                </div>
            );

        }

        let referralLink ='';
        if (Auth.isLoggedIn()){
            referralLink = "https://vendor.globalgarner.com/vendor-register?referrer_id="+ Auth.user().user_id+"&user_type=user";
        }

        return(
            <React.Fragment>
                <div className="my-5 myStats">
                    <UserLink referralLink={referralLink} loading={this.state.loadingData}/>
                </div>

                <div className="my-5 myStats">
                    <Social referralLink={referralLink} loading={this.state.loadingData}/>
                </div>

                <div className="my-5 myStats">
                    <Advertise referralLink={this.state.referralLink} loading={this.state.loading} value={2}/>
                </div>

                <div className="my-5 myStats">
                    <UserList
                        usersList={this.state.usersList}
                        NextPage={() => this.NextPage()}
                        stopLoadMore={this.state.stopLoadMore}
                        loading={this.state.loadingData}
                        loader={this.state.loader}
                    />
                </div>
            </React.Fragment>
        );
    }
}