import React, { Component } from 'react';
import {RadioGroup, Radio} from "@blueprintjs/core";
import MegabrandOrders from "./Container/MegaBrandsContainer";
import CoinVouchers from "./Container/VoucherContainer";
import GGVouchers from "./Container/GGVoucherContainer";

export default class Megabrands extends Component{
    constructor(props){
        super(props);
        this.state={
            selectedOption:1
        };
        this.handleOption=this.handleOption.bind(this);
    }
    handleOption(e){
        this.setState({
            selectedOption:Number(e.target.value)
        });
    }
    render(){
        return(
            <React.Fragment>
                <div className="megabrand">
                    <div className="megabrandOption">
                        <RadioGroup
                            onChange={this.handleOption}
                            name="type"
                            className="d-inline option-type"
                            selectedValue={this.state.selectedOption}>
                            <Radio label="Mega-Brand Orders" value={1} />
                            <Radio label="Coin Vouchers Orders" value={2} />
                            <Radio label="GG Voucher Orders" value={3} />
                        </RadioGroup>
                    </div>
                    {
                        this.state.selectedOption===1 ?
                            <MegabrandOrders/>
                            :
                            this.state.selectedOption===2 ?
                                <CoinVouchers/>
                            :
                                <GGVouchers/>
                    }
                </div>
            </React.Fragment>
        );
    }
}