import {combineReducers} from 'redux';
import profileReducer from './profileReducer';
import addressReducer from './addressReducer';
import cashbackReducer from './cashbackReducer';
import Users from './User';
import Stats from './Stats';
import Rewards from './Rewards';
import CoinList from './CoinList';
import PassbookCoin from './PassbookCoin';
import MegaBrandsOrders from './MegaBrands';
import Bsw from './Bsw';
import Recharge from './Recharge';
import Travel from './Travel';
import AllOrders from './AllOrders';
import Favourite from './Favourite';
import upvExchange from './upvExchange';
import GGVoucherOrders from './GGVoucherOrders';



const accounts = combineReducers({
    profileReducer,
    addressReducer,
    cashbackReducer,
    Users,
    Stats,
    Rewards,
    CoinList,
    PassbookCoin,
    MegaBrandsOrders,
    Bsw,
    Recharge,
    Travel,
    AllOrders,
    Favourite,
    upvExchange,
    GGVoucherOrders
})

export default accounts;