import React, {Component} from 'react';
import PageTitle from "../core/components/PageTitle";
import {Tab, Tabs} from "@blueprintjs/core";
import TopBar from "../core/components/TopBar";
import GGVendor from "./GGVendor";
import GGUpose from "./GGUpose";
import VendorProduct from "./VendorProduct";
import BSW from "./BSW";
import Megabrand from "./Megabrands";

export default class Favourites extends Component{
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render(){
        return(
            <div className="container">
                <PageTitle title="My Favourites - Global Garner"/>
                <TopBar title="My Favourites"/>
                <div className="favWrapper">
                    <Tabs
                        animate={false}
                        id="ordersTab"
                        renderActiveTabPanelOnly={true} vertical={false}>
                        <Tab id="gg-vendor" title="GG Vendor" panel={<GGVendor/>} />
                        <Tab id="gg-upose" title="GG UPOS" panel={<GGUpose />} />
                        <Tab id="vendor" title="Vendor Product" panel={<VendorProduct/>} />
                        <Tab id="bsw" title="Best Shopping Websites" panel={<BSW/>} />
                        <Tab id="megabrands" title="Mega Brands" panel={<Megabrand/>} />
                    </Tabs>
                </div>
            </div>
        );
    }
}
