import React from 'react';
import MoneyRequest from './Container/MoneyRequest'

export default class MyCoinRequest extends React.Component {
    constructor(props){
        super(props);
        this.state={
            loadingData : true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 1000);
    }

    render(){
        return(
            <div className="my-5">
                <MoneyRequest loading={this.state.loadingData}/>
            </div>
        );
    }
}