import React, { Component } from 'react';
import AllorderEmptyStates from "../../../core/images/empty-state/Allorder.svg";

export default class ItInquiriesComponent extends Component{
    constructor(props){
        super(props);
        this.state={
            loading: true
        }
    }

    componentDidMount(){
        this.setState( {
            loading: this.props.loading,
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    handleScroll=()=>{

        var hrInquiry = document.getElementById('itInquiry');
        var hrInquiryHeight = (hrInquiry.scrollHeight)-(hrInquiry.scrollTop+560);
        if (parseInt(hrInquiryHeight) === 0){
            if(this.props.stopLoadMore === false){
                this.props.NextPage()
            }
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="itinquiries" id={"itInquiry"} style={{'height':'100%','maxHeight':'575px','overflowY':'scroll'}} onScroll={this.handleScroll}>
                    <table className="bp3-html-table table-borderless">
                        <thead>
                        <tr>
                            <th>ID</th>
                           <th>Mobile No.</th>
                            <th>Email</th>
                            <th>Company Name</th>
                            <th>Project Description</th>
                            <th>Project Type</th>
                            <th>Time Frame</th>
                            <th>Budget</th>
                            <th>About us</th>
                            <th>Other</th>
                            <th>Created At</th>
                        </tr>
                        <tr className="d-none">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.loading ?
                                <tr>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                </tr>
                                :
                                this.props.ItInquiryList.length ?
                                    this.props.ItInquiryList.map((inquiry, key) => {
                                        return<tr key={key}>
                                            <td>{inquiry.id}</td>
                                            <td>{inquiry.mobile}</td>
                                            <td>{inquiry.email}</td>
                                            <td>{inquiry.company_name}</td>
                                            <td>{inquiry.project_descripton}</td>
                                            <td>{inquiry.project_type}</td>
                                            <td>{inquiry.timeframe}</td>
                                            <td>{inquiry.budget}</td>
                                            <td>{inquiry.about_us}</td>
                                            <td>{inquiry.other}</td>
                                            <td>{inquiry.created_at}</td>
                                        </tr>
                                    })
                                    :
                                    <tr>
                                        <td colSpan="11" align="center">
                                            <div className="text-center">
                                                <img src={AllorderEmptyStates} alt=""/>
                                                <p className="mt-3">No transactions for the selected option</p>
                                            </div>
                                        </td>
                                    </tr>
                        }
                        </tbody>
                    </table>
                    {
                        this.state.loading ?
                            null
                            :
                            this.props.stopLoadMore ?
                                ""
                                :
                                <React.Fragment>
                                    <center>
                                        <div className="my-3">
                                            <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }
}