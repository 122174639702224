import {connect} from 'react-redux';
import {getGgRewards, sendGgCoin, requestGgCoin} from '../../core/actions/action';
import {ggRewardsAction} from '../../core/actions';
import Balance from '../Components/AccountBalance'



const mapStateToProps = state => {

    return{
        ggRewards:state.Rewards.ggRewards,
    }
}

const mapDispatchToProps = dispatch => {

    return {

        getGgRewards :() => {
            return getGgRewards()
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(ggRewardsAction(sources.data));
                    }else{
                        dispatch(ggRewardsAction(null));
                    }
                }).catch((error)=>{
                    const sources = error.response;
                    console.log(sources);
                })
        },

        sendGgCoin : (formData) => {
            return sendGgCoin(formData);
        },

        requestGgCoin : (formData) => {
            return requestGgCoin(formData);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Balance);
