let initialState = {
    GGVoucherOrderList:[]
};

const GGVoucherOrders = (state = initialState, action) => {

    switch (action.type) {
        case 'REQUEST_GG_VOUCHER_ORDER_LIST':
            return {...state, GGVoucherOrderList: action.sources}
        default:
            return state
    }
}

export default GGVoucherOrders;
