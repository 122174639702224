import {connect} from 'react-redux';
import {getBswFavouriteList, removeBswFavourite} from '../../../core/actions/action';
import BswFavourite from '../Components/BswFavourite';

const mapStateToProps = state => {

    return{
        bswFavouriteList:state.Favourite.bswFavouriteList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getBswFavouriteList:(params) => {
            return getBswFavouriteList(params);
        },

        removeBswFavourite:(affiliateId) => {
            return removeBswFavourite(affiliateId);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BswFavourite);
