import React, { Component } from 'react';
import {Button, Intent, Position, Toaster} from "@blueprintjs/core";

export default class Invoice extends Component{
    constructor(props) {
        super(props);

        this.state ={
            isLoginStatus: false,
        }
    }

    invoiceDownload = (orderId) => {
        const formData = new FormData();
        formData.append('order_id', orderId)
        this.props.getRechargeInvoice(formData)
            .then((res)=>{
                const sources = res.data;
                let a = document.createElement("a");
                let file = new Blob([sources], {type: 'application/pdf'});
                let fileURL = URL.createObjectURL(file);
                a.href = fileURL;
                a.download = 'gg_invoice-'+orderId+'.pdf';
                a.click();
                this.setState({isLoginStatus:false});

            }).catch((error)=>{
            const sources = error.response;
            console.log(sources.data);
            Toaster.create({position: Position.TOP}).show({message:sources.data.message ,intent: Intent.DANGER});
        })
    }


    render() {
        return(
            <React.Fragment>
                <Button
                    small={true}
                    intent="primary"
                    title="Download"
                    className="btn-view mr-2" icon="download"
                    onClick={() => this.invoiceDownload(this.props.orderId)}
                    loading={this.state.isLoginStatus}
                />
            </React.Fragment>
        )
    }
}