import {connect} from 'react-redux';
import {getFlightOrderList} from '../../../core/actions/action';
import FlightOrder from '../Components/FlightIndex';

const mapStateToProps = state => {

    return{
        flightOrderList:state.Travel.flightOrderList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getFlightOrderList:(params) => {
            return getFlightOrderList(params);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlightOrder);
