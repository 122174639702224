import React from 'react';
import Main from '../container/UPVContainer';
import {Auth, NoLogin} from "gg-react-utilities";
import TopBar from "../../core/components/TopBar";
import PageTitle from "../../core/components/PageTitle";


export default class UPV extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isLoggedIn:false,
            cbType: props.match.params.cbType ? props.match.params.cbType : 'fifo'
        }
    }

    componentDidMount() {

        this.checkAuth();

        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1);
    }
  
    render(){
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <PageTitle title="UPV Cashback - Global Garner"/>
                        <TopBar title="UPV Cashback" />
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }
        return(
            <React.Fragment>
                <Main cbType={this.state.cbType} />
            </React.Fragment>
        );
    }
}