import React, { Component } from 'react';
import {Helper} from 'gg-react-utilities';
import {Dialog, Button} from '@blueprintjs/core';

export default class FIFO extends Component{

    constructor(props){
        super();
        this.state={
            isVisibleMilestone: false,
            milestones: [],
            data:[],
            counter:1,
            isSkeleton:true,
            total:0,
            coin:0.00,
            last_page:1,
            scrollLoader:true
        }
    }

    componentWillReceiveProps(nextProps) {
        let oldData = this.state.data;
        let newDataFromApi = nextProps.fpvFifoList.fifo.data;
        let newData = oldData.concat(newDataFromApi);
        this.setState({
            data:newData,
            total:nextProps.fpvFifoList.fifo.total,
            coin:nextProps.fpvFifoList.fifo_coin_cashback,
            last_page:nextProps.fpvFifoList.fifo.last_page,
            isSkeleton:false
        });
    }


    componentDidMount() {
        setTimeout(() => {
            this.props.getFPVFIFOList(this.state.counter);
        }, 1000);
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            this.loadMore()
        }
    }

    loadMore = () =>{
        this.setState({
            scrollLoader:false
        });
        setTimeout(() => {
            this.setState({
                counter: this.state.counter + 1,
                isLoading: true,
                scrollLoader:true
            }, () => {
                this.props.getFPVFIFOList(this.state.counter);
            })
        },1000)
    }

    render(){
        let str, strp;
        return(
            <React.Fragment>
                <div className="fifo mt-4">
                    <div className="fifoWrapper">
						<div className="row">
							<div className="col-sm-6 col-12">
								<div className="fifoRPPSection">
									<div className="icon">
										<img src={require('../../images/rupee.svg')} alt="" />
									</div>
									<div className="count">
										<h3 className={this.state.isSkeleton === true ? "bp3-skeleton" : ""}>{Helper.formatMoney(this.state.coin)}</h3>
										<p>Fuel Cashback</p>
									</div>
								</div>
							</div>
							<div className="col-sm-6 col-12 mt-s-4">
								<div className="fifoRPPSection">
									<div className="icon">
										<img src={require('../../images/QR.svg')} alt="" />
									</div>
									<div className="count">
										<h3 className={this.state.isSkeleton === true ? "bp3-skeleton" : ""}>{this.state.total}</h3>
										<p>Fuel Cashback Count</p>
									</div>
								</div>
							</div>
						</div>
                    </div>
                    <div style={{height:'100%',maxHeight:'590px',overflowY:'scroll'}} id={"allBooking"} onScroll={this.handleScroll} className="table-responsive mt-4">
						<table className="bp3-html-table bp3-html-table-bordered">
							<thead>
								<tr>
									<th>ID</th>
									<th>FPV ID</th>
									<th>CB Value</th>
									<th>Created Date</th>
									<th>Milestone</th>
								</tr>
							</thead>
							<tbody>
							{this.state.isSkeleton===true ?
									<tr>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>1</span></td>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>123456</span></td>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>70</span></td>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>16 Aug 2017</span></td>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>See Milestone</span></td>
									</tr>
								:
									this.state.data.length ?
										this.state.data.map((res, index) => {
											return(
												<tr key={index}>
													<td>{index+1}</td>
													<td>{res.fpv_id}</td>
													<td>{res.cb_value}</td>
													<td>{res.date_created}</td>
													<td><Button className={this.state.isSkeleton===true ? "bp3-skeleton bp3-small" : "bp3-small"}
														    onClick={() => {
														let title = "FPV No : "+res.fpv_id;
														this.setState({
															isVisibleMilestone:true,
															milestones:res.milestones,
															title:title
														});
													}}>See Milestone</Button></td>
												</tr>
											);
										})
										:
										<tr>
											<td colSpan="5" className="emptyState">
												<img src={require('../../../core/images/empty-state/UPV.svg')} alt=""/>
                                                <p className="mt-3">Oops! You don’t have any FPV under FIFO process. Use this option to get 100% cash back equivalent to fuel price.</p>
											</td>
										</tr>
							}
							</tbody>
						</table>
					</div>
                    {
                        this.state.last_page < this.state.counter ? null :
                            this.state.scrollLoader ?
                                null :
                                <React.Fragment>
                                    <center>
                                        <div className="my-3">
                                            <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                    }
                </div>
                <Dialog isOpen={this.state.isVisibleMilestone}
                        title={this.state.title}
                        className="milestoneWrapper bp3-dialog-large"
                        onClose={() => {
                            this.setState({
                                isVisibleMilestone:false,
                            });
                        }}>
                    <div className="milestone m-3 text-center">
                        <div className="row">
                            { this.state.milestones.length > 0 ?
                                this.state.milestones.map((resp, i)=> {
                                    i = i + 1;
                                    if (i === 1) {
                                        str = "1st";
                                        strp = "Cashback";
                                    } else if (i === 2) {
                                        str = "2nd";
                                        strp = "Cashback";
                                    } else if (i === 3) {
                                        str = "3rd";
                                        strp = "Cashback";
                                    } else if (i === 4) {
                                        str = "4th";
                                        strp = "GG Coin Conversion";
                                    }
                                    return(
                                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-s-4 mt-sm-4" key={i}>
                                            <div className="section">
                                                <div className="topSection pt-3 pb-3 text-white" style={{backgroundColor:"#236AEE"}}>
                                                    <h3>{str}</h3>
                                                    <p>{strp}</p>
                                                </div>
                                                <div className="bottomSection pt-3 pb-3" style={{opacity:0.8,backgroundColor:"#236AEE"}}>
                                                    <p>of ₹ {resp.amount} will be given On <br></br><strong>{resp.fpv_id}</strong><br></br> no. of FPV Generation</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }):
                                <td colSpan="5" className="emptyState">
                                    <img src={require('../../../core/images/empty-state/fuel-cashback.svg')} alt=""/>
                                </td>
                            }
                        </div>
                    </div>
                </Dialog> 
            </React.Fragment>
        );
    }
}