import React from 'react';
import UserLink from './Components/User';
import Social from './Components/Social';
import UserList from './Components/UserList';
import {Auth} from 'gg-react-utilities';
import Advertise from "./Container/Advertise";


export default class GgStats extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            usersList:[],
            page:1,
            stopLoadMore: false,
            loadingData : true,
            isLoggedIn:false,
            loader:false
        }
    }

    async componentDidMount() {
        let params={
            page:this.state.page,
        }
        this.getUsreRefList(params)

        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);

        let isLoggedIn=await Auth.isLoggedIn();
        this.setState({
            isLoggedIn:isLoggedIn
        })
    }

    getUsreRefList=(params)=>{
        this.props.getRefUserList(params)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    let oldAffData = this.state.usersList;
                    let newAffDataFromApi = sources.data.data;
                    let newAffData = oldAffData.concat(newAffDataFromApi);
                    this.setState( {
                        usersList:newAffData,
                        loader:false
                    });
                    if(sources.data.current_page === sources.data.last_page){
                        this.setState( {
                            stopLoadMore: true,
                        })
                    }else{
                        this.setState( {
                            stopLoadMore: false,
                        })
                    }
                }else{

                }
            }).catch((error)=>{
            const sources = error.response;
        })
    }

    NextPage= () =>{
        let oldPageNo = this.state.page;
        this.setState({
            page: oldPageNo + 1,
            loader:true
        },()=>{
            let params={
                page:this.state.page,
            }
            this.getUsreRefList(params)
        })

    }

    render(){

        let referralLink ='';
        if (Auth.isLoggedIn()){
            referralLink = "https://accounts.globalgarner.com/register?referer_id="+ Auth.user().user_id;
        }

        return(
            <React.Fragment>
                <div className="my-5 myStats">
                    <UserLink referralLink={referralLink} loading={this.state.loadingData}/>
                </div>

                <div className="my-5 myStats">
                    <Social referralLink={referralLink} loading={this.state.loadingData}/>
                </div>

                <div className="my-5 myStats">
                    <Advertise referralLink={this.state.referralLink} loading={this.state.loading} value={1}/>
                </div>

                <div className="my-5 myStats">
                    <UserList
                        usersList={this.state.usersList}
                        NextPage={() => this.NextPage()}
                        stopLoadMore={this.state.stopLoadMore}
                        loading={this.state.loadingData}
                        loader={this.state.loader}
                    />

                </div>
            </React.Fragment>
        );
    }
}