import {connect} from "react-redux";
import {getPassbookCoinList} from "../../../core/actions/action";
import PassBookCoin from "../Components/Coin";


const mapStateToProps = state =>{

    return{
        passbookMoneyList:state.PassbookCoin.passbookMoneyList,
    }

}

const mapDispatchToProps = dispatch =>{
    return{
        getPassbookCoinList : (params) =>{
            return getPassbookCoinList(params);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PassBookCoin)