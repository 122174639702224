import React from 'react';
import { Button, ControlGroup, InputGroup, Intent, Toaster, Position } from "@blueprintjs/core";

export default class copyImage extends React.Component {
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
        this.state = {
            referralLink: props.image,
            copySuccess: "",
            loading: true,
        };
    }

    componentDidMount() {
        this.setState({
            loading: this.props.loading,
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            loading: nextProps.loading
        })
    }

    copyToClipboard = (e) => {
        this.textInput.current.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the the whole text area selected.
        this.textInput.current.focus();
        Toaster.create({ position: Position.TOP }).show({ message: 'Copied!', intent: Intent.SUCCESS });
    };

    render() {
     
        return (
            <React.Fragment>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-5">
                        {
                        
                                <ControlGroup fill={true} vertical={false} className="brand-search">
                                    
                                    <InputGroup
                                        placeholder={this.state.referralLink}
                                        className="bs-input-search"
                                        defaultValue={this.state.referralLink}
                                        inputRef={this.textInput}
                                        readOnly
                                    />
                                    <Button className="bp3-button bp3-intent-primary btn-sm"
                                        intent={Intent.PRIMARY}
                                        text="Copy"
                                        onClick={() => this.copyToClipboard()}
                                    />
                                </ControlGroup>
                        }
                    </div>
            </React.Fragment>
        );
    }
}