import React, { Component } from 'react';
import AllorderEmptyStates from "../../../../core/images/empty-state/Allorder.svg";
import StoreDefaultImage from "../../../../core/images/order/store.svg";
import { getRedirectURL } from '../../../../core/actions/action';
import ViewNewOrders from './ViewOrders';
import { Button } from "@blueprintjs/core";
import Config from "../../../../Config";

export default class NewStoreOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderList: [],
            start: 0,
            length: 10,
            stopLoadMore: false,
            loading: true,
            isOpen: false,
            scrollLoader: true,
            StoreRedirectUrl:''
        }
    }

    componentDidMount() {
        this.setState({
            loading: this.props.loading,
        })

        let params = {
            length: this.state.length
        }
        this.getNewStoreOrderList(params);
        this.getStoreRedirectUrl();
    }

    getStoreRedirectUrl= () =>{

        getRedirectURL().then((res)=>{
            let data = res.data.data.magentoStoreUrl

            this.setState({
                StoreRedirectUrl: data
            })

        }).catch((error) => {
            const sources = error.response;
        })
    }

    redirectPage=(id)=>{

        let redirectUrl = this.state.StoreRedirectUrl + '&redirect_url=' + Config.SHOP_URL + 'sales/order/view/order_id/'+id

        const win = window.open(redirectUrl, "_blank")
        if (win != null) {
            win.focus();
        }

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            loading: nextProps.loading
        })
    }

    getNewStoreOrderList = (params) => {

        this.props.getNewStoreOrderList(params)
            .then((res) => {
                const sources = res.data.data;
                console.log("source", res.data.data)
                if (res.status === 200) {
                    this.setState({
                        orderList: [],
                    });
                    let oldData = this.state.orderList;
                    let newDataFromApi = sources;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState({
                        orderList: newDataFromApi,
                    });
                    if (sources === "") {
                        this.setState({
                            showEmptyStats: true,
                        });
                    }
                    if (newData.length === sources.length) {
                        this.setState({
                            stopLoadMore: true,
                        })
                    } else {
                        this.setState({
                            stopLoadMore: false,
                        })
                    }
                } else {

                }
            }).catch((error) => {
                const sources = error.response;
                console.log(error, 'testtttt');
            })
    }

    loadMore = () => {
        this.setState({
            scrollLoader: false
        });
        let oldPageNo = this.state.length;
        this.setState({
            isLoading: true,
            length: oldPageNo + 10,
            //loading:true,
            scrollLoader: true
        }, () => {
            let params = {
                length: this.state.length
            }
            this.getNewStoreOrderList(params);
        })
        this.stopLoading()
    }

    stopLoading = () => {
        setTimeout(() => {
            this.setState({
                loading: false,
            });
        }, 1000);
    }

    handleScroll = () => {

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight) - (allBooking.scrollTop + 590);
        if (this.state.stopLoadMore === false) {
            if (parseInt(allBookingHeight) === 0) {
                this.loadMore()
            }
        }
    }

    render() {
        console.log("sbshvjcsdfc",this.state.orderList)
        return (
            <React.Fragment>
                <div className="store-order" id={"allBooking"} style={{ 'height': '100%', 'maxHeight': '590px', 'overflowY': 'scroll' }} onScroll={this.handleScroll} >
                    <table className="bp3-html-table table-borderless">
                        <thead>
                            <tr>
                                <th>Order Details</th>
                                <th className="pr-3">Order ID</th>
                                <th>Order Date</th>
                                <th>Price</th>
                                <th className="pr-3">Cashback Type</th>
                                <th className="pr-3">Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.loading ?
                                    <tr>
                                        <td><div className="bp3-skeleton" style={{ width: "100%", height: "30px" }}></div></td>
                                        <td><div className="bp3-skeleton" style={{ width: "100%", height: "30px" }}></div></td>
                                        <td><div className="bp3-skeleton" style={{ width: "100%", height: "30px" }}></div></td>
                                        <td><div className="bp3-skeleton" style={{ width: "100%", height: "30px" }}></div></td>
                                        <td><div className="bp3-skeleton" style={{ width: "100%", height: "30px" }}></div></td>
                                        <td><div className="bp3-skeleton" style={{ width: "100%", height: "30px" }}></div></td>
                                        <td><div className="bp3-skeleton" style={{ width: "100%", height: "30px" }}></div></td>
                                    </tr>
                                    :
                                    
                                    this.state.orderList && this.state.orderList.length ?
                                        this.state.orderList.map((order, key) => {
                                            return <tr key={key}>
                                                <td>
                                                
                                                    <div className="orderTable">
                                                        <div className="image hidden-xs hidden-sm hidden-md visible-xl visible-lg">
                                                            <img src={StoreDefaultImage} alt="" />
                                                        </div>
                                                        <div className="title">
                                                            <p className="pt-2">{order.products[0].product_name ? order.products[0].product_name : '-'}</p>
                                                            <span>{order.products[0].category_name ? order.products[0].category_name : '-'}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{order.orderid}</td>
                                                <td>{order.order_date}</td>
                                                <td><b>₹ {order.grand_total}</b></td>
                                                <td>{order.cashbacktype ? order.cashbacktype : '-'}</td>
                                                <td>
                                                    <b>
                                                        { order.status }
                                                    </b>
                                                </td>
                                                <td>
                                                    <Button small={true} intent="primary" title="View" icon="eye-open" className="btn-view mr-2"
                                                        onClick={()=>this.redirectPage(order.redirectOrderId)}
                                                  />
                                                </td>
                                            </tr>
                                        })
                                        :
                                        <tr>
                                            <td colSpan="7" align="center">
                                                <div className="text-center">
                                                    <img src={AllorderEmptyStates} alt="" />
                                                    <p className="mt-3">No transactions for the selected option</p>
                                                </div>
                                            </td>
                                        </tr>
                            }
                        </tbody>
                    </table>
                    {
                        this.state.loading ?

                            <React.Fragment>
                                <center>
                                    <div className="my-3">
                                        <img src={require('../../../../core/images/loader.gif')} alt={""} style={{ width: '50px', height: '50px' }} />
                                    </div>
                                </center>
                            </React.Fragment>
                            : null
                    }
                </div>
            </React.Fragment>
        )
    }
}





