import React, {Component} from 'react';
import AllorderEmptyStates from "../../../../core/images/empty-state/Allorder.svg";
import StoreDefaultImage from "../../../../core/images/order/store.svg";
import ViewOrders from '../Container/ViewOrders';

export default class StoreOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderList:[],
            start:0,
            length:10,
            stopLoadMore: false,
            loading:true,
            isOpen: false,
            scrollLoader:true
        }
    }

    componentDidMount() {
        this.setState( {
            loading: this.props.loading,
        })

        let params={
            length:this.state.length
        }
        this.getStoreOrderList(params)
    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    getStoreOrderList=(params)=>{
        this.props.getStoreOrderList(params)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    this.setState( {
                        orderList:[],
                    });
                    let oldData = this.state.orderList;
                    let newDataFromApi = sources.data.data;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState( {
                        orderList:newDataFromApi,
                    });
                    if(sources.data.data === ""){
                        this.setState( {
                            showEmptyStats:true,
                        });
                    }
                    if(newData.length === sources.data.totalRecords){
                        this.setState( {
                            stopLoadMore: true,
                        })
                    }else{
                        this.setState( {
                            stopLoadMore: false,
                        })
                    }
                }else{

                }
            }).catch((error)=>{
            const sources = error.response;
            console.log(sources);
        })
    }

    loadMore = () =>{
        this.setState({
            scrollLoader:false
        });
        let oldPageNo = this.state.length;
            this.setState({
                isLoading: true,
                length:oldPageNo+10,
                //loading:true,
                scrollLoader:true
            }, () => {
                let params={
                    length:this.state.length
                }
                this.getStoreOrderList(params);
            })
        this.stopLoading()
    }

    stopLoading = () => {
        setTimeout(() => {
            this.setState({
                loading: false,
            });
        }, 1000);
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);
        if(this.state.stopLoadMore === false){
            if (parseInt(allBookingHeight) === 0){
                this.loadMore()
            }
        }
    }

    renderStatusHtml(status){

        switch (status) {
            case 1:
                return <label className="label text-warning">Awaiting Payment</label>;
            case 2:
                return <label className="badge text-warning">Awaiting Shipment</label>;
            case 3:
                return <label className="badge text-warning">Awaiting Pickup</label>;
            case 4:
                return <label className="badge text-success">Intransit</label>;
            case 5:
                return <label className="badge text-success">Delivered</label>;
            case 6:
                return <label className="badge text-success">Completed</label>;
            case 7:
                return <label className="badge text-danger">Cancelled</label>;
            case 8:
                return <label className="badge text-danger">Declined</label>;
            case 9:
                return <label className="badge text-success">Disputed</label>;
            default:
                return <label className="label text-default">--</label>;
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="storeorder" id={"allBooking"} style={{'height':'100%','maxHeight':'590px','overflowY':'scroll'}} onScroll={this.handleScroll} >
                    <table className="bp3-html-table table-borderless">
                        <thead>
                            <tr>
                                <th>Order Details</th>
                                <th>Order ID</th>
                                <th>Order Date</th>
                                <th>Price</th>
                                <th>Cashback Type</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.loading ?
                                <tr>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                </tr>
                                :
                                this.state.orderList.length ?
                                    this.state.orderList.map((order, key) => {
                                        return <tr key={key}>
                                            <td>
                                                <div className="orderTable">
                                                    <div className="image hidden-xs hidden-sm hidden-md visible-xl visible-lg">
                                                        <img src={StoreDefaultImage} alt="" />
                                                    </div>
                                                    <div className="title">
                                                        <p className="pt-2">{order.product.name}</p>
                                                        <span>{order.product.catalog_product.category.category_name}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{order.increament_id}</td>
                                            <td>{order.formatted_created_at}</td>
                                            <td><b>₹ {order.totals[5].value}</b></td>
                                            <td>{order.cb_choice_title}</td>
                                            <td>
                                                <b>
                                                    {
                                                        this.renderStatusHtml(order.status.order_status_id)
                                                    }
                                                </b>
                                            </td>
                                            <td>
                                                <ViewOrders isOpen={this.state.isOpen} orderid={order.order_id}/>
                                            </td>
                                        </tr>
                                    })
                                    :
                                    <tr>
                                        <td colSpan="7" align="center">
                                            <div className="text-center">
                                                <img src={AllorderEmptyStates} alt=""/>
                                                <p className="mt-3">No transactions for the selected option</p>
                                            </div>
                                        </td>
                                    </tr>
                        }
                        </tbody>
                    </table>
                    {
                        this.state.loading ?

                                <React.Fragment>
                                    <center>
                                        <div className="my-3">
                                            <img src={require('../../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                            : null
                    }
                </div>
            </React.Fragment>
        )
    }
}