import React, {Component} from 'react';
import {Button, Classes, Dialog, Intent} from "@blueprintjs/core";

export default class ViewComment extends Component {
    constructor(props){
        super(props);
        this.state = {
            isOpenComment: false,
        }
        this.handleCloseComment = this.handleCloseComment.bind(this)
    }

    handleCloseComment(){
        this.setState({ isOpenComment: false});
    }

    render() {
        return(
            <React.Fragment>
                <Button intent="primary" className="bp3-button bp3-small bp3-intent-primary" title="View Reason" icon="eye-open" onClick={() => {
                    this.setState({isOpenComment: true})
                }}/>
                <Dialog isOpen={this.state.isOpenComment} onClose={() => this.handleCloseComment()} className="bp3-dialog-large"
                        title="View Cancel Reason"
                        canOutsideClickClose={true}
                        canEscapeKeyClose={true}>
                    <div className={Classes.DIALOG_BODY}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-group mb-2">
                                <p>{this.props.cancelReason}</p>
                            </div>
                            <div className="col-12 text-group mt-s-4 mt-3 text-right">
                                <Button type="button" text="Ok" intent={Intent.PRIMARY}  onClick={() => this.handleCloseComment()} />
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}