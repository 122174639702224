import React from 'react';
import {Icon, Intent} from "@blueprintjs/core";
import Circle from './circle';

export default class circleView extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isCircleLoding:false,
            prevFPVID:[],
            circleData:[],
            lavel:null
        }

    }

    componentDidMount() {
        this.setState({
            circleData:this.props.circleData,
            prevFPVID:this.props.prevFPVID,
            lavel:this.props.lavel
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            circleData : nextProps.circleData,
            prevFPVID : nextProps.prevFPVID,
            lavel : nextProps.lavel,
        })
    }

    render() {
        if(this.state.circleData && this.state.prevFPVID) {
            return (
                <React.Fragment>
                    <div className="org-chart">
                        <ul>
                            <li>
                                {
                                    this.state.lavel === 1 ?
                                        <div className="user">
                                            <Icon icon="document" iconSize={18} intent={Intent.PRIMARY}/>
                                            <span className="badge">{this.state.circleData.fpv_id}</span>
                                        </div>
                                        :
                                        <a  onContextMenu={(e) =>{ this.props.userFpvInformation(e,this.state.circleData.fpv_id,this.state.lavel === 2 ? 1 : this.state.lavel)}} className="user">
                                            <Icon icon="document" iconSize={18} intent={Intent.PRIMARY}/>
                                            <span className="badge">{this.state.circleData.fpv_id}</span>
                                        </a>
                                }
                                <ul>
                                    <li>
                                        {
                                            this.state.circleData.child_list ?
                                                this.state.circleData.child_list.map((res, i) => {
                                                    if(this.state.lavel <= 8) {
                                                        if(i < this.state.circleData.max_circles_allowed) {
                                                            if (this.state.prevFPVID.find((e) => e == res.id) !== undefined) {
                                                                return (
                                                                    <Circle key={i}
                                                                            circleClass="active"
                                                                            nextLavelFpvFunction={true}
                                                                            userInformationFunction={false}
                                                                            addFpvCircleFunction={false}

                                                                            getNextLevelLoading={this.props.getNextLevelLoading}
                                                                            fpvId={res.id}
                                                                            lavel={this.state.lavel}
                                                                            getNextLevel={(fpvId, lavel) => {
                                                                                this.props.getNextLevel(fpvId, lavel)
                                                                            }}
                                                                    />
                                                                )
                                                            } else {
                                                                return (
                                                                    <Circle key={i}
                                                                            circleClass=""
                                                                            nextLavelFpvFunction={true}
                                                                            userInformationFunction={true}
                                                                            addFpvCircleFunction={false}

                                                                            getNextLevelLoading={this.props.getNextLevelLoading}
                                                                            fpvId={res.id}
                                                                            lavel={this.state.lavel}
                                                                            getNextLevel={(fpvId, lavel) => {
                                                                                this.props.getNextLevel(fpvId, lavel)
                                                                            }}
                                                                            userFpvInformation={(e, fpvId, lavel) => {
                                                                                this.props.userFpvInformation(e, fpvId, lavel)
                                                                            }}
                                                                    />
                                                                )
                                                            }
                                                        }
                                                    }else {
                                                        return (
                                                            <Circle key={i}
                                                                    circleClass=""
                                                                    nextLavelFpvFunction={false}
                                                                    userInformationFunction={true}
                                                                    addFpvCircleFunction={false}

                                                                    fpvId={res.id}
                                                                    lavel={this.state.lavel}
                                                                    userFpvInformation={(e,fpvId,lavel) =>{ this.props.userFpvInformation(e,fpvId,lavel)}}
                                                            />
                                                        )
                                                    }
                                                })
                                                :''
                                        }
                                        {
                                            this.state.circleData.child_list ?
                                                [...Array((this.state.circleData.max_circles_allowed - this.state.circleData.completed_circles))].map((e, i) => {
                                                    return (
                                                        <Circle key={i}
                                                                circleClass=""
                                                                nextLavelFpvFunction={false}
                                                                userInformationFunction={false}
                                                                addFpvCircleFunction={true}

                                                                fpvId={this.state.circleData.fpv_id}
                                                                lavel={this.state.lavel}
                                                                openAddCircleModal={(e,fpvId,lavel) =>{ this.props.openAddCircleModal(e,fpvId,lavel)}}
                                                        />
                                                    )
                                                })
                                                :''
                                        }
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </React.Fragment>
            )
        }
    }
}