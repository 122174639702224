import {connect} from "react-redux";
import {getGgStatsList} from '../../../core/actions/action';
import GgStats from '../../Ggstats'
const mapStateToProps = state =>{

    return{
        ggStatsList:state.Stats.ggStatsList,
    }

}

const mapDispatchToProps = dispatch =>{
    return{
        getGgStatsList : () =>{
            return getGgStatsList();
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GgStats)