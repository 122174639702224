import React, { Component } from 'react';
import AllorderEmptyStates from "../../../../core/images/empty-state/Allorder.svg";
import {Button, Dialog, Intent, Position, Toaster} from "@blueprintjs/core";
import {connect} from "react-redux";
import {getPackageList, getPackageDetail} from "../../../../core/actions/action";
import {getPackageDetailAction} from "../../../../core/actions";

class Package extends Component{
    constructor(props){
        super(props);
        this.state={
            packageList:[],
            loading:true,
            page:1,
            stopLoadMore: false,
            showEmptyStats:false,
            loadingData : true,
            isVisibleViewModal:false,
            packageDetail:{}
        };
    }

    componentDidMount(){
        this.setState( {
            loading: false,
        });
        this.getPackageList(this.state.page)
    }

    getPackageList(page){
        this.props.getPackageList(page)
    }

    componentWillReceiveProps(nextProps) {
        let oldData = this.state.packageList;
        let newDataFromApi = nextProps.packageList.data;
        let newData = oldData.concat(newDataFromApi);
        this.setState({
            packageList:newData
        });

        if(nextProps.packageList.current_page >= nextProps.packageList.last_page){
            this.setState( {
                stopLoadMore: true,
            })
        }else{
            this.setState( {
                stopLoadMore: false,
            })
        }
    }

    loadMore = () =>{
        this.setState({
            scrollLoader:false
        });
        setTimeout(() => {
            this.setState({
                page: this.state.page + 1,
                isLoading: true,
                scrollLoader: true
            }, () => {
                this.props.getPackageList(this.state.page);
            })
        },1000)
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            this.loadMore()
        }
    }

    getPackageDetail=(packageID)=>{
        this.props.getPackageDetail(packageID)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    this.setState({
                        packageDetail:sources.data
                    })
                }
            }).catch((error)=>{
            const sources = error.response;
        })
    }



    closeModal=() =>{
        this.setState({
            isVisibleViewModal:false
        })
    }

    renderStatusHtml(status){

        switch (status) {
            case "1":
                return <label className="label text-success">SUCCESS</label>;
            case "2":
                return <label className="badge text-danger">FAILED</label>;
            case "3":
                return <label className="badge text-warning">AWAITING</label>;
            case "4":
                return <label className="badge text-warning">PENDING</label>;
            case "0":
                return <label className="badge text-danger">CANCELED</label>;
            default:
                return <label className="label text-default">--</label>;
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="holiday">
                    <div className="recharge" id={"allBooking"} style={{'height':'100%','maxHeight':'590px','overflowY':'scroll'}} onScroll={this.handleScroll}>
                        <table className="bp3-html-table table-borderless">
                            <thead>
                            <tr>
                                <th>Package Name</th>
                                <th>Enquiry ID</th>
                                <th>Enquiry Date</th>
                                <th>Price</th>
                                <th>Cashback Type</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.loading ?
                                    <tr>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    </tr>
                                    :
                                    this.state.packageList.length ?
                                        this.state.packageList.map((res, key) => {
                                            return <tr key={key}>
                                                <td><div className="orderTable">
                                                        <div className="title">
                                                            <p>{res.city_name}</p>
                                                            <span>{res.package_name}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{res.id}</td>
                                                <td>{res.created_at}</td>
                                                <td>₹ {res.amount}</td>
                                                <td>{res.cb_choice_title}</td>
                                                <td><b style={{color:res.order_status_color}}>{res.order_status_text}</b></td>
                                                <td>
                                                    <Button small={true} intent="inform" className="btn-view mr-2" title="View" onClick={() =>{
                                                        this.setState({
                                                            isVisibleViewModal:true
                                                        },()=>{
                                                            this.getPackageDetail(res.id)
                                                        })
                                                    }}>
                                                    View
                                                    </Button>
                                                </td>
                                            </tr>
                                        })
                                            :
                                        <tr>
                                            <td colSpan="7" align="center">
                                                <div className="text-center">
                                                    <img src={AllorderEmptyStates} alt=""/>
                                                    <p className="mt-3">No transactions for the selected option</p>
                                                </div>
                                            </td>
                                        </tr>
                            }

                            </tbody>
                        </table>
                        {
                            this.state.loading ?
                                null
                                :
                                this.state.stopLoadMore ?
                                    ""
                                    :
                                    <React.Fragment>
                                        <center>
                                            <div className="my-3">
                                                <img src={require('../../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                            </div>
                                        </center>
                                    </React.Fragment>
                        }
                    </div>
                </div>
                <Dialog isOpen={this.state.isVisibleViewModal}
                        title={"View Details"}
                        className="viewDetail"
                        style={{marginTop:'250px'}}
                        onClose={this.closeModal.bind(this)} >
                        <div className="viewForm m-3">
                            <div className="row">
                                <div className="col-12">
                                    <div className="orderTable">
                                        <div className="title">
                                            <span>Holiday Package Name</span>
                                            <p>{"("+this.state.packageDetail.city_name+") "+ this.state.packageDetail.package_name}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="orderTable">
                                        <div className="title">
                                            <span>Duration</span>
                                            <p>{this.state.packageDetail.day+" Days/"+ this.state.packageDetail.night+" Nights"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="orderTable">
                                        <div className="title">
                                            <span>City Covered</span>
                                            <p>{this.state.packageDetail.city_name}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="orderTable">
                                        <div className="title">
                                            <span>Price</span>
                                            <p>{this.state.packageDetail.amount}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="orderTable">
                                        <div className="title">
                                            <span>Status</span>
                                            <p style={{color:this.state.packageDetail.order_status_color}}>{this.state.packageDetail.order_status_text}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="orderTable">
                                        <div className="title">
                                            <span>Enquiry ID</span>
                                            <p>{this.state.packageDetail.enquiry_id}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="orderTable">
                                        <div className="title">
                                            <span>Enquiry Date</span>
                                            <p>{this.state.packageDetail.prefer_date_time}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 text-center mt-4">
                                <Button className="btn-view mt-2" onClick={() => {
                                    window.location.href= this.state.packageDetail.package_detail_link
                                }}>Visit Package Detail</Button>
                            </div>
                        </div>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return{
        packageList:state.AllOrders.packageList,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPackageList :(page) => {
            return getPackageList(page)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(getPackageDetailAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(getPackageDetailAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
        getPackageDetail :(packageID) => {
            return getPackageDetail(packageID)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Package);