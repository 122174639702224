import {connect} from "react-redux";
import {getRefUserList} from '../../../core/actions/action';
import UserReferrals from '../index';
const mapStateToProps = state =>{

    return{
        userList:state.Users.userList,
    }

}

const mapDispatchToProps = dispatch =>{
    return{
        getRefUserList : (params) =>{
            return getRefUserList(params);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserReferrals)