import {connect} from "react-redux";
import {getPassbookMoneyList} from "../../../core/actions/action";
import Money from "../Components/Money";


const mapStateToProps = state =>{

    return{
        passbookMoneyList:state.PassbookCoin.passbookMoneyList,
    }

}

const mapDispatchToProps = dispatch =>{
    return{
        getPassbookMoneyList : (params) =>{
            return getPassbookMoneyList(params);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Money)