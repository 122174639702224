import {connect} from 'react-redux';
import Circles from '../../components/circles';
import {getCircleFPVLevelList, getFPVLevels} from "../../../../core/actions/action";

const mapDispatchToProps = dispatch => {
    return {
        getFPVLevels: (fpvID,obj) => {
            return getFPVLevels(fpvID,obj)
        },
        getCircleFPVLevelList: () => {
            return getCircleFPVLevelList()
        }
    }
}

export default connect(null, mapDispatchToProps)(Circles);
