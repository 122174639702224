import { ssoInstance, autoInstance, Auth } from 'gg-react-utilities';
import Config from '../../Config';
import InsuranceInquiriesIndex from "../../Orders/Inquiry/components/InsuranceInquiriesIndex";
import VehicleInquiriesIndex from "../../Orders/Inquiry/components/VehicleInquiriesIndex";

//Coin Converzation
export const getLevelOfCoin = (id) => {
    return ssoInstance.get(Config.UPV_API_URL + 'api/v1.0.2/circle-upvs/' + id + '/get-convertible-coins')
}

export const coinConverzation = (upvID, amount) => {
    return ssoInstance.post(Config.UPV_API_URL + 'api/v1.0.2/circle-upvs/' + upvID + '/convert-coins'
        , { amount: amount }
    );
}

export const advertiseImg = (test) => {
    return ssoInstance.get(Config.ADS_API_URL + 'api/referralads/shows/' + test );
}

export const getRefral = (res) => {
    return ssoInstance.post(Config.ADS_API_URL + 'api/referralads/getrefimage', res );
}

export const getRedirectURL = () =>{
    return ssoInstance.get(Config.STORE_API_URL + 'v1.0.3/user/get-magento-store-url')
}

export const getUPVFIFOList = (page) => {
    return ssoInstance.get(Config.UPV_API_URL + 'api/v1.0.2/users/' + Auth.user().user_id + '/upvs?page=' + page);
}

export const getRPPCurrentList = (page) => {
    return ssoInstance.get(Config.UPV_API_URL + "api/v1.0.2/users/" + Auth.user().user_id + '/rpp/current_month_upvs?page=' + page);
}

export const getRPPOldList = (page) => {
    return ssoInstance.get(Config.UPV_API_URL + "api/v1.0.2/users/" + Auth.user().user_id + '/rpp/old_upvs?page=' + page);
}

export const getICList = (page) => {
    return ssoInstance.get(Config.UPV_API_URL + "api/v1.0.2/users/" + Auth.user().user_id + '/icb/cashbacks?page=' + page);
}

export const getCircleList = (page,circleType) => {
    let type = '';
    if(circleType === '1'){
        type = "&with_tagged_upvs=" + circleType;
    }else if(circleType === '2'){
        type = "&with_un_tagged_upvs=" + circleType;
    }
    return ssoInstance.get(Config.UPV_API_URL + "api/v1.0.2/circle-upvs?page=" + page + type);
}

export const addCircle = (upvID, obj) => {
    return ssoInstance.post(Config.UPV_API_URL + 'api/v1.0.2/circle-upvs/' + upvID + '/add-referral', obj);
}

export const getUPVLevels = (upvID, obj) => {
    return ssoInstance.post(Config.UPV_API_URL + 'api/v1.0.2/circle-upvs/' + upvID + '/get-immediate-childes', obj);
}

export const getCircleUPVLevelList = () => {
    return ssoInstance.get(Config.UPV_API_URL + 'api/v1.0.2/circle-upvs/get-levels-list');
}

export const getUserDetailByUpvId = (upvId) => {
    return ssoInstance.get(Config.UPV_API_URL + 'api/v1.0.2/circle-upvs/' + upvId + '/get-details');
}

export const upvReplaceRequest = (upvId, request) => {
    return ssoInstance.post(Config.UPV_API_URL + 'api/v1.0.2/circle-upvs/replacement/' + upvId + '/replace-child', request);
}

export const getCashbackOption = () => {
    return ssoInstance.get(Config.UPV_API_URL + 'api/v1.0.2/cashback-choice');
}

export const changeCashbackOption = (choice) => {
    return ssoInstance.put(Config.UPV_API_URL + 'api/v1.0.2/cashback-choice/switch/' + choice);
}

export const getFPVFIFOList = (page) => {
    return ssoInstance.get(Config.FPV_API_URL + 'v3/users/fifo_dashboard?page=' + page);
}

export const getFPVRPPCurrentList = (page) => {
    return ssoInstance.get(Config.FPV_API_URL + "v3/users/current_month_rpp_dashboard?page=" + page);
}

export const getFPVRPPOldList = (page) => {
    return ssoInstance.get(Config.FPV_API_URL + "v3/users/rpp_dashboard?page=" + page);
}

export const getFPVICList = (page) => {
    return ssoInstance.get(Config.FPV_API_URL + "v3/users/icb_dashboard?page=" + page);
}

export const getFPVCashbackOption = () => {
    return ssoInstance.get(Config.FPV_API_URL + 'v3/cashback_choice');
}

export const changeFPVCashbackOption = (obj) => {
    return ssoInstance.post(Config.FPV_API_URL + 'v3/change_cashback_option', obj);
}

export const getWithdrawRequestList = (page) => {
    return ssoInstance.get(Config.WALLET_URL + 'api/v1.0.2/withdrawal/request-list?page=' + page);
}

export const getOTPRequest = (obj) => {
    return ssoInstance.post(Config.WALLET_URL + 'api/v1.0.2/withdrawal/request-otp', obj);
}

export const saveWithdrawalRequest = (obj) => {
    return ssoInstance.post(Config.WALLET_URL + 'api/v1.0.2/withdrawal/request', obj);
}

export const getRewardList = (page) => {
    return ssoInstance.get(Config.VOUCHER_API_URL + 'v3/gg-rewards?page=' + page);
}

export const changeVoucherStatus = (obj) => {
    return ssoInstance.post(Config.VOUCHER_API_URL + 'v3/toggle-rewards', obj);
}

export const setVoucherCode = (obj) => {
    return ssoInstance.post(Config.VOUCHER_API_URL + 'v3/set_voucher', obj);
}

export const getRefUserList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.ACCOUNT_API_URL + 'api/v1.0.2/user/' + Auth.user().user_id + '/referrals?' + queryString)
}

export const getRefVendorUserList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.STORE_API_URL + 'v1.0.3/user/' + Auth.user().user_id + '/vendor-referral?length=15&' + queryString)
}

export const getGgStatsList = () => {
    return ssoInstance.get(Config.STATS_URL + 'api/gg-stats')
}

export const getGgRewards = () => {
    return ssoInstance.get(Config.WALLET_URL + 'api/v1.0.2/customer/'+Auth.user().user_id+'?with_total_gg_money_cashback=1')
}

export const sendGgCoin = (formData) => {
    return ssoInstance.post(Config.WALLET_URL + 'api/v1.0.2/coin/send', formData)
}

export const requestGgCoin = (formData) => {
    return ssoInstance.post(Config.WALLET_URL + 'api/v1.0.2/coin/request', formData)
}

export const myRequestGgCoinList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.WALLET_URL + 'api/v1.0.2/coin/my-requests/list?' + queryString)
}

export const ggCoinRequestList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.WALLET_URL + 'api/v1.0.2/coin/requests/list/ALL?' + queryString)
}

export const getPassbookMoneyList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.WALLET_URL + 'api/v1.0.2/transactions/MONEY?' + queryString)
}


export const getPassbookCoinList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.WALLET_URL + 'api/v1.0.2/transactions/COIN?' + queryString)
}

export const acceptCoin = (requestId) => {
    return ssoInstance.post(Config.WALLET_URL + 'api/v1.0.2/coin/request/' + requestId + '/update-status/FULFILL')
}

export const rejectCoin = (requestId) => {
    return ssoInstance.post(Config.WALLET_URL + 'api/v1.0.2/coin/request/' + requestId + '/update-status/DECLINE')
}

/*Order API Call*/

export const getMegaBrandOrderList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.MEGABRAND_API_URL + 'user/v1.0.3/order-history?' + queryString)
}


export const getVoucherOrderList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.VOUCHER_API_URL + 'v3/user/user-voucher-order-list?' + queryString)
}

export const getGGVoucherOrderList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.WALLET_URL + 'api/v1.0.2/vouchers/order/list?' + queryString)
}

export const getGGVoucherInvoice = (orderId) => {
    return ssoInstance.post(Config.WALLET_URL + 'api/v1.0.2/vouchers/order/' + orderId + '/download-invoice', {}, {
        responseType: 'blob'
    });
}

export const getVoucherOrderDetails = (orderId, params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.VOUCHER_API_URL + 'v3/user/user-voucher-list/' + orderId + '?' + queryString)
}

export const setVoucherStatus = (orderDetailId) => {

    let formData = new FormData();
    formData.append('voucher_order_detail_id', orderDetailId);
    return ssoInstance.post(Config.VOUCHER_API_URL + 'v3/user/toggle-rewards', formData)
}

export const getBSWOrderList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.BSW_API_URL + 'user/v3/user/user_bills?' + queryString)
}

export const getRechargeOrderList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.post(Config.RECHARGE_API_URL + 'api/order?' + queryString)
}

export const getBusOrderList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.post(Config.BUS_API_URL + 'api/v3/booking_history?' + queryString)
}

export const getFlightOrderList = (params) => {

    let url = Config.FLIGHT_API_URL + 'user/flight/v1.0.3/bookingHistoryData';
    if (params.page !== null || params.page !== 1) {
        url = Config.FLIGHT_API_URL + 'user/flight/v1.0.3/bookingHistoryData?page=' + params.page;
    }

    return ssoInstance.post(url,
        {
            "booking_type": 1
        }
    )
}

export const getRailBhojOrderList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.RAILBHOJ_API_URL + 'api/order?' + queryString)
}

export const getStoreOrderList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.STORE_API_URL + 'v1.0.3/order/all-order?start=0&' + queryString)
}

export const getNewStoreOrderList = (params) => {
    return ssoInstance.get(Config.STORE_API_URL + 'v1.0.3/user/get-shop-orders', {
        "page": 1,
        "perPage": params
    })
}

export const getMyStatsList = () => {
    return ssoInstance.get(Config.STATS_URL + 'api/user-stats')
}

export const getApprovedOrderList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.UPV_API_URL + 'api/v1.0.2/users/16555/orders?' + queryString)
}

export const getFavouriteVendorlist = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    //return ssoInstance.get(Config.STORE_API_URL +'v1.0.3/vendor?columnSearch[is_favorite]=1&start=0&radius=0-10&'+queryString );
    return ssoInstance.get(Config.STORE_API_URL + 'v1.0.3/user/' + Auth.user().user_id + '/get_favourite_vendors?&start=0&' + queryString);
}

export const getFavouriteUposelist = (params) => {
    //var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.UPOSE_API_URL + 'v3/favourites');
}

export const removeFavouriteUpose = (agent_id) => {
    return ssoInstance.post(Config.UPOSE_API_URL + 'v3/toggle-favourite' , { "agent_id": agent_id })
}

export const getVendorProductWishList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.STORE_API_URL + 'v1.0.3/wishlist?start=0&' + queryString);
}

export const getBswFavouriteList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.BSW_API_URL + 'user/v3/favourite?' + queryString);
}

export const getMegaBrandFavouriteList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.MEGABRAND_API_URL + 'user/v1.0.3/favourite-brands?' + queryString)
}

export const getAllOrderList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    //&domain=MEGA-BRANDS
    return ssoInstance.get(Config.STATS_URL + 'api/all_orders?per-page=15&' + queryString)
}

export const removeFavouriteVendor = (vendorSlug) => {
    return ssoInstance.post(Config.STORE_API_URL + 'v1.0.3/vendor/toggle/favorites?vendor_slug=' + vendorSlug)
}

export const removeFavouriteProduct = (productId) => {
    return ssoInstance.post(Config.STORE_API_URL + 'v1.0.3/wishlist/toggle/product?product_id=' + productId)
}

export const removeBswFavourite = (affiliateId) => {
    return ssoInstance.post(Config.BSW_API_URL + 'user/v3/favourite?affiliate_id=' + affiliateId);
}

export const removeMegaBrandFavourite = (brandId) => {
    return ssoInstance.put(Config.MEGABRAND_API_URL + 'user/v1.0.3/favBrand/' + brandId);
}

export const getStoreServiceBookingList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.STORE_API_URL + 'v1.0.3/booking/service?start=0&' + queryString)
}

export const getUserBillView = (billNo) => {
    return ssoInstance.get(Config.BSW_API_URL + 'user/v3/user/bill/' + billNo)
}

export const viewStoreOrder = (orderId) => {
    return ssoInstance.get(Config.STORE_API_URL + 'v1.0.3/order/' + orderId)
}

export const cancelBookService = (serviceId, statusReason) => {
    return ssoInstance.post(Config.STORE_API_URL + 'v1.0.3/booking/service/cancel/' + serviceId + '?status_reason=' + statusReason);
}

export const getRechargeInvoice = (obj) => {
    return ssoInstance.post(Config.RECHARGE_API_URL + 'api/get_pdf', obj, { responseType: 'arraybuffer' });
}

export const getStoreBillDetails = (vendorId, billNumber) => {
    return ssoInstance.get(Config.STORE_API_URL + 'v1.0.3/user_bill_detail/' + vendorId + '/bill/' + billNumber);
}

export const getMegaBrandsOrderDetails = (receiptNo) => {
    return ssoInstance.post(Config.MEGABRAND_API_URL + 'user/v1.0.3/voucher-view', { receipt_no: +receiptNo });
}

// Here is an UPV EXCHANGE APIs

export const getBidsList = (page) => {
    return ssoInstance.get(Config.UPV_API_URL + 'api/v1.0.2/upv-exchange/bid-list?page=' + page);
}

export const getMyBidsList = (page) => {
    return ssoInstance.get(Config.UPV_API_URL + 'api/v1.0.2/upv-exchange/' + Auth.user().user_id + '/bid-list?page=' + page);
}

export const getUPVDetail = (upvID) => {
    return ssoInstance.get(Config.UPV_API_URL + 'api/v1.0.2/upv-exchange/' + upvID + '/bid-detail');
}

export const sellBid = (upvID, obj) => {
    return ssoInstance.post(Config.UPV_API_URL + 'api/v1.0.2/upv-exchange/' + upvID + '/update-selling-status/OPEN', obj);
}

export const getUPVSellList = (page) => {
    return ssoInstance.get(Config.UPV_API_URL + 'api/v1.0.2/users/16555/upvs?page=' + page);
}

export const addNewBid = (upvID, obj) => {
    return ssoInstance.post(Config.UPV_API_URL + 'api/v1.0.2/upv-exchange/' + Auth.user().user_id + '/add-bid/' + upvID, obj);
}

export const updateBid = (upvID, bidID, obj) => {
    return ssoInstance.post(Config.UPV_API_URL + 'api/v1.0.2/upv-exchange/' + Auth.user().user_id + '/update-bid/' + upvID + '/' + bidID, obj);
}

export const acceptBid = (upvID, bidID, obj) => {
    return ssoInstance.post(Config.UPV_API_URL + 'api/v1.0.2/upv-exchange/' + upvID + '/hold-bid/' + bidID, obj);
}

export const deleteUPV = (upvID) => {
    return ssoInstance.post(Config.UPV_API_URL + 'api/v1.0.2/upv-exchange/' + upvID + '/update-selling-status/NONE');
}

export const closeUPV = (upvID) => {
    return ssoInstance.post(Config.UPV_API_URL + 'api/v1.0.2/upv-exchange/' + upvID + '/update-selling-status/CLOSED');
}

export const declineBid = (upvID, bidID) => {
    return ssoInstance.post(Config.UPV_API_URL + 'api/v1.0.2/upv-exchange/' + upvID + '/decline-bid/' + bidID);
}

export const transactionList = (page) => {
    return ssoInstance.get(Config.UPV_API_URL + 'api/v1.0.2/upv-exchange/' + Auth.user().user_id + '/bid-txn-history?page=' + page);
}

export const getTransactionID = (obj) => {
    return ssoInstance.post(Config.UPV_API_URL + 'api/v1.0.2/upv-exchange/' + Auth.user().user_id + '/payment/generate-txn-id', obj);
}

export const getTransactionInvoice = (bidPaymentID) => {
    return ssoInstance.post(Config.UPV_API_URL + 'api/v1.0.2/upv-exchange/' + Auth.user().user_id + '/payment/' + bidPaymentID + '/download-invoice', {}, {
        responseType: 'blob'
    });

}

export const UPVConversion = (upvID, obj) => {
    return ssoInstance.post(Config.UPV_API_URL + 'api/v1.0.2/upv-conversion/' + upvID + '/generate-txn-id', obj);
}

export const UPVConversionCallback = (upvID, obj) => {
    return ssoInstance.post(Config.UPV_API_URL + 'api/v1.0.2/upv-conversion/' + upvID + '/callback', obj);
}

export const UPVConversionHistory = (page, oldUpvType, newUpvType) => {
    let filter = "";
    if (oldUpvType !== '') {
        filter += "&old_upv_type=" + oldUpvType;
    }
    if (newUpvType !== '') {
        filter += "&new_upv_type=" + newUpvType;
    }
    return ssoInstance.get(Config.UPV_API_URL + 'api/v1.0.2/upv-conversion/txn-history?page=' + page + filter);
}

export const getConversionInvoice = (upvId, paymentId) => {
    return ssoInstance.post(Config.UPV_API_URL + 'api/v1.0.2/upv-conversion/' + upvId + '/download-invoice/' + paymentId, {}, {
        responseType: 'blob'
    });

}


export const getDMTPDF = (obj) => {
    return ssoInstance.post(Config.RECHARGE_API_URL + 'api/get_dmt_pdf', obj, {
        responseType: 'blob'
    });
};

export const getPackageList = (page) => {
    return ssoInstance.get(Config.PACKAGE_API_URL + 'orderHistory?page=' + page);
};

export const getPackageDetail = (packageID) => {
    return ssoInstance.get(Config.PACKAGE_API_URL + 'orderDetail/' + packageID);
};

export const getHotelList = (page) => {
    return ssoInstance.get(Config.HOTEL_API_URL + 'booking/list?page=' + page);
};

export const getHotelInvoice = (bookingID) => {
    return ssoInstance.get(Config.HOTEL_API_URL + 'booking/' + bookingID + '/invoice', {
        responseType: 'blob'
    });
};

export const getHotelCancelBooking = (bookingID) => {
    return ssoInstance.post(Config.HOTEL_API_URL + 'booking/' + bookingID + '/cancel');
};

export const getUpvReplaceInboxList = (page) => {
    return ssoInstance.get(Config.UPV_API_URL + 'api/v1.0.2/circle-upvs/replacement?page=' + page);
}

export const getUpvReplaceSentList = (page) => {
    return ssoInstance.get(Config.UPV_API_URL + 'api/v1.0.2/circle-upvs/replacement/sent-request-list?page=' + page);
}

export const upvReplaceInboxOption = (upvID, obj, status) => {
    return ssoInstance.post(Config.UPV_API_URL + 'api/v1.0.2/circle-upvs/replacement/' + upvID + '/change-status/' + status + '', obj);
}

export const upvReplaceSentRequestCancel = (upvID, obj) => {
    return ssoInstance.post(Config.UPV_API_URL + 'api/v1.0.2/circle-upvs/replacement/' + upvID + '/remove-replacement-request', obj);
}

export const getHrInquiryList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.INQUIRY_API_URL + 'inquiry/hr?start=0&' + queryString);
}

export const getGrangeInquiryList =()=>{
    return ssoInstance.get(Config.INQUIRY_API_URL + 'inquiry/grange');
}

export const getAquashInquiryList =()=>{
    return ssoInstance.get(Config.INQUIRY_API_URL + 'inquiry/aquash');
}

export const getItInquiryList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.INQUIRY_API_URL + 'inquiry/it?start=0&' + queryString);
}

export const getInsuranceInquiriesList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.INQUIRY_API_URL + 'inquiry/insurance?start=0&' + queryString);
}

export const getLoanInquiriesList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.INQUIRY_API_URL + 'inquiry/loan?start=0&' + queryString);
}

export const getVehicleInquiriesList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.INQUIRY_API_URL + 'inquiry/vehicle?start=0&' + queryString);
}

export const getWebInquiryList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.INQUIRY_API_URL + 'inquiry/web?start=0&' + queryString);
}

export const getFPVLevels = (fpvID, obj) => {
    return ssoInstance.post(Config.FPV_API_URL + 'v3/circle-fpvs/' + fpvID + '/get-immediate-childes', obj);
}

export const getFpvCircleList = (page) => {
    return ssoInstance.get(Config.FPV_API_URL + "v3/circle-fpvs?page=" + page);
}

export const getCircleFPVLevelList = () => {
    return ssoInstance.get(Config.FPV_API_URL + 'v3/circle-fpvs/get-levels-list');
}

export const addFpvCircle = (fpvID, obj) => {
    return ssoInstance.post(Config.FPV_API_URL + 'v3/circle-fpvs/' + fpvID + '/add-referral', obj);
}

export const getUserDetailByFpvId = (fpvId) => {
    return ssoInstance.get(Config.FPV_API_URL + 'v3/circle-fpvs/' + fpvId + '/get-details');
}

export const fpvReplaceRequest = (fpvId, request) => {
    return ssoInstance.post(Config.FPV_API_URL + 'v3/circle-fpvs/replacement/' + fpvId + '/replace-child', request);
}

export const fpvCoinConverzation = (fpvID, amount) => {
    return ssoInstance.post(Config.FPV_API_URL + 'v3/circle-fpvs/' + fpvID + '/convert-coins'
        , { amount: amount }
    );
}

export const getFpvLevelOfCoin = (id) => {
    return ssoInstance.get(Config.FPV_API_URL + 'v3/circle-fpvs/' + id + '/get-convertible-coins')
}

export const getFpvReplaceInboxList = (page) => {
    return ssoInstance.get(Config.FPV_API_URL + 'v3/circle-fpvs/replacement?page=' + page);
}

export const getFpvReplaceSentList = (page) => {
    return ssoInstance.get(Config.FPV_API_URL + 'v3/circle-fpvs/replacement/sent-request-list?page=' + page);
}

export const fpvReplaceInboxOption = (fpvID, obj, status) => {
    return ssoInstance.post(Config.FPV_API_URL + 'v3/circle-fpvs/replacement/' + fpvID + '/change-status/' + status + '', obj);
}

export const fpvReplaceSentRequestCancel = (fpvID, obj) => {
    return ssoInstance.post(Config.FPV_API_URL + 'v3/circle-fpvs/replacement/' + fpvID + '/remove-replacement-request', obj);
}

export const getGrangeOrderList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.GRANGE_API_URL + 'orders?limit=10&' + queryString)
}

export const getGrangeOrderDetails = (oderId) => {
    return ssoInstance.get(Config.GRANGE_API_URL + 'orders/' + oderId)
}

export const getOrderAggregateData = () => {
    return ssoInstance.get(Config.UPV_API_URL + 'api/v1.0.2/users/' + Auth.user().user_id + '/order-aggregate');
}

export const getNewMallOrderList = (params) => {
    return ssoInstance.get(Config.STORE_API_URL + 'v1.0.3/user/get-mall-orders', {
        "page": 1,
        "perPage": params
    })
}

export const getNewRechargeOrderList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.post(Config.NEW_RECHARGE_API_URL + 'user/orders?' + queryString)
}

export const getNewRechargeInvoice = (obj) => {
    return ssoInstance.post(Config.NEW_RECHARGE_API_URL + 'user/get_pdf', obj, { responseType: 'arraybuffer' });
}