import React, { Component } from 'react';
import AllOrders from './Component/AllOrders';

export default class AllOrdersList extends Component{
    constructor(props){
        super(props);
        this.state={
            orderList:[],
            page:1,
            stopLoadMore: false,
            showEmptyStats:false,
            loadingData : true,
            domainVal:'All',
            isLoggedIn:false
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);

        let params={
            page:this.state.page,
        }
        this.getAllOrderList(params)
    }

    getAllOrderList=(params)=>{
        this.props.getAllOrderList(params)
            .then((res)=>{
                const sources = res.data;
                if(sources.status === true){
                    let oldData = this.state.orderList;
                    let newDataFromApi = sources.data.data;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState( {
                        orderList:newData,
                    });
                    if(sources.data.data === ""){
                        this.setState( {
                            showEmptyStats:true,
                        });
                    }
                    this.stopLoading();
                    if(sources.data.current_page === sources.data.last_page){
                        this.setState( {
                            stopLoadMore: true,
                        })
                    }else{
                        this.setState( {
                            stopLoadMore: false,
                        })
                    }
                }else{

                }
            }).catch((error)=>{
            const sources = error.response;
            console.log(sources);
        })
    }

    NextPage= () =>{
        let oldPageNo = this.state.page;
        if(this.state.domainVal === 'All'){
            this.setState({
                page: oldPageNo + 1
            },()=>{
                let params={
                    page:this.state.page,
                }
                this.getAllOrderList(params)
            })
        }else{
            this.setState({
                page: oldPageNo + 1
            },()=>{
                let params={
                    page:this.state.page,
                    domain:this.state.domainVal
                }
                this.getAllOrderList(params)
            })
        }

    }

    getDomainData = (domainVal) => {
        this.setState( {
            orderList:[],
            loadingData:true,
            domainVal:domainVal,
        });
        if(domainVal !== 'All'){
            let params={
                page:1,
                domain:domainVal
            }
            this.getAllOrderList(params)
        }else{
            let params={
                page:1
            }
            this.getAllOrderList(params)
        }
    }

    stopLoading = () => {
        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 1000);
    }

    render(){
        return(
            <React.Fragment>
                <AllOrders
                    loading={this.state.loadingData}
                    orderList={this.state.orderList}
                    NextPage={() => this.NextPage()}
                    stopLoadMore={this.state.stopLoadMore}
                    domainData={(domainVal) => this.getDomainData(domainVal)}
                />
            </React.Fragment>
        );
    }
}