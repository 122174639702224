import React from 'react';
import {Button, Classes, Collapse, Dialog, Intent, Position, Toaster} from "@blueprintjs/core";
import Select from "react-select";
import CircleView from './../../container/circleContainer/circleViewContainer';
import Loader from "../../../../core/components/Loader";
import {isNumber} from "@material-ui/core/es/styles/transitions";
import InsertLoader from '../../../../core/images/loader.gif';

export default class circleTree extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            treeViewOpen:false,
            nextChildLists:[],
            maxCirclesAllowed:null,
            circlesData:[],
            isVisibleAddCircleModal: false,
            referralID: '',
            ownFpvsWithoutTag:[],
            isLoading: false,
            circleFpvInformationStatus: false,
            circleFpvInformation:[],
            isReplaceCircleFpv:false,
            ReplaceCircleStatus:false,
            newChildFpvId:null,
            fpvReplaceRequestStatus:false,
        }
    }

    componentDidMount() {
        this.setState({
            circlesData:this.props.circlesData,
            treeViewOpen:this.props.treeViewOpen,
            ownFpvsWithoutTag:this.props.ownFpvsWithoutTag
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            treeViewOpen : nextProps.treeViewOpen,
            circlesData : nextProps.circlesData,
            ownFpvsWithoutTag: nextProps.ownFpvsWithoutTag
        })
    }



    openAddCircleModal(fpvId,index) {
        if(this.state.ownFpvsWithoutTag && this.state.ownFpvsWithoutTag.length) {
            let title = "Add Circles in FPV Number : " + fpvId;
            this.setState({
                ModalTitle: title,
                addFpvID: fpvId,
                fpvIndex: index,
                isLoading: false,
                isVisibleAddCircleModal: true
            })
        }else{
            Toaster.create({position: Position.TOP}).show({
                message: "Create your circle FPV to add here.",
                intent: Intent.WARNING
            });
        }
    }

    closeAddCircleModal() {
        this.setState({
            isVisibleAddCircleModal: false
        })
    }

    addCircle(fpvID, Index) {

        if (this.state.hasReferralIDError || this.state.referralID === '') {
            Toaster.create({position: Position.TOP}).show({
                message: "Please enter referral FPV ID.",
                intent: Intent.DANGER
            });
        } else {
            this.setState({
                isLoading: true
            });
            let obj = {
                "referrer_fpv_id": fpvID
            }
            this.props.addCircle(this.state.referralID, obj)
                .then((res) => {
                    const sources = res.data;
                    if (sources.status === true) {
                        Toaster.create({position: Position.TOP}).show({
                            message: sources.message,
                            intent: Intent.SUCCESS
                        });

                        this.setState({
                            isLoading:false,
                            //referralID:'',
                            ownFpvsWithoutTag: this.state.ownFpvsWithoutTag.filter(item => item != this.state.referralID)
                        }, () => {
                            let circlesData = this.state.circlesData;

                            if(Index > 0) {
                                this.state.circlesData[Index - 1].child_list.push(sources.data);
                                circlesData[Index - 1].completed_circles += 1;
                            }else{
                                this.state.circlesData[Index].child_list.push(sources.data);
                                circlesData[Index].completed_circles += 1;
                            }

                            this.setState({circlesData},()=>{ console.log(this.state.circlesData)});
                            this.closeAddCircleModal();

                            if(Index < 9){
                                this.props.getNextLevel(this.state.referralID, (Index));
                            }
                        })
                    } else {
                        Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                    }

                    this.closeAddCircleModal();

                }).catch((error) => {
                this.setState({
                    referralID: '',
                    isLoading:false
                })
                this.closeAddCircleModal();
                if (error.response && error.response.status !== 401) {

                    Object.keys(error.response.data.error).forEach((key) => {
                        error.response.data.error[key].forEach((error) => {
                            Toaster.create({position: Position.TOP}).show({
                                message: error,
                                intent: Intent.DANGER
                            });
                        });
                    });

                }
            })
        }
    }

    userFpvInformation = (e,fpvId,lavel) => {
        e.preventDefault();
        this.setState({
            circleFpvInformationStatus:true,
            fpvReplaceRequestStatus:false,
            ReplaceCircleStatus:false,
            fpvInformationId:fpvId,
            circleFpvInformation:null
        })
        this.props.getUserDetailByFpvId(fpvId)
            .then((res) => {
                const sources = res.data;
                if (sources.status === true) {
                    this.setState({
                        circleFpvInformation:sources.data,
                        newChildFpvId:null,
                        isReplaceCircleFpv: lavel === 1 ? true :false
                    })
                } else {
                    Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                }
            }).catch((error) => {
            if (error.response && error.response.status !== 401) {
                Object.keys(error.response.data.error).forEach((key) => {
                    error.response.data.error[key].forEach((error) => {
                        Toaster.create({position: Position.TOP}).show({
                            message: error,
                            intent: Intent.DANGER
                        });
                    });
                });
            }
        })

    }

    fpvReplaceRequest = () => {
        let request = {
            "old_child_fpv_id":this.state.fpvInformationId,
            "new_child_fpv_id":this.state.newChildFpvId
        }
        this.props.fpvReplaceRequest (this.props.fpvID,request)
            .then((res) => {
                const sources = res.data;
                if (sources.status === true) {
                    Toaster.create({position: Position.TOP}).show({
                        message: sources.message,
                        intent: Intent.SUCCESS
                    });
                } else {
                    Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                }
                this.setState({
                    circleFpvInformationStatus: false,
                    circleFpvInformation:null,
                    isReplaceCircleFpv:false,
                    ReplaceCircleStatus:false,
                    fpvReplaceRequestStatus:false,
                    newChildFpvId:null
                })
            }).catch((error) => {
            if (error.response.status == 401) {
                Toaster.create({position: Position.TOP}).show({
                    message: "Please login to continue",
                    intent: Intent.DANGER
                });
            } else {
                if (error.response && error.response.status !== 401) {
                    Object.keys(error.response.data.error).forEach((key) => {
                        error.response.data.error[key].forEach((error) => {
                            Toaster.create({position: Position.TOP}).show({
                                message: error,
                                intent: Intent.DANGER
                            });
                        });
                    });
                }
            }
            this.setState({
                circleFpvInformationStatus: false,
                circleFpvInformation:null,
                isReplaceCircleFpv:false,
                ReplaceCircleStatus:false,
                fpvReplaceRequestStatus:false,
                newChildFpvId:null
            })
        })

    }
    closeCircleFpvInformationModal() {
        this.setState({
            circleFpvInformationStatus: false
        })
    }

    render(){
        const ownFpvsWithoutTag = []
        if(this.state.ownFpvsWithoutTag) {
            this.state.ownFpvsWithoutTag.map((data, key) => {
                ownFpvsWithoutTag.push({value: data, label: 'Referral FPV ID : ' + data});
            })
        }
        return(
            <React.Fragment>
                {
                    this.state.circlesData ?
                        this.state.circlesData.map((res, index) => {
                            return(
                                <div className="pg-orgchart">
                                    <Collapse isOpen={this.state.treeViewOpen} key={index}>
                                        <CircleView
                                            circleData={res}
                                            lavel={(index+1)}
                                            getNextLevelLoading={this.props.getNextLevelLoading}
                                            prevFPVID={this.props.prevFPVID}
                                            getNextLevel={(referralID,Index) => { this.props.getNextLevel(referralID,Index)}}
                                            openAddCircleModal={(fpvId,index) => { this.openAddCircleModal(fpvId,index)}}
                                            userFpvInformation={(e,fpvId,index) => { this.userFpvInformation(e,fpvId,index)}}
                                        />
                                    </Collapse>
                                </div>
                            )
                        })
                        : ''
                }
                <Dialog isOpen={this.state.isVisibleAddCircleModal}
                        title={this.state.ModalTitle}
                        className="bidModal"
                        style={{marginTop: '250px'}}
                        onClose={this.closeAddCircleModal.bind(this)}>
                    <div className="bidForm m-3 text-center">
                        <div className="row">
                            <React.Fragment>
                                <div className="col-12">
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={this.state.referralID}
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={false}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="fpv"
                                        options={ownFpvsWithoutTag}
                                        onChange={(referralID) => {

                                            if(referralID !== '') {
                                                this.setState({
                                                    referralID: referralID.value,
                                                    hasReferralIDError: false
                                                })
                                            }else{
                                                this.setState({
                                                    referralID: ''
                                                })
                                            }

                                        }}
                                    />
                                </div>
                                <div className="col-12">
                                    {
                                        this.state.isLoading ?
                                            <div className="bidFormBtn mt-2 mb-2">
                                                <img src={InsertLoader} alt={""}
                                                     style={{width: '50px', height: '50px'}}/>
                                            </div>
                                            :
                                            <div className="bidFormBtn mt-4 mb-4">
                                                <Button
                                                    text={"Cancel"}
                                                    intent={"default"}
                                                    onClick={() => {
                                                        this.setState({
                                                            isVisibleAddCircleModal: false
                                                        })
                                                    }}
                                                />
                                                < Button
                                                    text={"Submit"}
                                                    intent={"primary"}
                                                    onClick={() => {
                                                        this.addCircle(this.state.addFpvID, this.state.fpvIndex)
                                                    }
                                                    }
                                                />
                                            </div>
                                    }
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                </Dialog>
                <Dialog isOpen={this.state.circleFpvInformationStatus}
                        title={ this.state.fpvInformationId !== undefined ? "FPV No. " + this.state.fpvInformationId : ''}
                        className="bidModal bp3-dialog-large CircleFpvInformationModal"
                        onClose={this.closeCircleFpvInformationModal.bind(this)}
                >
                    <div className={Classes.DIALOG_BODY+ ' bidForm text-center mb-1'}>
                        <div className="row">
                            {
                                this.state.circleFpvInformation ?
                                    <React.Fragment>
                                        <div className="col-12 text-center">
                                            <img
                                                src={this.state.circleFpvInformation.avatar_url}
                                                alt=""
                                                className="img-fluid img-thumbnail"
                                            />
                                            <h3>{this.state.circleFpvInformation.username}</h3>
                                            <h4>{this.state.circleFpvInformation.email}</h4>
                                            <h4>{this.state.circleFpvInformation.mobile}</h4>
                                        </div>

                                        <div className="col-12 border-top mt-2 px-0">
                                            <div className="d-sm-flex align-items-center justify-content-sm-between">
                                                <p>Generated on: {this.state.circleFpvInformation.created_at}</p>
                                                <p>Added in Circle: {this.state.circleFpvInformation.tagging_date}</p>
                                            </div>
                                        </div>
                                        {
                                            this.state.isReplaceCircleFpv && this.state.ReplaceCircleStatus === false ?
                                                <div className="col-12 border-top mt-2 px-0">
                                                    <div className="mt-4 mb-4">
                                                        <center>
                                                            <Button
                                                                className="bp3-button ml-3"
                                                                text="Replace FPV"
                                                                intent={Intent.PRIMARY}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        ReplaceCircleStatus: !this.state.ReplaceCircleStatus
                                                                    })
                                                                }}
                                                            />
                                                        </center>
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                    </React.Fragment>
                                    :
                                    <div className="col-12 text-center">
                                        <Loader/>
                                    </div>
                            }
                        </div>
                    </div>
                    {
                        this.state.ReplaceCircleStatus === true ?
                            <div className={Classes.DIALOG_FOOTER} >
                                <h3>Replace FPV</h3>
                                <h5>By this option, you can replace your non performing child FPV</h5>
                                <div className="bp3-input-group">
                                    <input
                                        type="number"
                                        className="bp3-input"
                                        required
                                        min={0}
                                        onChange={(e)=>{
                                            if(isNumber(e.target.value)) {
                                                this.setState({
                                                    newChildFpvId: e.target.value
                                                })
                                            }
                                        }}
                                    />
                                    <label className="form-control-placeholder">New child FPV No.</label>
                                </div>

                                <div className="mt-5 d-flex justify-content-end">
                                    {
                                        this.state.fpvReplaceRequestStatus ?
                                            <div className="bidFormBtn mt-2 mb-2">
                                                <img src={InsertLoader} alt={""}
                                                     style={{width: '50px', height: '50px'}}/>
                                            </div>
                                            :
                                            <React.Fragment>
                                                <Button
                                                    className="bp3-button bp3-outline bg-white"
                                                    text="Cancel"
                                                    onClick={()=>{
                                                        this.setState({
                                                            ReplaceCircleStatus:!this.state.ReplaceCircleStatus
                                                        })
                                                    }}
                                                />
                                                <Button
                                                    className="bp3-button ml-3"
                                                    text="Replace"
                                                    intent={Intent.PRIMARY}
                                                    onClick={()=>{
                                                        this.setState({
                                                            fpvReplaceRequestStatus:true
                                                        },()=>{
                                                            this.fpvReplaceRequest();
                                                        })

                                                    }}
                                                />
                                            </React.Fragment>
                                    }
                                </div>
                            </div>
                            :''
                    }
                </Dialog>
            </React.Fragment>
        )
    }
}