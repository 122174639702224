import React, { Component } from 'react';
import AllorderEmptyStates from "../../../core/images/empty-state/Allorder.svg";
import ViewBill from '../Container/ViewBill';
import PdfLogo from '../../../core/images/png/pdfLogo.png';

export default class BSW extends Component{
    constructor(props){
        super(props);
        this.state={
            orderList:[],
            loading: true,
        };
    }

    componentDidMount() {

        this.setState( {
            loading: this.props.loading,
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            if(this.props.stopLoadMore === false){
                this.props.NextPage()
            }
        }
    }


    renderStatusHtml(status) {

        switch (status) {
            case "0":
                return <label className="label text-warning">Pending</label>;
            case "1":
                return <label className="badge text-primary">Verify</label>;
            case "2":
                return <label className="label text-success">Accepted</label>;
            case "3":
                return <label className="label text-danger">Rejected</label>;
            default:
                return <label className="label text-default">Unresolved</label>;
        }
    }

    renderFileHtml(fileURl){
        let fileExt = fileURl.split('.').pop();
        if(fileExt === "pdf"){
            return  <img src={PdfLogo} alt="" />;
        }else{
            return  <img src={fileURl} alt="" />;
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="megabrand">
					<div className="bsw" id={"allBooking"} style={{'height':'100%','maxHeight':'590px','overflowY':'scroll'}} onScroll={this.handleScroll}>
						<table className="bp3-html-table table-borderless">
							<thead>
								<tr>
									<th>Product Details</th>
									<th>Order ID</th>
									<th>Order Date</th>
									<th>Price</th>
                                    <th>Cashback Type</th>
									<th>Status</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{
									this.state.loading ?
										<tr>
											<td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
											<td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
											<td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
											<td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
											<td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
											<td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
											<td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
										</tr>
										:
										this.props.orderList.length ?
											this.props.orderList.map((orders, key) => {
												return<tr key={key}>
														<td>
															<div className="orderTable">
																<div className="image hidden-xs hidden-sm hidden-md visible-xl visible-lg">
																	{
                                                                        this.renderFileHtml(orders.bill_url)
                                                                    }
																</div>
																<div className="title">
																	<p>{orders.brand_name}</p>
																	<span><span className="image hidden-sm hidden-md visible-xl visible-lg visivle-xs">Product Name :</span>
																		{
																			orders.product_name !== null ?
																			orders.product_name.length > 22 ?
																			orders.product_name.substr(0, 22) + "..."
																				: orders.product_name
																				: "--"
																		}
																	</span>
																</div>
															</div>
														</td>
														<td>{orders.bill_number}</td>
														<td>{orders.date_of_purchase}</td>
														<td><b>₹ {orders.bill_amount}</b></td>
                                                        <td>{orders.cb_choice_title}</td>
														<td>
															<b>
																{
																	this.renderStatusHtml(orders.is_approove)
																}
															</b>
														</td>
														<td>
															<ViewBill isOpen={this.state.isOpen} billNo={orders.bill_number}/>
														</td>
													</tr>
											})
											:

											<tr>
												<td colSpan="6" align="center">
													<div className="text-center">
														<img src={AllorderEmptyStates} alt=""/>
														<p className="mt-3">No transactions for the selected option</p>
													</div>
												</td>
											</tr>

								}

							</tbody>
						</table>
                        {
                            this.state.loading ?
                                null
                                :
                                this.props.stopLoadMore ?
                                    ""
                                    :
                                    <React.Fragment>
                                        <center>
                                            <div className="my-3">
                                                <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                            </div>
                                        </center>
                                    </React.Fragment>
                        }
					</div>
                </div>
            </React.Fragment>
        );
    }
}
