import React, { Component } from 'react';
import MallOrder from './Container/MallOrders'
import {Auth, NoLogin} from "gg-react-utilities";

export default class MallIndex extends Component{
    constructor(props){
        super(props);
        this.state={
            selectedOption:1,
            loadingData : true,
            isLoggedIn:false
        }
    }

    componentDidMount() {
        this.checkAuth();

        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 1000);
    }

    async checkAuth(){
        Auth.isLoggedInAsync().then((res) => {
            this.setState({isLoggedIn:res})
        });
    }

    render(){
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }

        return(
            <React.Fragment>
                <MallOrder loading={this.state.loadingData}/>
            </React.Fragment>
        );
    }
}