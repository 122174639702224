import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import CircleList from '../../components/circleList';
import { getFpvCircleList, addFpvCircle } from '../../../../core/actions/action';
import { getCircleListAction } from '../../../../core/actions';

const mapStateToProps = state => {
    return{
        circleList:state.cashbackReducer.circleList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getCircleList :(page) => {
            return getFpvCircleList(page)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(getCircleListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(getCircleListAction(null));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
        addCircle : (fpvID, obj) => {
            return addFpvCircle(fpvID, obj)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CircleList);
