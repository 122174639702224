import React from 'react';
import { Tab, Tabs } from "@blueprintjs/core";
import TopBar from "../core/components/TopBar";
import GGMoney from './Money';
import GGCoin from './Coin';
import {Auth, NoLogin} from "gg-react-utilities";
import PageTitle from "../core/components/PageTitle";

export default class MoneyRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn:false
        }
    }

    componentDidMount() {
        this.checkAuth();
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1);
    }

    render(){
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <PageTitle title="Passbook - Global Garner"/>
                        <TopBar title="GG Coin Request" />
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }

        return(
            <div className="container">
                <TopBar title="My Passbook" />
                <div className="upvWrapper mb-4">
                    <div className="upvContainer py-0">
                        <Tabs
                            animate={false}
                            id="TabsExample"
                            renderActiveTabPanelOnly={true} vertical={false}>
                            <Tab id="rx" title="GG-Money" panel={<GGMoney />} />
                            <Tab id="ng" title="GG-Coin" panel={<GGCoin />} />
                        </Tabs>
                    </div>
                </div>
                <div className="page-content-right">
                </div>
            </div>
        );
    }
}