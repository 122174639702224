import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import fpvReplaceInbox from '../components/fpvReplaceInbox';
import {getFpvReplaceInboxList, fpvReplaceInboxOption} from '../../../core/actions/action';
import { getFpvReplaceInboxListAction } from '../../../core/actions';

const mapStateToProps = state => {

    return{
        fpvReplaceInboxList:state.cashbackReducer.fpvReplaceInboxList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getFpvReplaceInboxList :(page) => {
            return getFpvReplaceInboxList(page)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(getFpvReplaceInboxListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(getFpvReplaceInboxListAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
        fpvReplaceInboxOption:(fpvID, obj, status) => {
            return fpvReplaceInboxOption(fpvID, obj, status)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(fpvReplaceInbox);
