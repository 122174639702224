import {connect} from 'react-redux';

import RPP from '../components/rpp';
import { getRPPCurrentList, getRPPOldList, UPVConversion } from '../../../core/actions/action';

const mapStateToProps = state => {

    return{
        rppCurrentList:state.cashbackReducer.rppCurrentList,
        rppOldList:state.cashbackReducer.rppOldList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getRPPCurrentList :(page) => {
            return getRPPCurrentList(page)

        },
        getRPPOldList :(page) => {
            return getRPPOldList(page)
        },
        UPVConversion:(upvID) => {
            return UPVConversion(upvID)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RPP);
