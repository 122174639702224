let initialState = {
    bidsList:[],
    myBidsList:[],
    upvDetail: null,
    sellList:[]
};

const upvExchange = (state = initialState, action) => {

    switch (action.type) {
        case 'GET_BIDS_LIST':
            return {...state, bidsList: action.sources}
        case 'GET_MYBIDS_LIST':
            return {...state, myBidsList: action.sources}
        case 'GET_UPV_DETAIL':
            return {...state, upvDetail: action.sources}
        case 'UPV_SELL_LIST':
            return {...state, sellList: action.sources}
        default:
            return state
    }
}

export default upvExchange;
