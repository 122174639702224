import {connect} from 'react-redux';
import {getGGVoucherInvoice} from '../../../core/actions/action';
import GGVoucherListCardComponent from '../components/GGVoucherOrder/GGVoucherListCardComponent';

const mapStateToProps = state => {

    return{
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getGGVoucherInvoice:(orderId) => {
            return getGGVoucherInvoice(orderId);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GGVoucherListCardComponent);
