import React from 'react';
import LinkedIN from '../../../core/images/reffrals/LinkedIN.png';
import Google from '../../../core/images/reffrals/google.png';
import MetaTag from './MetaTag'
import config from '../../../../src/Config'
import {
    WhatsappShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    FacebookShareButton
} from "react-share";
import {
    WhatsappIcon,
    TwitterIcon,
    LinkedinIcon,
    FacebookIcon
} from "react-share";
export default class AllStatus extends React.Component {
    constructor(props){
        super(props);

        this.state={
            base64ImageUrl: '',
            title:'',
            description:'',
            loading: true,
        };
    }

    componentDidMount(){

        this.setState( {
            base64ImageUrl: this.props.base64ImageUrl,
            title:this.props.title,
            description:this.props.description,
            loading: this.props.loading,
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    render(){
        let googleLink = "https://plus.google.com/share?url=" + this.state.base64ImageUrl;
        let linkedInLink = "http://www.linkedin.com/shareArticle?mini=true&url=" + this.state.base64ImageUrl;
        let image = this.state.base64ImageUrl.replace('https://tinyurl.com/','')
        let shareLink = encodeURI(config.APP_URL +"/referrals/image?url="+image+"&title="+this.state.title+"&description="+this.state.description);
      return(
            <React.Fragment>
				<div className="col-12">
                  <MetaTag image={this.state.base64ImageUrl}></MetaTag>
					<div className="row">					
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-1 pb-1 px-s-0">
							{
								this.state.loading ?
									<div className="bp3-skeleton" style={{width: "100%", height: "150px"}}></div>
									:								
									<div className="social">
										<h1 style={{color:"black"}}>{this.props.text}</h1>
                                        <div className={"row"}>
                                            <div className={" col-xl-2 col-lg-2 col-2 col-sm-2"}>
                                              <FacebookShareButton
                                                  url={shareLink}
                                                  quote={this.state.title}
                                                  separator=":::  "
                                              >
                                                  <FacebookIcon size={36} />
                                              </FacebookShareButton>
                                            </div>
                                            <div className={" col-xl-2 col-lg-2 col-2 col-sm-2"}>
                                              <WhatsappShareButton
                                                  url={shareLink}
                                                  title={this.state.title}
                                                  separator=":::  "
                                                
                                              >
                                                  <WhatsappIcon size={36} />
                                              </WhatsappShareButton>
                                            </div>
                                            <div className={" col-xl-2 col-lg-2 col-2 col-sm-2"}>
                                              <TwitterShareButton
                                                  url={shareLink}
                                                  title={this.state.title}
                                                  separator=":::  "
                                              >
                                                  <TwitterIcon size={36} />
                                              </TwitterShareButton>
                                            </div>
                                          <div className={"col-xl-2 col-lg-2 col-2 col-sm-2"}>
                                              <a href={linkedInLink} target="_blank" title="Click to share" rel="noopener noreferrer">
                                                  <img src={LinkedIN} className="float-left ml-1" alt="" />
                                              </a>
                                          </div>
                                            <div className={"col-xl-2 col-lg-2 col-2 col-sm-2"}>
                                                <a href={googleLink} target="_blank" title="Click to share" rel="noopener noreferrer">
                                                    <img src={Google} className="float-left ml-1" alt=""/>
                                                </a>
                                            </div>
                                        </div>
									</div>
							}
						</div>
					</div>
				</div>
            </React.Fragment>
        );
    }
}