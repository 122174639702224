export const ENV = '.com';
const config = {
   SHOP_URL: 'https://shop.globalgarner' + ENV + '/',
   MALL_URL: 'https://gg-mall.globalgarner' + ENV + '/',
   APP_URL: 'https://dashboard.globalgarner' + ENV,
   ACCOUNT_API_URL: 'https://accounts.globalgarner' + ENV + '/',
   UPOSE_API_URL: 'https://upos-api.globalgarner' + ENV + '/',
   ADS_API_URL: 'https://ads.globalgarner' + ENV + '/',
   UPV_API_URL: 'https://upv.globalgarner' + ENV + '/',
   FPV_API_URL: 'https://fpv.globalgarner' + ENV + '/',
   STORE_API_URL: 'https://store-api.globalgarner' + ENV + '/',
   STATS_URL: 'https://stats.globalgarner' + ENV + '/',
   WALLET_URL: 'https://wallet.globalgarner' + ENV + '/',
   HOTEL_URL: 'https://hotel.globalgarner' + ENV + '/',
   MEGABRAND_API_URL: 'https://mega-brands-api.globalgarner' + ENV + '/',
   VOUCHER_API_URL: 'https://voucher.globalgarner' + ENV + '/',
   BSW_API_URL: 'https://best-shopping-websites-api.globalgarner' + ENV + '/',
   BUS_API_URL: 'https://bus-api.globalgarner' + ENV + '/',
   RECHARGE_API_URL: 'https://recharge-api.globalgarner' + ENV + '/',
   NEW_RECHARGE_API_URL: 'https://recharge-api-new.globalgarner' + ENV + '/',
   FLIGHT_API_URL: 'https://flight-api.globalgarner' + ENV + '/',
   RAILBHOJ_API_URL: 'https://railbhoj-api.globalgarner' + ENV + '/',
   ADMIN_API_URL: 'https://admin.globalgarner' + ENV + '/api/',
   DEFAULT_IMAGE_URL: 'https://s3.ap-south-1.amazonaws.com/gg-statics/gg-statics-staging/Default.png',
   STORE_FAV: 'https://store.globalgarner' + ENV + '/',
   BSW_FAV: 'https://best-shopping-websites.globalgarner' + ENV + '/',
   MEGABRAND_FAV: 'https://mega-brands.globalgarner' + ENV + '/',
   MEGABRAND_DEFAULT_IMAGE: 'https://s3.ap-south-1.amazonaws.com/gg-mega-brands/brand_images/default.png',
   PACKAGE_API_URL: 'https://holiday-api.globalgarner' + ENV + '/user/packege/v1.0.3/',
   HOTEL_API_URL: 'https://hotel-api.globalgarner' + ENV + '/api/',
   INQUIRY_API_URL: 'https://inquiry-api.globalgarner' + ENV + '/api/v1/',
   SHOP_API_URL: 'https://shop.globalgarner' + ENV + '/',
   YOUTUBE_VIDEO_LINK: 'https://www.youtube.com/watch?v=',
   UPV_CASHBACK_VIDEO_URL: '3a3Twhjsavc',
   FPV_CASHBACK_VIDEO_URL: 'JMm-fFpltbU',
   Extension: ENV,
   IS_MAINTENANCE_MODE: false
};

export const packageConfigure = {
   APP_URL: 'https://dashboard.globalgarnaer' + ENV + '/',
   ACCOUNT_API_URL: 'https://accounts.globalgarner' + ENV + '/',
   DASHBOARD_URL: 'https://dashboard.globalgarnaer' + ENV + '/',
   //USER_PROFILE_URL:'https://dashboard.globalgarnaer.'+ENV+'/profile',
   MEGABRAND_API_URL: 'https://mega-brands-api.globalgarner' + ENV + '/user/v1.0.3/',
   APP_ENV: 'production',
   Extension: ENV
}

export default config;
