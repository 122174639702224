import React from 'react';
import TopBar from "../core/components/TopBar";
import PageTitle from "../core/components/PageTitle";
import RewardList from "./container/rewardContainer";
import {Auth, NoLogin} from "gg-react-utilities";

export default class OtherRewards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn:false,
            loadingData : true,
        }
    }

    componentDidMount() {
        this.checkAuth();

        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 1000);
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1);
    }

    render(){
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <PageTitle title="Other Rewards - Global Garner"/>
                        <TopBar title="GG other Rewards" />
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }
        return(
            <div className="container">
                <PageTitle title="Other Rewards - Global Garner"/>
                <TopBar title="GG Other Rewards"/>
                <RewardList loading={this.state.loadingData}/>
            </div>
        );
    }
}