import {connect} from 'react-redux';
import {getVendorProductWishList, removeFavouriteProduct} from '../../../core/actions/action';
import Wishlist from '../Component/Wishlist';

const mapStateToProps = state => {

    return{
        productFavouriteList:state.Favourite.productFavouriteList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getVendorProductWishList:(params) => {
            return getVendorProductWishList(params);
        },

        removeFavouriteProduct:(productId) => {
            return removeFavouriteProduct(productId);
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);
