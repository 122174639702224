import {connect} from 'react-redux';
import {getNewRechargeOrderList} from '../../../../core/actions/action';
import Recharge from '../Index';

const mapStateToProps = state => {

    return{
        rechargeOrderList:state.Recharge.rechargeOrderList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getRechargeOrderList:(params) => {
            return getNewRechargeOrderList(params);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recharge);
