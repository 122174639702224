let initialState = {
    vendorFavouriteList:[],
    uposeFavouriteList:[],
    productFavouriteList:[],
    bswFavouriteList:[],
    megaBrandFavouriteList:[],
    removeFavouriteVendor:null,
    removeFavouriteUpose:null,
    removeFavouriteProduct:null,
    removeBswFavourite:null,
    removeMegaBrandsFavourite:null,

};

const Favourite = (state = initialState, action) => {

    switch (action.type) {

        case 'VENDOR_FAVOURITE_LIST':
            return {...state, vendorFavouriteList: action.sources}
        case 'UPOSE_FAVOURITE_LIST':
            return { ...state, uposeFavouriteList: action.sources }    
        case 'PRODUCT_FAVOURITE_LIST':
            return {...state, productFavouriteList: action.sources}
        case 'BSW_FAVOURITE_LIST':
            return {...state, bswFavouriteList: action.sources}
        case 'MEGA_BRAND_FAVOURITE_LIST':
            return {...state, megaBrandFavouriteList: action.sources}
        case 'REMOVE_VENDOR_FAVOURITE':
            return {...state, removeFavouriteVendor: action.sources}
        case 'REMOVE_UPOSE_FAVOURITE':
            return { ...state, removeFavouriteUpose: action.sources }
        case 'REMOVE_VENDOR_PRODUCT_FAVOURITE':
            return {...state, removeFavouriteProduct: action.sources}
        case 'REMOVE_BSW_FAVOURITE':
            return {...state, removeFavouriteUpose: action.sources}
        case 'REMOVE_MEGA_BRAND_FAVOURITE':
            return {...state, removeMegaBrandsFavourite: action.sources}
        default:
            return state
    }
}

export default Favourite;
