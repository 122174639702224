import {connect} from 'react-redux';

import { getUPVSellList, sellBid } from '../../core/actions/action';
import BidList from "../components/BidList";

const mapStateToProps = state => {

    return{
        sellList:state.upvExchange.sellList,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUPVSellList :(page) => {
            return getUPVSellList(page)
        },
        sellBid:(upvID, obj) => {
            return sellBid(upvID, obj)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BidList);
