import React from 'react';
import pendingBills from '../../core/images/stats/GGStates8.svg';
import Verified from '../../core/images/stats/GGStates7.svg';
import Accepted from '../../core/images/stats/GGStates5.svg';
import TopBar from "../../core/components/TopBar";
import {Tab, Tabs} from "@blueprintjs/core";
import MyStats from "../Mystats/Container/MyStats";

export default class GgStats extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ggStatsList:[],
        }
    }

    componentDidMount() {
        this.getUsreRefList()
    }

    getUsreRefList=()=>{
        setTimeout(() => {
            this.props.getGgStatsList()
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        this.setState( {
                            ggStatsList:sources.data,
                        });
                    }else{

                    }
                }).catch((error)=>{
                const sources = error.response;
                console.log(sources);
            })
        }, 1000);
    }

    ggStats = () => {
        return(
        this.state.ggStatsList.common.length ?
            this.state.ggStatsList.common.map((user, key) => {
                if((user.type === "registered_users") && (user.web_status === 1)){
                return <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mb-4" key={key}>
                    <div className="pendingBills text-right state-bg">
                        <img src={pendingBills} alt=""/>
                        <span className="bill-count">{user.type_value}</span><br/>
                        <span className="heading"> {user.title}</span>
                    </div>
                </div>
                }
                else if((user.type === "total_cashback") && (user.web_status === 1)){
                    return <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mb-4" key={key}>
                        <div className="pendingBills text-right state-bg">
                            <img src={Verified} alt=""/>
                            <span className="bill-count">{user.type_value}</span><br/>
                            <span className="heading"> {user.title}</span>
                        </div>
                    </div>
                }
                else if((user.type === "total_gg_coin_cashback") && (user.web_status === 1)){
                    return <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mb-4" key={key}>
                        <div className="pendingBills text-right state-bg">
                            <img src={Accepted} alt=""/>
                            <span className="bill-count">{user.type_value}</span><br/>
                            <span className="heading"> {user.title}</span>
                        </div>
                    </div>
                }
            })
            :
            ""
        )
    }

    ggOtherStats = () => {
        return(

            <div className="col-12">
                <div className="cb-status-tab">
                    <Tabs className="cb-status-tab" animate={false} id="TabsExample" renderActiveTabPanelOnly={true} vertical={false} >
                        <Tab className="upv-tab"  id="ng" title="UPV" panel={this.upvState()} />
                        <Tab className="fpv-tab" id="rx" title="FPV" panel={this.fpvState()} />
                    </Tabs>
                </div>
            </div>
        );
    }

    upvState = () => {
        return(
            <div className="col-12 p-0">
                <div className="row">
                    {
                        this.state.ggStatsList.upv.length ?
                            this.state.ggStatsList.upv.map((upv, key) => {
                                if (upv.web_status === 1) {
                                    return <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-6 pt-3 pb-3" key={key}>
                                        <div className="pendingBills text-center">
                                            <span className="bill-title">{upv.key}</span>
                                            {
                                                upv.value.map((upvDetail, key1) => {
                                                    if(upvDetail.web_status == 1) {
                                                        return <div className="pt-3" key={key1}>
                                                            <span className="heading">{upvDetail.title}</span><br/>
                                                            <span className="bill-count">{upvDetail.type_value}</span>
                                                        </div>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            })
                            :
                            ""
                    }
                </div>
            </div>
        )
    }

    fpvState = () => {
        return(
            <div className="col-12 p-0">
                <div className="row">
                    {
                        this.state.ggStatsList.fpv.length ?
                            this.state.ggStatsList.fpv.map((fpv, key) => {
                                if (fpv.web_status === 1) {
                                    return <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-6 pt-3 pb-3" key={key}>
                                        <div className="pendingBills text-center" style={{"minHeight": "221px"}}>
                                            <span className="bill-title ">{fpv.key}</span>
                                            {
                                                fpv.value.map((fpvDetail, key1) => {
                                                    if(fpvDetail.web_status == 1) {
                                                        return <div className="pt-3" key={key1}>
                                                            <span className="heading">{fpvDetail.title}</span><br/>
                                                            <span className="bill-count">{fpvDetail.type_value}</span>
                                                        </div>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            })
                            :
                            ""
                    }
                </div>
            </div>
        )
    }
    render(){
        return(
            <div className="row my-5 myStats">
                {
                    this.state.ggStatsList.length === 0 ?
                        <React.Fragment>

                        <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mb-4" >
                                <div className="pendingBills text-right state-bg">
                                    <img src={pendingBills} alt="" className="bp3-skeleton"/>
                                    <span className="bill-count bp3-skeleton">value</span><br/>
                                    <span className="heading bp3-skeleton"> Lorem Ipsum</span>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mb-4">
                                <div className="pendingBills text-right state-bg">
                                    <img src={Verified} alt=""/>
                                    <span className="bill-count bp3-skeleton">value</span><br/>
                                    <span className="heading bp3-skeleton"> Lorem Ipsum</span>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mb-4">
                                <div className="pendingBills text-right state-bg">
                                    <img src={Accepted} alt=""/>
                                    <span className="bill-count bp3-skeleton">value</span><br/>
                                    <span className="heading bp3-skeleton"> Lorem Ipsum</span>
                                </div>
                            </div>

                            <div className="col-xl-12 col-lg-12 col-sm-6 col-12 mb-4">
                                <div className="pendingBills text-center">
                                    <span className="heading bp3-skeleton"> Lorem Ipsum giving information</span>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-4">
                                <div className="pendingBills text-center">
                                    <span className="bill-count bp3-skeleton">value</span><br/>
                                    <span className="heading bp3-skeleton"> Lorem Ipsum giving information</span>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-4">
                                <div className="pendingBills text-center">
                                    <span className="bill-count bp3-skeleton">value</span><br/>
                                    <span className="heading bp3-skeleton"> Lorem Ipsum giving information</span>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-4">
                                <div className="pendingBills text-center">
                                    <span className="bill-count bp3-skeleton">value</span><br/>
                                    <span className="heading bp3-skeleton"> Lorem Ipsum giving information</span>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-4">
                                <div className="pendingBills text-center">
                                    <span className="bill-count bp3-skeleton">value</span><br/>
                                    <span className="heading bp3-skeleton"> Lorem Ipsum giving information</span>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-4">
                                <div className="pendingBills text-center">
                                    <span className="bill-count bp3-skeleton">value</span><br/>
                                    <span className="heading bp3-skeleton"> Lorem Ipsum giving information</span>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-4">
                                <div className="pendingBills text-center">
                                    <span className="bill-count bp3-skeleton">value</span><br/>
                                    <span className="heading bp3-skeleton"> Lorem Ipsum giving information</span>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-4">
                                <div className="pendingBills text-center">
                                    <span className="bill-count bp3-skeleton">value</span><br/>
                                    <span className="heading bp3-skeleton"> Lorem Ipsum giving information</span>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-4">
                                <div className="pendingBills text-center">
                                    <span className="bill-count bp3-skeleton">value</span><br/>
                                    <span className="heading bp3-skeleton"> Lorem Ipsum giving information</span>
                                </div>
                            </div>
                        </React.Fragment>

                        :
                        <React.Fragment>
                            {this.ggStats()}
                            {this.ggOtherStats()}
                        </React.Fragment>
                }
            </div>
        );
    }
}