import React from 'react';
import AllorderEmptyStates from "../../../../core/images/empty-state/Allorder.svg";
import {Button, Intent, Position, Toaster} from "@blueprintjs/core";
import InsertLoader from "../../../../core/images/loader.gif";
import GGVoucherListCard from "../../Container/GGVoucherListCardContainer"

export default class GGVoucherOrderComponent extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            ggVoucherOrderList:[],
            loading: true,
        }
    }

    componentDidMount(){
        this.setState( {
            loading: this.props.loading,
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('GGVoucherBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            if(this.props.stopLoadMore === false){
                this.props.NextPage()
            }
        }
    }

    downloadInvoice(orderId){
        this.props.getGGVoucherInvoice(orderId)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "GG-Voucher-Invoice.pdf");
                document.body.appendChild(link);
                link.click();
                this.setState({
                    downloadLoader:false
                })
            })
            .catch((error) =>{
                Toaster.create({position: Position.TOP}).show({message:"Something went wrong",intent: Intent.DANGER});
                this.setState({
                    downloadLoader:false
                })
            })
    }

    render(){
        return(
            <React.Fragment>
                <div className="ggvoucher"  id={"GGVoucherBooking"} style={{'height':'100%','maxHeight':'590px','overflowY':'scroll'}} onScroll={this.handleScroll}>
                    <table className="bp3-html-table table-borderless">
                        <thead>
                            <tr>
                                <th className="pl-0">Order ID</th>
                                <th className="pr-3">Cashback Type</th>
                                <th className="pr-3">Amount</th>
                                <th className="pr-3">Payment Gateway Charge</th>
                                <th>Total</th>
                                <th className="pr-3">Payment Status</th>
                                <th>Order Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.loading ?
                                <tr>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                </tr>
                                :
                                this.props.voucherOrderList.length ?
                                    this.props.voucherOrderList.map((orders, key) => {
                                        return(
                                            <GGVoucherListCard OrderList={orders} key={key}/>
                                        )

                                    })
                                :
                                    <tr>
                                        <td colSpan="8" align="center">
                                            <div className="text-center">
                                                <img src={AllorderEmptyStates} alt=""/>
                                                <p className="mt-3">No transactions for the selected option</p>
                                            </div>
                                        </td>
                                    </tr>
                        }
                        </tbody>
                    </table>
                    {
                        this.state.loading ?
                            null
                            :
                            this.props.stopLoadMore ?
                                ""
                                :
                            <React.Fragment>
                                <center>
                                    <div className="my-3">
                                        <img src={require('../../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                    </div>
                                </center>
                            </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }
}