import React, {Component} from 'react';
import {Button, FormGroup, Intent, Position, Toaster} from "@blueprintjs/core";
import {Textbox} from 'react-inputs-validation';
import Countdown from 'react-countdown-now';

export default class SendMoney extends Component{
    constructor(props){
        super(props);
        this.state={
            timer:Date.now() + 60000,
            amount:"",
            hasAmountError:true,
            name:"",
            hasNameError:true,
            account:"",
            hasAccountError:true,
            ifsc:"",
            hasIFSCError:true,
            otp:"",
            hasOTPError:true,
            loader:false,
            validate:false,
			showOTP:false,
			hasOTPErrorMsg:"",
            isDisable:false,
			timerStatus:false,
			confirmAccount:"",
			hasConfirmAccountError:true,
        }
        this.onSubmitOTP=this.onSubmitOTP.bind(this);
        this.onSubmitRequest=this.onSubmitRequest.bind(this);
    }

    toggleValidating(validate) {
        this.setState({ validate });
    }

    onSubmitRequest(){
        this.toggleValidating(true);
		this.setState({
			loader:true
		})

		if(this.state.hasOTPError !== false){
			this.setState({
				hasOTPErrorMsg:'OTP cannot be empty',
			})
		}else{
			this.setState({
				hasOTPErrorMsg:'',
			})
		}
		// console.log(this.state.hasOTPErrorMsg)

        if(this.state.hasAmountError===false &&
            this.state.hasNameError===false &&
            this.state.hasAccountError===false &&
            this.state.hasIFSCError===false &&
			this.state.hasOTPError===false &&
            this.state.hasConfirmAccountError===false){

            let obj = {
                "amount":this.state.amount,
                "account_holder_name":this.state.name,
                "account_number":this.state.account,
                "ifsc_code":this.state.ifsc,
                "otp":this.state.otp
            }
            this.props.saveWithdrawalRequest(obj);
            this.setState({
				amount:"",
				hasAmountError:true,
				name:"",
				hasNameError:true,
				account:"",
				hasAccountError:true,
				ifsc:"",
				hasIFSCError:true,
				otp:"",
				hasOTPError:true,
				loader:false,
				validate:false,
				showOTP:false,
				isDisable:false,
				otpMessage:"",
				timerStatus:false,
				confirmAccount:"",
				hasConfirmAccountError:true,
			})
        }else{
            console.error("Please resolve all errors before submit the form");
			this.setState({
				loader:false
			})
        }
    }

    onSubmitOTP(){
        this.toggleValidating(true);

        if(this.state.hasAmountError===false &&
            this.state.hasNameError===false &&
            this.state.hasAccountError===false &&
            this.state.hasIFSCError===false &&
			this.state.hasConfirmAccountError===false){

            let obj = {
                "amount":this.state.amount,
                "account_holder_name":this.state.name,
                "account_number":this.state.account,
                "ifsc_code":this.state.ifsc
            }
			this.setState({
				loader:true,
			})
            this.props.getOTPRequest(obj)
				.then((res)=>{
					const sources = res.data;
					if(sources.status===true){
						Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
						this.setState({
							showOTP:true,
							timerStatus:true,
							isDisable:true,
							timer:Date.now() + 60000,
							loader:false
						})
					}else{
						Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
					}
				}).catch((res)=>{
					const sources =  res.response;
					if((sources.data.error.amount) && (sources.data.error.amount.length > 0)){
						Toaster.create({position: Position.TOP}).show({message:sources.data.error.amount ,intent: Intent.DANGER});
					}else{
						Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
					}
					this.setState({
						loader:false,
					})
				})



        }else{
            console.error("Please resolve all errors before submit the form");
			this.setState({
				loader:false
			})
        }
    }

    componentWillReceiveProps(nextProps) {
    	/*this.setState({
			otpMessage:nextProps.otpRequest.message,

		})

		if(nextProps.otpRequest.status === true){

		}*/
    }

	onResetRequest = () =>{
		this.setState({
			showOTP:false,
			isDisable:false,
		})
	}

    render(){
        return(
            <React.Fragment>
                <div className="sendMoneyWrapper justify-content-center d-flex">
					<div className="col-xl-10 col-lg-10 col-md-11 col-sm-12 col-12">
						<div className="heading">
							<h3>Send Money to Bank</h3>
							{this.props.minBalance === "0.00" ? "" :
								<p className="mt-2"><span className="text-danger font-weight-medium">Note:</span> Kindly
									maintain minimum balance in your wallet of ₹. {this.props.minBalance}</p>
							}
						</div>
						<div className="formWrapper">
							<div className="row">
								<div className="col-12 mb-2">
									<FormGroup>
										<Textbox
											tabIndex="1"
											id={"name"}
											placeholder="Account Holder Name"
											value={this.state.name}
											disabled={this.state.isDisable}
											onBlur={e => {}}
											onChange={(name, e) => {
												this.setState({ name });
											}}
											validate={this.state.validate}
											validationCallback={res =>
												this.setState({ hasNameError:res, validate: false })
											}
											validationOption={{
												name: 'Account Holder Name',
												check: true,
												required: true
											}}/>
									</FormGroup>
								</div>

								<div className="col-sm-6 col-12 mb-2">
									<FormGroup>
										<Textbox
											tabIndex="2"
											type="password"
											id={"account"}
											placeholder="Account Number"
											value={this.state.account}
											disabled={this.state.isDisable}
											onBlur={e => {}}
											onChange={(account, e) => {
												this.setState({ account });
											}}
											validate={this.state.validate}
											validationCallback={res =>
												this.setState({ hasAccountError:res, validate: false })
											}
											validationOption={{
												name: 'Account Number',
												check: true,
												required: true
											}}/>
									</FormGroup>
								</div>

								<div className="col-sm-6 col-12 mb-2">
									<FormGroup>
										<Textbox
											tabIndex="3"
											id={"account"}
											placeholder="Confirm Account Number"
											value={this.state.confirmAccount}
											disabled={this.state.isDisable}
											onBlur={e => {}}
											onChange={(confirmAccount, e) => {
												this.setState({ confirmAccount });
											}}
											validate={this.state.validate}
											validationCallback={res =>
												this.setState({ hasConfirmAccountError:res, validate: false })
											}
											validationOption={{
												name: 'Confirm Account Number',
												check: true,
												required: true,
												compare:this.state.account,
												msgOnError:"Account No. and Confirm Account No. do not match"
											}}/>
									</FormGroup>
								</div>

								<div className="col-sm-6 col-12 mb-2">
									<FormGroup>
										<Textbox
											tabIndex="4"
											id={"ifsc"}
											placeholder="IFSC Code"
											value={this.state.ifsc}
											disabled={this.state.isDisable}
											onBlur={e => {}}
											onChange={(ifsc, e) => {
												this.setState({ ifsc });
											}}
											validate={this.state.validate}
											validationCallback={res =>
												this.setState({ hasIFSCError:res, validate: false })
											}
											validationOption={{
												name: 'IFSC Code',
												check: true,
												required: true
											}}/>
									</FormGroup>
								</div>

								<div className="col-sm-6 col-12 mb-2">
									<FormGroup>
										<Textbox
											tabIndex="5"
											id={"amount"}
											placeholder="Amount"
											value={this.state.amount}
											disabled={this.state.isDisable}
											onBlur={e => {}}
											onChange={(amount, e) => {
												this.setState({ amount });
											}}
											validate={this.state.validate}
											validationCallback={res =>
												this.setState({ hasAmountError:res, validate: false })
											}
											validationOption={{
												name: 'Amount',
												check: true,
												required: true,
												type:"number"
											}}/>
									</FormGroup>
								</div>
								{
									this.state.showOTP === true ?
										<div className="col-sm-6 col-12 mb-2">
											<FormGroup
												helperText={this.state.hasOTPErrorMsg}
												className="otpStatus">
												<Textbox
													tabIndex="6"
													id={"otp"}
													placeholder="One Time Password"
													value={this.state.otp}
													onChange={(otp, e) => {
														this.setState({
															otp,
															hasOTPError:false
														});
													}}
													validationOption={{
														name: 'OTP',
														check: true,
														required: true,
														type: 'number'
													}}/>
											</FormGroup>

											<div className="otpMsgWrapper">
												<div className="otpCounter col-sm-12 col-12 p-0">
													{this.state.timerStatus?
														<Countdown
															date={this.state.timer}
															onComplete={()=>{
																this.setState({timerStatus:false})
															}}
															renderer={({minutes,seconds}) => {
																return <p className="m-0 pull-right f-14">Resend OTP Button will be enable in <span className="text-primary"><b>{minutes}:{seconds}</b></span> minute</p>
															}}
														/>
														:
														<Button
															className='pull-right btn-link'
															onClick={() => {
																this.onSubmitOTP()}} intent="none" text="Resend OTP"/>
													}

												</div>
											</div>
										</div>
										:
										""
								}
								{
									this.state.showOTP===true ?
										<div className="col-12">
											<Button type="Submit"
													text="Initiate Withdrawal Request"
													intent="primary"
													className="mt-4 bp3-large"
													loading={this.state.loader}
													onClick={this.onSubmitRequest}/>

											<Button type="Submit"
													text="Reset Request"
													intent="primary"
													className="mt-4 bp3-large ml-3"
													onClick={this.onResetRequest}/>
										</div>
										:
										<div className="col-12">
											<Button type="Submit"
													text="Initiate Withdrawal Request"
													intent="primary"
													className="mt-4 bp3-large"
													loading={this.state.loader}
													onClick={this.onSubmitOTP}/>
										</div>
								}
							</div>
						</div>
					</div>
                </div>
            </React.Fragment>
        );
    }
}