import {connect} from "react-redux";
import {ggCoinRequestList, acceptCoin, rejectCoin} from '../../../core/actions/action';
import CoinRequest from '../Components/CoinRequest';
const mapStateToProps = state =>{

    return{
        coinRequestList:state.CoinList.coinRequestList,
    }

}

const mapDispatchToProps = dispatch =>{
    return{
        ggCoinRequestList : (params) =>{
            return ggCoinRequestList(params);
        },

        acceptCoin : (requestId) =>{
            return acceptCoin(requestId);
        },

        rejectCoin : (requestId) =>{
            return rejectCoin(requestId);
        },


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoinRequest)