import {connect} from 'react-redux';
import {getBusOrderList} from '../../../core/actions/action';
import BusOrder from '../Components/BusIndex';

const mapStateToProps = state => {

    return{
        busOrderList:state.Travel.busOrderList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getBusOrderList:(params) => {
            return getBusOrderList(params);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BusOrder);
