import React, {Component} from 'react';
import Wishlist from './Container/Wishlist';

export default class GGVendorProduct extends Component{
    constructor(props){
        super(props);
        this.state={
            loadingData : true,
        };
    }

    componentDidMount() {

        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 1000);
    }

    render(){
        return(
            <React.Fragment>
                <Wishlist  loading={this.state.loadingData}/>
            </React.Fragment>
        );
    }
}