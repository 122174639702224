import {connect} from 'react-redux';
import { getFavouriteUposelist, removeFavouriteUpose} from '../../../core/actions/action';
import Favourite from '../Component/Favourite';

const mapStateToProps = state => {

    return{
        uposeFavouriteList:state.Favourite.uposeFavouriteList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getFavouriteUposelist:(params) => {
            return getFavouriteUposelist(params);
        },

        removeFavouriteUpose:(vendorSlug) => {
            return removeFavouriteUpose(vendorSlug);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Favourite);
