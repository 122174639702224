import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import Main from '../FPV/components/main';
import { getFPVCashbackOption, changeFPVCashbackOption } from '../../core/actions/action';
import { getFPVCashbackOptionAction, changeFPVCashbackOptionAction } from '../../core/actions';

const mapStateToProps = state => {

    return{
        cashbackFPVOpt:state.cashbackReducer.cashbackFPVOpt,
        changeFPVOption:state.cashbackReducer.changeFPVOption,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getFPVCashbackOption :() => {
            return getFPVCashbackOption()
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(getFPVCashbackOptionAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(getFPVCashbackOptionAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
        changeFPVCashbackOption :(obj) => {
            return changeFPVCashbackOption(obj)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(changeFPVCashbackOptionAction(sources));
                        Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(changeFPVCashbackOptionAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
