import React from 'react';
//import LoadMore from './LoadMore';
import CoinImage from "../../../core/images/empty-state/coin.svg";

export default class MoneyRequest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            requestCoinList:[],
            page:1,
            stopLoadMore: false,
            showEmptyStats:false,
            loading: true,
        }
    }

    componentDidMount() {
        this.setState( {
            loading: this.props.loading,
        })

        let params={
            page:this.state.page,
        }
        this.getCoinRequestList(params)
    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    getCoinRequestList=(params)=>{
        this.props.myRequestGgCoinList(params)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    let oldAffData = this.state.requestCoinList;
                    let newAffDataFromApi = sources.data.data;
                    let newAffData = oldAffData.concat(newAffDataFromApi);
                    this.setState( {
                        requestCoinList:newAffData,
                    });
                    if(sources.data.data === ""){
                        this.setState( {
                            showEmptyStats:true,
                        });
                    }
                    if(sources.data.current_page === sources.data.last_page){
                        this.setState( {
                            stopLoadMore: true,
                        })
                    }else{
                        this.setState( {
                            stopLoadMore: false,
                        })
                    }
                }else{

                }
            }).catch((error)=>{
            const sources = error.response;
            console.log(sources);
        })
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            if(this.state.stopLoadMore === false){
                this.NextPage()
            }
        }
    }

    NextPage= () =>{
        let oldPageNo = this.state.page;
        this.setState({
            page: oldPageNo + 1
        },()=>{
            let params={
                page:this.state.page,
            }
            this.getCoinRequestList(params)
        })

    }

    renderStatusHtml(status){

        switch (status) {
            case "PENDING":
                return <label className="label text-warning">PENDING</label>;
            case "FULFILLED":
                return <label className="badge text-success">FULFILLED</label>;
            case "DECLINED":
                return <label className="label text-danger">DECLINED</label>;
            default:
                return <label className="label text-default">Unresolved</label>;
        }
    }


    render(){
        return(
            <React.Fragment>
                <div style={{height:'100%',maxHeight:'590px',overflowY:'scroll'}} id={"allBooking"} onScroll={this.handleScroll} className="table-responsive mt-4">
                    <table className="bp3-html-table bp3-html-table-bordered">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>User Name</th>
                            <th>Amount</th>
                            <th>Message</th>
                            <th>Request Time</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.loading ?
                                    <tr>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    </tr>
                                    :
                                    this.state.requestCoinList.length ?
                                        this.state.requestCoinList.map((coin, key) => {
                                            return <tr key={key}>
                                                <td>{coin.request_id}</td>
                                                <td>{coin.remitter_customer_username}</td>
                                                <td>₹ {coin.amount}</td>
                                                <td>{coin.description}</td>
                                                <td>{coin.datetime}</td>
                                                <td>
                                                    {
                                                        this.renderStatusHtml(coin.status)
                                                    }
                                                </td>
                                            </tr>
                                        })
                                        :

                                        <tr>
                                            <td colSpan="6" align="center">
                                                <div className="text-center">
                                                    <img src={CoinImage} alt=""/>
                                                    <p className="mt-3">No transactions for the selected option</p>
                                                </div>
                                            </td>
                                        </tr>
                            }
                        </tbody>
                    </table>
                </div>

            </React.Fragment>
        );
    }
}