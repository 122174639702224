import {connect} from 'react-redux';
import {getVehicleInquiriesList} from '../../../core/actions/action';
import VehicleInquiriesIndex from '../components/VehicleInquiriesIndex';

const mapDispatchToProps = dispatch => {

    return {
        getVehicleInquiriesList:(params) => {
            return getVehicleInquiriesList(params);
        },
    }
}

export default connect(null, mapDispatchToProps)(VehicleInquiriesIndex);
