import React from 'react';
//import LoadMore from './LoadMore';
import CoinImage from "../../../core/images/empty-state/coin.svg";
import {Intent, Position, Toaster} from "@blueprintjs/core";
import AcceptCoin from './AcceptCoin';
import RejectCoin from './RejectCoin';

export default class CoinRequest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            coinList:[],
            page:1,
            stopLoadMore: false,
            showEmptyStats:false,
            isOpenAcceptCoin:false,
            isOpenRejectCoin:false,
            loading: true,
        }
    }

    componentDidMount() {
        this.setState( {
            loading: this.props.loading,
        })

        let params={
            page:this.state.page,
        }
        this.getGgCoinRequestList(params)
    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    getGgCoinRequestList=(params)=>{
        this.props.ggCoinRequestList(params)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    let oldAffData = this.state.coinList;
                    let newAffDataFromApi = sources.data.data;
                    let newAffData = oldAffData.concat(newAffDataFromApi);
                    this.setState( {
                        coinList:newAffData,
                    });
                    if(sources.data.data === ""){
                        this.setState( {
                            showEmptyStats:true,
                        });
                    }
                    if(sources.data.current_page === sources.data.last_page){
                        this.setState( {
                            stopLoadMore: true,
                        })
                    }else{
                        this.setState( {
                            stopLoadMore: false,
                        })
                    }
                }else{

                }
            }).catch((error)=>{
            const sources = error.response;
            console.log(sources);
        })
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            if(this.state.stopLoadMore === false){
                this.NextPage()
            }
        }
    }

    NextPage= () =>{
        let oldPageNo = this.state.page;
        this.setState({
            page: oldPageNo + 1
        },()=>{
            let params={
                page:this.state.page,
            }
            this.getGgCoinRequestList(params)
        })
    }

    acceptCoinRequest = (requestId) => {
        this.props.acceptCoin(requestId)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    Toaster.create({position: Position.TOP}).show({message:sources.message ,intent: Intent.SUCCESS});
                    let params={
                        page:1,
                    }
                    this.getGgCoinRequestList(params)
                    this.setState( {
                        isOpenAcceptCoin:true,
                        coinList:[]
                    })
                }
            }).catch((error)=>{
            const sources = error.response;
            if((sources.data.error.amount) && (sources.data.error.amount.length > 0)){
                Toaster.create({position: Position.TOP}).show({message:sources.data.error.amount ,intent: Intent.DANGER});
            }else{
                Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
            }
        })
    }

    rejectCoinRequest = (requestId) => {
        this.props.rejectCoin(requestId)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    Toaster.create({position: Position.TOP}).show({message:sources.message ,intent: Intent.SUCCESS});
                    let params={
                        page:1,
                    }
                    this.getGgCoinRequestList(params)
                    this.setState( {
                        isOpenRejectCoin:true,
                        coinList:[]
                    })
                }
            }).catch((error)=>{
            const sources = error.response;
            Toaster.create({position: Position.TOP}).show({message:sources.data.message ,intent: Intent.DANGER});
        })
    }

    renderStatusHtml(status, requestId){

        switch (status) {
            case "PENDING":
                return <div className="d-flex"><AcceptCoin
                    isOpenAcceptCoin={this.state.isOpenAcceptCoin}
                    reuqestNo={requestId}
                    acceptCoinRequest={this.acceptCoinRequest}
                /> <RejectCoin isOpenRejectCoin={this.state.isOpenRejectCoin}
                                               reuqestNo={requestId}
                               rejectCoinRequest={this.rejectCoinRequest}/></div>;
            case "FULFILLED":
                return <label className="badge text-success">Fulfilled</label>;
            case "DECLINED":
                return <label className="label text-danger">Declined</label>;
            default:
                return <label className="label text-default">Unresolved</label>;
        }
    }

    render(){
        return(
            <React.Fragment>
                <div style={{height:'100%',maxHeight:'590px',overflowY:'scroll'}} id={"allBooking"} onScroll={this.handleScroll} className="mt-4">
                    <table className="bp3-html-table bp3-html-table-bordered">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>User Name</th>
                            <th>Amount</th>
                            <th>Message</th>
                            <th>Request Time</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.loading ?
                                <tr>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                </tr>
                                :
                                this.state.coinList.length ?
                                    this.state.coinList.map((coin, key) => {
                                        return <tr key={key}>
                                            <td>{coin.request_id}</td>
                                            <td>{coin.beneficiary_customer_username}</td>
                                            <td>₹ {coin.amount}</td>
                                            <td>{coin.description}</td>
                                            <td>{coin.datetime}</td>
                                            <td>
                                                {
                                                    this.renderStatusHtml(coin.status, coin.request_id)
                                                }
                                            </td>
                                        </tr>
                                    })
                                    :
                                    <tr>
                                        <td colSpan="6" align="center">
                                            <div className="text-center">
                                                <img src={CoinImage} alt=""/>
                                                <p className="mt-3">No transactions for the selected option</p>
                                            </div>
                                        </td>
                                    </tr>
                        }
                        </tbody>
                    </table>
                </div>

            </React.Fragment>
        );
    }
}