import React, { Component } from 'react';
import {FavoriteRounded} from "@material-ui/icons";
import OrderhistoryEmptyStates from '../../../core/images/svg/Orderhistory.svg'
import GgCoin from '../../../core/images/svg/gg_coins.svg'
import Loding from "./Loding";
import {Intent, Position, Toaster} from "@blueprintjs/core";
import Config from '../../../Config';
import {Auth} from "gg-react-utilities";
import _ from "lodash";

export default class MegaBrandsFav extends Component{
    constructor(props){
        super(props);
        this.state={
            favouriteList:[],
            page:1,
            stopLoadMore: false,
            showEmptyStats:false,
            loadingData : true,
            isLoggedIn:false,
        }
    }

    componentDidMount() {
        this.checkAuth();

        this.setState( {
            loading: this.props.loading,
        })

        let params={
            page:this.state.page,
        }
        this.getFavouriteList(params)
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1000);
    }


    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    getFavouriteList=(params)=>{
        this.props.getMegaBrandFavouriteList(params)
            .then((res)=>{
                const sources = res.data;
                if(sources.status === true){
                    let oldData = this.state.favouriteList;
                    let newDataFromApi = sources.data.data;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState( {
                        favouriteList:newData,
                    });
                    if(sources.data.data === ""){
                        this.setState( {
                            showEmptyStats:true,
                        });
                    }
                    if(sources.data.current_page === sources.data.last_page){
                        this.setState( {
                            stopLoadMore: true,
                        })
                    }else{
                        this.setState( {
                            stopLoadMore: false,
                        })
                    }
                }else{

                }
            }).catch((error)=>{
            const sources = error.response;
        })
    }

    NextPage= () =>{
        let oldPageNo = this.state.page;
        this.setState({
            page: oldPageNo + 1
        },()=>{
            let params={
                page:this.state.page,
            }
            this.getFavouriteList(params)
        })

    }

    removeFromFavorite = (brandId) => {
        this.props.removeMegaBrandFavourite(brandId)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    this.setState( {
                        favouriteList:[],
                        loading:true,
                    });
                    let params={
                        page:1,
                    }
                    this.getFavouriteList(params)
                    this.stopLoading();
                    Toaster.create({position: Position.TOP}).show({message:sources.message ,intent: Intent.SUCCESS});
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.message ,intent: Intent.DANGER});
                }
            }).catch((error)=>{
            const sources = error.response;
        })
    }

    stopLoading = () => {
        setTimeout(() => {
            this.setState({
                loading: false,
            });
        }, 1000);
    }

    render() {
        /*if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }*/

        return (
            <React.Fragment>
                <div className="brands col-12">
                    <div className="row">
                        {
                            this.state.loading ?
                                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-3 mt-s-0 brand-col cb-box all-brands bp3-skeleton">
                                    <a href="#" rel="noopener noreferrer">
                                        <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 p-0 justify-content-center d-flex">
                                            <div className="brand-card text-center">
                                                <img
                                                    src={Config.MEGABRAND_DEFAULT_IMAGE}
                                                    className="img-fluid product-img"
                                                    alt='brand-name'
                                                    onError={(ev)=>{
                                                        ev.target.src = Config.MEGABRAND_DEFAULT_IMAGE;
                                                    }}
                                                />
                                                <p className="mt-minus">
                                                    <FavoriteRounded style={{'color':'#FF5252'}} />
                                                </p>
                                                <p className="brand-delivery text-center m-auto">
                                                    Delivery: Instant
                                                </p>
                                                <p className="brand-coin text-center m-auto">
                                                    {"GG Coins Applicable"}
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                :
                                this.state.favouriteList.length ?
                                    this.state.favouriteList.map((brand, key) => {
                                        let splitColorCode = _.split(brand.color_code, ',');
                                        let backgroundColorCode = {backgroundImage: `linear-gradient(-135deg, ${splitColorCode[0]} 0%, ${splitColorCode[1]} 100%)`};

                                        let ggCoin = "";

                                        if (1 === brand.coin_applicable)
                                            ggCoin =
                                                <img src={GgCoin} alt="GG Coins Applicable"
                                                     className="img-fluid coin-img mt-2 mr-2"/>;

                                        let tat = "";

                                        if (brand.tat === 0) {
                                            tat = "Instant";
                                        } else {
                                            tat = brand.tat + " Days"
                                        }

                                        let favourites = "";

                                        if (1 === brand.is_favorite) {
                                            favourites = <FavoriteRounded onClick={() => this.removeFromFavorite(brand.brand_id)}/>;
                                        }
                                        return(

                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mt-3 mt-s-0 mb-2 pb-1 brand-col mb-s-4" data-brandid={brand.brand_id}>
                                                <div className="mega-brand-card">
                                                    <div rel="noopener noreferrer">
                                                        <div className="brand-card d-flex justify-content-between item-align-center" style={backgroundColorCode}>
                                                            <p className="brand-name">{brand.brand_name}</p>
                                                            <p className="mt-minus">{favourites}</p>
                                                        </div>
                                                        <a href={Config.MEGABRAND_FAV + 'brand/'+ brand.slug} target="_blank" className="text-center">
                                                            <img
                                                                src={brand.image}
                                                                className="img-fluid brand-img ml-4"
                                                                alt={brand.brand_name}
                                                                onError={(ev)=>{
                                                                    ev.target.src = Config.MEGABRAND_DEFAULT_IMAGE;
                                                                }}
                                                            />
                                                            <div className="float-right pt-1 pr-2">
                                                                {ggCoin}
                                                            </div>
                                                        </a>
                                                        <div className="d-flex justify-content-between item-align-center pl-3 pr-3 pt-3 pb-2">
                                                            <p className="brand-delivery text-center m-auto">Delivery: {tat}</p>
                                                            <p className="brand-product-type text-center m-auto">{brand.product_type} Voucher</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <div className="col-12 my-4" >
                                        <center>
                                            <img src={OrderhistoryEmptyStates} alt=''/>
                                            <p className='mt-3 mb-2'>No favourite Brand found!</p>
                                        </center>
                                    </div>
                        }
                    </div>
                    {
                        this.state.loading ?
                            ""
                            :
                            <Loding
                                favouriteList={this.state.favouriteList}
                                NextPage={() => this.NextPage()}
                                stopLoadMore={this.state.stopLoadMore}/>
                    }
                </div>
            </React.Fragment>
        )
    }


}


{/*<div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-3 mt-s-0 brand-col cb-box all-brands"  key={key}>
                                                <a href={Config.MEGABRAND_FAV + 'brand/'+ brand.slug} rel="noopener noreferrer" target="_blank">
                                                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 p-0 justify-content-center d-flex">
                                                        <div className="brand-card text-center">
                                                            <img
                                                                src={brand.image}
                                                                className="img-fluid product-img"
                                                                alt={brand.brand_name}
                                                                onError={(ev)=>{
                                                                    ev.target.src = Config.MEGABRAND_DEFAULT_IMAGE;
                                                                }}
                                                            />
															<p className="brand-delivery text-center m-auto">
                                                                Delivery: {
                                                                    brand.tat === 0 ? "Instant": brand.tat + " Days"
                                                                }
                                                            </p>
                                                            <p className="brand-coin text-center m-auto">
                                                                {
                                                                    brand.coin_applicable === 1 ?
                                                                        "GG Coins Applicable"
                                                                        :
                                                                        ""
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <p className="mt-minus">
                                                    <FavoriteRounded style={{'color':'#FF5252'}} onClick={() => this.removeFromFavorite(brand.brand_id)}/>
                                                </p>
                                            </div>



                                        */}