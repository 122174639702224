import React, { Component } from 'react';
import {Intent, Position, Toaster, Button, InputGroup, Dialog} from "@blueprintjs/core";
import {Link} from "react-router-dom";
import config from "../../core/Config";

export default class BidList extends Component{
    constructor(props){
        super();
        this.state= {
            isSkeleton:true,
            sellList:[],
            counter: 1,
            isLoading: false,
            scrollLoader:false,
            isVisibleSellModal:false,
            upvID:"",
            amount:null,
            hasAmountError:true,
            isVisibleMilestone:false,
            milestones:{}
        }
    }

    componentDidMount() {
        this.getUPVSellList(this.state.counter);
        setTimeout(() => {
            this.setState({
                isSkeleton:false,
                scrollLoader:true
            })
        }, 1000);
        window.addEventListener('scroll', this.handleOnScroll);
    }

    handleOnScroll=()=> {
        let scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        let scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        let clientHeight = document.documentElement.clientHeight || window.innerHeight;
        let scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

        if (scrolledToBottom) {
            this.loadMore();
        }
    }

    loadMore = () =>{
        this.setState({
            scrollLoader:false
        });
        setTimeout(() => {
            this.setState({
                counter: this.state.counter + 1,
                isLoading: true,
                scrollLoader:true
            }, () => {
                this.getUPVSellList(this.state.counter);
            })
        },1000)
    }

    closeModal(){
        this.setState({
            isVisibleSellModal:false
        })
    }

    closeMileStoneModal(){
        this.setState({
            isVisibleMilestone:false,
            milestones:{}
        });
    }

    getUPVSellList(page){
        this.props.getUPVSellList(page)
        .then((res)=>{
            const sources = res.data;
            if(sources.status===true){
                let oldData = this.state.sellList;
                let newDataFromApi = sources.data.data;
                let newData = oldData.concat(newDataFromApi);
                this.setState({
                    sellList:newData,
                    last_page:sources.data.last_page,
                })
            }else{
                Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
            }
        }).catch((error)=>{
            Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
        })
    }

    openSellUPVModal(res){
        if(res.bid_status==="NONE") {
            this.setState({
                upvID: res.upv_id,
                isVisibleSellModal: true
            })
        }
    }

    sellBid(upvID){
        if(this.state.hasAmountError){
            Toaster.create({position: Position.TOP}).show({message:"Please enter your UPV selling amount.",intent: Intent.DANGER});
        }else{
            let obj = {
                "amount": this.state.amount
            }
            this.props.sellBid(upvID, obj)
                .then((res) => {
                    const sources = res.data;
                    if (sources.status === true) {
                        Toaster.create({position: Position.TOP}).show({message: sources.message, intent: Intent.SUCCESS});
                        this.setState({
                            isVisibleSellModal: false
                        })
                        setTimeout(() => {
                            window.location.href = '/sell-upv-exchange';
                        }, 3000)
                    } else {
                        Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                    }
                }).catch((error) => {
                    this.setState({
                        amount:"",
                    })
                    Toaster.create({position: Position.TOP}).show({message: error.response.data.error.amount, intent: Intent.DANGER});
            })
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="bidsWrapper pt-1 pb-1">
                    <div className="content">
                        <div className={"row"}>
                            {this.state.sellList.length === 0 ?
                                <div className="emptyState">
                                    <img src={require('../../core/images/empty-state/UPV.svg')} alt=""/>
                                </div>
                                :
                                this.state.sellList.map((res, i) => {
                                    let status;
                                    let intent;
                                    if(res.bid_status==="OPEN"){
                                        intent="success";
                                        status="Bid Open";
                                    }else if(res.bid_status==="NONE"){
                                        status="Sell";
                                        intent="primary";
                                    }else if(res.bid_status==="ON_HOLD"){
                                        status="On Hold";
                                        intent="warning";
                                    }else if(res.bid_status==="CLOSED"){
                                        status="Closed";
                                        intent="danger";
                                    }
                                    return(
                                        <div className={"col-12 col-lg-6 col-md-6 col-sm-6 col-xs-12"} key={i}>
                                            {res.bid_status === "NONE" ?
                                                <div
                                                    className={this.state.isSkeleton ? "bidBlock p-3 mt-3 bp3-skeleton" : "bidBlock p-3 mt-3"}>
                                                    <div className="row">
                                                        <div className="col-12 col-lg-2 col-md-2 col-sm-2">
                                                            <div className="upvRight">
                                                                <h5 className={"m-0"}>UPV No</h5>
                                                                <h4 className={"m-0"}>{res.upv_id}</h4>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-10 col-md-10 col-sm-10">
                                                            <div className="upvLeft text-left">
                                                                <div className={"topbar"} style={{height:'60px'}}>
                                                                    <div>
                                                                        <h5 className="m-0 timeText">{res.upv_generation_date} {res.upv_generation_time}</h5>
                                                                        <Button className="btn-link" onClick={() => {
                                                                            let title = "UPV No : "+res.upv_id;
                                                                            this.setState({
                                                                                isVisibleMilestone:true,
                                                                                milestones:res.milestones,
                                                                                title:title
                                                                            });
                                                                        }}>View Milestone</Button>
                                                                    </div>
                                                                    <div className="text-right pl-3"  style={{lineHeight:'60px',width:'100px',borderLeft:'1px solid #eee'}}>
                                                                        <Button className={"m-0 f-16"}
                                                                                text={status}
                                                                                intent={intent}
                                                                                style={{height: 25, padding: '2px 20px', fontSize:'14px',boxShadow:'none'}}
                                                                                onClick={() =>
                                                                                    this.openSellUPVModal(res)
                                                                                }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                    <div
                                                        className={this.state.isSkeleton ? "bidBlock p-3 mt-3 bp3-skeleton" : "bidBlock p-3 mt-3"}>
                                                        <div className="row">
                                                            <div className="col-12 col-lg-2 col-md-2 col-sm-2">
                                                                <div className="upvRight">
                                                                    <h5 className={"m-0"}>UPV No</h5>
                                                                    <h4 className={"m-0"}>{res.upv_id}</h4>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-10 col-md-10 col-sm-10">
                                                                <div className="upvLeft text-left">
                                                                    <div className={"topbar"}>
                                                                        <div>
                                                                            <h5 className="m-0 timeText">{res.upv_generation_date} {res.upv_generation_time}</h5>
                                                                            <Button className="btn-link" onClick={() => {
                                                                                let title = "UPV No : "+res.upv_id;
                                                                                this.setState({
                                                                                    isVisibleMilestone:true,
                                                                                    milestones:res.milestones,
                                                                                    title:title
                                                                                });
                                                                            }}>View Milestone</Button>
                                                                        </div>
                                                                        <div className="text-right pl-2" style={{width:'100px',borderLeft:'1px solid #eee'}}>
                                                                            <h4 className={"m-0 mb-2 f-16 text-"+intent} style={{fontWeight:'500'}}>{status}</h4>
                                                                            <Link to={{
                                                                                pathname: '/upv-sell-exchange-detail',
                                                                                state: {upvID: res.upv_id},
                                                                            }} className={"f-16"}
                                                                            >
                                                                                View Details
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                        {
                            this.state.last_page <= this.state.counter ? null :
                                this.state.scrollLoader ?
                                    null :
                                    <React.Fragment>
                                        <center>
                                            <div className="my-3">
                                                <img src={require('../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                            </div>
                                        </center>
                                    </React.Fragment>

                        }
                    </div>
                </div>
                <Dialog isOpen={this.state.isVisibleSellModal}
                        title={"Sell UPV "+ this.state.upvID}
                        className="bidModal"
                        style={{marginTop:'250px'}}
                        onClose={this.closeModal.bind(this)} >
                    <div className="bidForm m-3 text-center">
                        <p className="text-left f-14">This amount will be display to Buyers as your base amount.</p>
                        <div className="row">
                            <div className="col-12">
                                <InputGroup
                                    placeholder="Enter UPV Selling Price"
                                    small={false}
                                    type={"number"}
                                    value={this.state.amount}
                                    onChange={(e) => {
                                        this.setState({
                                            amount:e.target.value,
                                            hasAmountError:false
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <div className="bidFormBtn mt-4 mb-4">
                                    <Button
                                        text={"Cancel"}
                                        intent={"default"}
                                        onClick={() => {
                                            this.setState({
                                                amount:"",
                                                isVisibleSellModal:false
                                            })
                                        }}
                                    />
                                    <Button
                                        text={"Submit"}
                                        intent={"primary"}
                                        onClick={() => this.sellBid(this.state.upvID)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog isOpen={this.state.isVisibleMilestone}
                        title={this.state.title}
                        className="milestoneWrapper"
                        style={{marginTop:'250px'}}
                        onClose={this.closeMileStoneModal.bind(this)} >
                    <div className="milestone m-3 text-center">
                        <div className="row">
                            { this.state.milestones.length > 0 ?
                                this.state.milestones.map((resp, i)=>{
                                    let color, opacity;
                                    let str, strp;
                                    i = i + 1;
                                    if(i===1){
                                        str = "1st";
                                        strp= "Cashback";
                                    }else if(i===2){
                                        str = "2nd";
                                        strp= "Cashback";
                                    }else if(i===3){
                                        str = "3rd";
                                        strp= "Cashback";
                                    }else if(i===4){
                                        str = "4th";
                                        strp= "GG Coin Conversion";
                                    }

                                    if(resp.cashback_given){
                                        color="#FE5A23";
                                        opacity="#B53206";
                                    }else{
                                        color="#40BE65";
                                        opacity="#164A25";
                                    }
                                    return(
                                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-s-4 mt-sm-4" key={i}>
                                            <div className="section">
                                                <div className="topSection pt-3 pb-3 " style={{backgroundColor:color}}>
                                                    <h3>{str}</h3>
                                                    <p>{strp}</p>
                                                </div>
                                                <div className="bottomSection pt-3 pb-3" style={{opacity:0.8,backgroundColor:opacity}} >
                                                    <p>of {resp.milestone_description} <br></br><strong>{resp.upv_id}</strong><br></br> no. of UPV Generation</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }):
                                <td colSpan="3" className="emptyState">
                                    <img src={require('../../core/images/empty-state/UPV.svg')} alt=""/>
                                </td>
                            }
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}