import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import IC from '../components/ic';
import { getICList } from '../../../core/actions/action';
import { getICListAction } from '../../../core/actions';

const mapStateToProps = state => {

    return{
        icList:state.cashbackReducer.icList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getICList :(page) => {
            return getICList(page)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(getICListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(getICListAction(null));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IC);
