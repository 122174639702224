import React from 'react';
import Defaultbrands from '../../../../core/images/order/Defaultbrands.png';
import AllorderEmptyStates from "../../../../core/images/empty-state/Allorder.svg";
import {Button} from "@blueprintjs/core";
import MegaBrandDetails from "../../../AllOrders/Container/MegaBrandDetails";


export default class MegabrandOrders extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            megaBrandsOrderList:[],
            loading: true,
        }
    }

    componentDidMount(){
        this.setState( {
            loading: this.props.loading,
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    renderStatusHtml(status){

        switch (status) {
            case "1":
                return <label className="label text-warning">Awaiting for Payment</label>;
            case "2":
                return <label className="badge text-success">Payment Success</label>;
            case "3":
                return <label className="badge text-danger">Payment Fail</label>;
            case "4":
                return <label className="badge text-danger">Order Failed</label>;
            case "5":
                return <label className="badge text-success">Success</label>;
            default:
                return <label className="label text-default">--</label>;
        }
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            if(this.props.stopLoadMore === false){
                this.props.NextPage()
            }
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="mg-brand-order"  id={"allBooking"} style={{'height':'100%','maxHeight':'590px','overflowY':'scroll'}} onScroll={this.handleScroll}>
                    <table className="bp3-html-table table-borderless">
                        <thead>
                            <tr>
                                <th>Product Details</th>
                                <th>Order ID</th>
                                <th>Order Date</th>
                                <th>Price</th>
                                <th>Cashback Type</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.loading ?
                                <tr>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                </tr>
                                :
                                this.props.megaBrandsOrderList.length ?
                                    this.props.megaBrandsOrderList.map((orders, key) => {
                                        return<tr key={key}>
                                            <td>
                                                <div className="orderTable">
                                                    <div className="image hidden-xs hidden-sm hidden-md visible-xl visible-lg">
                                                        <img src={Defaultbrands} alt=""/>
                                                    </div>
                                                    <div className="title">
                                                        <p>Receipt No : </p>
                                                        <span>{orders.receipt_no ? orders.receipt_no : "--"}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{orders.order_id ? orders.order_id : "--"}</td>
                                            <td>{orders.create_at ? orders.create_at : "--"}</td>
                                            <td><b>₹ {orders.grandtotal ? orders.grandtotal : "--"}</b></td>
                                            <td>{orders.caseback_choice_text ? orders.caseback_choice_text : "--"}</td>
                                            <td>
                                                <b>
                                                    <label className="label" style={{color:orders.order_status_color}}>{orders.order_status_text}</label>
                                                </b>
                                            </td>
                                            <td>
                                                <React.Fragment>
                                                    <p className="hidden-xs hidden-sm hidden-md visible-lg visible-xl">
                                                        <MegaBrandDetails isOpen={this.state.isMegaBrandsOpen} invoiceDownloadLink={orders.invoice_download_link} voucherDetailView={orders.voucher_detail_view} receiptNo={orders.receipt_no}  />
                                                    </p>
                                                </React.Fragment>

                                            </td>
                                        </tr>
                                    })
                                    :
                                    <tr>
                                        <td colSpan="7" align="center">
                                            <div className="text-center">
                                                <img src={AllorderEmptyStates} alt=""/>
                                                <p className="mt-3">No transactions for the selected option</p>
                                            </div>
                                        </td>
                                    </tr>
                        }
                        </tbody>
                    </table>
                    {
                        this.state.loading ?
                            null
                            :
                            this.props.stopLoadMore ?
                                ""
                                :
                            <React.Fragment>
                                <center>
                                    <div className="my-3">
                                        <img src={require('../../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                    </div>
                                </center>
                            </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }
}