let initialState = {
    requestCoinList:[],
    coinRequestList:[],
    accptCoinRequest:null,
    rejectCoinRequest:null,
};

const CoinList = (state = initialState, action) => {

    switch (action.type) {

        case 'REQUEST_GG_COIN_LIST':
            return {...state, requestCoinList: action.sources}
        case 'GG_COIN_REQUEST_LIST':
            return {...state, coinRequestList: action.sources}
        case 'ACCEPT_COIN_REQUEST':
            return {...state, accptCoinRequest: action.sources}
        case 'REJECT_COIN_REQUEST':
            return {...state, rejectCoinRequest: action.sources}
        default:
            return state
    }
}

export default CoinList;
