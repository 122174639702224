import React from 'react';
import {Auth, NoLogin} from "gg-react-utilities";
import TopBar from "../../core/components/TopBar";
import PageTitle from "../../core/components/PageTitle";
import {Dialog} from '@blueprintjs/core';
import {Link} from "react-router-dom";



export default class UPVBidSuccess extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isLoggedIn:false
        }
    }

    componentDidMount() {
        this.checkAuth();
        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1);
    }

    render(){

        let slug = this.props.match.params.status ? this.props.match.params.status : "";

        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <PageTitle title="UPV Exchange - Global Garner"/>
                        <TopBar title="UPV Exchange" />
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }
        let title, message, image, redirectLink;
        if (slug === "success") {
            title = "Payment Success";
            message = "Your Bid payment is paid successfully.";
            image = require('../../core/images/svg/success.svg');
        }
        if (slug === "failed") {
            title = "Payment Failed";
            message = "Your Bid payment is failed.";
            image = require('../../core/images/svg/cancel.svg');
        }
        redirectLink = "/upv-exchange";

        // setTimeout(() => {
        //     window.location.href = redirectLink;
        // }, 5000);

        return(
            <React.Fragment>
                <div className="app-success-wrapper">
                    <div className="container">
                        <div className="app-success">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="app-above">
                                        <img src={image} alt="" />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="app-below">
                                        <p className="mb-0"><strong style={{textTransform:"capitalize"}}>{slug} ! </strong>{message}</p>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <Link to="/upv" className="bg-primary">Go Back</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}