import React, { Component } from 'react';
import {Card, Intent, Position, Toaster} from "@blueprintjs/core";
import Config from "../../../Config";
import {FavoriteRounded} from "@material-ui/icons";
import StarRatings from 'react-star-ratings';
import GG_coin from '../../../core/images/png/GG_coin.png';
import NoWishlistEmptyState from '../../../core/images/svg/NoWishlist.svg';
import {Auth} from "gg-react-utilities";

export default class Favourite extends Component{
    constructor(props) {
        super(props);

        this.state = {
            favouriteList:[],
            lenght:10,
            stopLoadMore: false,
            loading:true,
            isLoggedIn:false,
        }
    }

    componentDidMount() {
        this.checkAuth();

        this.setState( {
            loading: this.props.loading,
        })

        let params={
            lenght:this.state.lenght,
        }
        this.getProductWistList(params)
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1000);
    }
    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    getProductWistList=(params)=>{
        this.props.getVendorProductWishList(params)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    let oldData = this.state.favouriteList;
                    let newDataFromApi = sources.data;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState( {
                        favouriteList:newData,
                    });

                    if(newData.length === sources.data.totalRecords){
                        this.setState( {
                            stopLoadMore: true,
                        })
                    }else{
                        this.setState( {
                            stopLoadMore: false,
                        })
                    }
                }else{

                }
            }).catch((error)=>{
            const sources = error.response;
            console.log(sources);
        })
    }

    NextPage= () =>{
        let oldPageNo = this.state.lenght;
        this.setState({
            lenght: oldPageNo + 10
        },()=>{
            let params={
                lenght:this.state.lenght,
            }
            this.getProductWistList(params)
        })

    }

    removeFromFavorite = (productId) => {
        this.props.removeFavouriteProduct(productId)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    this.setState( {
                        favouriteList:[],
                        loading:true,
                    });
                    let params={
                        lenght:10,
                    }
                    this.getProductWistList(params)
                    this.stopLoading();
                    Toaster.create({position: Position.TOP}).show({message:sources.message ,intent: Intent.SUCCESS});
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.message ,intent: Intent.DANGER});
                }
            }).catch((error)=>{
            const sources = error.response;
            console.log(sources);
        })
    }

    stopLoading = () => {
        setTimeout(() => {
            this.setState({
                loading: false,
            });
        }, 1000);
    }

    render() {
        /*if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }*/

        return (
            <React.Fragment>
                <div className='wishlist row mb-5'>
                    {
                        this.state.loading?
                            <React.Fragment>
                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-s-2 mb-4">
                                    <div className="polaroid">
                                        <div className="bp3-skeleton">
                                            <a href="#" rel="noopener noreferrer" target="_blank">
                                                <img src={Config.DEFAULT_IMAGE_URL} alt="product" className="img-fluid"
                                                     onError={(ev)=>{
                                                         ev.target.src = Config.DEFAULT_IMAGE_URL;
                                                     }}/>

                                            </a>
                                        </div>

                                        <div className="container">
                                            <div className='row'>
                                                <div className="col-xl-12 text-left">
                                                    <a href="#" rel="">
                                                        <p className="activity-product bp3-skeleton">Name of the Product</p>
                                                    </a>
                                                </div>
                                                <div className='col-xl-12 text-left '>
                                                    <span className="review mr-2 bp3-skeleton">5.5</span>
                                                    <span className="bp3-skeleton">
                                                   <StarRatings
                                                       rating={5.5}
                                                       starRatedColor="#F5A623"
                                                       starDimension='10px'
                                                       numberOfStars={5}
                                                       name='rating'
                                                   />
                                                </span>

                                                </div>
                                                <div className="col-xl-12 text-left">
                                                    <p className="brand-name bp3-skeleton">Brand: Brand Name</p>
                                                </div>
                                                <div className="col-xl-12 col-12 text-left d-flex justify-content-between">
                                                    <p className="price bp3-skeleton">&#8377; 100</p>
                                                    <p className="price-strike bp3-skeleton"><strike>&#8377; 200</strike></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-s-2 mb-4">
                                    <div className="polaroid">
                                        <div className="bp3-skeleton">
                                            <a href="#" rel="noopener noreferrer" target="_blank">
                                                <img src={Config.DEFAULT_IMAGE_URL} alt="product" className="img-fluid"
                                                     onError={(ev)=>{
                                                         ev.target.src = Config.DEFAULT_IMAGE_URL;
                                                     }}/>

                                            </a>
                                        </div>

                                        <div className="container">
                                            <div className='row'>
                                                <div className="col-xl-12 text-left">
                                                    <a href="#" rel="">
                                                        <p className="activity-product bp3-skeleton">Name of the Product</p>
                                                    </a>
                                                </div>
                                                <div className='col-xl-12 text-left '>
                                                    <span className="review mr-2 bp3-skeleton">5.5</span>
                                                    <span className="bp3-skeleton">
                                                   <StarRatings
                                                       rating={5.5}
                                                       starRatedColor="#F5A623"
                                                       starDimension='10px'
                                                       numberOfStars={5}
                                                       name='rating'
                                                   />
                                                </span>

                                                </div>
                                                <div className="col-xl-12 text-left">
                                                    <p className="brand-name bp3-skeleton">Brand: Brand Name</p>
                                                </div>
                                                <div className="col-xl-12 col-12 text-left d-flex justify-content-between">
                                                    <p className="price bp3-skeleton">&#8377; 100</p>
                                                    <p className="price-strike bp3-skeleton"><strike>&#8377; 200</strike></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </React.Fragment>

                            :
                            this.state.favouriteList.length ?
                                this.state.favouriteList.map((res, key) => {
                                    return (
                                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-s-2 mb-4" key={key}>
                                            <div className="polaroid">
                                                <a href={Config.STORE_FAV + 'product/'+ res.product.slug} rel="noopener noreferrer" target="_blank">
                                                    {res.product.images.length===0?
                                                        <img src={Config.DEFAULT_IMAGE_URL} alt="product" className="img-fluid"
                                                             onError={(ev)=>{
                                                                 ev.target.src = Config.DEFAULT_IMAGE_URL;
                                                             }}/>
                                                        :
                                                        <img src={res.product.images[0].image} alt="product" className="img-fluid"
                                                             onError={(ev)=>{
                                                                 ev.target.src = Config.DEFAULT_IMAGE_URL;
                                                             }}/>
                                                    }

                                                </a>
                                                <div className="topleft">
                                                    {res.gg_coin_accept !== 1 ?
                                                        <div className=''>
                                                            <img src={GG_coin} alt='gg_coins' className='gg-coin-img'/>
                                                        </div>
                                                        :null}
                                                    <div className='icon-area'>
                                                        <FavoriteRounded className="favorite" style={{'color':'#FF5252'}} onClick={() => this.removeFromFavorite(res.product.product_id)}/>
                                                    </div>
                                                </div>
                                                <div className="container">
                                                    <div className='row'>
                                                        <div className="col-xl-12 text-left">
                                                            <a href={Config.STORE_FAV + 'product/'+ res.product.slug} rel="">
                                                                <p className="activity-product">{res.product.name}</p>
                                                            </a>
                                                        </div>
                                                        <div className='col-xl-12 text-left'>
                                                            <span className="review mr-2">{res.product.avg_rating}</span>
                                                            <StarRatings
                                                                rating={res.product.avg_rating}
                                                                starRatedColor="#F5A623"
                                                                starDimension='10px'
                                                                numberOfStars={5}
                                                                name='rating'
                                                            />
                                                        </div>
														<div className="col-xl-12 text-left">
                                                            <p className="text-muted brand-name">Brand: {res.product.brand_name}</p>
                                                        </div>
                                                        <div className="col-xl-12 col-12 text-left d-flex justify-content-between">
                                                            <p className="text-primary price">&#8377; {res.product.price.toFixed(2)}</p>
                                                            {/*<p className="text-muted price-strike"><strike>&#8377; {res.product.price.toFixed(2)}</strike></p>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <Card className='col-12'>
                                    <center>
                                        <img src={NoWishlistEmptyState} alt=''/>
                                        <p className='mt-3 mb-2'>No wishlist product found!</p>
                                    </center>
                                </Card>
                    }
                </div>
            </React.Fragment>
        )
    }
}