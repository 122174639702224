import React, { Component } from 'react';
import {Dialog, Button, Toaster, Position, Intent} from "@blueprintjs/core";
import BidList from "../containers/NewBidContainer";
import config from '../../../core/Config';

export default class TopContent extends Component{
    constructor(props){
        super();
        this.state= {
            isSkeleton:true,
            isVisibleMilestone:false,
            milestones:{},
            title:"",
            upvID:"",
            upvDetail:[],
            isVisibleDeleteBidModal:false,
            isVisibleCloseBidModal:false
        }
    }

    componentDidMount() {
        this.props.getUPVDetail(this.props.location.state.upvID);
        setTimeout(() => {
            this.setState({
                isSkeleton:false
            })
        }, 1000);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            upvDetail:nextProps.upvDetail,
            upvID:nextProps.upvDetail.upv_id
        })
    }

    // getUPVDetail(upvID){
    //     this.props.getUPVDetail(upvID)
    //         .then((res)=>{
    //             const sources = res.data;
    //             if(sources.status===true){
    //                 this.setState({
    //                     upvDetail:sources.data
    //                 })
    //             }else{
    //                 Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
    //             }
    //         }).catch((error)=>{
    //         Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
    //     })
    // }

    closeModal(){
        this.setState({
            isVisibleMilestone:false,
            milestones:{}
        });
    }
    closeDeleteBidModal(){
        this.setState({
            isVisibleDeleteBidModal:false,
        });
    }
    closeClosedBidModal(){
        this.setState({
            isVisibleCloseBidModal:false,
        });
    }

    deleteUPV(){
        this.props.deleteUPV(this.state.upvID)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    this.setState({
                        isVisibleDeleteBidModal:false
                    })
                    setTimeout(() => {
                        window.location.href='/sell-upv-exchange';
                    }, 3000)
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                }
            }).catch((error)=>{
            Toaster.create({position: Position.TOP}).show({message:"Something went wrong",intent: Intent.DANGER});
        })

    }

    closeUPV(){
        this.props.closeUPV(this.state.upvID)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    this.setState({
                        isVisibleCloseBidModal:false
                    })
                    setTimeout(() => {
                        window.location.href='/sell-upv-exchange';
                    }, 3000)
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                }
            }).catch((error)=>{
            Toaster.create({position: Position.TOP}).show({message:"Something went wrong",intent: Intent.DANGER});
        })

    }
    render(){
        const upvData = this.state.upvDetail
        let color, opacity;
        let status;
        let str, strp;
        if(upvData.bid_status==="OPEN"){
            color="text-success";
            status="Bid Open"
        }else if(upvData.bid_status==="ON_HOLD"){
            color="text-warning";
            status="On Hold"
        }else if(upvData.bid_status==="CLOSED"){
            color="text-danger"
            status="Bid Closed"
        }
        return(
            <React.Fragment>
                <div className="upvDetailContainer p-4">
                    <div className="row">
                        <div className="col-12 col-lg-1 col-md-1 col-sm-1">
                            <div className="upvRight">
                                <h5 className={"m-0"}>UPV No</h5>
                                <h4 className={"m-0 pt-1"}>{upvData.upv_id}</h4>
                            </div>
                        </div>
                        <div className="col-12 col-lg-11 col-md-11 col-sm-11">
                            <div className="upvLeft text-left">
                                <div className={"topbar"}>
                                    <div>
                                        <h4 className="m-0 nameText">{upvData.sso_username}</h4>
                                        <h5 className="m-0 f-16">{upvData.upv_generation_date} {upvData.upv_generation_time}</h5>
                                        <Button className="btn-link" onClick={() => {
                                            let title = "UPV No : "+upvData.upv_id;
                                            this.setState({
                                                isVisibleMilestone:true,
                                                milestones:upvData.milestones,
                                                title:title
                                            });
                                        }}>View Milestone</Button>
                                    </div>
                                    <div>
                                        <h4 className="m-0 priceText text-right">₹ {upvData.bid_amount ? upvData.bid_amount : "0.00"}</h4>
                                        <h4 className={"m-0 f-16 text-right "+color} style={{fontWeight:'500'}}>{status}</h4>
                                        <div>
                                            {upvData.bid_status==="ON_HOLD" ?
                                                <div className={"text-right"}>
                                                    <Button
                                                        icon={"minus"}
                                                        intent={"danger"}
                                                        onClick={() => {
                                                            this.setState({
                                                                isVisibleDeleteBidModal:true
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                :
                                                <div>
                                                    <Button
                                                        icon={"stop"}
                                                        intent={"warning"}
                                                        onClick={() => {
                                                            this.setState({
                                                                isVisibleCloseBidModal:true
                                                            })
                                                        }}
                                                    />
                                                    <Button
                                                        icon={"minus"}
                                                        intent={"danger"}
                                                        onClick={() => {
                                                            this.setState({
                                                                isVisibleDeleteBidModal:true
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Dialog isOpen={this.state.isVisibleMilestone}
                            title={this.state.title}
                            className="milestoneWrapper"
                            style={{marginTop:'250px'}}
                            onClose={this.closeModal.bind(this)} >
                        <div className="milestone m-3 text-center">
                            <div className="row">
                                { this.state.milestones.length > 0 ?
                                    this.state.milestones.map((resp, i)=>{
                                        i = i + 1;
                                        if(i===1){
                                            str = "1st";
                                            strp= "Cashback";
                                        }else if(i===2){
                                            str = "2nd";
                                            strp= "Cashback";
                                        }else if(i===3){
                                            str = "3rd";
                                            strp= "Cashback";
                                        }else if(i===4){
                                            str = "4th";
                                            strp= "GG Coin Conversion";
                                        }

                                        if(resp.cashback_given){
                                            color="#FE5A23";
                                            opacity="#B53206";
                                        }else{
                                            color="#40BE65";
                                            opacity="#164A25";
                                        }
                                        return(
                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-s-4 mt-sm-4" key={i}>
                                                <div className="section">
                                                    <div className="topSection pt-3 pb-3" style={{backgroundColor:color}}>
                                                        <h3>{str}</h3>
                                                        <p>{strp}</p>
                                                    </div>
                                                    <div className="bottomSection pt-3 pb-3" style={{opacity:0.8,backgroundColor:opacity}} >
                                                        <p>of {resp.milestone_description} <br></br><strong>{resp.upv_id}</strong><br></br> no. of UPV Generation</p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }):
                                    <td colSpan="3" className="emptyState">
                                        <img src={require('../../../core/images/empty-state/UPV.svg')} alt=""/>
                                    </td>
                                }
                            </div>
                        </div>
                    </Dialog>
                </div>
                <BidList {...this.props} />
                <Dialog isOpen={this.state.isVisibleDeleteBidModal}
                        title={"Remove UPV"}
                        className="bidModal"
                        style={{marginTop:'250px'}}
                        onClose={this.closeDeleteBidModal.bind(this)} >
                    <div className="bidForm m-3 text-center">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="text-left f-16">Do you want to remove this UPV from Selling?</h4>
                            </div>
                            <div className="col-12">
                                <div className="bidFormBtn mt-4 mb-4">
                                    <Button
                                        text={"Cancel"}
                                        intent={"default"}
                                        onClick={() => {
                                            this.setState({
                                                isVisibleDeleteBidModal:false
                                            })
                                        }}
                                    />
                                    <Button
                                        text={"Remove"}
                                        intent={"primary"}
                                        onClick={() => this.deleteUPV()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog isOpen={this.state.isVisibleCloseBidModal}
                        title={"Stop Bid"}
                        className="bidModal"
                        style={{marginTop:'250px'}}
                        onClose={this.closeClosedBidModal.bind(this)} >
                    <div className="bidForm m-3 text-center">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="text-left f-16">Are you sure you want to stop this UPV?</h4>
                            </div>
                            <div className="col-12">
                                <div className="bidFormBtn mt-4 mb-4">
                                    <Button
                                        text={"Cancel"}
                                        intent={"default"}
                                        onClick={() => {
                                            this.setState({
                                                isVisibleCloseBidModal:false
                                            })
                                        }}
                                    />
                                    <Button
                                        text={"Close"}
                                        intent={"primary"}
                                        onClick={() => this.closeUPV()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}