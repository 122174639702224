import {connect} from 'react-redux';
import {getNewRechargeInvoice} from '../../../../core/actions/action';
import Invoice from '../Components/Invoice';

const mapStateToProps = state => {

    return{
        rechargeInvoice:state.Recharge.rechargeInvoice,
    }
}

const mapDispatchToProps = dispatch => {

    return {

        getRechargeInvoice:(orderId) => {
            return getNewRechargeInvoice(orderId);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
