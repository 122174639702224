import {connect} from 'react-redux';
import {getNewMallOrderList} from '../../../../core/actions/action';
import MallOrder from '../Components/MallOrder';


const mapStateToProps = state => {

    console.log("hii",state)
        return{
        storeOrderList:state.Travel.newStoreOrderList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getNewMallOrderList:(params) => {
            return getNewMallOrderList(params);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MallOrder);
