let initialState = {
    rechargeOrderList:[],
    rechargeInvoice:null,

};

const Recharge = (state = initialState, action) => {

    switch (action.type) {

        case 'RECHARGE_ORDER':
            return {...state, rechargeOrderList: action.sources}
        case 'RECHARGE_INVOICE':
            return {...state, rechargeInvoice: action.sources}
        default:
            return state
    }
}

export default Recharge;
