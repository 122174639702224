import React from 'react';
import {Auth, NoLogin, Error, Helper} from "gg-react-utilities";
import TopBar from "../../../core/components/TopBar";
import PageTitle from "../../../core/components/PageTitle";
import {Link} from "react-router-dom";
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import {Button, Intent, Position, Toaster} from "@blueprintjs/core";
import {getTransactionInvoice} from "../../../core/actions/action";
import {connect} from "react-redux";
import Config from "../../../Config";
import axios from 'axios';


class BidTransactionDetail extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isLoggedIn:false,
            loading:false
        }
    }

    componentDidMount() {
        this.checkAuth();
        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1);
    }

    getTransactionInvoice(bidPaymentID) {
        this.props.getTransactionInvoice(bidPaymentID)
        .then((response)=>{
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'invoice.pdf');
            document.body.appendChild(link);
            link.click();
        }).catch((error)=> {
            Toaster.create({position: Position.TOP}).show({message: "Something went wrong.", intent: Intent.DANGER});
        });
    }


    render(){
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <PageTitle title="UPV Exchange - Global Garner"/>
                        <TopBar title="UPV Exchange" />
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }
        const upv = this.props.location.state ? this.props.location.state.upv : undefined;
        return(
            <React.Fragment>
                {this.props.location.state !== undefined ?
                    <div className="container mb-5">
                        <PageTitle title="UPV Exchange - Global Garner"/>
                        <div className="exchangeWrapper">
                            <div className="exchangeTopBar">
                                <div className="top-bar">
                                    <Link to='/bids-transaction'>
                                        <span className="back-icon"><KeyboardArrowLeftRounded/></span>
                                        <h1 className="title m-0">UPV Exchange / My UPV Transactions / <small><b>UPV Number {upv.upv_id}</b></small></h1>
                                    </Link>
                                </div>
                                <div className="leftTopBar">
                                    <Link to={"/upv-exchange-info"}>UPV Exchange info</Link>
                                </div>
                            </div>
                            <div className="paymentContainer">
                                {upv.upv_status === "PURCHASED" ?
                                    <div className="UPVblock">
                                        <div className="rupeeBlock purchase">
                                            <h4 className="m-1" style={{
                                                color: '#fff',
                                                fontSize: '20px',
                                                fontWeight: '500'
                                            }}>{upv.upv_status === "PURCHASED" ? "Purchased" : "Sold"}</h4>
                                            <h3 className="m-0">{Helper.formatMoney(upv.bid_price)}</h3>
                                        </div>
                                        <div className="bidCenterBlock">
                                            <div className="row">
                                                <div className="col-12 col-lg-3 col-md-3 col-sm-3">
                                                    <div className="upvRight">
                                                        <h5 className={"m-0 f-16"}>UPV No</h5>
                                                        <h4 className={"m-0"}>{upv.upv_id}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-9 col-md-9 col-sm-9">
                                                    <div className="upvLeft text-left">
                                                        <div className={"topbar"}>
                                                            <div>
                                                                <h4 className="m-0 nameText">Seller
                                                                    : {upv.seller_username}</h4>
                                                                <h5 className="m-0 timeText">{upv.formatted_created_at}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="footerBlock p-4">
                                            <Button className={"m-0 f-16 "}
                                                    text={"Download Invoice"}
                                                    intent={"primary"}
                                                    style={{
                                                        height: 25,
                                                        padding: '0px 10px',
                                                        boxShadow: 'none',
                                                        fontSize: '14px',
                                                        width: '100%',
                                                        borderRadius: '5px'
                                                    }}
                                                    onClick={() =>
                                                        this.getTransactionInvoice(upv.id)
                                                    }
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className="UPVblock">
                                        <div className="rupeeBlock sold">
                                            <h4 className="m-1" style={{
                                                color: '#fff',
                                                fontSize: '20px',
                                                fontWeight: '500'
                                            }}>{upv.upv_status === "PURCHASED" ? "Purchased" : "Sold"}</h4>
                                            <h3 className="m-0">{Helper.formatMoney(upv.bid_price)}</h3>
                                        </div>
                                        <div className="bidCenterBlock">
                                            <div className="row">
                                                <div className="col-12 col-lg-3 col-md-3 col-sm-3">
                                                    <div className="upvRight">
                                                        <h5 className={"m-0 f-16"}>UPV No</h5>
                                                        <h4 className={"m-0"}>{upv.upv_id}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-9 col-md-9 col-sm-9">
                                                    <div className="upvLeft text-left">
                                                        <div className={"topbar"}>
                                                            <div>
                                                                <h4 className="m-0 nameText">Buyer
                                                                    : {upv.buyer_username}</h4>
                                                                <h5 className="m-0 timeText">{upv.formatted_created_at}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-4 mb-4" style={{backgroundColor: '#F2F2F2', height: '1.56px',}}> </div>

                                            <div className="block">
                                                <h4 className="m-1">UPV Bid Price</h4>
                                                <h4 className="m-1" style={{fontWeight:'500'}}>{Helper.formatMoney(upv.bid_price)}</h4>
                                            </div>
                                            <div className="block">
                                                <h4 className="m-1">Exchange Charge ({upv.charge_percentage + "%"})</h4>
                                                <h4 className="m-1" style={{fontWeight:'500'}}>- {Helper.formatMoney(upv.charge)}</h4>
                                            </div>
                                            <div className="mt-4 mb-4" style={{backgroundColor: '#F2F2F2', height: '1.56px',}}> </div>

                                            <div className="block">
                                                <h4 className="m-0">Total</h4>
                                                <h4 className="m-0" style={{fontWeight:'500'}}>{Helper.formatMoney(upv.total_credit_amount)}</h4>
                                            </div>
                                            <div className="mt-4 mb-4" style={{backgroundColor: '#F2F2F2', height: '1.56px',}}> </div>

                                        </div>
                                        <p>Amount credited in GG Money</p>
                                        <div className="footerBlock p-4 m-0">
                                            <Link to={'/passbook'} style={{width:'100%'}}>
                                                <Button className={"m-0 f-16 "}
                                                        text={"Open Passbook"}
                                                        intent={"primary"}
                                                        style={{
                                                            height: 25,
                                                            padding: '0px 10px',
                                                            boxShadow: 'none',
                                                            fontSize: '14px',
                                                            width: '100%',
                                                            borderRadius: '5px'
                                                        }}
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <Error/>
            }
            </React.Fragment>
        );
    }
}


const mapDispatchToProps = dispatch => {

    return {
        getTransactionInvoice :(page) => {
            return getTransactionInvoice(page)
        }
    }
}

export default connect(null, mapDispatchToProps)(BidTransactionDetail);

