import React from 'react';
import AllStatus from './Components/AllStatus'
import BillStatus from './Components/BillStatus'
import {Auth, NoLogin} from "gg-react-utilities";



export default class MyStats extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingData : true,
            totalGgMoneyCashback:"0.00",
            myOnlineShopping:"0.00",
            myOfflineShopping:"0.00",
            referral_earning:"0.00",
            referral_count:"0",
            socialEarning:"0.00",
            upvstatus:'0',
            upvCount:'0.00',
            fpvStatus:"0",
            fpvCount:"0.00",
            pendingBills:'0',
            verifiedBills:'0',
            acceptedBills:'0',
            rejectedBills:'0',
            myStatsList:[],
            isLoggedIn:false
        }
    }

    componentDidMount() {

        this.checkAuth();

        this.getMyStatsList()

        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);
    }

    async checkAuth(){
        Auth.isLoggedInAsync().then((res) => {
            this.setState({isLoggedIn:res},()=>{
                if(!res){
                    Auth.login(true);
                }
            })
        });
    }

    getMyStatsList=()=>{
        setTimeout(() => {
            this.props.getMyStatsList()
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        this.setState( {
                            totalGgMoneyCashback:sources.data.total_gg_money_cashback,
                            myOnlineShopping:sources.data.online_shopping_amt,
                            myOfflineShopping:sources.data.offline_shopping_amt,
                            referral_earning:sources.data.user_referral_earning,
                            referral_count:sources.data.user_referral_count,
                            socialEarning:sources.data.user_social_earning,
                            upvstatus:sources.data.user_upv_count,
                            upvCount:sources.data.user_upv_cashback_amt,
                            fpvStatus:sources.data.user_fpv_count,
                            fpvCount:sources.data.user_fpv_cashback_amt,
                            pendingBills:sources.data.pending_bill_count,
                            verifiedBills:sources.data.verified_bill_count,
                            acceptedBills:sources.data.accepted_bill_count,
                            rejectedBills:sources.data.rejected_bill_count,
                        });
                    }else{

                    }
                }).catch((error)=>{
                const sources = error.response;
                console.log(sources);
            })
        }, 1000);
    }

    render(){
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="row my-5 myStats">
                    <AllStatus
                        loading={this.state.loadingData}
                        myOnlineShopping={this.state.myOnlineShopping}
                        myOfflineShopping={this.state.myOfflineShopping}
                        referral_earning={this.state.referral_earning}
                        referral_count={this.state.referral_count}
                        socialEarning={this.state.socialEarning}
                        upvstatus={this.state.upvstatus}
                        upvCount={this.state.upvCount}
                        fpvStatus={this.state.fpvStatus}
                        fpvCount={this.state.fpvCount}
                        totalGgMoneyCashback={this.state.totalGgMoneyCashback}
                    />
                </div>
                <div className="row my-5 myStats">
                    <BillStatus
                        loading={this.state.loadingData}
                        pendingBills={this.state.pendingBills}
                        verifiedBills={this.state.verifiedBills}
                        acceptedBills={this.state.acceptedBills}
                        rejectedBills={this.state.rejectedBills}
                    />
                </div>
            </React.Fragment>
        );
    }
}