import {connect} from 'react-redux';
import {getApprovedOrderList} from '../../../core/actions/action';
import Approved from '../index';

const mapStateToProps = state => {

    return{
        approvedOrderList:state.AllOrders.approvedOrderList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getApprovedOrderList:(params) => {
            return getApprovedOrderList(params);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Approved);
