
import React from 'react';
import { Button, Classes, Dialog } from "@blueprintjs/core";
import Config from "../../../../Config";

export default class ViewNewOrders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            userBill: ""
        };
        this.handleClose = this.handleClose.bind(this)
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        //this.setState({ userBill: nextProps.viewUserBill });
    }


    handleClose() {
        this.setState({ isOpen: false });
    }

    renderStatusHtml(status) {

        switch (status) {
            case 1:
                return <label className="label text-warning">Awaiting Payment</label>;
            case 2:
                return <label className="badge text-warning">Awaiting Shipment</label>;
            case 3:
                return <label className="badge text-warning">Awaiting Pickup</label>;
            case 4:
                return <label className="badge text-success">Intransit</label>;
            case 5:
                return <label className="badge text-success">Delivered</label>;
            case 6:
                return <label className="badge text-success">Completed</label>;
            case 7:
                return <label className="badge text-danger">Cancelled</label>;
            case 8:
                return <label className="badge text-danger">Declined</label>;
            case 9:
                return <label className="badge text-success">Disputed</label>;
            default:
                return <label className="label text-default">--</label>;
        }
    }


    render() {
        console.log("hisjcdsjcsdcsd", this.props)
        return (
            <React.Fragment>
                <Button small={true} intent="primary" title="View" icon="eye-open" className="btn-view mr-2"
                    onClick={() => {
                        this.setState({ isOpen: true })
                        // this.props.viewStoreOrder(this.props.orderDetails.orderid)
                    }} />
                <Dialog isOpen={this.state.isOpen} onClose={() => this.handleClose()} className="bp3-dialog-large store-order"
                    title={"Order Detail " + this.props.orderDetails.orderid}>
                    {this.props.orderDetails !== null ?

                        <div className={Classes.DIALOG_BODY}>
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-12 storeOrderDetails mt-s-4">
                                    <div style={{height: '120px', 'overflow-x': 'hidden', 'overflow-y': 'scroll'}}>
                                <div className="row">
                                    <div className="col-md-4 col-sm-4 col-12 orderImg text-center-xs">
                                        {this.props.orderDetails.products != undefined && this.props.orderDetails.products[0].product_image != 'NA' ?
                                            <img style={{ width: '100px' }} src={Config.DEFAULT_IMAGE_URL} alt="product" className="img-fluid"
                                                onError={(ev) => {
                                                    ev.target.src = Config.DEFAULT_IMAGE_URL;
                                                }} />
                                            :
                                            <img style={{ width: '100px' }} src={this.props.orderDetails.products != undefined && this.props.orderDetails.products[0].product_image}
                                                alt="product" className="img-fluid"
                                                onError={(ev) => {
                                                    ev.target.src = Config.DEFAULT_IMAGE_URL;
                                                }} />
                                        }
                                    </div>
                                    <div className="col-md-8 col-sm-8 col-12 storeOrderDetails mt-s-4">
                                        <div className="row mt-2">
                                            <div className="col-md-6 col-sm-6 col-6">
                                                <p className="heading">Product Name</p>
                                                <p classname="pt-1">ABC</p>
                                                <p className="headingTitle">{this.props.orderDetails.products != undefined && this.props.orderDetails.products[0].name}</p>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-6">
                                                <p className="heading">Quantity</p>
                                                <p className="headingTitle">{this.props.orderDetails.products != undefined && this.props.orderDetails.products[0].qty_ordered}</p>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-6">
                                                <p className="heading">category Name</p>
                                                <p>ABC</p>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-6">
                                                <p className="heading">Amount</p>
                                                <p>0000</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                </div>
                                
                                <div className="col-md-12 col-sm-12 col-12 storeOrderDetails mt-s-4">
                                    <div className="row mt-2">
                                        <div className="col-md-4 col-sm-4 col-6">
                                            <p className="heading">Order Id</p>
                                            <p className="headingTitle">{this.props.orderDetails.orderid}</p>
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-6">
                                            <p className="heading">Order Date</p>
                                            <p className="headingTitle">{this.props.orderDetails.order_date}</p>
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-6">
                                            <p className="heading">Order Status</p>
                                            <p className="headingTitle text-nowrap">
                                                {
                                                    this.renderStatusHtml(this.props.orderDetails.status.status)
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    {/* <div className="row mt-2">
                                        
                                        <div className="col-md-6 col-sm-6 col-6">
                                            <p className="heading">SKU</p>
                                            <p className="headingTitle">{this.props.orderDetails.products != undefined && this.props.orderDetails.products[0].sku}</p>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 col-sm-6 col-6">
                                            <p className="heading">Payment Method</p>
                                            <p className="headingTitle">{this.props.orderDetails.payment_method}</p>
                                        </div>
                                        
                                    </div> */}
                                </div>
                            </div>
                            <div className="row mt-2 mb-4">
                                <div className="col-md-12 col-12">
                                    <div className="OrderShippingDetails">
                                        <p className="userInfo">{this.props.orderDetails.shipping_details.firstname} {this.props.orderDetails.shipping_details.lastname}</p>
                                        <p className="">{this.props.orderDetails.shipping_details.street}, {this.props.orderDetails.shipping_details.city}</p>
                                        {/* <p>{this.props.shipping_details.address_area}</p>
                                        <p>{this.props.shipping_details.address_city}</p> */}
                                        <p>{this.props.orderDetails.shipping_details.region},{/*  {this.props.shipping_details.address_country}, */} {this.props.orderDetails.shipping_details.postcode}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12 col-12 orderTotal">
                                    <p><b>Payment summary</b></p>
                                    {/* {
                                        this.props.orderDetails ?
                                            this.props.products.map((orderTotal, key) => {
                                                if (orderTotal.code !== 'gg_commision') {
                                                    if (orderTotal.code !== 'grand_total') {
                                                        return (
															<div className="table-items">
																<div className="row tr-item">
																	<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
																		<p className="d1 totalTitle" key={key}>
                                                                            {orderTotal.subtotal}
																		</p>
																	</div>
																	<div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-2 px-s-0">
																		<div className="d2">&nbsp;</div>
																	</div>
																	<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
																		<p className="d3 t-sub-title">
                                                                            ₹ {orderTotal.grand_total}
																		</p>
																	</div>
																</div>
															</div>
                                                        )
                                                    }else{
                                                        return (
															<div className="table-items">
																<hr/>
																<div className="row tr-item">
																	<div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
																		<div className="d1 totalTitle" key={key}>
                                                                            {orderTotal.subtotal}
																		</div>
																	</div>
																	<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
																		<div className="d3 t-sub-title">
                                                                            ₹ {orderTotal.grand_total}
																		</div>
																	</div>
																</div>
															</div>
                                                        )
                                                    }

                                                }

                                            })
                                            :
                                            ""
                                    } */}
                                    <div className="table-items">
                                        <div className="row tr-item">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                                                <p className="d1 totalTitle" >
                                                    Grand Total
                                                </p>
                                            </div>
                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-2 px-s-0">
                                                <div className="d2">&nbsp;</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <p className="d3 t-sub-title">
                                                    ₹ {this.props.orderDetails.grand_total}
                                                </p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={Classes.DIALOG_BODY}>
                            <div className="bp3-skeleton" style={{ width: "100%", height: "200px" }}></div>
                        </div>
                    }
                </Dialog>
            </React.Fragment>
        );
    }
}