import React, { Component } from 'react';
import StoreOrder from './StoreOrder/Index'
import NewStore from './NewStore/Index'
import NewMall from './NewMall/Index'
import ServiceBooking from './ServiceBooking/Index'
import { Radio, RadioGroup } from "@blueprintjs/core";

export default class Store extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: 1,
        };
    }

    changeOrders(e) {
        this.setState({ selectedOption: Number(e.target.value) })
    }
    componentDidMount(){
        if(this.props.location.hash.split("#")[2] !=undefined){
      this.setState({selectedOption:Number(this.props.location.hash.split("#")[2])})}else{
          this.setState({selectedOption:1})
      }
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.location.hash.split("#")[2] !=undefined){
        this.setState({selectedOption:Number(nextProps.location.hash.split("#")[2])})}else{
            this.setState({selectedOption:1})
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="megabrand">
                    <div className="megabrandOption">
                        <RadioGroup
                            onChange={(e) => this.changeOrders(e)}
                            name="type"
                            className="d-inline option-type col-12"
                            selectedValue={this.state.selectedOption}>
                            <Radio label="Order" value={1} />
                            <Radio label="Service Booking" value={2} />
                            <Radio label="Online Vendors Orders" value={3} />
                            <Radio label="GG Mall Orders" value={4} />
                        </RadioGroup>
                    </div>
                    {
                        this.state.selectedOption === 1 ?
                            <StoreOrder />
                            : this.state.selectedOption === 2 ?
                                <ServiceBooking />
                                : this.state.selectedOption === 3 ?
                                    <NewStore />
                                    : this.state.selectedOption === 4 ?
                                        <NewMall />
                                        : <StoreOrder />

                    }
                </div>
            </React.Fragment>
        );
    }
}