import {connect} from 'react-redux';
import ViewBill from '../components/ViewBill';
import {getUserBillView} from '../../../core/actions/action';
import {viewUserBillAction} from '../../../core/actions';


const mapStateToProps = state => {

    return{
        viewUserBill:state.Bsw.viewUserBill,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getUserBillView :(billNo) => {
            dispatch(viewUserBillAction(null));
            return getUserBillView(billNo)
                .then((res)=>{
                    const sources = res.data.data;
                    dispatch(viewUserBillAction(sources));
                }).catch((error)=>{
                    const sources = error.response;
                    console.log(sources);
                })
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewBill);
