let initialState = {
    upvFifoList:[],
    icList:null,
    circleList:null,
    rppCurrentList:[],
    rppOldList:[],
    cashbackOpt:"",
    changeOption:"",
    fpvFifoList:[],
    fpvRPPCurrentList:null,
    fpvRPPOldList:null,
    fpvICList:[],
    cashbackFPVOpt:"",
    changeFPVOption:"",
    withdrawRequestList: [],
    otpRequest:[],
    saveRequest:[],
    rewardList:[],
    voucherStatus:"",
    setVoucher:null,
    upvReplaceInboxList:[],
    upvReplaceSentList:[],
    orderAggregateData:[],
};

const cashback = (state = initialState, action) => {

    switch (action.type) {

        case 'UPV_FIFO_LIST':
            return {...state, upvFifoList: action.sources};
        case 'RPP_CURRENT_LIST':
            return {...state, rppCurrentList: action.sources};
        case 'RPP_OLD_LIST':
            return {...state, rppOldList: action.sources};
        case 'IC_LIST':
            return {...state, icList: action.sources};
        case 'CIRCLE_LIST':
            return {...state, circleList: action.sources};
        case 'CASHBACK_OPTION':
            return {...state, cashbackOpt: action.sources};
        case 'CHANGE_CASHBACK_OPTION':
            return {...state, changeOption: action.sources};
        case 'FPV_FIFO_LIST':
            return {...state, fpvFifoList: action.sources};
        case 'FPV_CURRENT_RPP_LIST':
            return {...state, fpvRPPCurrentList: action.sources};
        case 'FPV_OLD_RPP_LIST':
            return {...state, fpvRPPOldList: action.sources};
        case 'FPV_IC_LIST':
            return {...state, fpvICList: action.sources};
        case 'CASHBACK_FPV_OPTION':
            return {...state, cashbackFPVOpt: action.sources};
        case 'CHANGE_CASHBACK_FPV_OPTION':
            return {...state, changeFPVOption: action.sources};
        case 'WITHDRAW_REQUEST':
            return {...state, withdrawRequestList: action.sources};
        case 'OTP_REQUEST':
            return {...state, otpRequest: action.sources};
        case 'SAVE_REQUEST':
            return {...state, saveRequest: action.sources};
        case 'REWARD_LIST':
            return {...state, rewardList: action.sources};
        case 'SET_VOUCHER':
            return {...state, setVoucher: action.sources};
        case 'VOUCHER_STATUS':
            return {...state, voucherStatus: action.sources};
        case 'UPV_REPLACE_INBOX_LIST':
            return {...state, upvReplaceInboxList: action.sources};
        case 'UPV_REPLACE_SENT_LIST':
            return {...state, upvReplaceSentList: action.sources};
        case 'FPV_REPLACE_INBOX_LIST':
            return {...state, fpvReplaceInboxList: action.sources};
        case 'FPV_REPLACE_SENT_LIST':
            return {...state, fpvReplaceSentList: action.sources};
        case 'ORDER_AGGREGATE_DATA':
            return {...state, orderAggregateData: action.sources};
        default:
            return state
    }
}

export default cashback;