import React from "react";
import PageTitle from "../../../core/components/PageTitle";
import TopBar from "../../../core/components/TopBar";
import waiting from "../../images/waiting.svg";
import '../../../core/styles/_coinconversion.scss';
import {
  PlayForWork
} from '@material-ui/icons';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getFpvLevelOfCoin, fpvCoinConverzation } from '../../../core/actions/action';
import { Button } from '@blueprintjs/core'
import Card from './coin_conversion_card';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

export default class coin_conversion extends React.Component {

  constructor(props) {
    super(props);
    this.dropzoneRef = React.createRef();
    this.cardOneRef = React.createRef();
    this.state = {
      fpvID: props.match.params.fpvID,
      data: '',
      Amount: "",
      Lavel: "",
      modalIsOpen3: false,
    };

  }

  componentDidMount() {
    var id = this.state.fpvID
    getFpvLevelOfCoin(id)
        .then((res) => {
          this.setState({
            data: res.data.data
          })


        }).catch((error) => {
      const sources = error.response;
    })


  }

  //Drag Start
  handleDragStart = (e) => {
    e.dataTransfer.setData('text/html', e.target.id);
    e.dataTransfer.setData('id', e.target.id);
  };

  //Drop the Content
  handleDrop = e => {
    var data = e.dataTransfer.getData('text/html');
    var id = e.dataTransfer.getData('id');
    let successFul = document.getElementById('successfulCon');

    if (this.state.data.convertible_coins >= this.state.Amount ) {
      let optionElement = document.getElementById("card-" +this.state.Lavel);
      var nodeCopy = optionElement.cloneNode(true); //copy card
      nodeCopy.id = "newId-" + this.state.Lavel
      e.target.appendChild(nodeCopy); //append card
      let newId = document.getElementById("newId-" + this.state.Lavel);
      newId.style.position = 'relative';
      newId.style.top = '-144px';

      optionElement.style.opacity = 0.2;
      optionElement.draggable = false;
      let loader = document.getElementById('loader');
      loader.style.zIndex = "1";
      setTimeout(() => {
        fpvCoinConverzation(this.state.fpvID, this.state.Amount)
            .then((res) => {
              if (res.status == 200) {
                loader.style.zIndex = "-1";
                let newId = document.getElementById("newId-" + this.state.Lavel);
                newId.parentNode.removeChild(newId)
                successFul.style.display = "flex"
                optionElement.replaceWith(successFul) //replace drag card
                e.target.removeChild(nodeCopy)
                optionElement.replaceChild(successFul, optionElement);
              }
            }).catch((error) => {
          const sources = error.response;
        })
      }, 6000);
    } else {
      let alert = document.getElementById('alert')
      e.target.appendChild(alert);
      alert.style.display = "flex"
      setTimeout(() => {
        alert.style.display = "none"
      }, 6000);
    }


  };

  //After Drag
  handleDragOver = e => {
    let event = e;
    event.preventDefault();
    event.stopPropagation();
  };


  setConvertedCoin = (coin,lavel) =>{
    this.setState({
      Amount: coin,
      Lavel:lavel
    })
  }

  render() {

    if (this.state.data == ''){
      return(
          <React.Fragment>
            <div className="container">
              <PageTitle title="FPV Exchange Info - Global Garner" />
              <div className="">
                <div className="exchangeTopBar">
                  <TopBar
                      title={
                        "Coin Conversion (Circle Number : " + this.state.fpvID + ")"
                      }
                  />

                  <div className="container mb-5">
                    <PageTitle title="FPV Exchange Info - Global Garner" />
                    <div className={"bidBlock p-3 mt-3 bp3-skeleton"}>
                      <div className="exchangeWrapper">
                        <div className="exchangeInfoContainer text-center">
                          <div className="container py-5 py-s-4">
                            <img src={waiting} alt="" />
                            <p className="pt-4 font-20 font-weight-1">GG Coin to GG Money Conversion will Open After you complete <br /> Level - 4 in you own Circle</p>
                            <div className="pt-4">
                              <Button
                                  text={"Cancel"}
                                  intent={"primary"}
                                  onClick={() => this.props.history.goBack()}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
      )
    }

    return (
        <React.Fragment>
          <div className="container">
            <PageTitle title="FPV Exchange Info - Global Garner" />
            <div className="">
              <div className="exchangeTopBar">
                <div className="row">
                  <div className="col-md-8 col-sm-8 col-xs-12">
                    <TopBar
                        title={
                          "Coin Conversion (Circle Number : " + this.state.fpvID + ")"
                        }
                    />
                  </div>

                  {this.state.data.levels_completed && this.state.data.levels_completed >= 4 ?

                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <p className="coin-his" onClick={() => this.props.history.push('/fpv/circle/coinconversion/history/' + this.state.fpvID)}>Coin Redeem History</p>
                      </div>:""}
                </div>

                {
                  this.state.data.levels_completed && this.state.data.levels_completed >= 4 ?
                      <React.Fragment>
                        <div className="pt-5 pl-3 pb-3 coins-bg">
                          <div className="row">
                            <div className="col-md-6 col-sm-6 col-6 d-flex border-right">
                              <img class="gg-coins-img" src={require('../../images/rupeeios.png')} alt="" />
                              <div className="con-gg-coins">
                                <p >Con. GG Coins</p>
                                <p className="co-prise font-weight-500">{this.state.data.convertible_coins}</p>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-6 d-flex">
                              <div className="con-gg-coins">
                                <p>Total GG Coins</p>
                                <p class="font-weight-500">{this.state.data.gg_coin_balance}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="pt-3 pb-3" >
                            <Carousel responsive={responsive} arrows={false}>
                              {
                                this.state.data.coin_conversion_options.map((value,key)=>{
                                  if(value == 0){
                                    return(
                                        <div class="coin-card sucess-coin-card m-3 position-relative" draggable={false} id="successfulCon" >
                                          <div class="coin-sucess-title">
                                            <p class="co-prise">{key == 0 ? 100 : key == 1 ? 360 : key == 2 ? 1000 : key == 3 ? 3000 : key == 4 ? 15000 : key == 5 ? 50000 : '' }</p>
                                            <p>Conversion Successful</p>
                                            <small>Amount Shown in 3 Days</small>
                                            <p className="reddme-detail" onClick={() => this.props.history.push('/fpv/circle/coinconversion/history/' + this.state.fpvID)}>&gt;&gt; Reddemed Coins Detail</p>
                                          </div>
                                        </div>
                                    )
                                  }else{
                                    return(
                                        <Card
                                            key={key}
                                            Lavel={4 + key}
                                            Amount={value}
                                            ggCoinBalance={this.state.data.gg_coin_balance}
                                            handleDragStart={(e)=>{
                                              this.handleDragStart(e)
                                            }}
                                            setConvertedCoin={(coin,lavel) => {
                                              this.setConvertedCoin(coin,lavel)
                                            }}
                                        />
                                    )
                                  }
                                })
                              }
                              <div className="amount-alert" id="alert">
                                please enter valid amount
                              </div>
                              <div class="coin-card sucess-coin-card m-3 position-relative" draggable={false} id="successfulCon" style={{ display: 'none' }} >
                                <div class="coin-sucess-title">
                                  <p class="co-prise">{this.state.data.levels_completed == 4 && this.state.Amount || this.state.data.levels_completed == 5 && this.state.Amount2 || this.state.data.levels_completed == 6 && this.state.Amount3 || this.state.data.levels_completed == 7 && this.state.Amount4 || this.state.levels_completed == 8 && this.state.Amount5 || this.state.data.levels_completed == 9 && this.state.Amount6}</p>
                                  <p>Conversion Successful</p>
                                  <small>Amount Shown in 3 Days</small>
                                  <p className="reddme-detail" onClick={() => this.props.history.push('/circle/coinconversion/history/' + this.state.fpvID)}>&gt;&gt; Reddemed Coins Detail</p>
                                </div>
                              </div>       </Carousel>
                          </div>
                          <div class="row">
                            <div class="col-lg-3 col-md-4 col-xs-12 pb-5 m-3">
                              <div class="text-center dropcard-drop mb-2">
                                <p class="drop-card mb-2"><small>Drag down to claim</small></p>
                                <PlayForWork />
                              </div>
                              <div onDrop={this.handleDrop} onDragOver={this.handleDragOver}>
                                <div class="drop-border" id="dropzone"
                                     ref={this.dropzoneRef} ><div class="water" id="loader"></div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                      : ""}
                {this.state.data && this.state.data.levels_completed < 4 || this.state.data.levels_completed ==0  ?
                    <div className="container mb-5">
                      <PageTitle title="FPV Exchange Info - Global Garner" />
                      <div className="exchangeWrapper">
                        <div className="exchangeInfoContainer text-center">
                          <div className="container py-5 py-s-4">
                            <img src={waiting} alt="" />
                            <p className="pt-4 font-20 font-weight-1">GG Coin to GG Money Conversion will Open After you complete <br /> Level - 4 in you own Circle</p>
                            <div className="pt-4">
                              <Button
                                  text={"Cancel"}
                                  intent={"primary"}
                                  onClick={() => this.props.history.goBack()}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ""}

              </div>
              <div />
            </div>
          </div>
          <div>
          </div>
        </React.Fragment>
    );

  }
}