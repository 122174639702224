import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';
import VoucherAction from '../components/VoucherAction';
import {changeVoucherStatus, setVoucherCode} from '../../core/actions/action';
import {  changeVoucherStatusAction } from '../../core/actions';

const mapStateToProps = state => {

    return{
        voucherStatus:state.cashbackReducer.voucherStatus,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        changeVoucherStatus :(obj) => {
            return changeVoucherStatus(obj)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(changeVoucherStatusAction(sources.data));
                        Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                        dispatch(changeVoucherStatusAction(null));
                    }
                }).catch((error)=>{
                    const sources = error.response;

                    Toaster.create({position: Position.TOP}).show({message:sources.data.error.id,intent: Intent.DANGER});
                })
        },
        setVoucherCode :(obj) => {
            return setVoucherCode(obj)
        /* .then((res)=>{
             const sources = res.data;
             if(sources.status===true){
                 dispatch(setVoucherCodeAction(sources.data));
             }else{
                 Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                 dispatch(setVoucherCodeAction(null));
             }
         }).catch((res)=>{
             Toaster.create({position: Position.TOP}).show({message:'something went wrong',intent: Intent.DANGER});
         })*/
    }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VoucherAction);
