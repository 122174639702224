import React from 'react';
import {Header, Footer, Auth} from "gg-react-utilities";
import PageTitle from "../core/components/PageTitle";
import {Link} from "react-router-dom";
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import TopBar from "../core/components/TopBar";

export default class UPVExchangeInfo extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isHeader:'Web'
        }
    }

    componentDidMount() {
        setTimeout(async() => {
            let isHeader = 'Web';
            if (typeof getDeviceName == 'function' && window['getDeviceName'] !== undefined){

                await Auth.update();
                isHeader = window.getDeviceName();
            }else if(typeof Android !== "undefined" && window['Android'] !== null) {

                await Auth.update();
                isHeader = window.Android.getDeviceName();
            }

            this.setState({
                isHeader:isHeader
            });
        }, 50);
    }

    render(){
        return(
            <React.Fragment>
                {this.state.isHeader === undefined || this.state.isHeader === 'Web' ?
                    <React.Fragment>
                        <Header headerType="dark"/>
                        <div className="container mb-5">
                            <PageTitle title="UPV Exchange Info - Global Garner"/>
                            <div className="exchangeWrapper">
                                <div className="exchangeTopBar">
                                    <TopBar title="UPV Exchange" />
                                </div>
                                <div className="exchangeInfoContainer text-left">
                                    <h4 className="m-3 font-20 text-body font-weight-bold text-center">UPV EXCHANGE INFORMATIONS</h4>
                                    <div className={"m-3"}>
                                        <p className="mt-1 font-15">Global Garner has now offered you the option to bid for your existing UPVs and sell your UPVs to other users or Company if you can’t wait and think it might take some time to get the cashback on your UPVs. </p>
                                        <p className={"mt-2 font-15"}>A User can select the SCB FIFO UPV, he wants to bid and offer it for the auction. Then other users can call out their bids, with each bid being higher than the subsequent bid. The bidders have to enter the amount at which they wish to purchase a given UPV to announce their bid price, so that the auctioneer can identify who is making the bid. The auction ends when the buyer making the highest bid gets the UPV or when selected by the auctioneer by paying the specified amount.
                                        </p>
                                        <p className="font-15">The auctioneer who is bidding his UPV has full right to offer for bidding any number of UPV and can select any bidder he wants. It is the sole discretion of the auctioneer irrespective of the amount he receives against his UPV. Once accepted the buyer has to pay the agreed-on amount to the Company and the Company shall transfer the same amount after deducting the fee @ 10% to the auctioneer in his GG rewards as GG Money, which can then be transferred to the bank account.</p>
                                        <p className="font-15">The auctioneer will cease to have all rights on the UPV he sells to the bidder and bidder will get all cashback on the UPV purchased by him and all the previous benefits will remain with the auctioneer. The Company can also place the bid if it wants and the same process will be followed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    : ""
                }
                {
                    this.state.isHeader === undefined || this.state.isHeader === 'Web' ?
                        <Footer/>
                        :
                        ''
                }
            </React.Fragment>
        );
    }
}