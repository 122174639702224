import React, {Component} from 'react';
import Favourite from './Container/Favourite';


export default class GGVendor extends Component{
    constructor(props){
        super(props);
        this.state={
            loadingData : true,
            isLoggedIn:false,
        };
    }

    componentDidMount() {

        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 1000);
    }

    render(){
        return(

            <React.Fragment>
                <Favourite loading={this.state.loadingData}/>
            </React.Fragment>
        );
    }
}