import {connect} from 'react-redux';
import Circles from '../../components/circles';
import {getCircleUPVLevelList, getUPVLevels} from "../../../../core/actions/action";

const mapDispatchToProps = dispatch => {

    return {
        getUPVLevels: (upvID,obj) => {
            return getUPVLevels(upvID,obj)
        },
        getCircleUPVLevelList: () => {
            return getCircleUPVLevelList()
        }
    }
}

export default connect(null, mapDispatchToProps)(Circles);
