import React from 'react';
//import LoadMore from "./LoadMore";
import PassBookImage from '../../../core/images/empty-state/passbook.svg';
import {Helper} from "gg-react-utilities";
export default class PassbookCoin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            passbookCoinList:[],
            page:1,
            stopLoadMore: false,
            showEmptyStats:false,
            scrollLoader:true,
            loading: true,
        }
    }

    componentDidMount() {
        this.setState( {
            loading: this.props.loading,
        })

        let params={
            page:this.state.page,
        }
        this.getPassbookCoinList(params)
    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    getPassbookCoinList=(params)=>{
        this.props.getPassbookCoinList(params)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    let oldAffData = this.state.passbookCoinList;
                    let newAffDataFromApi = sources.data.data;
                    let newAffData = oldAffData.concat(newAffDataFromApi);
                    this.setState( {
                        passbookCoinList:newAffData,
                    });
                    if(sources.data.data === ""){
                        this.setState( {
                            showEmptyStats:true,
                        });
                    }
                    if(sources.data.current_page === sources.data.last_page){
                        this.setState( {
                            stopLoadMore: true,
                        })
                    }else{
                        this.setState( {
                            stopLoadMore: false,
                        })
                    }
                }else{

                }
            }).catch((error)=>{
            const sources = error.response;
            console.log(sources);
        })
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            if(this.state.stopLoadMore === false){
                this.NextPage()
            }
        }
    }

    NextPage= () =>{
        this.setState({
            scrollLoader:false
        })
        let oldPageNo = this.state.page;
        this.setState({
            page: oldPageNo + 1,
            scrollLoader:true
        }, () => {
            let params = {
                page: this.state.page,
            }
            this.getPassbookCoinList(params)
        })
    }

    renderStatusHtml(txn_type){
        switch (txn_type) {
            case 1:
                return <label className="label text-danger">Debit</label>;
            case 0:
                return <label className="badge text-success">Credit</label>;
            default:
                return <label className="label text-default">NA</label>;
        }
    }

    render(){
        return(
            <React.Fragment>
                <div style={{height:'100%',maxHeight:'590px',overflowY:'scroll'}} id={"allBooking"} onScroll={this.handleScroll} className="table-responsive mt-4">
                    <table className="bp3-html-table bp3-html-table-bordered">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Transaction ID</th>
                            <th>Descriptions</th>
                            <th>Amount</th>
                            <th>Transaction type</th>
                            <th>Request Time</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.loading ?
                                <tr>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                </tr>
                                :

                                this.state.passbookCoinList.length ?
                                    this.state.passbookCoinList.map((money, key) => {
                                        return <tr key={key}>
                                            <td>{key+1}</td>
                                            <td>{money.txn_id}</td>
                                            <td>{money.description}</td>
                                            <td>{Helper.formatMoney(money.amount)}</td>
                                            <td>
                                                {
                                                    this.renderStatusHtml(money.txn_type)
                                                }
                                            </td>
                                            <td>{money.txn_date_time}</td>
                                        </tr>
                                    })
                                    :
                                    <tr>
                                        <td colSpan="6" align="center">
                                            <div className="text-center">
                                                <img src={PassBookImage} alt=""/>
                                                <p className="mt-3">No transactions for the selected option</p>
                                            </div>
                                        </td>
                                    </tr>


                        }
                        </tbody>
                    </table>
                </div>
                {
                    this.state.stopLoadMore ? null :
                        this.state.scrollLoader ?
                            null :
                            <React.Fragment>
                                <div className="my-3">
                                   <img src="https://www.alfaromeousa.com/content/dam/alfausa/redesign/Stelvio/Colorizer/temp/loader.gif" alt={""} style={{width:'50px',height:'50px'}}/>
                                </div>
                            </React.Fragment>
                }
            </React.Fragment>
        );
    }
}