import React, { Component } from 'react';

export default class Request extends Component{
    constructor(props){
        super();
        this.state= {
            data:[],
            isSkeleton:true,
            last_page:1,
            counter:1,
            scrollLoader:true,
            storeLoadMore:false,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.getWithdrawRequestList(this.state.counter);
        }, 1000);
    }

    componentWillReceiveProps(nextProps) {
        let oldData = this.state.data;
        let newDataFromApi = nextProps.withdrawRequestList.data;
        let newData = oldData.concat(newDataFromApi);
        this.setState({
            data:newData,
            isSkeleton:false,
            last_page:nextProps.withdrawRequestList.last_page
        });
    }


    handleScroll=()=>{
        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);
        if (parseInt(allBookingHeight) === 0){
            if(this.state.storeLoadMore === false){
                this.loadMore()
            }
        }
    }

    loadMore = () =>{
        this.setState({
            scrollLoader:false
        });
        this.setState({
            counter: this.state.counter + 1,
            isLoading: true,
            scrollLoader:true
        }, () => {
            this.props.getWithdrawRequestList(this.state.counter);
        });

        if(this.state.counter === this.state.last_page){
            this.setState({
                storeLoadMore:true,
            });
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="requestWrapper">
                    <h3>Withdraw Requests</h3>
                    <div style={{height:'100%',maxHeight:'590px',overflowY:'scroll'}} id={"allBooking"} onScroll={this.handleScroll} className="mt-4">
						<table className="bp3-html-table bp3-html-table-bordered">
							<thead>
							<tr>
								<th>Request ID</th>
								<th>Amount</th>
								<th>Message</th>
								<th>Account Holder</th>
								<th>Account Number</th>
								<th>IFSC</th>
								<th>Request Date</th>
								<th>Last Updated Date</th>
                                <th>Status</th>
							</tr>
							</thead>
							<tbody>
							{this.state.isSkeleton===true ?
									<tr>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>1</span></td>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>₹ 10</span></td>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>ddfdf</span></td>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>1234515525</span></td>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>1245454454</span></td>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>21424512</span></td>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>5454445</span></td>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>25445454</span></td>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>04-06-2018 17:00:54</span></td>
									</tr>
								:
								this.state.data.length === 0 ?
									<tr>
										<td colSpan="9" className="emptyState">
											<img src={require('../../core/images/empty-state/billhistory.svg')} alt=""/>
                                            <p className="mt-3">No transactions for the withdraw requests</p>
										</td>
									</tr>
								:
								this.state.data.map((res, key) =>{
									var classname;
									if(res.status==="PENDING"){
										classname = "text-warning";
									}else if(res.status==="COMPLETED"){
										classname = "text-success";
									}else if(res.status==="IN PROCESS"){
										classname="text-primary";
									}
									return(
										<tr key={key}>
											<td>{res.request_id}</td>
											<td>₹ {res.amount}</td>
											<td>{res.message}</td>
											<td>{res.account_holder_name}</td>
											<td>{res.account_number}</td>
											<td>{res.ifsc_code}</td>
											<td>{res.created_at}</td>
											<td>{res.updated_at}</td>
                                            <td className={classname}>{res.status}</td>
										</tr>
									);
								})
							}
							</tbody>
						</table>
					</div>
                    {
                        this.state.last_page < this.state.counter ? null :
                        this.state.scrollLoader ?
                            null :
                            <React.Fragment>
                                <center>
                                    <div className="my-3">
                                        <img src={require('../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                    </div>
                                </center>
                            </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }
}