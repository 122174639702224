import React, {Component} from 'react';
import AllorderEmptyStates from "../../../../core/images/empty-state/Allorder.svg";
import BusDefaultImg from "../../../../core/images/order/bus.svg";
import {Button} from "@blueprintjs/core";

export default class BusOrder extends Component{
    constructor(props){
        super(props);
        this.state={
            orderList:[],
            loading:true,
        };
    }

    componentDidMount(){
        this.setState( {
            loading: this.props.loading,
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            if(this.props.stopLoadMore === false){
                this.props.NextPage()
            }
        }
    }


    renderStatusHtml(status){

        switch (status) {
            case 0:
                return <label className="label text-warning">Awaiting Payment</label>;
            case 1:
                return <label className="label text-warning">Awaiting Fullfilment</label>;
            case 2:
                return <label className="badge text-success">Successful</label>;
            case 3:
                return <label className="badge text-danger">Cancelled</label>;
            case 4:
                return <label className="badge text-danger">Payment Failed</label>;
            case 5:
                return <label className="badge text-success">Refunded</label>;
            default:
                return <label className="label text-default">--</label>;
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="bus">
                    <div className="table-responsive" id={"allBooking"} style={{'height':'100%','maxHeight':'590px','overflowY':'scroll'}} onScroll={this.handleScroll}>
                        <table className="bp3-html-table table-borderless">
                            <thead>
                                <tr>
                                    <th>Booking Details</th>
                                    <th>Booking ID</th>
                                    <th>Booking Date</th>
                                    <th>Price</th>
                                    <th>Cashback Type</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.loading ?
                                    <tr>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    </tr>
                                    :
                                    this.props.orderList.length ?
                                        this.props.orderList.map((booking, key) => {
                                            return <tr key={key}>
                                                <td>
                                                    <div className="orderTable">
                                                        <div className="image hidden-xs hidden-sm hidden-md visible-xl visible-lg">
                                                            <img src={BusDefaultImg} alt=""/>
                                                        </div>
                                                        <div className="title">
                                                            <p>{booking.source_name} to {booking.destination_name}</p>
                                                            <span><span className="image hidden-sm hidden-md visible-xl visible-lg visivle-xs">Booked on :</span> {booking.doj}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{booking.booking_id}</td>
                                                <td>{booking.created_at}</td>
                                                <td><b>₹ {booking.total_amount}</b></td>
                                                <td>{booking.cb_choice}</td>
                                                <td>
                                                    {
                                                        this.renderStatusHtml(booking.status)
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        booking.status_type === 'Successful' ?
                                                            <a href={booking.ticket_url} className="bp3-button bp3-small bp3-intent-primary btn-view" rel="noopener noreferrer"  title="Download Ticket" target="_blank">
                                                                <span className="bp3-icon bp3-icon-import bp3-text-medium"></span>

                                                            </a>
                                                            :
                                                            <Button small={true} intent="inform" disabled={true} className="btn-view mr-2" icon="import" title="Download Ticket"/>
                                                    }

                                                </td>
                                            </tr>
                                        })
                                        :
                                        <tr>
                                            <td colSpan="7" align="center">
                                                <div className="text-center">
                                                    <img src={AllorderEmptyStates} alt=""/>
                                                    <p className="mt-3">No transactions for the selected option</p>
                                                </div>
                                            </td>
                                        </tr>
                            }
                            </tbody>
                        </table>
                        {
                            this.state.loading ?
                                null
                                :
                                this.props.stopLoadMore ?
                                    ""
                                    :
                                    <React.Fragment>
                                        <center>
                                            <div className="my-3">
                                                <img src={require('../../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                            </div>
                                        </center>
                                    </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}