import {connect} from 'react-redux';
import {viewStoreOrder} from '../../../../core/actions/action';
import {viewStoreOrderAction} from '../../../../core/actions';
import ViewOrders from '../Components/ViewOrders';

const mapStateToProps = state => {

    return{
        viewStoreOrderDetails:state.Travel.viewStoreOrderDetails,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        viewStoreOrder :(orderId) => {
            dispatch(viewStoreOrderAction(null));
            return viewStoreOrder(orderId)
                .then((res)=>{
                    const sources = res.data.data;
                    dispatch(viewStoreOrderAction(sources));
                }).catch((error)=>{
                    const sources = error.response;
                    console.log(sources);
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrders);
