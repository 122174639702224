import {connect} from 'react-redux';
import {getVoucherOrderDetails, setVoucherStatus} from '../../../core/actions/action';
import VoucherList from '../components/CoinVoucherOrder/VoucherList';

const mapStateToProps = state => {

    return{
        voucherOrderList:state.MegaBrandsOrders.voucherOrderList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getVoucherOrderDetails:(orderId, params) => {
            return getVoucherOrderDetails(orderId, params);
        },
        setVoucherStatus : (orderDetailId) => {
            return setVoucherStatus(orderDetailId);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VoucherList);
