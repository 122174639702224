import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import FIFO from '../components/fifo';
import {getUPVFIFOList, sellBid} from '../../../core/actions/action';
import { getUPVFIFOListAction } from '../../../core/actions';

const mapStateToProps = state => {

    return{
        upvFifoList:state.cashbackReducer.upvFifoList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getUPVFIFOList :(page) => {
            return getUPVFIFOList(page)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(getUPVFIFOListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(getUPVFIFOListAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },

        sellBid:(upvID, obj) => {
            return sellBid(upvID, obj)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FIFO);
