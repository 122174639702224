import React, {Component} from 'react';
import FlightOrder from './Flight/FlightOrder';
import {Auth, NoLogin} from "gg-react-utilities";

export default class FlightOrderIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderList: [],
            page: 1,
            booking_type: 1,
            stopLoadMore: false,
            showEmptyStats: false,
            loadingData : true,
            isLoggedIn:false
        };
    }

    componentDidMount() {
        this.checkAuth();

        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 1000);

        let params={
            page:this.state.page,
            booking_type:this.state.booking_type,
        }
        this.getFlightOrderList(params)
    }

    getFlightOrderList=(params)=>{
        this.props.getFlightOrderList(params)
            .then((res)=>{
                const sources = res.data;
                if(sources.status === true){
                    let oldData = this.state.orderList;
                    let newDataFromApi = sources.data.data;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState( {
                        orderList:newData,
                    });
                    if(sources.data.data === ""){
                        this.setState( {
                            showEmptyStats:true,
                        });
                    }
                    if(sources.data.current_page === sources.data.last_page){
                        this.setState( {
                            stopLoadMore: true,
                        })
                    }else{
                        this.setState( {
                            stopLoadMore: false,
                        })
                    }
                }else{

                }
            }).catch((error)=>{
            const sources = error.response;
            console.log(sources);
        })
    }

    NextPage= () =>{
        let oldPageNo = this.state.page;
        this.setState({
            page: oldPageNo + 1
        },()=>{
            let params={
                page:this.state.page,
                booking_type:this.state.booking_type,
            }
            this.getFlightOrderList(params)
        })

    }

    async checkAuth(){
        Auth.isLoggedInAsync().then((res) => {
            this.setState({isLoggedIn:res})
        });
    }

    render(){
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <FlightOrder
                    loading={this.state.loadingData}
                    orderList={this.state.orderList}
                    NextPage={() => this.NextPage()}
                    stopLoadMore={this.state.stopLoadMore}
                />
            </React.Fragment>
        )
    }
}