import {connect} from 'react-redux';
import {getLoanInquiriesList} from '../../../core/actions/action';
import LoanInquiriesIndex from '../components/LoanInquiriesIndex';

const mapDispatchToProps = dispatch => {

    return {
        getLoanInquiryList:(params) => {
            return getLoanInquiriesList(params);
        },
    }
}

export default connect(null, mapDispatchToProps)(LoanInquiriesIndex);
