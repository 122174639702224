import React, {Component} from 'react';
import AllorderEmptyStates from "../../../../core/images/empty-state/Allorder.svg";
import ServiceCancel from '../Container/ServiceCancel';
import ViewComment from './ViewComment';
import {Button} from "@blueprintjs/core";

export default class ServiceBooking extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderList:[],
            length:10,
            stopLoadMore: false,
            loading:true,
            isOpen: false,
            isOpenComment:false,
        }
    }

    componentDidMount() {
        this.setState( {
            loading: this.props.loading,
        })

        let params={
            length:this.state.length,
        }
        this.getBookingList(params)
    }

    getList = () =>{
        this.setState( {
            orderList: [],
            loading:true,
        })

        let params={
            length:this.state.length,
        }
        this.getBookingList(params)
    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    getBookingList=(params)=>{
        this.props.getStoreServiceBookingList(params)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    this.setState( {
                        orderList:[],
                    });
                    let oldData = this.state.orderList;
                    let newDataFromApi = sources.data.data;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState( {
                        orderList:newData,
                    });
                    this.stopLoading();
                    if(sources.data.data === ""){
                        this.setState( {
                            showEmptyStats:true,
                        });
                    }

                    if(newData.length === sources.data.totalRecords){
                        this.setState( {
                            stopLoadMore: true,
                        })
                    }else{
                        this.setState( {
                            stopLoadMore: false,
                        })
                    }
                }else{

                }
            }).catch((error)=>{
            const sources = error.response;
            console.log(sources);
        })
    }

    NextPage= () =>{
        let oldPageNo = this.state.length;
        this.setState({
            length: oldPageNo + 10,
            /*orderList:[],
            loading:true,*/
        },()=>{
            let params={
                length:this.state.length,
            }
            this.getBookingList(params)
        })
        this.stopLoading();
    }

    stopLoading = () => {
        setTimeout(() => {
            this.setState({
                loading: false,
            });
        }, 1000);
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if(this.state.stopLoadMore === false){
            if (parseInt(allBookingHeight) === 0){
                this.loadMore()
            }
        }
    }

    renderStatusHtml(status){

        switch (status) {
            case 1:
                return <label className="label text-warning">Pending</label>;
            case 2:
                return <label className="badge text-warning">Pending</label>;
            case 3:
                return <label className="badge text-danger">Reject</label>;
            case 4:
                return <label className="badge text-success">--</label>;
            case 5:
                return <label className="badge text-danger">Decline</label>;
            case 6:
                return <label className="badge text-success">Completed</label>;
            case 7:
                return <label className="badge text-danger">Outdated</label>;
            default:
                return <label className="label text-default">--</label>;
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="service-booking" id={"allBooking"} style={{'height':'100%','maxHeight':'590px','overflowY':'scroll'}} onScroll={this.handleScroll} >
                    <table className="bp3-html-table table-borderless">
                        <thead>
                            <tr>
                                <th>Service Booking</th>
                                <th>Booking ID</th>
                                <th>Booking Date</th>
                                <th>Price</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.loading ?
                                <tr>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                </tr>
                                :
                                this.state.orderList.length ?
                                    this.state.orderList.map((service, key) => {
                                        return <tr key={key}>
                                            <td>
                                                <div className="orderTable">
                                                    <div className="title">
                                                        <p className="pt-2">{service.service_title}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{service.increament_id}</td>
                                            <td>{service.service_start}</td>
                                            <td><b>₹ {service.price}</b></td>
                                            <td>
                                                <b>
                                                    {
                                                        this.renderStatusHtml(service.status)
                                                    }
                                                </b>
                                            </td>
                                            <td>
                                                {
                                                    service.status === 1 ?
                                                        <ServiceCancel isOpen={this.state.isOpen} bookingId={service.booking_id} getList={()=>this.getList()}/>
                                                        :
                                                        service.status === 5 ?
                                                            <ViewComment cancelReason={service.status_reason} isOpenComment={this.state.isOpenComment}/>
                                                            :
                                                            <Button small={true} intent="inform" disabled={true} className="btn-view mr-2" title="View" icon="eye-open" />
                                                }

                                            </td>
                                        </tr>
                                    })
                                    :
                                    <tr>
                                        <td colSpan="7" align="center">
                                            <div className="text-center">
                                                <img src={AllorderEmptyStates} alt=""/>
                                                <p className="mt-3">No transactions for the selected option</p>
                                            </div>
                                        </td>
                                    </tr>
                        }
                        </tbody>
                    </table>
                    {
                        this.state.loading ?

                            <React.Fragment>
                                <center>
                                    <div className="my-3">
                                        <img src={require('../../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                    </div>
                                </center>
                            </React.Fragment>
                            : null
                    }
                </div>
            </React.Fragment>
        )
    }
}