export const GET_ADDRESS_LIST = 'get_address_list';



export function getUserAddressListAction(sources) {

    return {
        type: GET_ADDRESS_LIST,
        sources: sources

    }
}