import React from 'react';
import {Button, Classes, Dialog, FormGroup, Intent, Position, Toaster} from "@blueprintjs/core";
import {Textbox} from "react-inputs-validation";

export default class ServiceCancel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            userBill: "",
            bookingId:props.bookingId,
            status_reason:"",
            length:10,
        };
        this.handleClose = this.handleClose.bind(this)
        this.onSubmit = this.onSubmit.bind(this);
    }


    handleClose() {
        this.setState({isOpen: false, status_reason:''});
    }

    toggleValidating(validate) {
        this.setState({ validate });
    }

    onSubmit(event){
        this.toggleValidating(true);
        if(!this.state.errors){
            if(this.state.status_reason !== ""){
                this.props.cancelBookService(this.props.bookingId, this.state.status_reason)
                    .then((res)=>{
                        const sources = res.data;
                        if(sources.status===true){
                            Toaster.create({position: Position.TOP}).show({message:sources.message ,intent: Intent.SUCCESS});
                            this.handleClose()

                            this.props.getList()
                        }
                    }).catch((error)=>{
                        const sources = error.response;
                        console.log(sources)
                        /*if((sources.data.message) && (sources.data.message !== "")){
                            Toaster.create({position: Position.TOP}).show({message:sources.data.message ,intent: Intent.DANGER});
                        }*/
                })
            }
        }
        event.preventDefault();
    }

    render() {
        return (
            <React.Fragment>
                <Button small={true} intent="danger" title="Cancel Service" icon="disable" className="btn-view mr-2"
                        onClick={() => {
                            this.setState({isOpen: true})
                        }}/>
                <Dialog isOpen={this.state.isOpen} onClose={() => this.handleClose()} className="bp3-dialog-large"
                        title="Why you want to cancel?">
                        <div className={Classes.DIALOG_BODY}>
                            <form onSubmit={this.onSubmit} className="form-horizontal" encType="multipart/form-data">
                                <div className="row form-data">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-group mb-2">
                                        <FormGroup>
                                            <Textbox
                                                id={"status_reason"}
                                                name="status_reason"
                                                type="text"
                                                value={this.state.status_reason}
                                                placeholder="Enter Cancel Reason"
                                                onChange={(status_reason) => {
                                                    this.setState({ status_reason });
                                                }}
                                                onBlur={e => {}}
                                                validate={this.state.validate}
                                                validationCallback={res =>
                                                    this.setState({ errors:res, validate: false })
                                                }
                                                validationOption={{
                                                    name: "Enter Cancel Reason",
                                                    check: true,
                                                    required: true,
                                                }}/>
                                        </FormGroup>
                                    </div>
                                    <div className="col-12 text-group mt-s-4 mt-3 text-right">
                                        <Button type="submit" text="Cancel" className="bp3-secondary mr-3" intent={Intent.secondary} onClick={() => this.handleClose()} />
                                        <Button type="submit" text="Submit" intent={Intent.PRIMARY} loading={this.state.loader} />
                                    </div>
                                </div>
                            </form>
                        </div>
                </Dialog>
            </React.Fragment>
        );
    }
}