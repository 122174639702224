let initialState = {
    ggStatsList:[],
    myStatsList:[],
};

const stats = (state = initialState, action) => {

    switch (action.type) {

        case 'GG_STATS':
            return {...state, ggStatsList: action.sources}
        case 'MY_STATS':
            return {...state, myStatsList: action.sources}
        default:
            return state
    }
}

export default stats;
