import {connect} from 'react-redux';
import {getGrangeOrderList} from '../../../core/actions/action';
import GrangeOrder from '../Components/GrangeOrder';

const mapStateToProps = state => {

    return{
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getGrangeOrderList:(params) => {
            return getGrangeOrderList(params);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GrangeOrder);
