import React, { Component } from 'react';
import {Helper} from "gg-react-utilities";
//import {Button} from "@blueprintjs/core";

export default class IC extends Component{

    constructor(props){
        super();
        this.state={
            isVisibleMilestone:false,
            isLoading: false,
            data:[],
            counter:1,
            isSkeleton:true,
            total:0,
            money:0.00,
            coin:0,
            movie:0,
			bottle:0,
            last_page:1,
			scrollLoader:true
        }
    }

    isOpenMilestone(){
        this.setState({
            isVisibleMilestone:true
        });
    }
    closeModal(){
        this.setState({
            isVisibleMilestone:false
        });
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.getFPVICList(this.state.counter);
        }, 1000);
    }

    componentWillReceiveProps(nextProps) {
        let oldData = this.state.data;
        let newDataFromApi = nextProps.fpvICList.icb.data;
        let newData = oldData.concat(newDataFromApi);
        this.setState({
            data:newData,
            isSkeleton:false,
            total:nextProps.fpvICList.icb.total,
            money:nextProps.fpvICList.icb_money_cashback,
            coin:nextProps.fpvICList.icb_coin_cashback,
            movie:nextProps.fpvICList.icb_fpv_movie_ticket,
			bottle:nextProps.fpvICList.icb_fpv_water_bottle,
            last_page:nextProps.fpvICList.icb.last_page
        });
    }

	handleScroll=()=>{

		var allBooking = document.getElementById('allBooking');
		var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

		if (parseInt(allBookingHeight) === 0){
			this.loadMore()
		}
	}

	loadMore=()=>{
		this.setState({
			scrollLoader:false
		});
		setTimeout(() => {
			this.setState({
				counter: this.state.counter + 1,
				isLoading: true,
				scrollLoader:true
			}, () => {
				this.props.getFPVICList(this.state.counter);
			})
		},1000)
	}
    render(){
        return(
            <React.Fragment>
                <div className="fifo mt-4">
                    <div className="fifoWrapper text-left">
						<div className="row">
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
								<div className="fifoICSection">
									<div className="icon">
										<img src={require('../../images/rupee.svg')} alt="" />
									</div>
									<div className="count">
										<h3 className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>{Helper.formatMoney(this.state.money)}</h3>
										<p>Money Cashback</p>
									</div>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-s-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0">
								<div className="fifoICSection">
									<div className="icon">
										<img src={require('../../images/check.svg')} alt="" />
									</div>
									<div className="count">
										<h3 className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>{this.state.coin}</h3>
										<p>Coin Cashback</p>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-4">
								<div className="fifoICSection">
									<div className="icon">
										<img src={require('../../images/QR.svg')} alt="" />
									</div>
									<div className="count">
										<h3 className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>{this.state.total}</h3>
										<p>Cashback Count</p>
									</div>
								</div>
							</div>

							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0">
								<div className="fifoICSection">
									<div className="icon">
										<img src={require('../../images/Movie.svg')} alt="" />
									</div>
									<div className="count">
										<h3 className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>{this.state.movie}</h3>
										<p>GG Gift Voucher</p>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0">
								<div className="fifoICSection">
									<div className="icon">
										<img src={require('../../images/water.svg')} alt="" />
									</div>
									<div className="count">
										<h3 className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>{this.state.bottle}</h3>
										<p>Water Bottle Count</p>
									</div>
								</div>
							</div>
						</div>
                    </div>
					<div style={{height:'100%',maxHeight:'590px',overflowY:'scroll'}} id={"allBooking"} onScroll={this.handleScroll} className="instant-cashback mt-4">
						<table className="bp3-html-table bp3-html-table-bordered">
							<thead>
								<tr>
									<th>ID</th>
									<th>Order ID</th>
									<th>IC Cashback</th>
									<th>Coins Amount</th>
									<th>Amount</th>
									<th>Created Date</th>
								</tr>
							</thead>
							<tbody>
							{ this.state.isSkeleton===true ?
								<tr>
									<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>1</span></td>
									<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>12345</span></td>
									<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>23</span></td>
									<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>₹ 3.55</span></td>
									<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>₹ 55</span></td>
									<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>16 Aug 2018</span></td>
								</tr>
								:
									this.state.data.length ?
										this.state.data.map((res, i) => {
											return(
												<tr key={i}>
													<td>{i+1}</td>
													<td>{res.id}</td>
													<td>
														{res.icb_choice === 1 ? "GG Gift Voucher" : ""}
														{res.icb_choice===2 ?  "Instant Cashback" : ""}
														{res.icb_choice===3 ?  "Free Water Bottle (BOGO)" : ""}
													</td>
													<td>{Helper.formatMoney(res.coins_amount)}</td>
													<td>{Helper.formatMoney(res.amount)}</td>
													<td>{res.created_at}</td>
												</tr>
											);
										})
									:
										<tr>
											<td colSpan="6" className="emptyState">
												<img src={require('../../../core/images/empty-state/UPV.svg')} alt=""/>
												<p className="mt-3">Oops! You don’t have any FPV under IC process. Use this option to get Rs. 5 as instant cashback or GG Gift Vouchers worth Rs. 100</p>
											</td>
										</tr>
							}
							</tbody>
						</table>
					</div>
					{
						this.state.last_page < this.state.counter ? null :
							this.state.scrollLoader ?
								null :
								<React.Fragment>
									<center>
										<div className="my-3">
											<img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
										</div>
									</center>
								</React.Fragment>
					}
                </div>
            </React.Fragment>
        );
    }
}