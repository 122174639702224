import {connect} from 'react-redux';
import {getNewStoreOrderList} from '../../../../core/actions/action';
import NewStoreOrder from '../Components/StoreOrder';


const mapStateToProps = state => {

    console.log("hii",state)
        return{
        storeOrderList:state.Travel.newStoreOrderList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getNewStoreOrderList:(params) => {
            return getNewStoreOrderList(params);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewStoreOrder);
