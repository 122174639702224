import React from 'react';
import Balance from './Balance';
import RewardRequest from './RewardRequest';
import {Toaster, Position, Intent,} from "@blueprintjs/core";

export default class AccountBalance extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ggRewards:[],
            ggMoneyBalance:[],
            ggCoinBalance:[],
            isOpenSendCoin: false,
            isOpenRequestCoin: false,
            loading: true,
            total_gg_money_cashback:0
        }
    }

    componentDidMount() {
        this.props.getGgRewards()
    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            ggRewards:nextProps.ggRewards,
            ggMoneyBalance:nextProps.ggRewards[0],
            ggCoinBalance:nextProps.ggRewards[1],
            total_gg_money_cashback:nextProps.ggRewards.total_gg_money_cashback,
        })
    }

    sendCoinRequest = (formData) => {
        this.props.sendGgCoin(formData)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    Toaster.create({position: Position.TOP}).show({message:sources.message ,intent: Intent.SUCCESS});
                    /*setTimeout(() => {
                        window.location.reload();
                    }, 1000);*/
                    this.setState( {
                        isOpenSendCoin:true,
                    })
                    this.props.getGgRewards()
                }
            }).catch((error)=>{
            const sources = error.response;
            if((sources.data.error.amount) && (sources.data.error.amount.length > 0)){
                Toaster.create({position: Position.TOP}).show({message:sources.data.error.amount ,intent: Intent.DANGER});
            }
            if((sources.data.error.receiver_username) && (sources.data.error.receiver_username.length > 0)){
                Toaster.create({position: Position.TOP}).show({message:sources.data.error.receiver_username ,intent: Intent.DANGER});
            }
            if((sources.data.error.description) && (sources.data.error.description.length > 0)){
                Toaster.create({position: Position.TOP}).show({message:sources.data.error.description ,intent: Intent.DANGER});
            }
            if((sources.data.error.sso_user_id) && (sources.data.error.sso_user_id.length > 0)){
                Toaster.create({position: Position.TOP}).show({message:sources.data.error.sso_user_id ,intent: Intent.DANGER});
            }
        })
    }

    requestCoinRequest = (formData) => {
        this.props.requestGgCoin(formData)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    Toaster.create({position: Position.TOP}).show({message:sources.message ,intent: Intent.SUCCESS});
                    /*setTimeout(() => {
                        window.location.reload();
                    }, 1000);*/
                    this.setState( {
                        isOpenRequestCoin:true,
                    })
                    this.props.getGgRewards()
                }
            }).catch((error)=>{
            const sources = error.response;
            if((sources.data.error.amount) && (sources.data.error.amount.length > 0)){
                Toaster.create({position: Position.TOP}).show({message:sources.data.error.amount ,intent: Intent.DANGER});
            }
            if((sources.data.error.sender_username) && (sources.data.error.sender_username.length > 0)){
                Toaster.create({position: Position.TOP}).show({message:sources.data.error.sender_username ,intent: Intent.DANGER});
            }
            if((sources.data.error.description) && (sources.data.error.description.length > 0)){
                Toaster.create({position: Position.TOP}).show({message:sources.data.error.description ,intent: Intent.DANGER});
            }
            if((sources.data.error.sso_user_id) && (sources.data.error.sso_user_id.length > 0)){
                Toaster.create({position: Position.TOP}).show({message:sources.data.error.sso_user_id ,intent: Intent.DANGER});
            }


        })
    }

    render(){
        return(
            <React.Fragment>
                {/*<div className="col-12 img-full mb-2 mt-2">
                    <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}>&nbsp;</div>
                </div>*/}
                <Balance
                    ggMoneyBalance={this.state.ggMoneyBalance}
                    ggCoinBalance={this.state.ggCoinBalance}
                    total_gg_money_cashback={this.state.total_gg_money_cashback}
                    loading={this.props.loading}
                />
                <RewardRequest sendCoinRequest={this.sendCoinRequest}
                               requestCoinRequest={this.requestCoinRequest}
                               isOpenSendCoin={this.state.isOpenSendCoin}
                               isOpenRequestCoin={this.state.isOpenRequestCoin}
                               loading={this.props.loading}
                               ggMoneyBalance={this.state.ggMoneyBalance}
                />
            </React.Fragment>
        );
    }
}