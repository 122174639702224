import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import TopBar from "../core/components/TopBar";
import PageTitle from '../core/components/PageTitle';
import {Auth, NoLogin} from "gg-react-utilities";

export default class Cashback extends Component{

	constructor(props){
		super(props);
		this.state={
			isLoggedIn:false
		}
	}

	componentDidMount() {
		this.checkAuth();

		setTimeout(() => {
			this.setState({
				loadingData: false,
			});
		}, 2000);
	}

	async checkAuth(){
		Auth.isLoggedInAsync().then((res) => {
			this.setState({isLoggedIn:res},()=>{
				if(!res){
					Auth.login(true);
				}
			})
		});
	}

    render(){
		if (this.state.isLoggedIn === false) {
			return (
				<div className="container mb-5">
					<div className="upvWrapper">
						<PageTitle title="Cashback - Global Garner"/>
						<TopBar title="UPV Cashback" />
						<div className="upvContainer" style={{padding:'200px 0' }}>
							<NoLogin homeLink={false} imageShow={true}/>
						</div>
					</div>
				</div>
			);

		}
        return(
            <div className="container mb-5">
                <PageTitle title="Cashback - Global Garner"/>
                <TopBar title="Cashback"/>
				<div className="row">
					<div className="cashbackWrapper">
						<div className="container pb-5">
							<div className="row">
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
									<div className="upvSection">
										<div className="icon">
											<img src={require('./images/UPV.svg')} alt="" />
										</div>
										<h3>UPV Cashback Status</h3>
										<p>Get detailed information about your UPV Cashback and status.</p>
										<div className="link">
											<Link to="/upv">Check your User Purchase Value <span className="bp3-icon bp3-icon-arrow-right ml-2" style={{fontSize:15}}></span></Link>
										</div>
									</div>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-s-4">
									<div className="fpvSection">
										<div className="icon">
											<img src={require('./images/FPV.svg')} alt="" />
										</div>
										<h3>FPV Cashback Status</h3>
										<p>Get detailed information about your FPV Cashback and status.</p>
										<div className="link">
											<Link to="/fpv">Check your Fuel Purchase Value <span className="bp3-icon bp3-icon-arrow-right ml-2" style={{fontSize:15}}></span></Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
        );
    }
}