import React from 'react';
import GGVoucherOrderComponent from './GGVoucherOrder/GGVoucherOrderComponent'
import {Auth} from "gg-react-utilities";

export default class GGVoucherIndex extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            voucherOrderList:[],
            page:1,
            stopLoadMore: false,
            showEmptyStats:false,
            loadingData : true,
            isLoggedIn:false
        }
    }

    componentDidMount() {
        this.checkAuth();

        let params={
            page:this.state.page,
        }
        this.getGGVoucherOrderList(params)
    }

    getGGVoucherOrderList=(params)=>{
        this.props.getGGVoucherOrderList(params)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    let oldData = this.state.voucherOrderList;
                    let newDataFromApi = sources.data.data;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState( {
                        voucherOrderList:newData,
                        loadingData: false,
                    });
                    if(sources.data.data === ""){
                        this.setState( {
                            showEmptyStats:true,
                        });
                    }
                    if(sources.data.current_page === sources.data.last_page){
                        this.setState( {
                            stopLoadMore: true,
                        })
                    }else{
                        this.setState( {
                            stopLoadMore: false,
                        })
                    }
                }
            }).catch((error)=>{
                const sources = error.response;
                this.setState({
                    loadingData: false,
                });
        })
    }

    NextPage= () =>{
        let oldPageNo = this.state.page;
        this.setState({
            page: oldPageNo + 1
        },()=>{
            let params={
                page:this.state.page,
            }
            this.getGGVoucherOrderList(params)
        })

    }

    async checkAuth(){
        Auth.isLoggedInAsync().then((res) => {
            this.setState({isLoggedIn:res})
        });
    }

    render(){

        return (
            <React.Fragment>
                <GGVoucherOrderComponent
                    loading={this.state.loadingData}
                    voucherOrderList={this.state.voucherOrderList}
                    NextPage={() => this.NextPage()}
                    stopLoadMore={this.state.stopLoadMore}
                />
            </React.Fragment>
        )
    }
}