import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import Request from '../components/request';
import { getWithdrawRequestList } from '../../core/actions/action';
import { getWithdrawRequestListAction } from '../../core/actions';

const mapStateToProps = state => {

    return{
        withdrawRequestList:state.cashbackReducer.withdrawRequestList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getWithdrawRequestList :(page) => {
            return getWithdrawRequestList(page)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(getWithdrawRequestListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(getWithdrawRequestListAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Request);
