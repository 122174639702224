import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import fpvReplaceSent from '../components/fpvReplaceSent';
import {getFpvReplaceSentList, fpvReplaceSentRequestCancel} from '../../../core/actions/action';
import { getFpvReplaceSentListAction } from '../../../core/actions';

const mapStateToProps = state => {

    return{
        fpvReplaceSentList:state.cashbackReducer.fpvReplaceSentList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getFpvReplaceSentList :(page) => {
            return getFpvReplaceSentList(page)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(getFpvReplaceSentListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(getFpvReplaceSentListAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
        fpvReplaceSentRequestCancel:(fpvID,obj) => {
            return fpvReplaceSentRequestCancel(fpvID,obj)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(fpvReplaceSent);
