import React from 'react';
import {Auth, NoLogin} from "gg-react-utilities";
import TopBar from "../../core/components/TopBar";
import PageTitle from "../../core/components/PageTitle";
import {Link} from "react-router-dom";
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import TransactionList from './containers/transaction';


export default class BidTransaction extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isLoggedIn:false
        }
    }

    componentDidMount() {
        this.checkAuth();
        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1);
    }


    render(){
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <PageTitle title="UPV Exchange - Global Garner"/>
                        <TopBar title="UPV Exchange" />
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }
        return(
            <div className="container mb-5">
                <PageTitle title="UPV Exchange - Global Garner"/>
                <div className="exchangeWrapper">
                    <div className="exchangeTopBar">
                        <div className="top-bar">
                            <Link to='/upv-exchange'>
                                <span className="back-icon"><KeyboardArrowLeftRounded /></span>
                                <h1 className="title m-0">UPV Exchange / <small><b>My UPV Transactions</b></small></h1>
                            </Link>
                        </div>
                        <div className="leftTopBar">
                            <a href="#" target="_blank">UPV Exchange info</a>
                        </div>
                    </div>
                    <TransactionList />
                </div>
            </div>
        );
    }
}
