import React from 'react';
import pendingBills from '../../../core/images/stats/pending-bills.svg';
import Verified from '../../../core/images/stats/verified-bills.svg';
import Accepted from '../../../core/images/stats/accepted-bills.svg';
import Rejected from '../../../core/images/stats/rejected-bills.svg';

export default class AllStatus extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        }
    }

    componentDidMount(){
        this.setState( {
            loading: this.props.loading,
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }
    render(){

        return(
            <React.Fragment>
                <div className="popular mb-4">
                    <h2 className="m-0 bill-status-text">Bill Status</h2>
                </div>
                {
                    this.state.loading ?
                        <div className="col-lg-3 col-lg-3 col-sm-6 col-12 mb-3">
                            <div className="pendingBills text-right">
                                <img src={pendingBills} alt=""/>
                                <span className="bill-count bp3-skeleton">100</span><br/>
                                <span className="heading bp3-skeleton">Pending Bills</span>
                            </div>
                        </div>

                        :
                        <div className="col-lg-3 col-lg-3 col-sm-6 col-12 mb-3">
                            <div className="pendingBills text-right">
                                <img src={pendingBills} alt=""/>
                                <span className="bill-count">{this.props.pendingBills}</span>
                                <p className="heading">Pending Bills</p>
                            </div>
                        </div>

                }

                {
                    this.state.loading ?
                        <div className="col-lg-3 col-lg-3 col-sm-6 col-12 mb-3">
                            <div className="pendingBills text-right">
                                <img src={Verified} alt=""/>
                                <span className="bill-count bp3-skeleton">100</span><br/>
                                <span className="heading bp3-skeleton">Verified</span>
                            </div>
                        </div>
                        :
                        <div className="col-lg-3 col-lg-3 col-sm-6 col-12 mb-3">
                            <div className="pendingBills text-right">
                                <img src={Verified} alt=""/>
                                <span className="bill-count">{this.props.verifiedBills}</span>
                                <p className="mb-0 mt-1">Verified</p>
                            </div>
                        </div>

                }

                {
                    this.state.loading ?
                        <div className="col-lg-3 col-lg-3 col-sm-6 col-12 mb-3">
                            <div className="pendingBills text-right">
                                <img src={Accepted} alt=""/>
                                <span className="bill-count bp3-skeleton">100</span><br/>
                                <span className="heading bp3-skeleton">Accepted</span>
                            </div>
                        </div>
                        :
                        <div className="col-lg-3 col-lg-3 col-sm-6 col-12 mb-3">
                            <div className="pendingBills text-right">
                                <img src={Accepted} alt=""/>
                                <span className="bill-count">{this.props.acceptedBills}</span>
                                <p className="mb-0 mt-1">Accepted</p>
                            </div>
                        </div>

                }

                {
                    this.state.loading ?
                        <div className="col-lg-3 col-lg-3 col-sm-6 col-12 mb-3">
                            <div className="pendingBills text-right">
                                <img src={Rejected} alt=""/>
                                <span className="bill-count bp3-skeleton">100</span><br/>
                                <span className="heading bp3-skeleton">Rejected</span>
                            </div>
                        </div>
                        :
                        <div className="col-lg-3 col-lg-3 col-sm-6 col-12 mb-3">
                            <div className="pendingBills text-right">
                                <img src={Rejected} alt=""/>
                                <span className="bill-count">{this.props.rejectedBills}</span>
                                <p className="mb-0 mt-1">Rejected</p>
                            </div>
                        </div>

                }
            </React.Fragment>
        );
    }
}