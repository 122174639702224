import React from 'react';
import {Auth, NoLogin} from "gg-react-utilities";
import TopBar from "../core/components/TopBar";
import PageTitle from "../core/components/PageTitle";
import {Link} from "react-router-dom";
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import {Tab, Tabs} from "@blueprintjs/core";
import Bids from './containers/BidsContainer';
import MyBids from './containers/MyBidsContainer';


export default class UPVExchange extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isLoggedIn:false
        }
    }

    componentDidMount() {
        this.checkAuth();
        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1);
    }

    render(){
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <PageTitle title="UPV Exchange - Global Garner"/>
                        <TopBar title="UPV Exchange" />
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }
        return(
            <div className="container mb-5">
                <PageTitle title="UPV Exchange - Global Garner"/>
                <div className="exchangeWrapper">
                    <div className="exchangeTopBar">
                        <TopBar title="UPV Exchange" />
                        <div className="leftTopBar">
                            <Link to={"/upv-exchange-info"}>UPV Exchange info</Link>
                        </div>
                    </div>
                    <div className="exchangeContainer">
                        <Tabs
                            animate={false}
                            id="exchangeTabs"
                            renderActiveTabPanelOnly={true} vertical={false}>
                            <Tab id="bids" title="Bids" panel={<Bids />} />
                            <Tab id="mybids" title="My Bids" panel={<MyBids/>} />
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}