import {connect} from 'react-redux';
import {getGrangeInquiryList} from '../../../core/actions/action';
import GrangeInquiriesIndex from '../components/GrangeInquiriesIndex';

const mapDispatchToProps = dispatch => {

    return {
        getGrangeInquiryList:() => {
            return getGrangeInquiryList();
        },
    }
}

export default connect(null, mapDispatchToProps)(GrangeInquiriesIndex);
