import React, { Component } from 'react';
import {Helper} from 'gg-react-utilities';
import {Dialog, Button, InputGroup, Toaster, Position, Intent, RadioGroup, Radio} from '@blueprintjs/core';
import {Link} from "react-router-dom";
import config from "../../../core/Config";

export default class FIFO extends Component{
    constructor(props){
        super();
        this.state= {
            isVisibleMilestone: false,
            milestones: {},
            data:[],
            counter:1,
            isSkeleton:true,
            scrollLoader:false,
            stopLoadMore: false,
            isVisibleSellModal:false,
            isVisibleProceedModal:false,
            upvDetail:[],
            newUpvType:"RPP"
        }
    }

    closeModal(){
        this.setState({
            isVisibleMilestone:false,
            milestones:{}
        });
    }

    closeConvertUpvModal(){
        this.setState({
            isConvertYourUPV:false,
            convertUpvId:""
        });
    }

    componentWillReceiveProps(nextProps) {
        let oldData = this.state.data;
        let newDataFromApi = nextProps.upvFifoList.data;
        let newData = oldData.concat(newDataFromApi);
        this.setState({
            data:newData,
            isSkeleton:false,
            scrollLoader:false
        });

        if(nextProps.upvFifoList.current_page === nextProps.upvFifoList.last_page){
            this.setState( {
                stopLoadMore: true
            })
        }else{
            this.setState( {
                stopLoadMore: false

            })
        }
    }


    componentDidMount() {
        this.props.getUPVFIFOList(this.state.counter)
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            this.loadMore()
        }
    }

    loadMore = () =>{

        this.setState({
            counter: this.state.counter + 1,
            scrollLoader:true
        }, () => {
            this.props.getUPVFIFOList(this.state.counter);
        })

    }

    openSellUPVModal(res){
        if(res.bid_status==="NONE") {
            this.setState({
                upvID: res.upv_id,
                isVisibleSellModal: true
            })
        }
    }

    closeSellModal(){
        this.setState({
            isVisibleSellModal:false
        })
    }

    sellBid(upvID){
        let obj = {
            "amount":this.state.amount
        }
        this.props.sellBid(upvID, obj)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    this.setState({
                        isVisibleSellModal:false
                    })
                    setTimeout(() => {
                        window.location.href=config.APP_URL+'/upv';
                    },3000)
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                }
            }).catch((error)=>{
            Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
        })
    }

    closeProceedModal(){
        this.setState({
            isVisibleProceedModal:false
        })
    }

    render(){
        let str, strp;
        return(
            <React.Fragment>
                <div className="fifo mt-4">
                    <div className="fifoWrapper">
                        <div className="row">
							<div className="col-sm-4 col-12">
								<div className="fifoSection">
									<div className="icon">
										<img src={require('../../images/check.svg')} alt="" />
									</div>
									<div className="count">
										<h3 className={this.state.isSkeleton===true ? "bp3-skeleton":""}>{this.props.upvFifoList.total_fifo_upvs==="" ? 0 : this.props.upvFifoList.total_fifo_upvs }</h3>
										<p>Number of UPV's Generated</p>
									</div>
								</div>
							</div>
							<div className="col-sm-4 col-12 mt-s-4">
								<div className="fifoSection">
									<div className="icon">
										<img src={require('../../images/rupee.svg')} alt="" />
									</div>
									<div className="count">
										<h3 className={this.state.isSkeleton===true ? "bp3-skeleton":""}> {this.props.upvFifoList.total_fifo_cashback==="" ? "₹ "+ 0.00 : Helper.formatMoney(this.props.upvFifoList.total_fifo_cashback) }</h3>
										<p>Cashback Received Through UPV</p>
									</div>
								</div>
							</div>
                            <div className="col-sm-4 col-12 mt-s-4">
                                <div className="fifoSection" style={{justifyContent:'center'}}>
                                    <Link to="/upv-exchange">
                                        <Button
                                            className={this.state.isSkeleton===true ? "bp3-skeleton":""}
                                            text={"UPV Exchange"}
                                            intent={"primary"}
                                        />
                                    </Link>
                                </div>
                            </div>
						</div>
                    </div>
                    {
                        this.props.orderAggregateData.fifo_message ?
                        <div className="fifoWrapper mt-3">
                            <div className="row">
                                <div className="col-sm-12 col-12">
                                    <div className="alert alert-success">
                                        <strong>{this.props.orderAggregateData.fifo_message}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :''
                    }
                    <div style={{height:'100%',maxHeight:'590px',overflowY:'scroll'}} id={"allBooking"} onScroll={this.handleScroll} className="table-responsive mt-3">
						<table className="bp3-html-table bp3-html-table-bordered">
							<thead>
								<tr>
									<th>ID</th>
									<th>UPV ID</th>
									<th>Created Date</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{this.state.isSkeleton===true ?
									<tr>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>1</span></td>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>12365</span></td>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>16 Aug 8382</span></td>
										<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>See Milestone</span></td>
									</tr>
									:
										this.state.data.length ?
											this.state.data.map((res, index) => {
                                                // let status;
                                                // let intent;
                                                // if(res.bid_status==="OPEN"){
                                                //     intent="success";
                                                //     status="Bid Open";
                                                // }else if(res.bid_status==="NONE"){
                                                //     status="Sell";
                                                //     intent="primary";
                                                // }else if(res.bid_status==="ON_HOLD"){
                                                //     status="On Hold";
                                                //     intent="warning";
                                                // }else if(res.bid_status==="CLOSED"){
                                                //     status="Closed";
                                                //     intent="danger";
                                                // }
												return(
													<tr key={index}>
														<td>{index+1}</td>
														<td>{res.upv_id}</td>
														<td>{res.upv_generation_date+" "+res.upv_generation_time}</td>
														<td>
                                                            <Button className="bp3-small mr-1"
                                                                    text={"See Milestone"}
                                                                    intent={"primary"}
                                                                    onClick={() => {
                                                                        let title = "UPV No : "+res.upv_id;
                                                                        this.setState({
                                                                            isVisibleMilestone:true,
                                                                            milestones:res.milestones,
                                                                            title:title
                                                                        });
														            }} />
                                                            {
                                                                res.upv_conversion_payment_id !== null ?
                                                                    <span className="bp3-small bp3-bg-success text-white pl-1 pr-1">Converted your UPV</span>
                                                                :
                                                                    <Button className="bp3-small mr-1"
                                                                            text={"Convert your UPV"}
                                                                            intent={"primary"}
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    upvDetail: res,
                                                                                    isVisibleProceedModal: true
                                                                                })
                                                                            }}/>
                                                            }
                                                            {/*<Button className="bp3-small mr-1"*/}
                                                            {/*        text={status}*/}
                                                            {/*        intent={intent}*/}
                                                            {/*        style={{width:80}}*/}
                                                            {/*        onClick={() => {*/}
                                                            {/*            this.openSellUPVModal(res)*/}
                                                            {/*        }} />*/}
                                                            {/*{res.bid_status!=="NONE" ?*/}
                                                            {/*<Link to={{*/}
                                                            {/*    pathname: '/upv-sell-exchange-detail',*/}
                                                            {/*    state: {upvID: res.upv_id}}}>*/}
                                                            {/*    <Button className="bp3-small pl-3 pr-3"*/}
                                                            {/*        icon={"eye-open"}*/}
                                                            {/*        intent={"primary"}*/}
                                                            {/*        style={{width:80}}*/}
                                                            {/*        onClick={() => {*/}

                                                            {/*        }} />*/}
                                                            {/*</Link>*/}
                                                            {/*    : ""*/}
                                                            {/*}*/}
														</td>
													</tr>
												);
											})
										:
											<tr>
												<td colSpan="4" className="emptyState">
													<img src={require('../../../core/images/empty-state/UPV.svg')} alt=""/>
													<p className="mt-3">No UPVs generated under SCB FIFO yet, Continue shopping to get 100% cash back and 100% instant GGCoins.</p>
												</td>
											</tr>
								}
							</tbody>
						</table>
					</div>
                    {
                        this.state.last_page < this.state.counter ? null :
                            this.state.scrollLoader ?
                                <React.Fragment>
                                    <center>
                                        <div className="my-3">
                                            <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                            :null

                    }
                </div>
                <Dialog isOpen={this.state.isVisibleMilestone}
                    title={this.state.title}
                    className="milestoneWrapper bp3-dialog-large"
                    onClose={this.closeModal.bind(this)} >
                    <div className="milestone m-3 text-center">
                        <div className="row">
                            { this.state.milestones.length > 0 ?

                            this.state.milestones.map((resp, i)=>{
                                let opacity, color;
                                i = i + 1;
                                if(i===1){
                                    str = "1st";
                                    strp= "Cashback";
                                }else if(i===2){
                                    str = "2nd";
                                    strp= "Cashback";
                                }else if(i===3){
                                    str = "3rd";
                                    strp= "Cashback";
                                }else if(i===4){
                                    str = "4th";
                                    strp= "GG Coin Conversion";
                                }

                                if(resp.cashback_given){
                                    color="#FE5A23";
                                    opacity="#B53206";
                                }else{
                                    color="#40BE65";
                                    opacity="#164A25";
                                }
                                return(
                                    <div className="col-xl-3 col-lg-3 col-sm-6 col-md-6 col-12 my-2" key={i}>
                                        <div className="section">
                                            <div className="topSection pt-3 pb-3 text-white" style={{backgroundColor:color}}>
                                                <h3 className="">{str}</h3>
                                                <p className="">{strp}</p>
                                            </div>
                                            <div className="bottomSection pt-3 pb-3 " style={{opacity:0.8,backgroundColor:opacity}} >
                                                <p className="">of {resp.milestone_description} <br></br><strong>{resp.upv_id}</strong><br></br> no. of UPV Generation</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }):
                            <td colSpan="3" className="emptyState">
                                <img src={require('../../../core/images/empty-state/UPV.svg')} alt=""/>
                            </td>
                            }
                        </div>
                    </div>
                </Dialog>
                <Dialog isOpen={this.state.isVisibleProceedModal}
                        title={"Convert your UPV"}
                        className="bidModal"
                        style={{marginTop:'250px'}}
                        onClose={this.closeProceedModal.bind(this)} >
                    <div className="bidForm m-3 text-center">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="f-16 text-left">Are you sure want to change Cashback Process?
                                </h4>
                            </div>
                            <div className="col-12 text-left">
                                <RadioGroup
                                    onChange={(e) => {
                                        this.setState({
                                            newUpvType:e.target.value
                                        })
                                    }}
                                    name="type"
                                    className="d-inline option-type col-12"
                                    selectedValue={this.state.newUpvType}>
                                    <Radio label="FIFO to RPP" value={"RPP"} />
                                    <Radio label="FIFO to Circle" value={"CIRCLE"} />
                                </RadioGroup>
                            </div>
                            <div className="col-12">
                                <div className="bidFormBtn mt-4 mb-4">
                                    <Button
                                        text={"Cancel"}
                                        intent={"default"}
                                        onClick={() => {
                                            this.setState({
                                                isVisibleProceedModal:false
                                            })
                                        }}
                                    />
                                    <Link to={{pathname:'/upv-conversion',state:{upv:this.state.upvDetail,oldUpvType:"FIFO",newUpvType:this.state.newUpvType}}}>
                                        <Button
                                            text={"Proceed"}
                                            intent={"primary"}
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog isOpen={this.state.isVisibleSellModal}
                        title={"Sell UPV "+ this.state.upvID}
                        className="bidModal"
                        style={{marginTop:'250px'}}
                        onClose={this.closeSellModal.bind(this)} >
                    <div className="bidForm m-3 text-center">
                        <p className="text-left f-14">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                        <div className="row">
                            <div className="col-12">
                                <InputGroup
                                    placeholder="Enter UPV Selling Price"
                                    small={false}
                                    type={"number"}
                                    value={this.state.amount}
                                    onChange={(e) => {
                                        this.setState({
                                            amount:e.target.value,
                                            hasAmountError:false
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <div className="bidFormBtn mt-4 mb-4">
                                    <Button
                                        text={"Cancel"}
                                        intent={"default"}
                                        onClick={() => {
                                            this.setState({
                                                isVisibleSellModal:false
                                            })
                                        }}
                                    />
                                    <Button
                                        text={"Submit"}
                                        intent={"primary"}
                                        onClick={() => this.sellBid(this.state.upvID)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}