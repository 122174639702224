import {connect} from 'react-redux';

import MyBids from '../components/MyBids';
import {declineBid, getMyBidsList, getTransactionID} from '../../core/actions/action';

const mapStateToProps = state => {

    return{
        myBidsList:state.upvExchange.myBidsList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getMyBidsList :(page) => {
            return getMyBidsList(page)
        },
        declineBid:(upvID, bidID) => {
            return declineBid(upvID, bidID)
        },
        getTransactionID:(obj) => {
            return getTransactionID(obj)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyBids);
