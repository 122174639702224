import React, {Component} from 'react';
import PageTitle from "../core/components/PageTitle";
import {Tab, Tabs} from "@blueprintjs/core";
import AllOrders from "./AllOrders/Container/AllOrders";
import TopBar from "../core/components/TopBar";
import Approved from "./Approved/Container/ApprovedOrder";
import Megabrands from "./Megabrands/megabrands";
import BSW from "./BSW/Container/Shopping";
import Recharge from "./Recharge/index";
import Travel from "./Travel/travel";
import Store from "./Store";
import Grange from "./Grange";
import Inquiry from "./Inquiry";

export default class Orders extends Component{
constructor(props){
    super(props)
    this.state={
        selectTabId:"all",
    }
}
tabChange=(value)=>{
   console.log(value)
 this.setState({selectTabId:value})
}
componentDidMount(){
    this.setState({selectTabId:this.props.location.hash.split('#')[1]})
    
}
componentWillReceiveProps(nextProps){
    this.setState({selectTabId:nextProps.location.hash.split('#')[1]})
}
    render(){
        return(
            <div className="container">
                <PageTitle title="My Orders - Global Garner"/>
                <TopBar title="My Orders"/>
                <div className="orderWrapper">
                    <Tabs
                        animate={false}
                        id="ordersTab"
                        selectedTabId={this.state.selectTabId}
                        onChange={this.tabChange}
                        renderActiveTabPanelOnly={true} vertical={false}>
                        <Tab id="all" title="All" panel={<AllOrders />} />
                        <Tab id="approved" title="Approved Orders" panel={<Approved/>} />
                        <Tab id="megabrands" title="Mega Brands" panel={<Megabrands/>} />
                        <Tab id="bsw" title="BSW" panel={<BSW />} />
                        <Tab id="recharge" title="Recharge" panel={<Recharge/>} />
                        <Tab id="travel" title="Travel" panel={<Travel {...this.props}/>} />
                        <Tab id="store" title="Store" panel={<Store {...this.props} />} />
                        <Tab id="grange" title="Grange" panel={<Grange {...this.props} />} />
                        <Tab id="inquiry" title="Inquiry" panel={<Inquiry {...this.props}/>} />
                    </Tabs>
                </div>
            </div>
        );
    }
}
