import React, { Component } from 'react';
import {Button, Classes, Dialog, Label } from "@blueprintjs/core";

export default class MegaBrandDetails extends Component{
    constructor(props){
        super(props);

        this.state = {
            isOpen:false,
            userBill:"",
            megaBrandOrderDetails: [],
            loading: true,
        };

    }

    handleClose=()=>{
        this.setState({ isOpen: false });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            megaBrandOrderDetails: nextProps.megaBrandOrderDetails
        })
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.props.voucherDetailView == 1 ?
                        <Button small={true} intent="inform" className="btn-view mr-2" icon="eye-open"
                                title="View Order Details"
                                onClick={() => {
                                    this.setState({isOpen: true})
                                    this.props.getMegaBrandsOrderDetails(this.props.receiptNo)
                                }}/>
                    :
                        <React.Fragment>
                            <br/>
                            <Button small={true} disabled={true} intent="inform" className="btn-view mr-2" icon="eye-open" title="View Order Details" />
                        </React.Fragment>
                }
                {
                    this.props.invoiceDownloadLink != "" ?
                        <a href={this.props.invoiceDownloadLink}  title="download Invoice" className="bp3-button bp3-small bp3-intent-primary btn-view" rel="noopener noreferrer" target="_blank">
                            <span className="bp3-icon bp3-icon-import bp3-text-medium"></span>
                        </a>
                        :
                        <Button small={true} intent="inform" disabled={true} className="btn-view mr-2" title="download Invoice" icon="import" />
                }
            
                <Dialog isOpen={this.state.isOpen} onClose={() => this.handleClose()} className="bp3-dialog-large" title="Order Details">
                    {this.state.megaBrandOrderDetails && this.state.megaBrandOrderDetails.voucher_detail && this.state.megaBrandOrderDetails.order_detail  ?
                        <div className={Classes.DIALOG_BODY + " popup-voucher"}>
                         
                            <div className="fix-order-voucher-list mb-4">
                                {
                                    this.state.megaBrandOrderDetails.voucher_detail.length ?
                                        this.state.megaBrandOrderDetails.voucher_detail.map((item, key) => {
                                            if (item.message_show === true) {
                                                return (
                                                    <p className="no-voucher" key={key}>Note: Your voucher
                                                        No. {item.receipt_no} not
                                                        generated yet.{item.message}</p>
                                                )
                                            } else {
                                                let defaultChecked = 'Unused';

                                                if (item.is_used === 1) {
                                                    defaultChecked = 'Used';
                                                }

                                                return (
                                                    <div className="voucher-block" key={key}>
                                                        <div className="row">
                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-3 col-6">
                                                                <Label className="title">
                                                                    Brand Name
                                                                    <span className="sub-title">{item.brandName}</span>
                                                                </Label>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                                                                <Label className="title">
                                                                    Card Price
                                                                    <span
                                                                        className="sub-title">{item.cardPrice}</span>
                                                                </Label>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                                                <Label className="title">
                                                                    Product Type
                                                                    <span
                                                                        className="sub-title">{item.productType}</span>
                                                                </Label>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="row mt-2">
                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                                                <Label className="title">
                                                                    Voucher Number
                                                                    <span
                                                                        className="sub-title">{item.voucherNumber}</span>
                                                                </Label>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                                                                <Label className="title">
                                                                    Voucher Pin
                                                                    <span
                                                                        className="sub-title">{item.voucherPin}</span>
                                                                </Label>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                                                                <Label className="title">
                                                                    Expiry Date
                                                                    <span
                                                                        className="sub-title">{item.expiryDate}</span>
                                                                </Label>
                                                            </div>
                                                            {/*<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                                                <Label className="title">{defaultChecked}</Label>
                                                            </div>*/}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                        : ""
                                }
                            </div>
                            <div className="row">
                                <div className="col-4 mb-4">
                                    <Label className="s-title">
                                        Order id
                                        <span className="s-sub-title">{this.state.megaBrandOrderDetails.order_detail.order_id} </span>
                                    </Label>
                                </div>
                                <div className="col-4 mb-4">
                                    <Label className="s-title">
                                        Receipt No.
                                        <span className="s-sub-title">{this.state.megaBrandOrderDetails.order_detail.receipt_no} </span>
                                    </Label>
                                </div>
                                <div className="col-4 mb-4">
                                    <Label className="s-title">
                                        Date Of Purchase
                                        <span className="s-sub-title">{this.state.megaBrandOrderDetails.order_detail.create_at} </span>
                                    </Label>
                                </div>
                                <div className="col-4 mb-4">
                                    <Label className="s-title">
                                        Cashback Option
                                        <span
                                            className="s-sub-title">{this.state.megaBrandOrderDetails.order_detail.caseback_choice_text} </span>
                                    </Label>
                                </div>
                                <div className="col-4 mb-4">
                                    <Label className="s-title">
                                        Order Status
                                        <span
                                            className="s-sub-title c-g">{this.state.megaBrandOrderDetails.order_detail.order_status_text} </span>
                                    </Label>
                                </div>
                                <div className="col-4 mb-4">
                                    <Label className="s-title">
                                        UPV Status
                                        <span
                                            className="s-sub-title c-g">{this.state.megaBrandOrderDetails.order_detail.upv_status_text} </span>
                                    </Label>
                                </div>
                            </div>
                            <div className="row mt-4">
                                {/*<div className="col-12">
                                    {
                                        this.state.megaBrandOrderDetails.voucher_detail.length ?
                                            this.state.megaBrandOrderDetails.voucher_detail.map((item, key) => {
                                                return (
                                                    <div className="table-items mb-3" key={key}>
                                                        <div className="row tr-item">
                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                                                                <div className="d1 t-title">{item.brandName}</div>
                                                            </div>
                                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-4">
                                                                <div className="d2">&nbsp;</div>
                                                            </div>
                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                                <div className="d3 t-sub-title">&#8377; {item.cardPrice}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>*/}
                                <div className="col-12">
                                    <div className="table-items mb-3">
                                        <div className="row tr-item">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                                                <div className="d1 t-title">Sub Total</div>
                                            </div>
                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-4">
                                                <div className="d2">&nbsp;</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <div className="d3 t-sub-title">&#8377;&nbsp;
                                                    {this.state.megaBrandOrderDetails.order_detail.subtotal}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-items mb-3">
                                        <div className="row tr-item">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                                                <div className="d1 t-title">Shipping Charges</div>
                                            </div>
                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-4">
                                                <div className="d2">&nbsp;</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <div className="d3 t-sub-title">&#8377;&nbsp;
                                                    {this.state.megaBrandOrderDetails.order_detail.shipping_charges}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-items mb-3">
                                        <div className="row tr-item">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                                                <div className="d1 t-title">Payment Charges</div>
                                            </div>
                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-4">
                                                <div className="d2">&nbsp;</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <div className="d3 t-sub-title">&#8377;&nbsp;
                                                    {this.state.megaBrandOrderDetails.order_detail.tax}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr/>

                                    <div className="table-items mb-3">
                                        <div className="row tr-item">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                                                <div className={"d1 t-title bold"}>Grand Total</div>
                                            </div>
                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-4">
                                                <div className="d2">&nbsp;</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <div className={"d3 t-sub-title bold"}>&#8377;&nbsp;
                                                    {this.state.megaBrandOrderDetails.order_detail.grandtotal}
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="table-items mb-3">
                                        <div className="row tr-item">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                                                <div className="d1 t-title">GG Coins Redeem</div>
                                            </div>
                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-4">
                                                <div className="d2">&nbsp;</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <div className="d3 t-sub-title">&#8377;&nbsp;
                                                    {this.state.megaBrandOrderDetails.order_detail.order_coin}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-items mb-3">
                                        <div className="row tr-item">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                                                <div className="d1 t-title">GG Vocuher Redeem Amount</div>
                                            </div>
                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-4">
                                                <div className="d2">&nbsp;</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <div className="d3 t-sub-title">&#8377;&nbsp;
                                                    {this.state.megaBrandOrderDetails.order_detail.used_gg_pay_voucher_amount ? this.state.megaBrandOrderDetails.order_detail.used_gg_pay_voucher_amount : 0}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr/>

                                    <div className="table-items mb-3">
                                        <div className="row tr-item">
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                                                <div className={"d1 t-title bold"}>Payable Total</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <div className={"d3 t-sub-title bold"}>&#8377;&nbsp;
                                                    {this.state.megaBrandOrderDetails.order_detail.payable_total}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                    
                        <div className="row mb-5">
              
                            <div className="col-12 mb-4">
                                <div className="bp3-skeleton" style={{width: "95%", height: "85px", margin: "0 auto"}}>&nbsp;</div>
                            </div>

                            <div className="col-12 mb-4">
                                <div className="bp3-skeleton" style={{width: "95%", height: "170px", margin: "0 auto"}}>&nbsp;</div>
                            </div>

                            <div className="col-12 mb-4">
                                <div className="bp3-skeleton" style={{width: "95%", height: "250px", margin: "0 auto"}}>&nbsp;</div>
                            </div>
                        </div>
                    }
                </Dialog>
            </React.Fragment>
        )
    }
}