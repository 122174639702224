import React from 'react';
import PassBookCoin from './Container/PassBookCoin'

export default class CoinRequest extends React.Component {
    constructor(props){
        super(props);
        this.state={
            loadingData : true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 1000);
    }

    render(){
        return(
            <div className="my-5">
               <PassBookCoin  loading={this.state.loadingData}/>
            </div>
        );
    }
}