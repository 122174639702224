let initialState = {
    passbookCoinList:[],
    passbookMoneyList:[],
};

const PassbookCoin = (state = initialState, action) => {

    switch (action.type) {

        case 'GG_MONEY_PASSBOOK_LIST':
            return {...state, passbookMoneyList: action.sources}
        case 'GG_COIN_PASSBOOK_LIST':
            return {...state, passbookCoinList: action.sources}
        default:
            return state
    }
}

export default PassbookCoin;
