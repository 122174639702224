import React from 'react';
import {Button, Intent, Position, Toaster} from "@blueprintjs/core";
import InsertLoader from "../../../../core/images/loader.gif";


export default class GGVoucherListCardComponent extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            downloadLoader:false
        }
    }


    downloadInvoice(orderId){
        this.props.getGGVoucherInvoice(orderId)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "GG-Voucher-Invoice.pdf");
                document.body.appendChild(link);
                link.click();
                this.setState({
                    downloadLoader:false
                })
            })
            .catch((error) =>{
                Toaster.create({position: Position.TOP}).show({message:"Something went wrong",intent: Intent.DANGER});
                this.setState({
                    downloadLoader:false
                })
            })
    }

    render(){
        return(
            <React.Fragment>
                <tr>
                    <td>{this.props.OrderList.id ? this.props.OrderList.id : "--"}</td>
                    <td>{this.props.OrderList.cb_choice_title ? this.props.OrderList.cb_choice_title : "--"}</td>
                    <td><b>₹ {this.props.OrderList.basic_amount ? this.props.OrderList.basic_amount : "--"}</b></td>
                    <td><b>₹ {this.props.OrderList.pg_charge ? this.props.OrderList.pg_charge : "--"}</b></td>
                    <td><b>₹ {this.props.OrderList.amount ? this.props.OrderList.amount : "--"}</b></td>
                    <td>
                        <b>
                            {
                                this.props.OrderList.payment_id != null ?
                                    <label className="badge text-success">Success</label>
                                :
                                    <label className="badge text-danger">Payment Fail</label>
                            }
                        </b>
                    </td>
                    <td>{this.props.OrderList.formatted_created_at ? this.props.OrderList.formatted_created_at : "--"}</td>
                    <td style={{width:"10%"}}>
                        {
                            this.props.OrderList.payment_id != null ?
                                !this.state.downloadLoader ?
                                    <Button small={true} intent="inform" className="btn-view mr-2" title="download Invoice" icon="import"
                                            onClick={() => {
                                                this.setState({
                                                    downloadLoader: true
                                                })
                                                this.downloadInvoice(this.props.OrderList.id)
                                            }}
                                    />
                                    :
                                    <img src={InsertLoader} alt={""}
                                         style={{width: '50px', height: '37px'}}/>
                                :
                                <Button small={true} intent="inform" disabled={true} className="btn-view mr-2" title="download Invoice" icon="import" />
                        }

                    </td>
                </tr>
            </React.Fragment>
        );
    }
}