import {connect} from 'react-redux';

import Bids from '../components/Bids';
import { getBidsList } from '../../core/actions/action';

const mapStateToProps = state => {

    return{
        bidsList:state.upvExchange.bidsList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getBidsList :(page) => {
            return getBidsList(page)
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bids);
