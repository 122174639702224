import React, { Component } from 'react';
import {Card, Toaster, Intent, Position} from "@blueprintjs/core";
import { FavoriteRounded, LocationOnRounded, PhoneAndroidRounded } from '@material-ui/icons';
import favoriteEmptyState from '../../../core/images/empty-state/No_favorite.svg';
import Config from '../../../Config';
//import GG_coin from '../../../core/images/png/GG_coin.png';
import StarRatings from 'react-star-ratings';
import LoadMore from "./loading";
import {Auth, NoLogin} from "gg-react-utilities";



export default class Favourite extends Component{
    constructor(props) {
        super(props);

        let localtion = JSON.parse(localStorage.getItem('locationData'));
        let latitude="23.02295890";
        let longitude="72.53093990";

        if(JSON.parse(localStorage.getItem('locationData')) !== null){
            latitude=localtion.lat;
            longitude=localtion.lng;
        }

        this.state = {
            favouriteList:[],
            length:10,
            stopLoadMore: false,
            loading:true,
            latitude:latitude,
            longitude:longitude,
            showEmptyStats:false,
            isLoggedIn:false,
        }
    }

    componentDidMount() {

        this.checkAuth();

        this.setState( {
            loading: this.props.loading,
        })

        let params={
            length:this.state.length,
            latitude:this.state.latitude,
            longitude:this.state.longitude,
        }
        this.getVendorFavouriteList(params)
    }


    async checkAuth(){
            setTimeout(()=>{
                Auth.isLoggedInAsync().then((res) => {
                    this.setState({
                        isLoggedIn:res,
                    })
                });

                if (this.state.isLoggedIn === false) {
                    this.checkAuth();
                }
            },1);


    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    getVendorFavouriteList=(params)=>{
        this.props.getFavouriteVendorlist(params)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    this.setState( {
                        favouriteList:[],
                    });
                    let oldData = this.state.favouriteList;
                    let newDataFromApi = sources.data.data;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState( {
                        favouriteList:newData,
                    });

                    if(sources.data.data === null){
                        this.setState( {
                            showEmptyStats:true,
                        });
                    }

                    if(newData.length === sources.data.totalRecords){
                        this.setState( {
                            stopLoadMore: true,
                        })
                    }else{
                        this.setState( {
                            stopLoadMore: false,
                        })
                    }
                }else{

                }
            }).catch((error)=>{
            const sources = error.response;
        })
    }

    NextPage= () =>{
        let oldPageNo = this.state.length;
        this.setState({
            length: oldPageNo + 10
        },()=>{
            let params={
                length:this.state.length,
                latitude:this.state.latitude,
                longitude:this.state.longitude,
            }
            this.getVendorFavouriteList(params)
        })

    }

    removeFromFavorite = (vendorSlug) => {
        this.props.removeFavouriteVendor(vendorSlug)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    this.setState( {
                        favouriteList:[],
                        loading:true,
                    });
                    let params={
                        lenght:10,
                        latitude:this.state.latitude,
                        longitude:this.state.longitude,
                    }
                    this.getVendorFavouriteList(params)
                    this.stopLoading();
                    Toaster.create({position: Position.TOP}).show({message:sources.message ,intent: Intent.SUCCESS});
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.message ,intent: Intent.DANGER});
                }
            }).catch((error)=>{
            const sources = error.response;
        })
    }

    stopLoading = () => {
        setTimeout(() => {
            this.setState({
                loading: false,
            });
        }, 1000);
    }

    render() {
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }

        return (
            <React.Fragment>
                <ul className="vendor-list">
                    {
                        this.state.loading ?
                            <li className='col-xl-12 col-12 mb-4'>
                                <div className='rounded-0 vendor-box'>
                                    <div className="row">
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-12 mt-s-2 text-left">
                                            <div className="bp3-skeleton">
                                                <a href="#" rel="noopener noreferrer">
                                                    <img src={Config.DEFAULT_IMAGE_URL}
                                                         alt="place"
                                                         className="image img-fluid "
                                                         onError={(ev)=>{
                                                             ev.target.src = Config.DEFAULT_IMAGE_URL;
                                                         }}/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-xl-9 col-lg-9 col-md-9 col-sm-8 col-12 pl-xl-4 pl-lg-4 pl-md-3 pl-sm-2 pl-3 mt-s-3">
                                            <div className='pull-right bp3-skeleton'>
                                                <FavoriteRounded style={{'color':'#FF5252'}}/>
                                            </div>
                                            <div className="">
                                                <a href="#" rel="noopener noreferrer" target="_blank">
                                                    <p className='title bp3-skeleton'>Global Garner</p>
                                                </a>
                                            </div>

                                            <div className='row'>
                                                <div className='col-xl-3 col-lg-3 col-md-5 col-sm-5 col-5 d-flex justify-content-start align-items-center pr-s-0 text-nowrap'>
                                                                <span className="review bp3-skeleton">
                                                                    5.5
                                                                </span>
                                                    <span className='ml-2 bp3-skeleton'>
																	<StarRatings
                                                                        rating={0}
                                                                        starRatedColor="#F5A623"
                                                                        starDimension='10px'
                                                                        numberOfStars={5}
                                                                        name='rating'
                                                                    />
																</span>
                                                </div>
                                                    <div className='col-xl-9 col-lg-9 col-md-7 col-sm-7 col-7 d-flex justify-content-start align-items-center pl-s-0 '>

                                                        <p className="gg-coin bp3-skeleton">GG Coins Applicable</p>
                                                    </div>
                                            </div>
                                            <div className="vendor-text mb-3 mt-2 bp3-skeleton">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also                                            </div>
                                            <div className="vendor-text">
                                                    <p className="d-flex mb-1 bp3-skeleton"><PhoneAndroidRounded/>&nbsp;&nbsp;9999999999</p>
                                            </div>
                                            <div className="vendor-address mb-s-2 bp3-skeleton">
                                                <LocationOnRounded />&nbsp;&nbsp;
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            :
                            this.state.favouriteList.length?
                                this.state.favouriteList.map((vendor, key) => {
                                    return(
                                        <li key={key} className='col-xl-12 col-12 mb-4 p-0'>
                                            <div className='rounded-0 vendor-box'>
                                                <div className="row">
                                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-12 mt-s-2 text-left">
														<a href={Config.STORE_FAV + 'vendor/'+ vendor.vendor_slug} rel="noopener noreferrer" target="_blank">
															<img src={vendor.display_pic===null||vendor.display_pic===''?Config.DEFAULT_IMAGE_URL:vendor.display_pic}
															 alt="place"
															 className="image img-fluid"
															 onError={(ev)=>{
																 ev.target.src = Config.DEFAULT_IMAGE_URL;
															}}/>
														</a>
                                                    </div>
                                                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-8 col-12 pl-xl-4 pl-lg-4 pl-md-3 pl-sm-2 pl-3 mt-s-3">
                                                        <div className='pull-right'>
                                                            <FavoriteRounded style={{'color':'#FF5252'}} onClick={() => this.removeFromFavorite(vendor.vendor_slug)}/>
                                                        </div>
                                                        <a href={Config.STORE_FAV + 'vendor/'+ vendor.vendor_slug} rel="noopener noreferrer" target="_blank">
                                                            <p className='title'>{vendor.company_name}</p>
                                                        </a>
                                                        <div className='row'>
                                                            <div className='col-xl-3 col-lg-3 col-md-5 col-sm-5 col-5 d-flex justify-content-start align-items-center pr-s-0 text-nowrap'>
                                                                <span className="review">
                                                                    {
                                                                        vendor.avg_rating===null?
                                                                            0
                                                                            :
                                                                            vendor.avg_rating.length > 5 ?
                                                                                vendor.avg_rating.substr(0, 4)
                                                                                :
                                                                                vendor.avg_rating
                                                                    }
                                                                </span>
																<span className='ml-2'>
																	<StarRatings
																		rating={vendor.avg_rating===null?0:vendor.avg_rating}
																		starRatedColor="#F5A623"
																		starDimension='10px'
																		numberOfStars={5}
																		name='rating'
																	/>
																</span>
															</div>
															{/*{vendor.gg_coin_accept === 1 ?
                                                                <div className='col-xl-9 col-lg-9 col-md-7 col-sm-7 col-7 d-flex justify-content-start align-items-center pl-s-0'>
																	<img src={GG_coin} alt="gg_coins" className='gg-coin-img'/>
                                                                    <p className="gg-coin">GG Coins Applicable</p>
                                                                </div>
															:null}*/}
                                                        </div>
                                                        <div className="vendor-text mb-3 mt-2">
                                                            {vendor.vendor_description}
                                                        </div>
                                                        {/*<div className="vendor-text">
                                                            {vendor.vendor_contact_person.length>0 ?
                                                                <p className="d-flex mb-1"><PhoneAndroidRounded/>&nbsp;&nbsp;{vendor.vendor_contact_person[0].mobile}</p>
                                                                : <p><PhoneAndroidRounded/></p>}
                                                        </div>*/}
                                                        <div className="vendor-address mb-s-2">
                                                            <LocationOnRounded />&nbsp;&nbsp;
                                                            {vendor.street1+' '+vendor.street2 +', '+vendor.landmark +', ' +vendor.area +', ' + vendor.state+' - '+vendor.pincode}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                                :
                                <li className='col-xl-12 col-12 mb-4'>
                                    <Card className=''>
                                        <center>
                                            <img src={favoriteEmptyState} alt=''/>
                                            <p className='mt-3 mb-2'>No favourite vendor found!</p>
                                        </center>
                                    </Card>
                                </li>



                    }
                </ul>
                {
                    this.state.loading ?
                        ""
                        :
                        <LoadMore
                            orderList={this.state.favouriteList}
                            NextPage={() => this.NextPage()}
                            stopLoadMore={this.state.stopLoadMore}/>
                }
            </React.Fragment>
        )
    }

}