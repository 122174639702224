import {connect} from 'react-redux';

import { addNewBid, updateBid } from '../../../core/actions/action';
import BidList from "../components/BidList";


const mapDispatchToProps = dispatch => {
    return {
        addNewBid:(upvID, obj) => {
            return addNewBid(upvID, obj)
        },
        updateBid:(upvID, bidID, obj) => {
            return updateBid(upvID, bidID, obj)
        }

    }
}

export default connect(null, mapDispatchToProps)(BidList);
