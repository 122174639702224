import React from 'react';
import {Button, Classes, Dialog} from "@blueprintjs/core";
import PdfLogo from "../../../core/images/png/viewPDF.jpeg";

export default class BillHistory extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            isOpen:false,
            userBill:""
        };
        this.handleClose = this.handleClose.bind(this)
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        //this.setState({ userBill: nextProps.viewUserBill });
    }


    handleClose(){
        this.setState({ isOpen: false });
    }

    renderStatusHtml(status){

        switch (status) {
            case "0":
                return <label className="label text-warning">Pending</label>;
            case "1":
                return <label className="badge text-primary">Verify</label>;
            case "2":
                return <label className="label text-success">Accepted</label>;
            case "3":
                return <label className="label text-danger">Rejected</label>;
            default:
                return <label className="label text-default">Unresolved</label>;
        }
    }

    renderFileHtml(fileURl){
        let fileExt = fileURl.split('.').pop();
        if(fileExt === "pdf"){
            return  <div>
                <img src={PdfLogo} alt="" className="img-fluid" />
                <p>
                    <a href={fileURl} target="_blank" rel="noopener noreferrer" className="btn bp3-button bp3-intent-primary">View Uploaded Bill</a>
                </p>
            </div>;
        }else{
            return  <img src={fileURl} alt="" className="img-fluid" />;
        }
    }


    render(){
        return(
            <React.Fragment>
                <Button small={true} intent="primary" icon="eye-open" title="View" className="btn-view mr-2"
                onClick={()=>{
                    this.setState({isOpen:true})
                    this.props.getUserBillView(this.props.billNo)
                }}/>
                <Dialog isOpen={this.state.isOpen} onClose={() => this.handleClose()} className="bp3-dialog-large bsw-order" title="View Bill">
                    {this.props.viewUserBill!==null?
                    <div className={Classes.DIALOG_BODY}>
                        <div className="row">
                            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 offset-xl9 offset-lg-1 offset-md-1 offset-sm-1 user-bill text-center">
                                {
                                    this.renderFileHtml(this.props.viewUserBill.bill_url)
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-10 col-lg-10 col-md-11 col-sm-11 col-12 offset-xl9 offset-lg-1 offset-md-1 offset-sm-1">
                                <div className="row  bill-info">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 mt-4">
                                        <p className="heading">Brand Name</p>
                                        <p className="headingTitle">{this.props.viewUserBill.brand_name}</p>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 	col-sm-4 col-6 mt-4">
                                        <p className="heading">Product Name</p>
                                        <p className="headingTitle">{this.props.viewUserBill.product_name}</p>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 mt-4">
                                        <p className="heading">	Status</p>
                                        <p className="headingTitle">
                                            {
                                                this.renderStatusHtml(this.props.viewUserBill.is_approove)
                                            }
                                        </p>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 mt-4">
                                        <p className="heading text-nowrap">Date Of Purchase</p>
                                        <p className="headingTitle">{this.props.viewUserBill.date_of_purchase}</p>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 mt-4 mb-s-4">
                                        <p className="heading">Bill Amount</p>
                                        <p className="headingTitle">₹ {this.props.viewUserBill.bill_amount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Button onClick={() => this.handleClose()} intent="danger" text="Close" className="btn float-right"/>
                            </div>
                        </div>

                    </div>
                        :null}
                </Dialog>
            </React.Fragment>
        );
    }
}