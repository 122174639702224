import React from 'react';
import {
    Intent,
    Dialog,
    Classes,
    Toaster,
    Position,
    Tabs, Tab,
    FormGroup,
} from "@blueprintjs/core";
import {Textbox, Textarea} from 'react-inputs-validation';
import Carousel from "react-multi-carousel";
import '../../../../src/core/styles/_advertise.scss';
import Social from './shareAdvertise'
import { Auth } from 'gg-react-utilities';
import Loader from '../../../core/components/Loader';
import CopyImage from './copyAdvertiseLink'
import { triggerBase64Download } from 'react-base64-downloader';
import { NavigateNext, NavigateBefore, FavoriteBorderRounded, FavoriteRounded } from '@material-ui/icons';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

export default class Advertise extends React.Component {
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
        this.state = {
            data: [],
            isLoading: false,
            name: '',
            imageData: '',
            VshareLink: '',
            HshareLink: '',
            Himage: '',
            Vimage: '',
            loading: true,
            mobileNumber: '',
            file: null,
            email: '',
            Image_Quality:'',
            fileUrl:'',
            loadingImg:true,
            iframeImg: '',
            location: '',
            isForm: true,
            modalIsOpen: false,
            referralLink: props.referralLink,
            validate: false,
        };
    }

    componentDidMount() {
        let userInfo = Auth.user();
        let Address = '';
        if(!(userInfo.house_no == '' || userInfo.house_no == null)) {
            Address = userInfo.house_no + ' ';
        }
        if(!(userInfo.street == '' || userInfo.street == null)) {
            Address += userInfo.street + ' ';
        }
        if(!(userInfo.landmark == '' || userInfo.landmark == null)) {
            Address += userInfo.landmark + ' ';
        }
        if(!(userInfo.locality == '' || userInfo.locality == null)) {
            Address += userInfo.locality + ' ';
        }
        if(!(userInfo.city == '' || userInfo.city == null)) {
            Address += userInfo.city + ' ';
        }
        if(!(userInfo.state == '' || userInfo.state == null)) {
            Address += userInfo.state + ' ';
        }
        if(!(userInfo.country == '' || userInfo.country == null)) {
            Address += userInfo.country + ' ';
        }
        if(!(userInfo.pincode == '' || userInfo.pincode == null)) {
            Address += userInfo.pincode;
        }

        this.setState({
            loading: this.props.loading,
            name: userInfo.username,
            mobileNumber: userInfo.mobile,
            email: userInfo.email,
            location: Address,
        })

        this.props.advertiseImg(this.props.value)
            .then((res) => {
                    this.setState({
                    data: res.data.data,
                    loadingImg:false
                })

            }).catch((error) => {
                const sources = error.response;
            })


    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            loading: nextProps.loading
        })
    }


    submitButton = () => {

        if(this.state.name == '' || this.state.name == null){
            Toaster.create({ position: Position.TOP_CENTER }).show({
                message: "Name cannot be empty.",
                intent: Intent.DANGER
            });
            return false
        }else if(this.state.mobileNumber == '' || this.state.mobileNumber == null){
            Toaster.create({ position: Position.TOP_CENTER }).show({
                message: "Mobile Number cannot be empty.",
                intent: Intent.DANGER
            });
            return false
        }else if(this.state.email == '' || this.state.email == null){
            Toaster.create({ position: Position.TOP_CENTER }).show({
                message: "Email Address cannot be empty.",
                intent: Intent.DANGER
            });
            return false
        }else if(this.state.location == '' || this.state.location == null){
            Toaster.create({ position: Position.TOP_CENTER }).show({
                message: "Address cannot be empty.",
                intent: Intent.DANGER
            });
            return false
        }else {

            this.setState({isLoading: true});
            let file = this.state.file ? this.state.file : Auth.user().avatar_url;
            if (file) {
                this.props.getRefral(this.state.name, this.state.mobileNumber, file, this.state.email, this.state.location, this.state.iframeImg)
                    .then((res) => {
                        const sources = res.data;
                        if (sources.status == true) {
                            this.setState({
                                isForm: false,
                                imageData: sources.data
                            })

                        } else {
                            Toaster.create({position: Position.TOP_CENTER}).show({
                                message: sources.message,
                                intent: Intent.DANGER
                            });
                        }
                        this.setState({isLoading: false});

                    }).catch((error) => {
                    if (error.response) {
                        Toaster.create({position: Position.TOP_CENTER}).show({
                            message: error.response.data.errors[0],
                            intent: Intent.DANGER
                        });
                    }
                    this.setState({isLoading: false});
                })
            } else {
                Toaster.create({position: Position.TOP_CENTER}).show({
                    message: "plese select profile",
                    intent: Intent.DANGER
                });
                this.setState({isLoading: false});
            }
        }
    }
    closeModals = () => {
        this.setState({ modalIsOpen: false ,
            fileUrl: '', file:null});
    }

    handleFile = (event) => {
        var file = document.getElementById('file');
        this.setState({
            file: event.target.files[0],
            fileUrl: URL.createObjectURL(event.target.files[0]),
        });

        file.textContent = event.target.files[0].name;
    }

    downloadImg = (img) => {
        var element = document.createElement("a");
        var file = new Blob(
            [
                img
            ],
            { type: "image/*" }
        );
        element.href = URL.createObjectURL(file);
        element.download = "image.jpg";
        element.click();
    };

    openModal = (e) => {
        this.setState({ modalIsOpen: true, isForm: true, iframeImg: e });
    }

    render() {
 
        return (
            <React.Fragment>
                {this.state.loadingImg == true ?
                    <div className="col-12 bp3-skeleton" style={{width: "100%", height: "200px"}}>
                    </div>
                :
                    <div class="position-relative">
                        <Carousel
                            ref={(el) => (this.Carousel = el)}
                            draggable={false}
                            responsive={responsive}
                            arrows={false}>
                            {
                                this.state.data.map((template, index) => (
                                    <div className="m-1">
                                        <img className="w-100" style={{ height: 200 }} src={template.iframe_image}
                                            onClick={() => this.openModal(template.id)} />
                                    </div>
                                ))
                            }
                        </Carousel>
                        {this.state.data.length != 0 && this.state.data.length >4?
                        <div>
                        <button className="awa-pre" onClick={() => {
                            const previousSlide = this.Carousel.state.currentSlide - 1;                           
                            this.Carousel.previous()
                            this.Carousel.goToSlide(previousSlide)
                        }}><NavigateBefore /></button> 

                    <button className="awa-next" onClick={() => {
                            const nextSlide = this.Carousel.state.currentSlide + 1;
                            this.Carousel.next()
                            this.Carousel.goToSlide(nextSlide)
                        }}><NavigateNext /></button></div>
:""}
                    </div>
                }
                <Dialog isOpen={this.state.modalIsOpen}
                        onClose={this.closeModals}
                        className="bp3-dialog-large pb-0" >
                    <div id="model">
                        <div className="referrals p-4">
                            <div className="container" >
                                {
                                    this.state.isForm ?
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-xs-12" >
                                                <div className="row">
                                                    <div className="col-md-2 col-sm-2 col-xs-12" >
                                                        <img className="mb-3" style={{ width: 100, height: 100 }} src={this.state.fileUrl?this.state.fileUrl:Auth.user().avatar_url} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-xs-12">
                                                <FormGroup>
                                                    <label className="bp3-file-input bp3-fill">
                                                        <input type="file" required name={"file"} id={"file"} accept=".jpeg,.jpg" onChange={this.handleFile} />
                                                        <span className="bp3-file-upload-input">Select Image File</span>
                                                    </label>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-xs-12 mb-4">
                                                <Textbox
                                                    tabIndex="1"
                                                    id={"Name"}
                                                    placeholder="Name"
                                                    value={this.state.name}
                                                    onBlur={e => {
                                                    }}
                                                    onChange={(name,e) => {
                                                        this.setState({ name })
                                                    }}
                                                    validate={this.state.validate}
                                                    validationCallback={res =>
                                                        this.setState({hasNameError: res, validate: false})
                                                    }
                                                    validationOption={{
                                                        name: 'Name',
                                                        check: true,
                                                        required: true
                                                    }}/>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-xs-12 mb-4">
                                                <Textbox
                                                    tabIndex="2"
                                                    id={"phone"}
                                                    name="phone"
                                                    type="text"
                                                    value={this.state.mobileNumber}
                                                    placeholder="Mobile Number"
                                                    // onFocus={() => this.checkAuth()}
                                                    onChange={(mobileNumber) => {
                                                        this.setState({mobileNumber});
                                                    }}
                                                    onBlur={e => {
                                                    }}
                                                    validate={this.state.validate}
                                                    validationCallback={res =>
                                                        this.setState({hasMobileError: res, validate: false})
                                                    }
                                                    validationOption={{
                                                        name: "Mobile Number",
                                                        check: true,
                                                        required: true,
                                                    }}/>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-xs-12 mb-4">
                                                <Textbox
                                                    tabIndex="3"
                                                    id={"email"}
                                                    name="email"
                                                    type="text"
                                                    value={this.state.email}
                                                    // onFocus={() => this.checkAuth()}
                                                    placeholder="Email ID"
                                                    onChange={(email) => {
                                                        this.setState({email});
                                                    }}
                                                    onBlur={e => {
                                                    }}
                                                    validate={this.state.validate}
                                                    validationCallback={res =>
                                                        this.setState({hasEmailError: res, validate: false})
                                                    }
                                                    validationOption={{
                                                        name: "Email ID",
                                                        check: true,
                                                        required: true,
                                                        customFunc: email => {
                                                            if (/[^@]+@[^@]+\.[^@]+/.test(String(email).toLowerCase())) {
                                                                return true;
                                                            } else {
                                                                return "Is not a valid email address";
                                                            }
                                                        }
                                                    }}/>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12 mb-4">
                                                <Textarea
                                                    tabIndex="14"
                                                    id={"Address"}
                                                    name="Address"
                                                    type="text"
                                                    rows="4"
                                                    value={this.state.location}
                                                    placeholder="Address"
                                                    onChange={(location) => {
                                                        this.setState({ location });
                                                    }}
                                                    onBlur={e => {}}
                                                    validate={this.state.validate}
                                                    validationCallback={res =>
                                                        this.setState({ hasOtherInfoError:res, validate: false })
                                                    }
                                                    validationOption={{
                                                        name: "Address",
                                                        check: true,
                                                        required: true,
                                                        type:"string"
                                                    }}/>
                                            </div>

                                        </div>
                                        :
                                        <div className="row">
                                            <div className="col-12 p-0">
                                                <div className="cb-status-tab">
                                                    <Tabs className="cb-status-tab" animate={false} id="TabsExample" renderActiveTabPanelOnly={true} vertical={false} >
                                                        <Tab className="upv-tab"  id="ng" title="Post"
                                                             panel={
                                                                 <div className="row">

                                                                     <div className="col-sm-5 col-md-5 col-xs-12 text-center" style={{ borderRight: "1px solid #727272" }}>
                                                                         <img className="mb-3" style={{backgroundSize: "100%",width: "280px",height: "230px"}} src={this.state.imageData.horizontal_image} />
                                                                     </div>

                                                                     <div className="col-sm-7 col-md-7 col-xs-12">
                                                                         <h1 className="mt-0" style={{ color: "#383838" }}>Download Image</h1>

                                                                         <button type="button"
                                                                                 onClick={() => triggerBase64Download(`data:image/jpeg;base64,${this.state.imageData.base64_horizontal_image}`, 'GG_Post')}
                                                                                 className="bp3-button bp3-intent-primary text-left d-inline-block"
                                                                                 tabIndex="0">Download</button>
                                                                         <div >
                                                                             <CopyImage image={this.state.imageData.horizontal_image} />
                                                                         </div>
                                                                         <div className="my-5 myStats">
                                                                             <Social base64ImageUrl={this.state.imageData.horizontal_image} title={this.state.imageData.title} description={this.state.imageData.description} text={"Share Post with"}
                                                                                     loading={this.state.loading} />
                                                                         </div>
                                                                     </div>
                                                                 </div>
                                                             }  />
                                                        <Tab className="fpv-tab" id="rx" title="Story"
                                                             panel={
                                                                 <div className="row">

                                                                     <div className="col-sm-5 col-md-5 col-xs-12 text-center" style={{ borderRight: "1px solid #727272" }}>
                                                                         <img className="mb-3" style={{backgroundSize: "100%",width: "250px",height: "350px"}} src={this.state.imageData.vertical_image} />
                                                                     </div>
                                                                     <div className="col-sm-7 col-md-7 col-xs-12">
                                                                         <h1 className="mt-0" style={{ color: "#383838" }}>Download Image</h1>

                                                                         <button type="button"
                                                                                 onClick={() => triggerBase64Download(`data:image/jpeg;base64,${this.state.imageData.base64_vertical_image}`, 'GG_Story')}
                                                                                 className="bp3-button bp3-intent-primary text-left d-inline-block"
                                                                                 tabIndex="0">Download</button>
                                                                         <div >
                                                                             <CopyImage image={this.state.imageData.vertical_image} />
                                                                         </div>

                                                                         <div className="my-5 myStats">
                                                                             <Social base64ImageUrl={this.state.imageData.vertical_image} title={this.state.imageData.title} description={this.state.imageData.description} text={"Share Story with"}
                                                                                     loading={this.state.loading} />
                                                                         </div>
                                                                     </div>
                                                                 </div>
                                                             }
                                                        />
                                                    </Tabs>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    {
                                        this.state.isLoading ?
                                            <Loader/>
                                            :
                                            <React.Fragment>
                                                {
                                                    this.state.isForm ?
                                                        <button type="button" className="bp3-button bp3-intent-primary"
                                                                tabIndex="0" onClick={this.submitButton}>Submit</button>
                                                        : ""
                                                }
                                                <button type="button" className="bp3-button bp3-intent-danger" tabIndex="0" onClick={this.closeModals}>Close</button>
                                            </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}