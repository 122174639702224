import React, {Component} from 'react';
import BswFavourite from './Container/BswFavourites'


export default class BSW extends Component{
    constructor(props){
        super(props);
        this.state={
            loadingData : true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 1000);
    }

    render(){
        return(
            <React.Fragment>
                <BswFavourite loading={this.state.loadingData}/>
            </React.Fragment>
        );
    }
}