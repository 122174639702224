import React, { Component } from 'react';
import {Button, Dialog} from "@blueprintjs/core";
import Config from "../../../Config";
import {getPackageDetail} from "../../../core/actions/action";
import {connect} from "react-redux";

class ViewPackage extends Component{
    constructor(props){
        super(props);

        this.state = {
            isOpen:false,
            packageDetail:{}
        };
        this.closeModal = this.closeModal.bind(this)
    }

    closeModal(){
        this.setState({ isOpen: false });
    }

    getPackageDetail=(packageID)=>{
        this.props.getPackageDetail(packageID)
            .then((res)=>{
                const sources = res.data;
                console.log(sources);
                if(sources.status===true){
                    this.setState({
                        packageDetail:sources.data
                    })
                }
            }).catch((error)=>{
            const sources = error.response;
        })
    }

    render() {
        return(
            <React.Fragment>
                <Button small={true} intent="primary" icon="eye-open" className="btn-view mr-2"
                        onClick={()=>{
                            this.setState({isOpen:true})
                            this.getPackageDetail(this.props.orderID)
                        }}/>
                <Dialog isOpen={this.state.isOpen}
                        title={"View Details"}
                        className="viewDetail"
                        style={{marginTop:'250px'}}
                        onClose={this.closeModal.bind(this)} >
                    <div className="viewForm m-3">
                        <div className="row">
                            <div className="col-12">
                                <div className="orderTable">
                                    <div className="title">
                                        <span>Holiday Package Name</span>
                                        <p>{"("+this.state.packageDetail.city_name+") "+ this.state.packageDetail.package_name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="orderTable">
                                    <div className="title">
                                        <span>Duration</span>
                                        <p>{this.state.packageDetail.day+" Days/"+ this.state.packageDetail.night+" Nights"}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="orderTable">
                                    <div className="title">
                                        <span>City Covered</span>
                                        <p>{this.state.packageDetail.city_name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="orderTable">
                                    <div className="title">
                                        <span>Price</span>
                                        <p>{this.state.packageDetail.amount}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="orderTable">
                                    <div className="title">
                                        <span>Status</span>
                                        <p style={{color:this.state.packageDetail.order_status_color}}>{this.state.packageDetail.order_status_text}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="orderTable">
                                    <div className="title">
                                        <span>Enquiry ID</span>
                                        <p>{this.state.packageDetail.enquiry_id}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="orderTable">
                                    <div className="title">
                                        <span>Enquiry Date</span>
                                        <p>{this.state.packageDetail.prefer_date_time}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center mt-4">
                            <Button className="btn-view mt-2" onClick={() => {
                                window.location.href= this.state.packageDetail.package_detail_link
                            }}>Visit Package Detail</Button>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}


const mapDispatchToProps = dispatch => {
    return {
        getPackageDetail :(packageID) => {
            return getPackageDetail(packageID)
        }
    }
}

export default connect(null, mapDispatchToProps)(ViewPackage);