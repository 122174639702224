import {connect} from 'react-redux';
import CircleTree from '../../components/circle_components/circleTree';
import {addCircle, getUserDetailByUpvId, upvReplaceRequest} from "../../../../core/actions/action";

const mapDispatchToProps = dispatch => {

    return {
        addCircle: (upvID, obj) => {
            return addCircle(upvID, obj)
        },
        getUserDetailByUpvId : (upvId) => {
            return getUserDetailByUpvId(upvId)
        },
        upvReplaceRequest : (upvId,request) => {
            return upvReplaceRequest(upvId,request)
        }
    }
}

export default connect(null, mapDispatchToProps)(CircleTree);
