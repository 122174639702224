import React,{Component} from 'react';

import {Helmet} from "react-helmet";


export default class PageTitle extends Component {
    render(){
        return(
            <Helmet>
                <meta charSet="utf-8" />
                <title>{this.props.title}</title>
             </Helmet>
        );
    }
}
