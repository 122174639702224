import React, { Component } from 'react';
import {Button, Dialog, Intent, Position, Radio, RadioGroup, Toaster} from "@blueprintjs/core";
import {Link} from "react-router-dom";
import {Helper} from "gg-react-utilities";

export default class RPP extends Component {
    constructor(props) {
        super();
        this.state = {
            rppList: [],
            selectedOption: true,
            isSkeleton: true,
            total: 0,
            cash: 0.00,
            currentCounter: 1,
            oldCounter: 1,
            currentRPPData: [],
            oldRPPData: [],
            scrollLoader: false,
            last_page: 1,
            rppCurrentList: [],
            rppOldList: [],
            isVisibleProceedModal:false,
            upvDetail:[],
            newUpvType:"FIFO"
        }
        this.getRPPCurrentList = this.getRPPCurrentList.bind(this)
    }

    componentDidMount() {
        setTimeout(() => {
            this.getRPPCurrentList(this.state.currentCounter);
            this.getRPPOldList(this.state.oldCounter);
        }, 1000)
    }

    getRPPCurrentList = (page) => {
        this.props.getRPPCurrentList(page)
            .then((res) => {
                const sources = res.data;

                if (sources.status === true) {
                    let currentRPPData = this.state.currentRPPData;
                    let updateCurrentRPPData = sources.data.data;
                    let updateCurrentData = currentRPPData.concat(updateCurrentRPPData);
                    this.setState({
                        rppCurrentList: sources.data,
                        currentRPPData: updateCurrentData,
                        isSkeleton: false
                    })
                } else {
                    Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                }
                this.setState({
                    scrollLoader:false
                })
            }).catch((error) => {
                const sources = error.response;
                this.setState({
                    scrollLoader:false
                })
            })
    }

    getRPPOldList = (page) => {
        this.props.getRPPOldList(page)
            .then((res) => {
                const sources = res.data;
                if (sources.status === true) {
                    let oldRPPData = this.state.oldRPPData;
                    let updateOldRPPData = sources.data.data;
                    let updateOldData = oldRPPData.concat(updateOldRPPData);
                    this.setState({
                        rppOldList: sources.data,
                        oldRPPData: updateOldData,
                        isSkeleton: false
                    })
                } else {
                    Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                }
                this.setState({
                    scrollLoader:false
                })
            }).catch((error) => {
                Toaster.create({position: Position.TOP}).show({message: "Something went wrong.", intent: Intent.DANGER});
                this.setState({
                    scrollLoader:false
                })
            })
    }


    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            this.state.selectedOption ?
                this.loadMoreForCurrentRPP()
                :
                this.loadMoreForOldRPP()
        }
    }

    loadMoreForCurrentRPP = () =>{

        this.setState({
            currentCounter: this.state.currentCounter + 1,
            isLoading: true,
            scrollLoader:true
        }, () => {
            this.getRPPCurrentList(this.state.currentCounter);
        })

    }

    loadMoreForOldRPP = () =>{

        this.setState({
            oldCounter: this.state.oldCounter + 1,
            isLoading: true,
            scrollLoader:true
        }, () => {
            this.getRPPOldList(this.state.oldCounter);
        })

    }

    closeProceedModal(){
        this.setState({
            isVisibleProceedModal:false
        })
    }


    render(){
        let money = '';
        let total = '';
        const {rppOldList,rppCurrentList} = this.state;
        if(rppOldList!==null || rppCurrentList!==null){
            money = this.state.selectedOption?rppCurrentList.total_rpp_cashback:rppOldList.total_rpp_cashback;
            total = this.state.selectedOption?rppCurrentList.total_rpp_upvs:rppOldList.total_rpp_upvs;
        }

        return(
            <React.Fragment>
                <div className="fifo mt-4">
                    <div className="fifoWrapper">
						<div className="row">
                            <div className="col-sm-6 col-12 mt-s-6">
								<div className="fifoSection">
									<div className="icon">
										<img src={require('../../images/check.svg')} alt="" />
									</div>
									<div className="count">
										<h3 className={this.state.isSkeleton===true ? "bp3-skeleton":""}>{total}</h3>
										<p>Number of UPV's Generated</p>
									</div>
								</div>
							</div>
							<div className="col-sm-6 col-12 mt-s-6">
								<div className="fifoSection">
									<div className="icon">
										<img src={require('../../images/rupee.svg')} alt="" />
									</div>
									<div className="count">
										<h3 className={this.state.isSkeleton===true ? "bp3-skeleton":""}>{Helper.formatMoney(money)}</h3>
										<p>Cashback Received Through UPV</p>
									</div>
								</div>
							</div>
                            {/*<div className="col-sm-4 col-12 mt-s-4">
                                <div className="fifoSection" style={{justifyContent:'center'}}>
                                    <Link to="/conversion-transaction-history">
                                        <Button
                                            className={this.state.isSkeleton===true ? "bp3-skeleton p-1":"p-1" }
                                            text={"My Conversion Transactions"}
                                            intent={"primary"}
                                        />
                                    </Link>
                                </div>
                            </div>*/}
						</div>
                    </div>
                    {
                        this.props.orderAggregateData.rpp_message ?
                            <div className="fifoWrapper mt-3">
                                <div className="row">
                                    <div className="col-sm-12 col-12">
                                        <div className="alert alert-success">
                                            <strong>{this.props.orderAggregateData.rpp_message}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :''
                    }
                    <div className="mt-3 text-left">
                        <label className="bp3-control bp3-switch bp3-large pl-0 bp3-text-medium">
                            Current Month
                            <input type="checkbox" checked={this.state.selectedOption===true ? "checked":""} onChange={() =>{
                                this.setState({
                                    selectedOption:!this.state.selectedOption,
                                    isSkeleton:true,
                                    currentCounter:1,
                                    oldCounter:1,
                                    currentRPPData:[],
                                    oldRPPData:[],
                                    scrollLoader:true,
                                    last_page:1
                                },() => {
                                    this.state.selectedOption===true ?
                                        setTimeout(() => {
                                            this.getRPPCurrentList(this.state.currentCounter)
                                        },1000)
                                        :
                                        setTimeout(() => {
                                            this.getRPPOldList(this.state.oldCounter)
                                        }, 1000)
                                })
                            }}/>
                            <span className="bp3-control-indicator ml-2"> </span>
                        </label>
                    </div>
                    <div style={{height:'100%',maxHeight:'590px',overflowY:'scroll'}} id={"allBooking"} onScroll={this.handleScroll} className="table-responsive mt-3">
						<table className="bp3-html-table bp3-html-table-bordered">
							<thead>
								<tr>
									<th>ID</th>
									<th>UPV ID</th>
                                    <th>Generated Date</th>
                                    <th>Action</th>
								</tr>
							</thead>
							<tbody>
								{this.state.selectedOption===true ?
									this.state.isSkeleton===true ?
										<tr>
											<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>1</span></td>
											<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>123456</span></td>
											<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>16 Aug 2028</span></td>
											<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>16 Aug 2028</span></td>
										</tr>
										:
                                        this.state.currentRPPData.length===0 ?
											<tr className="emptyState w-100">
												<td colSpan="4" className="text-center">
													<img src={require('../../../core/images/empty-state/UPV.svg')} alt=""  className="img-fluid"/>
                                                    <p className="mt-3">No UPVs generated under SCB RPP yet, Continue shopping to get 2% to 100% cashback / reward in a month and 100% instant GGCoins.</p>
												</td>
											</tr>
											:
                                            this.state.currentRPPData.map((res, index) =>{
												return(
													<tr key={index}>
														<td>{index+1}</td>
														<td>{res.upv_id}</td>
														<td>{res.upv_generation_date+" "+res.upv_generation_time}</td>
                                                        <td>
                                                            {
                                                                res.payment_id !== null ?
                                                                    <span className="bp3-small bp3-bg-success text-white pl-1 pr-1">Converted your UPV</span>
                                                                :
                                                                    <Button className="bp3-small mr-1"
                                                                            text={"Convert your UPV"}
                                                                            intent={"primary"}
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    upvDetail: res,
                                                                                    isVisibleProceedModal: true
                                                                                })
                                                                            }}/>
                                                            }
                                                        </td>
													</tr>
												);
											})
									:
									this.state.isSkeleton===true ?
										<tr>
											<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>1</span></td>
											<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>123456</span></td>
											<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>16 Aug 2028</span></td>
											<td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>16 Aug 2028</span></td>
										</tr>
										:
                                        this.state.oldRPPData.length === 0 ?
											<tr>
												<td colSpan="4" className="emptyState">
													<img src={require('../../../core/images/empty-state/UPV.svg')} alt=""/>
                                                    <p className="mt-3">No UPVs generated under SCB RPP yet, Continue shopping to get 2% to 100% cashback / reward in a month and 100% instant GGCoins.</p>
												</td>
											</tr>
											:
                                            this.state.oldRPPData.map((res, index) =>{
												return(
													<tr key={index}>
														<td>{index+1}</td>
														<td>{res.upv_id}</td>
														<td>{res.upv_generation_date+" "+res.upv_generation_time}</td>
                                                        <td>
                                                            {res.payment_id !== null ? "" :
                                                                <Button className="bp3-small mr-1"
                                                                        text={"Convert in SCB FIFO"}
                                                                        intent={"primary"}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                upvDetail: res,
                                                                                isVisibleProceedModal: true
                                                                            })
                                                                        }}/>
                                                            }
                                                        </td>
													</tr>
												);
											})
								}
							</tbody>
						</table>
					</div>
                    {this.state.selectedOption ?
                        this.state.last_page < this.state.currentCounter ? null :
                            this.state.scrollLoader ?
                                <React.Fragment>
                                    <center>
                                        <div className="my-3">
                                            <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                             :null
                        :
                        this.state.last_page < this.state.oldCounter ? null :
                            this.state.scrollLoader ?
                                <React.Fragment>
                                    <center>
                                        <div className="my-3">
                                            <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                             :null
                    }
                </div>
                <Dialog isOpen={this.state.isVisibleProceedModal}
                        title={"Convert your UPV"}
                        className="bidModal"
                        style={{marginTop:'250px'}}
                        onClose={this.closeProceedModal.bind(this)} >
                    <div className="bidForm m-3 text-center">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="f-16 text-left">Are you sure want to change Cashback Process?
                                </h4>
                            </div>
                            <div className="col-12 text-left">
                                <RadioGroup
                                    onChange={(e) => {
                                        this.setState({
                                            newUpvType:e.target.value
                                        })
                                    }}
                                    name="type"
                                    className="d-inline option-type col-12"
                                    selectedValue={this.state.newUpvType}>
                                    <Radio label="RPP to FIFO" value={"FIFO"} />
                                    <Radio label="RPP to Circle" value={"CIRCLE"} />
                                </RadioGroup>
                            </div>
                            <div className="col-12">
                                <div className="bidFormBtn mt-4 mb-4">
                                    <Button
                                        text={"Cancel"}
                                        intent={"default"}
                                        onClick={() => {
                                            this.setState({
                                                isVisibleProceedModal:false
                                            })
                                        }}
                                    />
                                    <Link to={{pathname:'/upv-conversion',state:{upv:this.state.upvDetail,oldUpvType:"RPP",newUpvType:this.state.newUpvType}}}>
                                        <Button
                                            text={"Proceed"}
                                            intent={"primary"}
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}