import {connect} from "react-redux";
import {getMyStatsList} from '../../../core/actions/action';
import Mystats from '../../Mystats'
const mapStateToProps = state =>{

    return{
        myStatsList:state.Stats.myStatsList,
    }

}

const mapDispatchToProps = dispatch =>{
    return{
        getMyStatsList : () =>{
            return getMyStatsList();
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mystats)