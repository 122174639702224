import React,{Component} from 'react'
import {Button, Intent, Position, Switch, Toaster} from "@blueprintjs/core";

export default class VoucherAction extends Component{
    constructor(props) {
        super(props);

        this.state={
            loader:false,
            counter:1,
            isUsed:0,
            voucherStatus:[]
        }
    }

    componentDidMount() {
        if(this.props.list){
            this.setState( {
                isUsed: this.props.list.status,
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            voucherStatus: nextProps.voucherStatus,
        })
    }

    redeemVoucher(obj){
        this.props.setVoucherCode(obj)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    this.props.updateRewardList(this.props.ArrayKey,sources.data);
                    Toaster.create({position: Position.TOP}).show({message:sources.message+" of ID "+sources.data.id,intent: Intent.SUCCESS});
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                }
                this.setState({
                    loader:false
                })
            }).catch((res)=>{
                Toaster.create({position: Position.TOP}).show({message:res.response.data.message,intent: Intent.DANGER});
                this.setState({
                    loader:false
                })
        })
    }
    render() {
        return (
            <React.Fragment>
                {
                    this.props.list.voucher_status === 1 ?
                        <div className="text-left">
                            <label className='bp3-text-success'>{this.props.list.voucher_status_msg}</label><br/>
                            <label className="bp3-control bp3-switch bp3-small" style={{"padding-left":"0px"}}>
                                {
                                    <Switch defaultChecked={this.props.list.status} value={this.state.isUsed}
                                        onChange={() => {
                                            this.setState({
                                                isUsed: !this.state.isUsed,
                                                isloadin: true
                                            }, () => {
                                                let obj = {
                                                    id: this.props.list.id
                                                }
                                                this.props.changeVoucherStatus(obj)
                                            })
                                        }} label={ this.state.isUsed ? "Used" : "Unused"
                                    }/>
                                }
                            </label>
                        </div>

                        :
                            this.props.list.voucher_status === 0 ?
                                this.state.loader ?
                                        <img src={require('../../core/images/loader.gif')} alt={""} style={{width:'40px',height:'40px'}}/>
                                :
                                    <Button type="button" text="Redeem" intent="success" className="bp3-small"
                                            onClick={()=>{
                                        let obj = {
                                            "user_voucher_id":this.props.list.id,
                                            "voucher_type":this.props.list.voucher_type,
                                        };
                                        this.setState({
                                            loader:true
                                        })
                                        this.redeemVoucher(obj);
                                    }}/>
                                /*<label className='bp3-text-warning'>{this.props.list.voucher_status_msg}</label>*/
                            :
                                "-"/*<label className='bp3-text-danger'>{this.props.list.voucher_status_msg}</label>*/
                }
                {/*<td style={{display:'flex',alignItems:'baseline'}}>
                    {res.voucher_status === 0 ?
                        <Button type="button" text="Redeem" intent="success" className="bp3-small"
                                loading={this.state.loader} onClick={()=>{
                                    let obj = {
                                        "user_voucher_id":res.id,
                                        "voucher_type":res.voucher_type,
                                    };
                                    this.redeemVoucher(obj);
                                    this.setState({
                                        counter:1
                                    },() => {
                                        this.props.getRewardList(this.state.counter)
                                    });
                        }}/> :
                        <label className="bp3-control bp3-switch bp3-small">
                            <Switch defaultChecked={res.status} value={this.state.isUsed} onChange={()=>{
                                this.setState({
                                    isUsed:!this.state.isUsed,
                                }, () => {
                                    let obj ={
                                        id:res.id
                                    }
                                    this.props.changeVoucherStatus(obj)
                                })
                            }} label={res.status?"Used":"Unused"}/>
                        </label>
                    }
                </td>*/}
            </React.Fragment>
        )
    }

}