import React from 'react';
import { Tab, Tabs } from "@blueprintjs/core";
import TopBar from "../core/components/TopBar";
import CoinRequest from './CoinRequest';
import MyCoinRequest from './MyCoinRequest';
import {Auth, NoLogin} from "gg-react-utilities";
import PageTitle from "../core/components/PageTitle";

export default class MoneyRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn:false
        }
    }

    componentDidMount() {
        this.checkAuth();
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1);
    }

    render(){
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <PageTitle title="Money Requests - Global Garner"/>
                        <TopBar title="Money Requests" />
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin/>
                        </div>
                    </div>
                </div>
            );

        }
        return(
            <div className="container">
                <TopBar title="GG-Coin Request" />
                <div className="upvWrapper mb-4">
                    <div className="upvContainer py-0">
                        <Tabs
                            animate={false}
                            id="TabsExample"
                            renderActiveTabPanelOnly={true} vertical={false}>
                            <Tab id="rx" title="GG-Coin Request" panel={<CoinRequest />} />
                            <Tab id="ng" title="My GG-Coin Request" panel={<MyCoinRequest />} />
                        </Tabs>
                    </div>
                </div>
                <div className="page-content-right">
                </div>
            </div>
        );
    }
}