import React from 'react';
import {Collapse, Icon, Intent} from "@blueprintjs/core";

export class Child extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            treeViewOpen:false,
            nextChildLists:[],
            maxCirclesAllowed:null,
            prevUPVID:[],
            circlesData:[]
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            treeViewOpen : nextProps.treeViewOpen,
            circlesData : nextProps.circlesData,
            prevUPVID : nextProps.prevUPVID
        })
    }

    createNextChildItem = (res,index) => {
        let childItem = [];

        res.child_list.map((res, i) => {
            if(index <= 7) {
                this.state.prevUPVID.find((e) => e == res.id) !== undefined ?
                    childItem.push(<li key={i} className="active">
                        <a  onClick={() => {
                            this.props.getNextLevel(res.id, index)
                        }}
                           className="user">
                            <Icon icon="document" iconSize={18} intent={Intent.PRIMARY}/>
                            <span className="badge">{res.id}</span>
                        </a>
                    </li>)
                    :
                    childItem.push(<li key={i}>
                        <a  onContextMenu={(e) =>{this.props.contextMenu(e,res.id,index)}}
                            onClick={() => {
                            this.props.getNextLevel(res.id, index)
                        }}
                           className="user">
                            <Icon icon="document" iconSize={18} intent={Intent.PRIMARY}/>
                            <span className="badge">{res.id}</span>
                        </a>
                    </li>)
            }else{
                childItem.push(<li key={i}>
                    <div
                        className="user">
                        <Icon icon="document" iconSize={18} intent={Intent.PRIMARY}/>
                        <span className="badge">{res.id}</span>
                    </div>
                </li>)
            }
        })
        let childLength = res.child_list.length;
        for( let i = 1; i <= (res.max_circles_allowed - childLength); i++ ){
            childItem.push(<li>
                <a  onClick={() => {
                    this.props.addCircle(res.upv_id,(index))
                }}
                    className="user font-20 add-child">
                    <Icon icon="plus" iconSize={18} intent={Intent.PRIMARY} />
                </a>
            </li>)
        }
        return childItem;
    }

    getLevels = (res,index) =>{
        let levels = [];
            levels.push(
                <div className="org-chart">
                    <ul>
                        <li>
                            <a  onContextMenu={(e) =>{this.props.contextMenu(e,res.upv_id,index === 1 ? 0 : index)}} className="user">
                                <Icon icon="document" iconSize={18} intent={Intent.PRIMARY}/>
                                <span className="badge">{res.upv_id}</span>
                            </a>
                            <ul>
                                <li>
                                    {this.createNextChildItem(res,index)}
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            );
        return levels;
    }

    render(){
        return(
            <React.Fragment>
                {this.state.circlesData.length ?
                    this.state.circlesData.map((res, index) => {
                        return(
                            <Collapse isOpen={this.state.treeViewOpen} key={index}>
                                <div className="pg-orgchart">
                                    {this.getLevels(res,(index+1))}
                                </div>
                            </Collapse>
                        )
                    })
                    : null
                }
            </React.Fragment>
        )
    }
}