import React from 'react';
import {Button} from "@blueprintjs/core";


export default class LoadMore extends React.Component {
    constructor(props){
        super(props);

        this.state={
            orderList:[],
        };
    }

    NextPage = () => {
        this.props.NextPage()
    }


    render(){
        return(
            <React.Fragment>
                {
                    this.props.orderList.length >= 10 ?
                        this.props.stopLoadMore ?
                            ""
                            :

                            <div className="text-center my-4">
                                <center><Button intent="primary" text="Load More" onClick={() => this.NextPage()}/></center>
                            </div>
                        :
                        ""
                }
            </React.Fragment>
        );
    }
}