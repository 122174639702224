import {connect} from 'react-redux';
import {getMegaBrandFavouriteList, removeMegaBrandFavourite} from '../../../core/actions/action';
import MegaBrandsFav from '../Component/MegaBrandsFav';

const mapStateToProps = state => {

    return{
        megaBrandFavouriteList:state.Favourite.megaBrandFavouriteList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getMegaBrandFavouriteList:(params) => {
            return getMegaBrandFavouriteList(params);
        },

        removeMegaBrandFavourite:(brandId) => {
            return removeMegaBrandFavourite(brandId);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MegaBrandsFav);
