import {connect} from 'react-redux';
import {getWebInquiryList} from '../../../core/actions/action';
import WebInquiriesIndex from '../components/WebInquiriesIndex';

const mapDispatchToProps = dispatch => {

    return {
        getWebInquiryList:(params) => {
            return getWebInquiryList(params);
        },
    }
}

export default connect(null, mapDispatchToProps)(WebInquiriesIndex);
