import React from 'react';
import PageTitle from '../../../core/components/PageTitle';
import { Tab, Tabs, Dialog, RadioGroup, Radio, Button, Intent } from "@blueprintjs/core";
import {Link} from 'react-router-dom';
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import FIFO from '../container/FIFOContainer';
import RPP from '../container/RPPContainer';
import IC from '../container/ICContainer';
import Circle from '../container/circleContainer/CircleListContainer';
import Config from "../../../Config";

export default class UPV extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isVisible:false,
            hasRadioError:false,
            hasRadioErrorMsg:"",
            selectedOption: 3,
            orderAggregateData: []
        }
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        this.props.getOrderAggregateData()
    }

    isOpenModal(){
        this.setState({
            isVisible:true
        })
        this.props.getCashbackOption();
    }

    closeModal(){
        this.setState({isVisible:false})
    }

    changeCashbackOption(e){
        this.setState({
            selectedOption:Number(e.target.value)
        });
    }

    onCashbackChoice(){
        this.props.changeCashbackOption(this.state.selectedOption);
        this.setState({
            isVisible:false
        })
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            selectedOption:nextProps.cashbackOpt.cashback_choice,
            orderAggregateData:nextProps.orderAggregateData
        })
    }

    render(){
        return(
            <div className="container mb-5">
                <PageTitle title="UPV Cashback - Global Garner"/>
                <div className="upvWrapper">
					<div className="upvTopBar">
						<div className="top-bar">
							<Link to='/cashback'>
								<span className="back-icon"><KeyboardArrowLeftRounded /></span>
								<h1 className="title m-0">UPV Cashback</h1>
							</Link>
						</div>
						<div className="leftTopBar">
							<a href={Config.YOUTUBE_VIDEO_LINK + Config.UPV_CASHBACK_VIDEO_URL} rel="noopener noreferrer" target="_blank">Tutorial Video</a>
                            <Link to="/upv-conversion-transaction-history">
                                My UPV Conversion
                            </Link>
                            {/*<a href={'/upv-conversion-transaction-history'} rel="noopener noreferrer" target="_blank">My Conversion Transactions</a>*/}
							<Link to="#" onClick={this.isOpenModal.bind(this)} rel="">Change Cashback</Link>
						</div>
					</div>
					<div className="upvContainer">
						<div className="justify-content-center d-flex">
							<div className="col-xl-10 col-lg-10 col-md-11 col-sm-12 col-12 px-s-0 upv-cashback">
                                <Tabs
                                    animate={false}
                                    id="cashbackTabs"
                                    defaultSelectedTabId={this.props.cbType}
                                    vertical={false}>
                                    <Tab id="fifo" title= {<Link to="/upv/fifo">SCB First In First Out</Link>} panel={<FIFO orderAggregateData={this.state.orderAggregateData} />} />
                                    <Tab id="rpp" title= {<Link to="/upv/rpp">SCB Random Pick Process</Link>} panel={<RPP orderAggregateData={this.state.orderAggregateData} />} />
                                    <Tab id="ic" title= {<Link to="/upv/ic">Instant Cashback</Link>} panel={<IC />} />
                                    <Tab id="circle" title= {<Link to="/upv/circle">Circle</Link>} panel={<Circle orderAggregateData={this.state.orderAggregateData}/>} />
                                </Tabs>
								{/*<Tabs
									animate={false}
									id="cashbackTabs"
									renderActiveTabPanelOnly={true} vertical={false}>
									<Tab id="fifo" title="SCB First In First Out" panel={<FIFO />} />
									<Tab id="rpp" title="SCB Random Pick Process" panel={<RPP />} />
									<Tab id="ic" title="Instant Cashback" panel={<IC />} />
									<Tab id="circle" title="Circle" panel={<Circle />} />
								</Tabs>*/}
							</div>
						</div>
					</div>
                </div>
                <Dialog isOpen={this.state.isVisible}
                        title="Change Cashback"
                        className="planWrapper bp3-dialog-large"
                        onClose={this.closeModal}>
                    <div className="cashback m-3 mx-5 mx-s-3">
                        <div className="section mt-3">
                            <p>You Can Change Your cashback Options From Here <a href="https://www.globalgarner.com/systematic-cashback" rel="noopener noreferrer" target="_blank">Learn More</a></p>
                            <div className="sectionOption">
                                <RadioGroup
                                    helperText={this.state.hasRadioErrorMsg}
                                    onChange={this.changeCashbackOption.bind(this)}
                                    name="upv"
                                    selectedValue={this.state.selectedOption}>
                                    <Radio label="FIRST IN FIRST OUT (SCB)" value={1} className="mb-0"/>
                                    <p className="ml-4 mb-0">Guaranteed 100% Cashback through SCB FIFO Process</p>
                                    <p className="ml-4">Video link: <a href='https://www.youtube.com/watch?v=amfYCLHttnE' target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=amfYCLHttnE</a> <br></br>
                                        Important Note: Cashback/Reward will only be given on generation of new UPVs if no new UPVs are generated then no cashback/Reward will be given. GG coin conversion will be maximum Rs.10000 with each UPV depending upon GG coins available in the GG Rewards.</p>
                                    <Radio label="RANDOM PICK PROCESS (RPP)" value={2} className="mb-0"/>
                                    <p className="ml-4">2% to 100% Cashback in a month</p>
                                    <Radio label="INSTANT CASHBACK (IC)" value={3} className="mb-0"/>
                                    <p className="ml-4 mb-0">2% to 10% Cashback Instant</p>
                                    <Radio label="CIRCLE" value={4} className="mb-0 mt-3"/>
                                    <p className="ml-4 mb-0">Make your circle. Get cashback</p>
                                </RadioGroup>
                            </div>
                            <div className="sectionFooter mt-4 text-right">
                                <Button className="bp3-button bp3-intent-primary bp3-button bp3-intent-primary" intent={Intent.PRIMARY} onClick={this.onCashbackChoice.bind(this)}>Submit</Button>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>

        );
    }
}