import React from "react";
import { Textbox } from 'react-inputs-validation';
import '../../../core/styles/_coinconversion.scss';
import {
    PlayForWork,
    Create
} from '@material-ui/icons';
import "react-multi-carousel/lib/styles.css";
import { Toaster, Dialog, Classes, Intent, Position  } from '@blueprintjs/core'

export default class coin_conversion_card extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            modalIsOpen: false,
            Amount: this.props.Amount <= this.props.ggCoinBalance ? this.props.Amount : this.props.ggCoinBalance,
            editAmount:this.props.Amount
        }        
    }

    closeModal = () => { 
        if(this.state.editAmount > this.props.Amount){

            Toaster.create({ position: Position.TOP }).show({ message: "You can convert only " + this.props.Amount+" GG Coins ", intent: Intent.DANGER });
        }else{
        
            this.setState({
                Amount:this.state.editAmount
            })
            this.setState({ modalIsOpen: false }); 
        }
    }

    openModal = () => { this.setState({ modalIsOpen: true }); }

    render() {        
        return (
            this.props.Amount ?
            <React.Fragment>
                    <div class={this.props.Lavel % 3 == 1 ? "coin-card blue-coin-card d-flex m-3" : this.props.Lavel % 3 == 0 ? "coin-card green-coin-card d-flex m-3" : this.props.Lavel % 3 == 2 ? "coin-card pink-coin-card d-flex m-3" : "coin-card blue-coin-card d-flex m-3"} id={"card-"+this.props.Lavel} draggable={true}
                    onMouseDown ={e =>{
                        this.props.setConvertedCoin(this.state.Amount, this.props.Lavel)     
                    }}
                    onDragStart={e => {
                        this.props.handleDragStart(e);
                    }} >
                    <div class="coin-card-title">
                        <p>You can Convert at Level - {this.props.Lavel}</p>
                        <p><b>{this.state.Amount}</b> <span onClick={this.openModal}><Create /></span>
                            <Dialog isOpen={this.state.modalIsOpen} 
                                    onClose={this.closeModal} 
                                    title="Edit Converted Coins" 
                                    className="bp3-dialog-large">

                                <div className={Classes.DIALOG_BODY}>
                                    <Textbox
                                        tabIndex="2"
                                        id={"Amount"}
                                        name="Amount"
                                        type="text"
                                        style={{ border: "1px solid grey", width: "100%", padding: "10px" }}
                                        value={this.state.editAmount}
                                        placeholder="Amount"
                                        onChange={(value,e) => {                                            
                                                this.setState({editAmount:value})
                                                //this.props.setConvertedCoin(value)                                                
                                        }}
                                        validate={this.state.validate}
                                        validationOption={{
                                            name: "Amount",
                                            type: 'number',
                                            check: true,
                                            required: true,
                                            number: true,
                                        }} />
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        <button type="button" className="bp3-button bp3-secondary" tabIndex="0" onClick={this.closeModal}>Ok</button>
                                    </div>
                                </div>
                            </Dialog>
                        </p>
                    </div>
                    <img class="wallet-ico" src={require('../../images/wallet.png')} alt="" />
                </div>    
            </React.Fragment>
            :''
        )
    }    
}

