import React from 'react';
//import {Button} from "@blueprintjs/core";
import ReferralsImage from "../../../core/images/empty-state/referrals.svg";
import Loader from "../../../core/components/Loader";

export default class AllStatus extends React.Component {
    constructor(props){
        super(props);

        this.state={
            usersList:[],
            loading: true,
        };
    }

    componentDidMount(){
        this.setState( {
            loading: this.props.loading,
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            usersList:nextProps.usersList,
            loading: nextProps.loading
        })
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0 && this.props.stopLoadMore === false){
            this.NextPage()
        }
    }

    NextPage = () => {
        this.props.NextPage()
    }
    render(){
        return(
            <React.Fragment>
                <div className="social">
                    <div className="title">Sharing List</div>
                </div>
                <div style={{height:'100%',maxHeight:'590px',overflowY:'scroll'}} id={"allBooking"} onScroll={this.handleScroll} className="vendor-referrals">
                    <table className="bp3-html-table bp3-html-table-bordered">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>User Name</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.loading ?
                                <tr>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                </tr>
                                :
                                this.state.usersList.length ?
                                    this.state.usersList.map((user, key) => {
                                        return <tr key={key}>
                                            <td>{user.vendor_request_id}</td>
                                            <td>{user.vendor_username}</td>
                                            <td>{user.fname} {user.lname}</td>
                                            <td>{user.email}</td>
                                            <td>{user.mobile}</td>
                                        </tr>
                                    }):
                                    <tr>
                                        <td colSpan="5" align="center">
                                            <div className="text-center">
                                                <img src={ReferralsImage} alt=""/>
                                                <p className="mt-3">You don't have Referrals though you can share your referral using following button.</p>
                                            </div>
                                        </td>
                                    </tr>
                        }
                        </tbody>
                    </table>
                </div>
                {
                    this.props.loader ?
                        <Loader />
                        :''
                }
            </React.Fragment>
        );
    }
}