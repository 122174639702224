import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import accounts from '../reducers';

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist:[]
  }

const persistedReducer = persistReducer(persistConfig, accounts);

export default () => {
    let store = createStore(
        persistedReducer,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
    let persistor = persistStore(store)
    return { store, persistor }
  }