import React from 'react';
import TopBar from "../core/components/TopBar";
import { Tab, Tabs } from "@blueprintjs/core";
import MyStats from './Mystats/Container/MyStats';
import GgStats from './Ggstats/Container/GgStats';


export default class stats extends React.Component {
    render(){
        return(
            <div className="container">
                <TopBar title="Dashboard" isDashboard={true} />
                <Tabs
                    animate={false}
                    id="TabsExample"
                    renderActiveTabPanelOnly={true} vertical={false}
                >
                    <Tab id="ng" title="GG Stats" panel={<GgStats />} />
                    <Tab id="rx" title="My Stats" panel={<MyStats />} />
                </Tabs>
                <div className="page-content-right">
                </div>
            </div>
        );
    }
}