import React, {Component} from 'react';
import PageTitle from '../../../core/components/PageTitle';
import {Link} from "react-router-dom";
import {Button, Dialog, Intent, Radio, RadioGroup, Tab, Tabs} from "@blueprintjs/core";
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import FIFO from '../container/FIFOContainer';
import RPP from '../container/RPPContainer';
import IC from '../container/ICContainer';
import Config from '../../../Config';
import Circle from "../container/circleContainer/CircleListContainer";

export default class Main extends Component{

    constructor(props){
        super(props);
        this.state={
            isVisible:false,
            hasRadioError:false,
            hasRadioErrorMsg:"",
            selectedChoiceOption: 1,
            selectedICOption:1
        };
        this.changeCashbackOption=this.changeCashbackOption.bind(this);
        this.changeICOption = this.changeICOption.bind(this);
    }
    isOpenModal(){
        this.setState({
            isVisible:true
        });
        this.props.getFPVCashbackOption();
    }
    changeCashbackOption(e){
        this.setState({
            selectedChoiceOption:Number(e.target.value)
        });
    }
    changeICOption(e){
        this.setState({
            selectedICOption:Number(e.target.value)
        });
    }

    onSubmitCashbackOption(){
        let obj = {
            "choice":this.state.selectedChoiceOption,
            "icb_choice":this.state.selectedICOption
        }
        this.props.changeFPVCashbackOption(obj);
        this.setState({
            isVisible:false
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            selectedChoiceOption:nextProps.cashbackFPVOpt.choice,
            selectedICOption:nextProps.cashbackFPVOpt.icb_choice
        });
    }

    render(){
        return(
            <div className="container mb-5">
                <PageTitle title="FPV Cashback - Global Garner"/>
                <div className="upvWrapper">
                    <div className="upvTopBar">
                        <div className="top-bar">
                            <Link to='/cashback'>
                                <span className="back-icon"><KeyboardArrowLeftRounded /></span>
                                <h1 className="title m-0">FPV Cashback</h1>
                            </Link>
                        </div>
                        <div className="leftTopBar">
                            <a href={Config.YOUTUBE_VIDEO_LINK + Config.FPV_CASHBACK_VIDEO_URL} rel="noopener noreferrer" target="_blank">Tutorial Video</a>
                            <Link to="#" onClick={this.isOpenModal.bind(this)} rel="">Change Cashback</Link>
                        </div>
                    </div>
                    <div className="upvContainer">
                        <div className="justify-content-center d-flex">
                            <div className="col-xl-10 col-lg-10 col-md-11 col-sm-12 col-12 p-0">
                                <div className="text-left">
                                    <img src={require("../../../core/images/aquash_logo.png")} width="70" />
                                </div>
                                <Tabs
                                    animate={false}
                                    id="cashbackTabs"
                                    defaultSelectedTabId={this.props.cbType}
                                    renderActiveTabPanelOnly={true}
                                    vertical={false}>
                                    <Tab id="fifo" title={<Link to="/fpv/fifo">FPV First In First Out</Link>} panel={<FIFO />} />
                                    <Tab id="rpp" title={<Link to="/fpv/rpp">FPV Random Pick Process</Link>} panel={<RPP />} />
                                    <Tab id="ic" title={<Link to="/fpv/ic">Instant Cashback</Link>} panel={<IC />} />
                                    <Tab id="circle" title= {<Link to="/fpv/circle">Circle</Link>} panel={<Circle />} />
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog isOpen={this.state.isVisible}
                        title="Change Cashback"
                        className="planWrapper bp3-dialog-large"
                        onClose={() => {
                            this.setState({
                                isVisible:!this.state.isVisible,
                            });
                        }}>
                    <div className="cashback m-3 mx-5 mx-s-3">
                        <div className="section mt-3">
                            <p>You Can Change Your cashback Options From Here <a href="https://www.globalgarner.com/systematic-cashback"  rel="noopener noreferrer" target="_blank">Learn More</a></p>
                            <div className="sectionOption">
                                <RadioGroup
                                    helperText={this.state.hasRadioErrorMsg}
                                    onChange={this.changeCashbackOption.bind(this)}
                                    name="fpv"
                                    selectedValue={this.state.selectedChoiceOption}>
                                    <Radio label="FIRST IN FIRST OUT (SCB)" value={1} className={"mb-0"}/>
                                    <p className="ml-4 mb-0">Guaranteed 100% Cashback of fuel value through SCB FIFO Process</p>
                                    <p className="ml-4">Video link: <a href="https://www.youtube.com/watch?v=JMm-fFpltbU" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=JMm-fFpltbU</a><br></br>
                                        Important Note: Cashback/Reward will only be given on generation of new FPVs if no new FPVs are generated then no cashback/Reward will be given.</p>
                                    <Radio label="RANDOM PICK PROCESS (RPP)" value={2} className={"mb-0"}/>
                                    <p className="ml-4">2% to 100% Cashback of fuel value in a month</p>
                                    <Radio label="INSTANT CASHBACK (IC)" value={3} className={"mb-0"}/>
                                    <p className={"ml-4 mb-0"}>Click to view Cashback options</p>
                                    {this.state.selectedChoiceOption === 3 ?
                                        <RadioGroup
                                            helperText={this.state.hasRadioErrorMsg}
                                            onChange={this.changeICOption.bind(this)}
                                            name="ic"
                                            className="ml-4"
                                            selectedValue={this.state.selectedICOption}>
                                            <Radio label="GG Gift Voucher" value={1}/>
                                            <Radio label="INSTANT CASHBACK" value={2}/>
                                             <Radio label="BUY ONE GET ONE Free Water Bottle (BOGO) " value={3}/>
                                        </RadioGroup>
                                        : ""
                                    }
                                    <Radio label="CIRCLE" value={4} className="mb-0 mt-3"/>
                                    <p className="ml-4 mb-0">Make your circle. Get cashback</p>
                                </RadioGroup>
                            </div>
                            <div className="sectionFooter mt-4 text-right">
                                <Button className="bp3-button bp3-intent-primary bp3-button bp3-intent-primary" intent={Intent.PRIMARY} onClick={this.onSubmitCashbackOption.bind(this)}>Submit</Button>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}