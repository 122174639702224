let initialState = {
    busOrderList:[],
    flightOrderList:[],
    railBhojOrderList:[],
    storeOrderList:[],
    newStoreOrderList:[],
    serviceBookingList:[],
    viewStoreOrderDetails:null,
    cancelSerice:null,
    viewGrangeOrderDetails:[],
};

const Travel = (state = initialState, action) => {

    switch (action.type) {

        case 'BUS_ORDER':
            return {...state, busOrderList: action.sources}
        case 'FLIGHT_ORDER':
            return {...state, flightOrderList: action.sources}
        case 'RAIL_BHOJ_ORDER':
            return {...state, railBhojOrderList: action.sources}
        case 'STORE_ORDER':
            return {...state, storeOrderList: action.sources}
        case 'NEW_STORE_ORDER':
            return {...state, newStoreOrderList: action.sources}
        case 'STORE_SERVICE_BOOKING':
            return {...state, serviceBookingList: action.sources}
        case 'VIEW_STORE_ORDER':
            return {...state, viewStoreOrderDetails: action.sources}
        case 'CANCEL_SERVICE':
            return {...state, cancelSerice: action.sources}
        case 'GRANGE_ORDER_DETAILS':
            return {...state, viewGrangeOrderDetails: action.sources}
        default:
            return state
    }
}

export default Travel;
