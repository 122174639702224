import React from 'react';
import CoinRequestImg from "../../core/images/rewards/Request_GG-Coin.svg";
import PassBookImg from "../../core/images/rewards/My_Passbook.svg";
import SendToBankImg from "../../core/images/rewards/Send_to_Bank.svg";
import RewardsImg from "../../core/images/rewards/GG_rewards.svg";
import {Link} from "react-router-dom";
import SendCoin from './SendCoin'
import RequestCoin from './RequestCoin'
import { Auth } from 'gg-react-utilities';

export default class RewardRequest extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            isOpenRequestCoin: false,
            isOpenSendCoin: false,
            isLoggedIn:Auth.isLoggedIn(),
        };
    }

    render(){
        // console.log(this.props);
        return(
            <React.Fragment>
                <div className="row my-5 reward-request">
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 pr-s-2">
                        {
                            this.state.isLoggedIn ?
                                <SendCoin sendCoinRequest={this.props.sendCoinRequest} isOpenSendCoin={this.props.isOpenSendCoin}/>
                                :
                                <div className="sendGGCoinDiv mb-4 mt-1 mb-s-3">
                                    <p>&nbsp;</p>
                                    <div className="bp3-skeleton col-4 offset-4"  style={{width: "100%", height: "100px"}}></div>
                                    <p>&nbsp;</p>
                                    <div className="bp3-skeleton col-6 offset-3"  style={{width: "100%", height: "20px"}}></div>
                                </div>
                        }
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 pl-s-2">
                        {
                            this.state.isLoggedIn ?
                            <RequestCoin requestCoinRequest={this.props.requestCoinRequest} isOpenRequestCoin={this.props.isOpenRequestCoin}/>
                            :
                            <div className="requestCoinDiv mb-4 mt-1 mb-s-3">
                                <p>&nbsp;</p>
                                <div className="bp3-skeleton col-4 offset-4"  style={{width: "100%", height: "100px"}}></div>
                                <p>&nbsp;</p>
                                <div className="bp3-skeleton col-6 offset-3"  style={{width: "100%", height: "20px"}}></div>
                            </div>
                        }

                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 pr-s-2">
                        {
                            this.state.isLoggedIn ?
                                <Link to="/money-requests">
                                    <div className="GgCoinRequestsDiv mb-4 mt-1 mb-s-3">
                                        <img className="mt-5 mt-s-3" src={CoinRequestImg} alt=""/>
                                        <p className="mt-5 mt-s-4">GG-Coin Requests</p>
                                    </div>
                                </Link>
                                :
                                <div className="GgCoinRequestsDiv mb-4 mt-1">
                                    <p>&nbsp;</p>
                                    <div className="bp3-skeleton col-4 offset-4"  style={{width: "100%", height: "100px"}}></div>
                                    <p>&nbsp;</p>
                                    <div className="bp3-skeleton col-6 offset-3"  style={{width: "100%", height: "20px"}}></div>
                                </div>
                        }


                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 pl-s-2">
                        {
                            this.state.isLoggedIn ?
                                <Link to="/Passbook">
                                    <div className="passbookDiv mb-4 mt-1 mb-s-3">
                                        <img className="mt-5 mt-s-3" src={PassBookImg} alt=""/>
                                        <p className="mt-5 mt-s-4">My Passbook</p>
                                    </div>
                                </Link>
                                :
                                <div className="passbookDiv mb-4 mt-1">
                                    <p>&nbsp;</p>
                                    <div className="bp3-skeleton col-4 offset-4"  style={{width: "100%", height: "100px"}}></div>
                                    <p>&nbsp;</p>
                                    <div className="bp3-skeleton col-6 offset-3"  style={{width: "100%", height: "20px"}}></div>
                                </div>
                        }

                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 pr-s-2">
                        {
                            this.state.isLoggedIn ?
                                <Link to={{ pathname: '/withdraw', state: { moneyBalance: this.props.ggMoneyBalance.minimum_balance} }}>
                                    <div className="sendToBankDiv mb-4 mt-1 mb-s-3">
                                        <img className="mt-5 mt-s-3" src={SendToBankImg} alt=""/>
                                        <p className="mt-5 mt-s-4">Send to Bank</p>
                                    </div>
                                </Link>
                                :
                                <div className="sendToBankDiv mb-4 mt-1">
                                    <p>&nbsp;</p>
                                    <div className="bp3-skeleton col-4 offset-4"  style={{width: "100%", height: "100px"}}></div>
                                    <p>&nbsp;</p>
                                    <div className="bp3-skeleton col-6 offset-3"  style={{width: "100%", height: "20px"}}></div>
                                </div>
                        }
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 pl-s-2">
                        {
                            this.state.isLoggedIn ?
                                <Link to="/other-rewards">
                                    <div className="otherRewardsDiv mb-4 mt-1 mb-s-3">
                                        <img className="mt-5 mt-s-3" src={RewardsImg} alt=""/>
                                        <p className="mt-5 mt-s-4">Other Rewards</p>
                                    </div>
                                </Link>
                                :
                                <div className="otherRewardsDiv mb-4 mt-1">
                                    <p>&nbsp;</p>
                                    <div className="bp3-skeleton col-4 offset-4"  style={{width: "100%", height: "100px"}}></div>
                                    <p>&nbsp;</p>
                                    <div className="bp3-skeleton col-6 offset-3"  style={{width: "100%", height: "20px"}}></div>
                                </div>
                        }

                    </div>
                </div>
            </React.Fragment>
        );
    }
}