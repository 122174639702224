import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import FIFO from '../components/fifo';
import { getFPVFIFOList } from '../../../core/actions/action';
import { getFPVFIFOListAction } from '../../../core/actions';

const mapStateToProps = state => {

    return{
        fpvFifoList:state.cashbackReducer.fpvFifoList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getFPVFIFOList :(page) => {
            return getFPVFIFOList(page)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(getFPVFIFOListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(getFPVFIFOListAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FIFO);
