import React, {Component} from 'react';
import {Button, Intent, Position, Switch, Toaster} from "@blueprintjs/core";
import VoucherAction from "../container/VoucherActionContainer";

export default class RewardList extends Component{
    constructor(props){
        super(props);
        this.state={
            loader:false,
            counter:1,
            isUsed:0,
            isUsedLabel:"",
            isSkeleton:true,
            last_page:1,
            rewardData:[],
            scrollLoader:true,
            storeLoadMore:false,
        }
    }
    componentDidMount() {
        this.setState( {
            isSkeleton: this.props.loading,
        })

        this.getRewardList(this.state.counter)
        //this.props.getRewardList(this.state.counter);
    }

    updateRewardList=(key,data)=>{
        let rewardData = this.state.rewardData;
        rewardData[key]=data;
        this.setState({
            rewardData
        })
    }
    getRewardList=(page)=>{
        this.props.getRewardList(page)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    let oldAffData = this.state.rewardData;
                    let newAffDataFromApi = sources.data.data;
                    let newAffData = oldAffData.concat(newAffDataFromApi);
                    this.setState( {
                        rewardData:newAffData,
                        last_page: sources.data.last_page
                    });
                }else{

                }
                this.setState({
                    scrollLoader:true
                })
            }).catch((error)=>{
                const sources = error.response;
                this.setState({
                    scrollLoader:true
                })
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            isSkeleton: nextProps.loading
        })
    }

    handleScroll=()=>{
        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);
        if (parseInt(allBookingHeight) === 0){
            if(this.state.storeLoadMore === false){
                this.loadMore()
            }
        }
    }

    loadMore = () =>{

        this.setState({
            counter: this.state.counter + 1,
            isLoading: true,
            scrollLoader:false
        }, () => {
            this.getRewardList(this.state.counter);
        });

        if(this.state.counter === this.state.last_page){
            this.setState({
                storeLoadMore:true,
            });
        }
    }

    render(){

        return(
            <React.Fragment>
                <div style={{height:'100%',maxHeight:'590px',overflowY:'scroll'}} id={"allBooking"} onScroll={this.handleScroll} className="table-responsive mt-4 mb-5">
                    <table className="bp3-html-table bp3-html-table-bordered">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Reward Name</th>
                                <th>Code</th>
                                <th>Expiry</th>
                                <th>Domain</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.isSkeleton ?
                                <tr>
                                    <td><span className={this.state.isSkeleton? "bp3-skeleton" : ""}>1</span></td>
                                    <td><span className={this.state.isSkeleton? "bp3-skeleton" : ""}>GG Gift Voucher</span></td>
                                    <td><span className={this.state.isSkeleton? "bp3-skeleton" : ""}>AXYTMNIRFAN74</span></td>
                                    <td><span className={this.state.isSkeleton? "bp3-skeleton" : ""}>2019-10-13</span></td>
                                    <td><span className={this.state.isSkeleton? "bp3-skeleton" : ""}>UPV</span></td>
                                    <td style={{display:'flex'}}><span className={this.state.isSkeleton? "bp3-skeleton" : ""}>
                                            <Button type="button" text="Redeem" intent="success" className="bp3-small"
                                                loading={this.state.loader} />
                                        </span>
                                    </td>
                                </tr>
                            :
                                this.state.rewardData && this.state.rewardData.length ?
                                    this.state.rewardData.map((res, i) => {
                                        return(
                                            <tr key={i}>
                                                <td>{res.id}</td>
                                                <td>{res.voucher_name}</td>
                                                <td>{res.code}</td>
                                                <td>{res.expiry}</td>
                                                <td>{res.domain}</td>
                                                <td>
                                                    <VoucherAction list={res}
                                                          ArrayKey={i}
                                                          updateRewardList={(key,data) =>{ this.updateRewardList(key,data)}}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })
                                    :
                                    <tr>
                                        <td colSpan="6" className="emptyState"  align="center">
                                            <div className="text-center">
                                                <img src={require('../../core/images/empty-state/billhistory.svg')} alt=""/>
                                                <p className="mt-3">No transactions for the selected option</p>
                                            </div>
                                        </td>
                                    </tr>

                        }
                        </tbody>
                    </table>
                    {
                        this.state.last_page < this.state.counter ? null :
                            this.state.scrollLoader ?
                                null :
                                <React.Fragment>
                                    <center>
                                        <div className="my-3">
                                            <img src={require('../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }
}