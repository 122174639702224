import React from 'react';
import BackImage from '../../../core/images/reffrals/BG.png';
import {Button, ControlGroup, InputGroup, Intent, Toaster, Position} from "@blueprintjs/core";
import Advertise from '../../UserReferrals/Components/Advertise'

export default class AllStatus extends React.Component {
    constructor(props){
        super(props);
        this.textInput = React.createRef();
        this.state={
            referralLink:props.referralLink,
            copySuccess:"",
            loading: true,
        };
    }

    componentDidMount(){
        this.setState( {
            loading: this.props.loading,
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    copyToClipboard = (e) => {
        this.textInput.current.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the the whole text area selected.
        this.textInput.current.focus();
        Toaster.create({position: Position.TOP}).show({message: 'Copied!',intent: Intent.SUCCESS});
    };

    render(){
        const total_shoppings = {backgroundImage: `url(${BackImage})`};
        return(
            <React.Fragment>
                <div className="col-12 user-link" style={total_shoppings}>
                    <div className="title mt-5">Share your Referral Link</div>
					<div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12 pl-4 p-s-0 offset-xl-2 offset-lg-2 offset-md-2 offset-sm-2 mt-5">
                        {
                            this.state.loading ?
                                <div className="col-12 bp3-skeleton" style={{width: "100%", height: "50px"}}></div>
                                :
                                <ControlGroup fill={true} vertical={false} className="brand-search">
                                    <InputGroup
                                        placeholder="https://accounts.globalgarner.com/register?referer_id=9630"
                                        className="bs-input-search"
                                        defaultValue={this.state.referralLink}
                                        inputRef={this.textInput}
                                        readOnly
                                    />
                                    <Button className="bp3-button bp3-intent-primary btn-sm"
                                            intent={Intent.PRIMARY}
                                            text="Copy"
                                            onClick={() => this.copyToClipboard()}
                                    />
                                </ControlGroup>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}