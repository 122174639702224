import React from 'react';
import {Auth, NoLogin, Error} from "gg-react-utilities";
import TopBar from "../../../core/components/TopBar";
import PageTitle from "../../../core/components/PageTitle";
import {Link} from "react-router-dom";
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import {Button, Intent, Position, Toaster} from "@blueprintjs/core";
import {UPVConversion, UPVConversionCallback} from "../../../core/actions/action";
import {connect} from "react-redux";
import moment from "moment";
import Config from '../../../Config'
import InsertLoader from '../../../core/images/loader.gif';

class RPPFIFOConversion extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isLoggedIn:false,
            loading:false,
        }
    }

    componentDidMount() {
        this.checkAuth();
        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1);
    }

    UPVConversion = (upvID, obj) => {
        this.setState({loading: true});
        this.props.UPVConversion(upvID, obj).then((res) => {

            const resdata = res.data;
            if (resdata.status === true) {
                const PayUBiz = resdata.data.pg;
                if(PayUBiz.amount > 0) {
                    let form = document.createElement("form");
                    form.setAttribute("method", "POST");
                    form.setAttribute("action", PayUBiz.url);

                    for (let key in PayUBiz) {
                        let hiddenField = document.createElement("input");
                        hiddenField.setAttribute("type", "hidden");
                        hiddenField.setAttribute("name", key);
                        hiddenField.setAttribute("value", PayUBiz[key]);
                        form.appendChild(hiddenField);
                    }
                    document.body.appendChild(form);
                    form.submit();
                }else{
                    let callbackData = {
                        "status": "success",
                        "key": PayUBiz.key,
                        "txnid": PayUBiz.txnid,
                        "amount": PayUBiz.amount,
                        "udf1": PayUBiz.udf1,
                        "udf2": PayUBiz.udf2,
                        "udf3":PayUBiz.udf3,
                        "udf4": PayUBiz.udf4,
                        "udf5": PayUBiz.udf5,
                        "udf6": null,
                        "udf7": null,
                        "udf8": null,
                        "udf9": null,
                        "udf10": null,
                        "addedon": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        "hash": PayUBiz.hash
                    }

                    this.UPVConversionCallback(upvID,callbackData);
                    this.setState({loading: false});
                }
            } else {
                Toaster.create({position: Position.TOP,}).show({message: resdata.message, intent: Intent.DANGER});
                this.setState({loading: false});
            }

        }).catch((error) => {

            if (error.response && error.response.status !== 401) {

                Object.keys(error.response.data.error).forEach((key) => {
                    error.response.data.error[key].forEach((error) => {
                        Toaster.create({position: Position.TOP}).show({
                            message: error,
                            intent: Intent.DANGER
                        });
                    });
                });

            }
            this.setState({loading: false});
        });
    }

    UPVConversionCallback(upvID,obj){
        this.props.UPVConversionCallback(upvID, obj).then((res) => {
            const resdata = res.data;
            if (resdata.status === true) {
                window.location.href=Config.APP_URL+"/upv-conversion/payment/success?message="+resdata.message;
            } else {
                window.location.href=Config.APP_URL+"upv-conversion/payment/failed?message="+resdata.message;
            }
            this.setState({loading: false});

        }).catch((error) => {

            if (error.response && error.response.status !== 401) {

                Object.keys(error.response.data.error).forEach((key) => {
                    error.response.data.error[key].forEach((error) => {
                        window.location.href=Config.APP_URL+"upv-conversion/payment/failed?message="+error;
                    });
                });
                this.setState({loading: false});
            }
        });
    }

    render(){
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <PageTitle title="UPV Exchange - Global Garner"/>
                        <TopBar title="UPV Exchange" />
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }
        const upv = this.props.location.state ? this.props.location.state.upv : undefined;
        const newUpvType = this.props.location.state ? this.props.location.state.newUpvType : undefined;
        const oldUpvType = this.props.location.state ? this.props.location.state.oldUpvType : undefined;

        return(
            <React.Fragment>
                <div className="container mb-5">
                    <PageTitle title="UPV Exchange - Global Garner"/>
                    <div className="exchangeWrapper">
                        <div className="exchangeTopBar">
                            <div className="top-bar">
                                <Link to='/upv'>
                                    <span className="back-icon"><KeyboardArrowLeftRounded/></span>
                                    <h1 className="title m-0">UPV Exchange / My UPV Transactions / <small><b>UPV Number {oldUpvType === "CIRCLE" ? upv.id : upv.upv_id}</b></small></h1>
                                </Link>
                            </div>
                            <div className="leftTopBar">
                                <a href="#" target="_blank">UPV Exchange info</a>
                            </div>
                        </div>
                        <div className="paymentContainer">
                        {upv !== undefined ?
                            <div className="UPVblock">
                                <div className="bidCenterBlock pt-4">
                                    <div className="row">
                                        <div className="col-12 col-lg-3 col-md-3 col-sm-3">
                                            <div className="upvRight" style={{backgroundColor: '#40BE65'}}>
                                                <h5 className={"m-0 f-16"}>UPV No</h5>
                                                <h4 className={"m-0"}>{ oldUpvType === "CIRCLE" ? upv.id : upv.upv_id}</h4>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-9 col-md-9 col-sm-9">
                                            <div className="upvLeft text-left">
                                                <div className={"topbar"}>
                                                    <div>
                                                        <h4 className="m-0 nameText">{upv.upv_generation_date}</h4>
                                                        <h5 className="m-0 timeText">{upv.upv_generation_time}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 mb-4" style={{backgroundColor: '#F2F2F2', height: '1.56px',}}></div>

                                    <div className="block">
                                        <h4 className="m-1">Convert {oldUpvType} to {newUpvType}</h4>
                                    </div>

                                    <div className="mt-4 mb-4" style={{backgroundColor: '#F2F2F2', height: '1.56px',}}></div>
                                    {
                                        upv.conversion_charge != 0 ?
                                            <div>
                                                <div className="block">
                                                    <h4 className="m-1">Conversion Charge ({upv.conversion_percentage + "%"})</h4>
                                                    <h4 className="m-1" style={{fontWeight: '500'}}>₹ {upv.conversion_charge}</h4>
                                                </div>
                                                <div className="mt-4 mb-4" style={{backgroundColor: '#F2F2F2', height: '1.56px',}}></div>

                                                <div className="block">
                                                    <h4 className="m-0">Total</h4>
                                                    <h4 className="m-0"
                                                        style={{fontWeight: '500'}}>₹ {upv.conversion_charge}</h4>
                                                </div>
                                            </div>
                                        :
                                            <div className="block">
                                                <h4 className="m-1">Conversion Charge </h4>
                                                <h4 className="m-1" style={{fontWeight: '500'}}>Free</h4>
                                            </div>
                                    }
                                    < div className="mt-4 mb-4" style={{backgroundColor: '#F2F2F2', height: '1.56px',}}></div>
                                </div>

                                <div className="footerBlock p-4 m-0">
                                    <div>
                                        <Link to={'/upv/'+oldUpvType.toLowerCase()} style={{width: '100%'}}>
                                            <Button className={"m-0 f-16 "}
                                                    text={"Not Interested"}
                                                    intent={"default"}
                                                    style={{
                                                        height: 25,
                                                        padding: '0px 10px',
                                                        boxShadow: 'none',
                                                        fontSize: '14px',
                                                        borderRadius: '5px'
                                                    }}
                                            />
                                        </Link>
                                    </div>
                                    <div>
                                        {
                                            !this.state.loading ?
                                                <Button className={"m-0 f-16 "}
                                                        text={upv.conversion_charge != 0 ? "Pay" : "Convert Now"}
                                                        intent={"primary"}
                                                        style={{
                                                            height: 25,
                                                            padding: '0px 10px',
                                                            boxShadow: 'none',
                                                            fontSize: '14px',
                                                            borderRadius: '5px'
                                                        }}
                                                        onClick={() => {
                                                            let obj = {
                                                                "platform": "WEB",
                                                                "upv_type": oldUpvType,
                                                                "new_upv_type": newUpvType,
                                                                "upv_generation_date_time": oldUpvType === "FIFO" ? upv.date_created : upv.created_at
                                                            };
                                                            this.UPVConversion(oldUpvType === "CIRCLE" ? upv.id : upv.upv_id, obj)
                                                        }}
                                                />
                                            :
                                                <img src={InsertLoader} alt={""}
                                                     style={{width: '50px', height: '50px'}}/>

                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <Error/>
                        }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapDispatchToProps = dispatch => {

    return {
        UPVConversion:(upvID, obj) => {
            return UPVConversion(upvID, obj)
        },
        UPVConversionCallback:(upvID, obj) => {
            return UPVConversionCallback(upvID, obj)
        }
    }
}

export default connect(null, mapDispatchToProps)(RPPFIFOConversion);

