import {connect} from 'react-redux';
import {getFavouriteVendorlist, removeFavouriteVendor} from '../../../core/actions/action';
import Favourite from '../Component/Favourite';

const mapStateToProps = state => {

    return{
        vendorFavouriteList:state.Favourite.vendorFavouriteList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getFavouriteVendorlist:(params) => {
            return getFavouriteVendorlist(params);
        },

        removeFavouriteVendor:(vendorSlug) => {
            return removeFavouriteVendor(vendorSlug);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Favourite);
