import React from 'react';
import TopBar from "../core/components/TopBar";
import Balance from './Container/Balance';
import {Auth, NoLogin} from "gg-react-utilities";
import PageTitle from "../core/components/PageTitle";

export default class Rewards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingData : true,
            isLoggedIn:false
        }
    }

    componentDidMount() {

        this.checkAuth();

        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1);
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1);
    }

    render(){
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <PageTitle title="GG Rewards - Global Garner"/>
                        <TopBar title="GG Rewards" />
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }

        return(
            <div className="container">
                <PageTitle title="GG Rewards - Global Garner"/>
                <TopBar title="GG Rewards" />
                <Balance loading={this.state.loadingData}/>
                <div className="page-content-right">
                </div>
            </div>
        );
    }
}