import React from 'react';
import {Button, Classes, Collapse, Dialog, Intent, Position, Toaster} from "@blueprintjs/core";
import Select from "react-select";
import CircleView from './../../container/circleContainer/circleViewContainer';
import Loader from "../../../../core/components/Loader";
import {isNumber} from "@material-ui/core/es/styles/transitions";
import InsertLoader from '../../../../core/images/loader.gif';

export default class circleTree extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            treeViewOpen:false,
            nextChildLists:[],
            maxCirclesAllowed:null,
            circlesData:[],
            isVisibleAddCircleModal: false,
            referralID: '',
            ownUpvsWithoutTag:[],
            isLoading: false,
            circleUpvInformationStatus: false,
            circleUpvInformation:[],
            isReplaceCircleUpv:false,
            ReplaceCircleStatus:false,
            newChildUpvId:null,
            upvReplaceRequestStatus:false,
        }
    }

    componentDidMount() {
        this.setState({
            circlesData:this.props.circlesData,
            treeViewOpen:this.props.treeViewOpen,
            ownUpvsWithoutTag:this.props.ownUpvsWithoutTag
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            treeViewOpen : nextProps.treeViewOpen,
            circlesData : nextProps.circlesData,
            ownUpvsWithoutTag: nextProps.ownUpvsWithoutTag
        })
    }



    openAddCircleModal(upvId,index) {
        if(this.state.ownUpvsWithoutTag && this.state.ownUpvsWithoutTag.length) {
            let title = "Add Circles in UPV Number : " + upvId;
            this.setState({
                ModalTitle: title,
                addUpvID: upvId,
                upvIndex: index,
                isLoading: false,
                isVisibleAddCircleModal: true
            })
        }else{
            Toaster.create({position: Position.TOP}).show({
                message: "Create your circle UPV to add here.",
                intent: Intent.WARNING
            });
        }
    }

    closeAddCircleModal() {
        this.setState({
            isVisibleAddCircleModal: false
        })
    }

    addCircle(upvID, Index) {

        if (this.state.hasReferralIDError || this.state.referralID === '') {
            Toaster.create({position: Position.TOP}).show({
                message: "Please enter referral UPV ID.",
                intent: Intent.DANGER
            });
        } else {
            this.setState({
                isLoading: true
            });
            let obj = {
                "referrer_upv_id": upvID
            }
            this.props.addCircle(this.state.referralID, obj)
                .then((res) => {
                    const sources = res.data;
                    if (sources.status === true) {
                        Toaster.create({position: Position.TOP}).show({
                            message: sources.message,
                            intent: Intent.SUCCESS
                        });
                        this.setState({
                            isLoading:false,
                            //referralID:'',
                            ownUpvsWithoutTag: this.state.ownUpvsWithoutTag.filter(item => item != this.state.referralID)
                        }, () => {
                            let circlesData = this.state.circlesData;

                            if(Index > 0) {
                                this.state.circlesData[Index - 1].child_list.push(sources.data);
                                circlesData[Index - 1].completed_circles += 1;
                            }else{
                                this.state.circlesData[Index].child_list.push(sources.data);
                                circlesData[Index].completed_circles += 1;
                            }

                            this.setState({circlesData});
                            this.closeAddCircleModal();

                            if(Index < 9){
                                this.props.getNextLevel(this.state.referralID, (Index));
                            }
                        })
                    } else {
                        Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                    }

                    this.closeAddCircleModal();

                }).catch((error) => {
                this.setState({
                    referralID: '',
                    isLoading:false
                })
                this.closeAddCircleModal();
                if (error.response && error.response.status !== 401) {

                    Object.keys(error.response.data.error).forEach((key) => {
                        error.response.data.error[key].forEach((error) => {
                            Toaster.create({position: Position.TOP}).show({
                                message: error,
                                intent: Intent.DANGER
                            });
                        });
                    });

                }
            })
        }
    }

    userUpvInformation = (e,upvId,lavel) => {
        e.preventDefault();
        this.setState({
            circleUpvInformationStatus:true,
            upvReplaceRequestStatus:false,
            ReplaceCircleStatus:false,
            upvInformationId:upvId,
            circleUpvInformation:null
        })
        this.props.getUserDetailByUpvId(upvId)
            .then((res) => {
                const sources = res.data;
                if (sources.status === true) {
                    this.setState({
                        circleUpvInformation:sources.data,
                        newChildUpvId:null,
                        isReplaceCircleUpv: lavel === 1 ? true :false
                    })
                } else {
                    Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                }
            }).catch((error) => {
                if (error.response && error.response.status !== 401) {
                    Object.keys(error.response.data.error).forEach((key) => {
                        error.response.data.error[key].forEach((error) => {
                            Toaster.create({position: Position.TOP}).show({
                                message: error,
                                intent: Intent.DANGER
                            });
                        });
                    });
                }
        })

    }

    upvReplaceRequest = () => {
        let request = {
            "old_child_upv_id":this.state.upvInformationId,
            "new_child_upv_id":this.state.newChildUpvId
        }
        this.props.upvReplaceRequest (this.props.upvID,request)
            .then((res) => {
                const sources = res.data;
                if (sources.status === true) {
                    Toaster.create({position: Position.TOP}).show({
                        message: sources.message,
                        intent: Intent.SUCCESS
                    });
                } else {
                    Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                }
                this.setState({
                    circleUpvInformationStatus: false,
                    circleUpvInformation:null,
                    isReplaceCircleUpv:false,
                    ReplaceCircleStatus:false,
                    upvReplaceRequestStatus:false,
                    newChildUpvId:null
                })
            }).catch((error) => {
            if (error.response.status == 401) {
                Toaster.create({position: Position.TOP}).show({
                    message: "Please login to continue",
                    intent: Intent.DANGER
                });
            } else {
                if (error.response && error.response.status !== 401) {
                    Object.keys(error.response.data.error).forEach((key) => {
                        error.response.data.error[key].forEach((error) => {
                            Toaster.create({position: Position.TOP}).show({
                                message: error,
                                intent: Intent.DANGER
                            });
                        });
                    });
                }
            }
            this.setState({
                circleUpvInformationStatus: false,
                circleUpvInformation:null,
                isReplaceCircleUpv:false,
                ReplaceCircleStatus:false,
                upvReplaceRequestStatus:false,
                newChildUpvId:null
            })
        })

    }
    closeCircleUpvInformationModal() {
        this.setState({
            circleUpvInformationStatus: false
        })
    }

    render(){
        const ownUpvsWithoutTag = []
        if(this.state.ownUpvsWithoutTag) {
            this.state.ownUpvsWithoutTag.map((data, key) => {
                ownUpvsWithoutTag.push({value: data, label: 'Referral UPV ID : ' + data});
            })
        }
        return(
            <React.Fragment>
                {
                    this.state.circlesData ?
                        this.state.circlesData.map((res, index) => {
                            return(
                                <div className="pg-orgchart">
                                    <Collapse isOpen={this.state.treeViewOpen} key={index}>
                                        <CircleView
                                            circleData={res}
                                            lavel={(index+1)}
                                            getNextLevelLoading={this.props.getNextLevelLoading}
                                            prevUPVID={this.props.prevUPVID}
                                            getNextLevel={(referralID,Index) => { this.props.getNextLevel(referralID,Index)}}
                                            openAddCircleModal={(upvId,index) => { this.openAddCircleModal(upvId,index)}}
                                            userUpvInformation={(e,upvId,index) => { this.userUpvInformation(e,upvId,index)}}
                                        />
                                    </Collapse>
                                </div>
                            )
                        })
                    : ''
                }
                <Dialog isOpen={this.state.isVisibleAddCircleModal}
                        title={this.state.ModalTitle}
                        className="bidModal"
                        style={{marginTop: '250px'}}
                        onClose={this.closeAddCircleModal.bind(this)}>
                    <div className="bidForm m-3 text-center">
                        <div className="row">
                            <React.Fragment>
                                <div className="col-12">
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={this.state.referralID}
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={false}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="upv"
                                        options={ownUpvsWithoutTag}
                                        onChange={(referralID) => {

                                            if(referralID !== '') {
                                                this.setState({
                                                    referralID: referralID.value,
                                                    hasReferralIDError: false
                                                })
                                            }else{
                                                this.setState({
                                                    referralID: ''
                                                })
                                            }

                                        }}
                                    />
                                </div>
                                <div className="col-12">
                                    {
                                        this.state.isLoading ?
                                            <div className="bidFormBtn mt-2 mb-2">
                                                <img src={InsertLoader} alt={""}
                                                     style={{width: '50px', height: '50px'}}/>
                                            </div>
                                            :
                                            <div className="bidFormBtn mt-4 mb-4">
                                                <Button
                                                    text={"Cancel"}
                                                    intent={"default"}
                                                    onClick={() => {
                                                        this.setState({
                                                            isVisibleAddCircleModal: false
                                                        })
                                                    }}
                                                />
                                                < Button
                                                    text={"Submit"}
                                                    intent={"primary"}
                                                    onClick={() => {
                                                        this.addCircle(this.state.addUpvID, this.state.upvIndex)
                                                    }
                                                    }
                                                />
                                            </div>
                                    }
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                </Dialog>
                <Dialog isOpen={this.state.circleUpvInformationStatus}
                        title={ this.state.upvInformationId !== undefined ? "UPV No. " + this.state.upvInformationId : ''}
                        className="bidModal bp3-dialog-large CircleUpvInformationModal"
                        onClose={this.closeCircleUpvInformationModal.bind(this)}
                >
                    <div className={Classes.DIALOG_BODY+ ' bidForm text-center mb-1'}>
                        <div className="row">
                            {
                                this.state.circleUpvInformation ?
                                    <React.Fragment>
                                        <div className="col-12 text-center">
                                            <img
                                                src={this.state.circleUpvInformation.avatar_url}
                                                alt=""
                                                className="img-fluid img-thumbnail"
                                            />
                                            <h3>{this.state.circleUpvInformation.username}</h3>
                                            <h4>{this.state.circleUpvInformation.email}</h4>
                                            <h4>{this.state.circleUpvInformation.mobile}</h4>
                                        </div>

                                        <div className="col-12 border-top mt-2 px-0">
                                            <div className="d-sm-flex align-items-center justify-content-sm-between">
                                                <p>Generated on: {this.state.circleUpvInformation.created_at}</p>
                                                <p>Added in Circle: {this.state.circleUpvInformation.tagging_date}</p>
                                            </div>
                                        </div>
                                        {
                                            this.state.isReplaceCircleUpv && this.state.ReplaceCircleStatus === false ?
                                                <div className="col-12 border-top mt-2 px-0">
                                                    <div className="mt-4 mb-4">
                                                        <center>
                                                            <Button
                                                                className="bp3-button ml-3"
                                                                text="Replace UPV"
                                                                intent={Intent.PRIMARY}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        ReplaceCircleStatus: !this.state.ReplaceCircleStatus
                                                                    })
                                                                }}
                                                            />
                                                        </center>
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                    </React.Fragment>
                                    :
                                    <div className="col-12 text-center">
                                        <Loader/>
                                    </div>
                            }
                        </div>
                    </div>
                    {
                        this.state.ReplaceCircleStatus === true ?
                            <div className={Classes.DIALOG_FOOTER} >
                                <h3>Replace UPV</h3>
                                <h5>By this option, you can replace your non performing child UPV</h5>
                                <div className="bp3-input-group">
                                    <input
                                        type="number"
                                        className="bp3-input"
                                        required
                                        min={0}
                                        onChange={(e)=>{
                                            if(isNumber(e.target.value)) {
                                                this.setState({
                                                    newChildUpvId: e.target.value
                                                })
                                            }
                                        }}
                                    />
                                    <label className="form-control-placeholder">New child UPV No.</label>
                                </div>

                                <div className="mt-5 d-flex justify-content-end">
                                    {
                                        this.state.upvReplaceRequestStatus ?
                                            <div className="bidFormBtn mt-2 mb-2">
                                                <img src={InsertLoader} alt={""}
                                                     style={{width: '50px', height: '50px'}}/>
                                            </div>
                                            :
                                            <React.Fragment>
                                                <Button
                                                    className="bp3-button bp3-outline bg-white"
                                                    text="Cancel"
                                                    onClick={()=>{
                                                        this.setState({
                                                            ReplaceCircleStatus:!this.state.ReplaceCircleStatus
                                                        })
                                                    }}
                                                />
                                                <Button
                                                    className="bp3-button ml-3"
                                                    text="Replace"
                                                    intent={Intent.PRIMARY}
                                                    onClick={()=>{
                                                        this.setState({
                                                            upvReplaceRequestStatus:true
                                                        },()=>{
                                                            this.upvReplaceRequest();
                                                        })

                                                    }}
                                                />
                                            </React.Fragment>
                                    }
                                </div>
                            </div>
                            :''
                    }
                </Dialog>
            </React.Fragment>
        )
    }
}