import React, { Component } from 'react';
import {Dialog, Button, InputGroup, Toaster, Position, Intent} from "@blueprintjs/core";
import {Helper} from "gg-react-utilities";

export default class BidList extends Component{
    constructor(props){
        super();
        this.state= {
            isSkeleton:true,
            isVisibleBidModal:false,
            upvBidsList:[],
            amount:'',
            hasAmountError:false,
            upvID:"",
            bidID:"",
            isOpenAcceptBidModal:false,
            upvDetail:[],
            isVisibleUpdateBidModal:false
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isSkeleton:false
            })
        }, 1000);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            upvID:nextProps.upvDetail.upv_id,
            upvBidsList:nextProps.upvDetail.upv_bid_list,
            upvDetail:nextProps.upvDetail
        })
    }

    closeModal(){
        this.setState({
            isVisibleBidModal:false
        })
    }

    closeUpdateBidModal(){
        this.setState({
            isVisibleUpdateBidModal:false
        })
    }

    closeAcceptBidModal(){
        this.setState({
            isVisibleAcceptBidModal:false
        })
    }

    addNewBid(){
        if(!this.state.hasAmountError){
            Toaster.create({position: Position.TOP}).show({message:"Please enter bid amount for this UPV.",intent: Intent.DANGER});
        }else {
            let obj = {
                "amount": this.state.amount
            }
            this.props.addNewBid(this.state.upvID, obj)
                .then((res) => {
                    const sources = res.data;
                    if (sources.status === true) {
                        Toaster.create({position: Position.TOP}).show({
                            message: "You have done Bid on UPV number : "+this.state.upvID,
                            intent: Intent.SUCCESS
                        });
                        this.setState({
                            isVisibleBidModal: false
                        })
                        setTimeout(()=>{
                            window.location.reload();
                        },3000)
                    } else {
                        Toaster.create({position: Position.TOP}).show({
                            message: "You are not allowed to Bid on this UPV.",
                            intent: Intent.DANGER
                        });
                    }
                }).catch((error) => {
                    this.setState({
                        amount:""
                    })
                    const sources = error.response;
                    Toaster.create({position: Position.TOP}).show({
                        message: sources.data.error.amount,
                        intent: Intent.DANGER

                });
            })
        }

    }

    updateBid(){
        if(!this.state.hasAmountError){
            Toaster.create({position: Position.TOP}).show({message:"Please enter bid amount for this UPV.",intent: Intent.DANGER});
        }else {
            let obj = {
                "amount": this.state.amount
            };
            this.props.updateBid(this.state.upvID, this.state.bidID, obj)
                .then((res) => {
                    const sources = res.data;
                    if (sources.status === true) {
                        Toaster.create({position: Position.TOP}).show({
                            message: "You have done Bid on UPV number : "+this.state.upvID,
                            intent: Intent.SUCCESS
                        });
                        this.setState({
                            isVisibleUpdateBidModal: false
                        })
                        setTimeout(()=>{
                            window.location.reload();
                        },3000)
                    } else {
                        Toaster.create({position: Position.TOP}).show({
                            message: "You are not allowed to Bid on this UPV.",
                            intent: Intent.DANGER
                        });
                    }
                }).catch((error) => {
                    this.setState({
                        amount:""
                    })
                    const sources = error.response;
                    Toaster.create({position: Position.TOP}).show({
                        message: sources.data.error.amount,
                        intent: Intent.DANGER
                });
            })
        }

    }

    render(){
        const upvData = this.state.upvBidsList;
        const upvDetail = this.state.upvDetail;
        return(
            <React.Fragment>
                <div className="upvBidContainer pt-5 pb-5" style={{minHeight:400}}>
                    <div className="upvTopBar">
                        <h2 className={"m-0"}>Bids</h2>
                        {upvDetail.allow_bid_on_upv ?
                            <Button
                                text={"Place your Bid"}
                                intent={"primary"}
                                onClick={() => {
                                    this.setState({
                                        isVisibleBidModal: true
                                    })
                                }}
                            />
                            :
                            ""
                        }
                    </div>
                    <div className="content">
                        <div className={"row"}>
                            {upvData.length === 0 ?
                                <div className="emptyState">
                                    <img src={require('../../../core/images/empty-state/UPV.svg')} alt=""/>
                                </div>
                                :
                                upvData.map((res, i) => {
                                    return(
                                        <div className={"col-12 col-lg-6 col-md-6 col-sm-6 col-xs-12"} key={i}>
                                            <div
                                                className={this.state.isSkeleton ? "bidBlock p-3 mt-3 bp3-skeleton" : "bidBlock p-3 mt-3"}>
                                                <div className="row">
                                                    <div className="col-12 col-lg-2 col-md-2 col-sm-2">
                                                        <div className="upvRight">
                                                            <img src={require('../../../core/images/svg/user.svg')} alt=""/>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-10 col-md-10 col-sm-10">
                                                        <div className="upvLeft text-left">
                                                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                                                <div>
                                                                    <h4 className="m-0 nameText">{res.sso_username}</h4>
                                                                    <h5 className="m-0 timeText">{res.created_at}</h5>
                                                                    <h4 className="m-0 priceText">{res.bid_amount ? Helper.formatMoney(res.bid_amount) : Helper.formatMoney(0)}</h4>
                                                                </div>
                                                                {res.allow_update_bid_on_upv === 1 ?
                                                                    <div>
                                                                        <Button
                                                                            text={"Update Bid"}
                                                                            intent={"primary"}
                                                                            small={true}
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    upvID:res.upv_id,
                                                                                    bidID:res.id,
                                                                                    isVisibleUpdateBidModal:true
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    ""
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
                <Dialog isOpen={this.state.isVisibleBidModal}
                        title={"Place Your Bid"}
                        className="bidModal"
                        style={{marginTop:'250px'}}
                        onClose={this.closeModal.bind(this)} >
                    <div className="bidForm m-3 text-center pt-4">
                        <div className="row">
                            <div className="col-12">
                                <InputGroup
                                    placeholder="Enter your Bid Amount"
                                    small={false}
                                    type={"number"}
                                    value={this.state.amount}
                                    onChange={(e) => {
                                        this.setState({
                                            amount:e.target.value,
                                            hasAmountError:true
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <div className="bidFormBtn mt-4 mb-4">
                                    <Button
                                        text={"Cancel"}
                                        intent={"default"}
                                        onClick={() => {
                                            this.setState({
                                                amount:"",
                                                isVisibleBidModal:false
                                            })
                                        }}
                                    />
                                    <Button
                                        text={"Submit"}
                                        intent={"primary"}
                                        onClick={() => this.addNewBid()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog isOpen={this.state.isVisibleUpdateBidModal}
                        title={"Update Your Bid"}
                        className="bidModal"
                        style={{marginTop:'250px'}}
                        onClose={this.closeUpdateBidModal.bind(this)} >
                    <div className="bidForm m-3 text-center pt-4">
                        <div className="row">
                            <div className="col-12">
                                <InputGroup
                                    placeholder="Enter your Update Bid Amount"
                                    small={false}
                                    type={"number"}
                                    value={this.state.amount}
                                    onChange={(e) => {
                                        this.setState({
                                            amount:e.target.value,
                                            hasAmountError:true
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <div className="bidFormBtn mt-4 mb-4">
                                    <Button
                                        text={"Cancel"}
                                        intent={"default"}
                                        onClick={() => {
                                            this.setState({
                                                amount:"",
                                                isVisibleUpdateBidModal:false
                                            })
                                        }}
                                    />
                                    <Button
                                        text={"Submit"}
                                        intent={"primary"}
                                        onClick={() => this.updateBid()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog isOpen={this.state.isVisibleAcceptBidModal}
                        title={"Accept Bid"}
                        className="bidModal"
                        style={{marginTop:'250px'}}
                        onClose={this.closeAcceptBidModal.bind(this)} >
                    <div className="bidForm m-3 text-center">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="text-left f-16">Are you sure accept a bid in ₹ 5000.</h4>
                            </div>
                            <div className="col-12">
                                <div className="bidFormBtn mt-4 mb-4">
                                    <Button
                                        text={"Decline"}
                                        intent={"default"}
                                        onClick={() => {
                                            this.setState({
                                                isVisibleBidModal:false
                                            })
                                        }}
                                    />
                                    <Button
                                        text={"Accept"}
                                        intent={"primary"}
                                        onClick={() => this.addNewBid()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}