import {connect} from 'react-redux';
import {getVoucherOrderList} from '../../../core/actions/action';
import CoinVoucher from '../components/CoinVoucherIndex';

const mapStateToProps = state => {

    return{
        voucherOrderList:state.MegaBrandsOrders.voucherOrderList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getVoucherOrderList:(params) => {
            return getVoucherOrderList(params);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoinVoucher);
