import React, { Component } from 'react';
import {getFPVRPPCurrentListAction, getFPVRPPOldListAction} from "../../../core/actions";
import {Intent, Position, Toaster} from "@blueprintjs/core";
import {Helper} from "gg-react-utilities";

export default class RPP extends Component{

    constructor(props){
        super(props);
        this.state={
            isVisibleMilestone:false,
            selectedOption:true,
            isSkeleton:true,
            oldRPPData:[],
            currentRPPData:[],
            currentCounter:1,
            oldCounter:1,
            scrollLoader:true,
            last_page:1,
            fpvRPPCurrentList:[],
            fpvRPPOldList:[],
            totalCurrentList:null,
            totalOldList:null
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.getFPVRPPCurrentList();
            this.getFPVRPPOldList();
        }, 1000)
    }

    getFPVRPPCurrentList = (page) => {
        this.props.getFPVRPPCurrentList(page)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    let currentRPPData = this.state.currentRPPData;
                    let updateCurrentRPPData = sources.data.rpp.data;
                    let updateCurrentData = currentRPPData.concat(updateCurrentRPPData);
                    this.setState({
                        fpvRPPCurrentList:sources.data,
                        totalCurrentList:sources.data.rpp.total,
                        currentRPPData: updateCurrentData,
                        isSkeleton: false
                    })
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                }
            }).catch((error)=>{
            Toaster.create({position: Position.TOP}).show({message:error,intent: Intent.DANGER});
        })
    }

    getFPVRPPOldList = (page) => {
        this.props.getFPVRPPOldList(page)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    let oldRPPData = this.state.oldRPPData;
                    let updateOldRPPData = sources.data.rpp.data;
                    let updateOldData = oldRPPData.concat(updateOldRPPData);
                    this.setState({
                        fpvRPPOldList:sources.data,
                        totalOldList:sources.data.rpp.total,
                        oldRPPData: updateOldData,
                        isSkeleton: false
                    })
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                }
            }).catch((error)=>{
            Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
        })
    }


    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            this.state.selectedOption ?
                this.loadMoreForCurrentRPP()
                :
                this.loadMoreForOldRPP()
        }
    }

    loadMoreForCurrentRPP = () =>{
        this.setState({
            scrollLoader:false
        });
        setTimeout(() => {
            this.setState({
                currentCounter: this.state.currentCounter + 1,
                isLoading: true,
                scrollLoader:true
            }, () => {
                this.getFPVRPPCurrentList(this.state.currentCounter);
            })
        },1000)
    }

    loadMoreForOldRPP = () =>{
        this.setState({
            scrollLoader:false
        });
        setTimeout(() => {
            this.setState({
                oldCounter: this.state.oldCounter + 1,
                isLoading: true,
                scrollLoader:true
            }, () => {
                this.getFPVRPPOldList(this.state.oldCounter);
            })
        },1000)
    }

    render(){
        let coin = '';
        let money = '';
        let total = '';
        const {fpvRPPOldList,fpvRPPCurrentList} = this.state;
        if(fpvRPPOldList!==null || fpvRPPCurrentList!==null ){
            coin = this.state.selectedOption?fpvRPPCurrentList.rpp_coin_cashback:fpvRPPOldList.rpp_coin_cashback;
            money = this.state.selectedOption?fpvRPPCurrentList.rpp_money_cashback:fpvRPPOldList.rpp_money_cashback;
            total = this.state.selectedOption?this.state.totalCurrentList:this.state.totalOldList;
        }
        return(
            <React.Fragment>
                <div className="fifo mt-4">
                    <div className="fifoWrapper">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="fifoRPPSection">
                                    <div className="icon">
                                        <img src={require('../../images/rupee.svg')} alt="" />
                                    </div>
                                    <div className="count">
                                        <h3 className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>{Helper.formatMoney(coin)}</h3>
                                        <p>Fuel Coin Cashback</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-s-4">
                                <div className="fifoRPPSection">
                                    <div className="icon">
                                        {/*<img src={require('../../images/QR.svg')} alt="" />*/}
                                        <img src={require('../../images/check.svg')} alt="" />
                                    </div>
                                    <div className="count">
                                        <h3 className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>{Helper.formatMoney(money)}</h3>
                                        <p>Fuel Money Cashback</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-s-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0">
                                <div className="fifoRPPSection">
                                    <div className="icon">
                                        <img src={require('../../images/QR.svg')} alt="" />
                                        {/*<img src={require('../../images/Movie.svg')} alt="" />*/}
                                    </div>
                                    <div className="count">
                                        <h3 className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>{total}</h3>
                                        <p>Fuel Cashback Count</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 text-left">
                        <label className="bp3-control bp3-switch bp3-large pl-0 bp3-text-medium">
                            Current Month
                            <input type="checkbox" checked={this.state.selectedOption===true ? "checked":""} onChange={() =>{
                                this.setState({
                                    selectedOption:!this.state.selectedOption,
                                    isSkeleton:true,
                                    currentRPPData:[],
                                    oldRPPData:[],
                                    scrollLoader:true,
                                    currentCounter:1,
                                    oldCounter:1,
                                    last_page:1
                                },()=>{
                                    this.state.selectedOption===true ?
                                        setTimeout(() => {
                                            this.getFPVRPPCurrentList()
                                        }, 1000)
                                        :
                                        setTimeout(() => {
                                            this.getFPVRPPOldList()
                                        }, 1000)
                                })
                            }}/>
                            <span className="bp3-control-indicator ml-2"> </span>
                        </label>
                    </div>
                    <div style={{height:'100%',maxHeight:'590px',overflowY:'scroll'}} id={"allBooking"} onScroll={this.handleScroll} className="table-responsive mt-4">
                        <table className="bp3-html-table bp3-html-table-bordered">
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>FPV ID</th>
                                <th>CB Value</th>
                                <th>Created Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.selectedOption===true ?
                                this.state.isSkeleton===true ?
                                    <tr>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>1</span></td>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>70</span></td>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>12356</span></td>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>16 Aug 2928</span></td>
                                    </tr>
                                    :
                                    this.state.currentRPPData.length===0 ?
                                        <tr>
                                            <td colSpan="4" className="emptyState">
                                                <img src={require('../../../core/images/empty-state/fuel-cashback.svg')} alt=""/>
                                                <p className="mt-3">Oops! You don’t have any FPV under RPP process. Use this option to get 2% to 100% cash back value in a month.</p>
                                            </td>
                                        </tr>
                                        :
                                        this.state.currentRPPData.map((res, index) =>{
                                            return(
                                                <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>{res.fpv_id}</td>
                                                    <td>{res.cb_value}</td>
                                                    <td>{res.created_at}</td>
                                                </tr>
                                            );
                                        })
                                :
                                this.state.isSkeleton===true ?
                                    <tr>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>1</span></td>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>70</span></td>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>12356</span></td>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>16 Aug 2928</span></td>
                                    </tr>
                                    :
                                    this.state.oldRPPData.length === 0 ?
                                        <tr>
                                            <td colSpan="4" className="emptyState">
                                                <img src={require('../../../core/images/empty-state/fuel-cashback.svg')} alt=""/>
                                                <p className="mt-3">Oops! You don’t have any FPV under RPP process. Use this option to get 2% to 100% cash back value in a month.</p>
                                            </td>
                                        </tr>
                                        :
                                        this.state.oldRPPData.map((res, index) =>{
                                            return(
                                                <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>{res.fpv_id}</td>
                                                    <td>{res.cb_value}</td>
                                                    <td>{res.created_at}</td>
                                                </tr>
                                            );
                                        })

                            }
                            </tbody>
                        </table>
                    </div>
                    {this.state.selectedOption ?
                        this.state.last_page < this.state.currentCounter ? null :
                            this.state.scrollLoader ?
                                null :
                                <React.Fragment>
                                    <center>
                                        <div className="my-3">
                                            <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                        :
                        this.state.last_page < this.state.oldCounter ? null :
                            this.state.scrollLoader ?
                                null :
                                <React.Fragment>
                                    <center>
                                        <div className="my-3">
                                            <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }
}