import React, { Component } from 'react';
import {Dialog, Button, InputGroup, Toaster, Position, Intent} from "@blueprintjs/core";

export default class BidList extends Component{
    constructor(props){
        super();
        this.state= {
            isSkeleton:true,
            isVisibleBidModal:false,
            upvBidsList:[],
            amount:'',
            hasAmountError:false,
            upvID:"",
            isOpenAcceptBidModal:false,
            status:"",
            bidID:""
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isSkeleton:false
            })
        }, 1000);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            upvID:nextProps.upvDetail.upv_id,
            upvBidsList:nextProps.upvDetail.upv_bid_list,
            status:nextProps.upvDetail.bid_status
        })
    }

    closeModal(){
        this.setState({
            isVisibleBidModal:false
        })
    }
    closeAcceptBidModal(){
        this.setState({
            isVisibleAcceptBidModal:false
        })
    }

    // addNewBid(){
    //     let obj = {
    //         "amount":this.state.amount
    //     }
    //     this.props.addNewBid(this.state.upvID, obj)
    //         .then((res)=>{
    //             const sources = res.data;
    //             if(sources.status===true){
    //                 Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
    //                 this.setState({
    //                     isVisibleBidModal:false
    //                 })
    //             }else{
    //                 Toaster.create({position: Position.TOP}).show({message:"You are not allowed to Bid on this UPV.",intent: Intent.DANGER});
    //             }
    //         }).catch((error)=>{
    //         Toaster.create({position: Position.TOP}).show({message:"You are not allowed to Bid on this UPV.",intent: Intent.DANGER});
    //     })
    //
    // }


    acceptBid(bidID){
        let obj = {
            "amount":this.state.amount
        }
        this.props.acceptBid(this.state.upvID, bidID, obj)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    this.setState({
                        isVisibleAcceptBidModal:false
                    })
                    setTimeout(() => {
                        window.location.reload();
                    },3000)
                }else{
                    Toaster.create({position: Position.TOP}).show({message:"You are not allowed to Bid on this UPV.",intent: Intent.DANGER});
                }
            }).catch((error)=>{
            Toaster.create({position: Position.TOP}).show({message:"You are not allowed to Bid on this UPV.",intent: Intent.DANGER});
        })

    }

    render(){
        const upvData = this.state.upvBidsList
        return(
            <React.Fragment>
                <div className="upvBidContainer pt-5 pb-5" style={{minHeight:400}}>
                    <div className="upvTopBar">
                        <h2 className={"m-0"}>Bids</h2>
                    </div>
                    <div className="content">
                        <div className={"row"}>
                            {upvData.length === 0 ?
                                <div className="emptyState">
                                    <img src={require('../../../core/images/empty-state/UPV.svg')} alt=""/>
                                </div>
                                :
                                upvData.map((res, i) => {
                                    let status;
                                    let intent;
                                    if(res.status==="ACCEPTED_BY_SELLER" && this.state.status==="ON_HOLD"){
                                        intent="success";
                                        status="Accepted Bid";
                                    }else if(res.status==="PENDING" && this.state.status==="OPEN"){
                                        status="Accept Bid";
                                        intent="primary";
                                    }else{
                                        status="Cancelled";
                                        intent="danger"
                                    }
                                    return(
                                        <div className={"col-12 col-lg-6 col-md-6 col-sm-6 col-xs-12"} key={i}>
                                            <div
                                                className={this.state.isSkeleton ? "bidBlock p-3 mt-3 bp3-skeleton" : "bidBlock p-3 mt-3"}>
                                                <div className="row">
                                                    <div className="col-12 col-lg-2 col-md-2 col-sm-2">
                                                        <div className="upvRight">
                                                            <img src={require('../../../core/images/svg/user.svg')}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-10 col-md-10 col-sm-10">
                                                        <div className="upvLeft text-left">
                                                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                                                <div>
                                                                    <h4 className="m-0 nameText">{res.sso_username}</h4>
                                                                    <h5 className="m-0 timeText">{res.created_at}</h5>
                                                                    <h4 className="m-0 priceText">₹ {res.bid_amount ? res.bid_amount : "0.00"}</h4>
                                                                </div>
                                                                <Button className={"m-0 f-16"}
                                                                        text={status}
                                                                        intent={intent}
                                                                        style={{height:35,padding: '5px 15px'}}
                                                                        onClick={()=> {
                                                                            if(res.status==="PENDING" && this.state.status==="OPEN"){
                                                                                this.setState({
                                                                                    isVisibleAcceptBidModal: true,
                                                                                    bidID:res.id
                                                                                })
                                                                            }
                                                                        }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
                {/*<Dialog isOpen={this.state.isVisibleBidModal}*/}
                {/*        title={"Place Your Bid"}*/}
                {/*        className="bidModal"*/}
                {/*        style={{marginTop:'250px'}}*/}
                {/*        onClose={this.closeModal.bind(this)} >*/}
                {/*    <div className="bidForm m-3 text-center pt-5">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-12">*/}
                {/*                <InputGroup*/}
                {/*                    placeholder="Enter your Bid Amount"*/}
                {/*                    small={false}*/}
                {/*                    type={"number"}*/}
                {/*                    value={this.state.amount}*/}
                {/*                    onChange={(e) => {*/}
                {/*                        this.setState({*/}
                {/*                            amount:e.target.value,*/}
                {/*                            hasAmountError:true*/}
                {/*                        })*/}
                {/*                    }}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*            <div className="col-12">*/}
                {/*                <div className="bidFormBtn mt-4 mb-4">*/}
                {/*                    <Button*/}
                {/*                        text={"Cancel"}*/}
                {/*                        intent={"default"}*/}
                {/*                        onClick={() => {*/}
                {/*                            this.setState({*/}
                {/*                                isVisibleBidModal:false*/}
                {/*                            })*/}
                {/*                        }}*/}
                {/*                    />*/}
                {/*                    <Button*/}
                {/*                        text={"Submit"}*/}
                {/*                        intent={"primary"}*/}
                {/*                        onClick={() => this.addNewBid()}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Dialog>*/}

                <Dialog isOpen={this.state.isVisibleAcceptBidModal}
                        title={"Accept Bid"}
                        className="bidModal"
                        style={{marginTop:'250px'}}
                        onClose={this.closeAcceptBidModal.bind(this)} >
                    <div className="bidForm m-3 text-center">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="text-left f-16">Are you sure you want to accept the bid ?</h4>
                            </div>
                            <div className="col-12">
                                <div className="bidFormBtn mt-4 mb-4">
                                    <Button
                                        text={"Cancel"}
                                        intent={"default"}
                                        onClick={() => {
                                            this.setState({
                                                isVisibleAcceptBidModal:false
                                            })
                                        }}
                                    />
                                    <Button
                                        text={"Accept"}
                                        intent={"primary"}
                                        onClick={() => this.acceptBid(this.state.bidID)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}