import React,{Component, Fragment} from 'react';
import TopBar from "../core/components/TopBar";
import PageTitle from "../core/components/PageTitle";
import SendMoney from "./container/withdrawContainer";
import Request from "./container/requestContainer";
import {Auth, NoLogin, Error} from "gg-react-utilities";

export default class Withdraw extends Component {
    constructor(props){
        super(props);
        this.state={
            isLoggedIn:false
        }
    }

    componentDidMount(){
        this.checkAuth();
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1);
    }

    render(){
        if(this.props.location.state!==undefined){

        };
        try {
            return (
                <Fragment>
                    {!Auth.isLoggedIn()?
                        <div className="container mb-5">
                            <div className="upvWrapper">
                                <PageTitle title="Withdraw Request - Global Garner"/>
                                <TopBar title="Send / Pay Money to Bank" />
                                <div className="upvContainer" style={{padding:'200px 0' }}>
                                    <NoLogin homeLink={false}/>
                                </div>
                            </div>
                        </div>
                        :
                        this.props.location.state!==undefined ?
                            <div className="container">
                                <PageTitle title="Withdraw Request - Global Garner"/>
                                <TopBar title="Send / Pay Money to Bank"/>
                                <SendMoney minBalance={this.props.location.state.moneyBalance}/>
                                <Request/>
                            </div>
                             :<Error />
                    }
                </Fragment>

            )
        } catch (e) {
            return(
                <Error />
            )

        }

    }
}