import React from 'react';
import PageTitle from "../../../core/components/PageTitle";
import TopBar from "../../../core/components/TopBar";
import {Tab, Tabs} from "@blueprintjs/core";
import UpvReplaceInbox from "../container/UpvReplaceInboxContainer";
import UpvReplaceSent from "../container/UpvReplaceSentContainer";

export default class upvReplaceList extends React.Component{

    constructor(props){
        super(props);
        this.state = {
        }
    }

    render() {
        return(
            <div className="container mb-5">
                <PageTitle title="UPV Cashback - Global Garner"/>
                <div className="upvWrapper">
                    <div className="exchangeTopBar">
                        <TopBar
                            title={"Replace UPV Approvals"}
                        />
                    </div>
                    <div className="upvContainer">
                        <div className="justify-content-center d-flex">
                            <div className="col-xl-10 col-lg-10 col-md-11 col-sm-12 col-12 px-s-0 upv-cashback">
                                <Tabs
                                    animate={false}
                                    id="cashbackTabs"
                                    renderActiveTabPanelOnly={true} vertical={false}>
                                    <Tab id="fifo" title="Inbox" panel={<UpvReplaceInbox />} />
                                    <Tab id="rpp" title="Sent" panel={<UpvReplaceSent />} />
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}