import {connect} from "react-redux";
import {myRequestGgCoinList} from '../../../core/actions/action';
import MoneyRequest from '../Components/MoneyRequest';
const mapStateToProps = state =>{

    return{
        requestCoinList:state.CoinList.requestCoinList,
    }

}

const mapDispatchToProps = dispatch =>{
    return{
        myRequestGgCoinList : (params) =>{
            return myRequestGgCoinList(params);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoneyRequest)