import {connect} from 'react-redux';
import CircleView from '../../components/circle_components/circleView';

const mapDispatchToProps = dispatch => {

    return {

    }
}

export default connect(null, mapDispatchToProps)(CircleView);
