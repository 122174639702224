import React, { Component } from 'react';
import {Button, Dialog, Intent, Position, Toaster} from "@blueprintjs/core";
import {Link} from "react-router-dom";
import {Helper} from "gg-react-utilities";


export default class Bids extends Component{
    constructor(props){
        super();
        this.state= {
            sort:"",
            isSkeleton:true,
            myBidsList:[],
            scrollLoader:true,
            counter:1,
            isLoading:false,
            isVisiblePaymentModal:false,
            isVisibleDeclineModal:false,
            bidID:"",
            upvID:"",
            loading:false,
            platform:"WEB",
            bidDetail:"",
            path:""
        }
    }

    componentDidMount() {
        this.getMyBidsList(this.state.counter);
        setTimeout(() => {
            this.setState({
                isSkeleton:false
            })
        }, 1000);
        window.addEventListener('scroll', this.handleOnScroll);
    }

    getMyBidsList(page){
        this.props.getMyBidsList(page)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    let oldData = this.state.myBidsList;
                    let newDataFromApi = sources.data.data;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState({
                        myBidsList:newData,
                        last_page:sources.data.last_page,
                    })
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                }
            }).catch((error)=>{
            Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
        })
    }

    handleOnScroll=()=> {
        let scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        let scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        let clientHeight = document.documentElement.clientHeight || window.innerHeight;
        let scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

        if (scrolledToBottom) {
            this.loadMore();
        }
    }

    loadMore = () =>{
        this.setState({
            scrollLoader:false
        });
        setTimeout(() => {
            this.setState({
                counter: this.state.counter + 1,
                isLoading: true,
                scrollLoader:true
            }, () => {
                this.getMyBidsList(this.state.counter);
            })
        },1000)
    }

    closePaymentModal(){
        this.setState({
            isVisiblePaymentModal:false
        })
    }

    closeDeclineModal(){
        this.setState({
            isVisibleDeclineModal:false
        })
    }

    declineBid(){
        this.props.declineBid(this.state.upvID, this.state.bidID)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    this.setState({
                        isVisibleAcceptBidModal:false
                    })
                }else{
                    Toaster.create({position: Position.TOP}).show({message:"You are not allowed to Bid on this UPV.",intent: Intent.DANGER});
                }
            }).catch((error)=>{
            Toaster.create({position: Position.TOP}).show({message:"You are not allowed to Bid on this UPV.",intent: Intent.DANGER});
        })

    }


    acceptPayment = async () => {
        if(this.state.upvID===""){
            Toaster.create({position: Position.TOP}).show({message:'Invalid UPV ID',intent: Intent.DANGER});
            return false;
        }
        if(this.state.bidID!==""){

            let bidRequest = {
                "bid_id":this.state.bidID,
                "upv_id":this.state.upvID,
                "platform":this.state.platform
            }

            this.setState({loading: true});

            this.props.getTransactionID(bidRequest).then((res) => {

                this.setState({loading: false});

                const resdata = res.data;

                if (resdata.status === true) {

                    const PayUBiz = resdata.data.pg;
                    let form = document.createElement("form");
                    form.setAttribute("method", "POST");
                    form.setAttribute("action", PayUBiz.url);

                    for (let key in PayUBiz) {
                        let hiddenField = document.createElement("input");
                        hiddenField.setAttribute("type", "hidden");
                        hiddenField.setAttribute("name", key);
                        hiddenField.setAttribute("value", PayUBiz[key]);
                        form.appendChild(hiddenField);
                    }
                    document.body.appendChild(form);
                    form.submit();
                } else {
                    Toaster.create({position: Position.TOP,}).show({message: resdata.message, intent: Intent.DANGER});
                }

            }).catch((error) => {
                this.setState({loading: false});
                const sources = error.response
                if (sources.data.message === "Validation Error") {
                    sources.data.error.map((res) => {
                        Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                        return res
                    })
                } else {
                    Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                }
            });
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="bidsWrapper text-right">
                    <Link to="/bids-transaction">
                        <Button
                            text={"My Bidding Transactions"}
                            intent={"primary"}
                            onClick={() => {
                                // window.location.href="/bids-transaction"
                            }}
                        />
                    </Link>
                    <div className="content mt-lg-4">
                        <div className="row">
                            {this.state.myBidsList.length === 0 ?
                                <div className="emptyState">
                                    <img src={require('../../core/images/empty-state/UPV.svg')} alt=""/>
                                </div>
                                :
                                this.state.myBidsList.map((res, i) => {
                                    return (
                                        <div className={"col-12 col-lg-6 col-md-6 col-sm-6 col-xs-12"} key={i}>
                                            { res.status==="ACCEPTED_BY_SELLER" ?
                                                <Link to={{ pathname: '/bid-payment', state: { upv: res}  }}>
                                                    <div className={this.state.isSkeleton ? "bidBlock p-3 mt-3 bp3-skeleton" : "bidBlock p-3 mt-3 shadowBlock"}>
                                                        <div className="row">
                                                            <div className="col-12 col-lg-2 col-md-2 col-sm-2">
                                                                <div className="upvRight">
                                                                    <h5 className={"m-0"}>UPV No</h5>
                                                                    <h4 className={"m-0"}>{res.upv_id}</h4>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-10 col-md-10 col-sm-10">
                                                                <div className="upvLeft text-left ">
                                                                    <div className={"topbar"}>
                                                                        <div>
                                                                            <h4 className="m-0 nameText">{res.sso_username}</h4>
                                                                            <h4 className="m-0 priceText">{res.bid_amount ? Helper.formatMoney(res.bid_amount) : Helper.formatMoney(0)}</h4>
                                                                            <h5 className="m-0 timeText">{res.created_at}</h5>
                                                                        </div>
                                                                        <div className={"text-right"}>
                                                                            {res.status==="ACCEPTED_BY_SELLER" ?
                                                                                <h4 className={"text-success m-0 f-16"} style={{fontWeight:'500'}}>{"Accepted Bid"}</h4>
                                                                                :
                                                                                ""
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                                :
                                                    <div className={this.state.isSkeleton ? "bidBlock p-3 mt-3 bp3-skeleton" : "bidBlock p-3 mt-3"}>
                                                        <div className="row">
                                                            <div className="col-12 col-lg-2 col-md-2 col-sm-2">
                                                                <div className="upvRight">
                                                                    <h5 className={"m-0"}>UPV No</h5>
                                                                    <h4 className={"m-0"}>{res.upv_id}</h4>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-10 col-md-10 col-sm-10">
                                                                <div className="upvLeft text-left ">
                                                                    <div className={"topbar"}>
                                                                        <div>
                                                                            <h4 className="m-0 nameText">{res.sso_username}</h4>
                                                                            <h4 className="m-0 priceText">₹ {res.bid_amount ? res.bid_amount : "0.00"}</h4>
                                                                            <h5 className="m-0 timeText">{res.created_at}</h5>
                                                                        </div>
                                                                        <div className={"text-right"}>
                                                                            {res.status==="ACCEPTED_BY_SELLER" ?
                                                                                <h4 className={"text-success m-0 f-16"} style={{fontWeight:'500'}}>{"Accepted Bid"}</h4>
                                                                                :
                                                                                ""
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                        </div>
                                    );
                                })
                            }
                        </div>
                        {
                            this.state.last_page <= this.state.counter ? null :
                                this.state.scrollLoader ?
                                    null :
                                    <React.Fragment>
                                        <center>
                                            <div className="my-3">
                                                <img src={require('../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                            </div>
                                        </center>
                                    </React.Fragment>

                        }
                    </div>
                </div>

                <Dialog isOpen={this.state.isVisiblePaymentModal}
                        title={"₹ "+this.state.bidDetail.bid_amount}
                        className="bidModal"
                        style={{marginTop:'250px'}}
                        onClose={this.closePaymentModal.bind(this)} >
                    <div className="bidForm m-3 text-center">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="text-left f-16">Are you sure you want to <strong>PAY</strong> for UPV Number <strong>{this.state.bidDetail.upv_id}</strong> Bid?</h4>
                            </div>
                            <div className="col-12">
                                <div className="bidFormBtn mt-4 mb-4">
                                    <Button
                                        text={"Cancel"}
                                        intent={"default"}
                                        onClick={() => {
                                            this.setState({
                                                isVisiblePaymentModal:false
                                            })
                                        }}
                                    />
                                    <Button
                                        text={"Pay Now"}
                                        intent={"primary"}
                                        loading={this.state.loading}
                                        onClick={() => this.acceptPayment()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog isOpen={this.state.isVisibleDeclineModal}
                        title={"₹ "+this.state.bidDetail.bid_amount}
                        className="bidModal"
                        style={{marginTop:'250px'}}
                        onClose={this.closeDeclineModal.bind(this)} >
                    <div className="bidForm m-3 text-center">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="text-left f-16">Are you sure you want to <strong>DECLINE</strong> UPV Number <strong>{this.state.bidDetail.upv_id}</strong> Bid?</h4>
                            </div>
                            <div className="col-12">
                                <div className="bidFormBtn mt-4 mb-4">
                                    <Button
                                        text={"Cancel"}
                                        intent={"default"}
                                        onClick={() => {
                                            this.setState({
                                                isVisibleDeclineModal:false
                                            })
                                        }}
                                    />
                                    <Button
                                        text={"Decline"}
                                        intent={"primary"}
                                        onClick={() => this.declineBid()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}