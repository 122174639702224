import React, {Component} from 'react';
import {Button, Dialog, Classes} from "@blueprintjs/core";
import VoucherList from '../../Megabrands/Container/VoucherDetailsContainer';

export default class ViewCoinVoucher extends Component{
    constructor(props) {
        super(props);

        this.state = {
            page:1,
            stopLoadMore: false,
            showEmptyStats:false,
            isOpen: false,
        }

        this.handleClose = this.handleClose.bind(this)
    }

    componentDidMount() {
    }

    handleClose(){
        this.setState({ isOpen: false });
    }


    renderStatusHtml(status){

        switch (status) {
            case 0:
                return <label className='bp3-text-warning'>Awaiting Payment</label>;
            case 1:
                return <label className='bp3-text-success'>Successful</label>;
            case 2:
                return <label className='bp3-text-danger'>Failed</label>;
            case 3:
                return <label className='bp3-text-info'>Refunded</label>;
            default:
                return <label className="label text-default">--</label>;
        }
    }


    render(){
        return(
            <React.Fragment>
                <Button small={true} intent="primary" icon="eye-open" title="View" className="btn-view mr-2"
                        onClick={()=>{
                            this.setState({isOpen:true})
                        }}/>
                <Dialog isOpen={this.state.isOpen}
                        onClose={() => this.handleClose()}
                        className="bp3-dialog-large"
                        title="Coin Vouchers Voucher Order Details"
                        canOutsideClickClose={true}
                        canEscapeKeyClose={true}
                >
                    <div className={Classes.DIALOG_BODY + " popup-voucher"}>
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <VoucherList voucherId={this.props.voucherId}/>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}