import React from 'react';
import {Icon, Intent} from "@blueprintjs/core";
import Circle from './circle';

export default class circleView extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isCircleLoding:false,
            prevUPVID:[],
            circleData:[],
            lavel:null
        }

    }

    componentDidMount() {
        this.setState({
            circleData:this.props.circleData,
            prevUPVID:this.props.prevUPVID,
            lavel:this.props.lavel
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            circleData : nextProps.circleData,
            prevUPVID : nextProps.prevUPVID,
            lavel : nextProps.lavel,
        })
    }

    render() {
        if(this.state.circleData && this.state.prevUPVID) {
            return (
                <React.Fragment>
                    <div className="org-chart">
                        <ul>
                            <li>
                                {
                                    this.state.lavel === 1 ?
                                        <div className="user">
                                            <Icon icon="document" iconSize={18} intent={Intent.PRIMARY}/>
                                            <span className="badge">{this.state.circleData.upv_id}</span>
                                        </div>
                                    :
                                        <a  onContextMenu={(e) =>{ this.props.userUpvInformation(e,this.state.circleData.upv_id,this.state.lavel === 2 ? 1 : this.state.lavel)}} className="user">
                                            <Icon icon="document" iconSize={18} intent={Intent.PRIMARY}/>
                                            <span className="badge">{this.state.circleData.upv_id}</span>
                                        </a>
                                }
                                <ul>
                                    <li>
                                        {
                                            this.state.circleData.child_list ?
                                                this.state.circleData.child_list.map((res, i) => {
                                                    if(this.state.lavel <= 8) {
                                                        if (this.state.prevUPVID.find((e) => e == res.id) !== undefined) {
                                                            return (
                                                                <Circle key={i}
                                                                    circleClass="active"
                                                                    nextLavelUpvFunction={true}
                                                                    userInformationFunction={false}
                                                                    addUpvCircleFunction={false}

                                                                    getNextLevelLoading={this.props.getNextLevelLoading}
                                                                    upvId={res.id}
                                                                    lavel={this.state.lavel}
                                                                    getNextLevel={(upvId,lavel) =>{ this.props.getNextLevel(upvId,lavel)}}
                                                                />
                                                            )
                                                        } else {
                                                            return (
                                                                <Circle key={i}
                                                                        circleClass=""
                                                                        nextLavelUpvFunction={true}
                                                                        userInformationFunction={true}
                                                                        addUpvCircleFunction={false}

                                                                        getNextLevelLoading={this.props.getNextLevelLoading}
                                                                        upvId={res.id}
                                                                        lavel={this.state.lavel}
                                                                        getNextLevel={(upvId,lavel) =>{ this.props.getNextLevel(upvId,lavel)}}
                                                                        userUpvInformation={(e,upvId,lavel) =>{ this.props.userUpvInformation(e,upvId,lavel)}}
                                                                />
                                                            )
                                                        }
                                                    }else {
                                                        return (
                                                            <Circle key={i}
                                                                    circleClass=""
                                                                    nextLavelUpvFunction={false}
                                                                    userInformationFunction={true}
                                                                    addUpvCircleFunction={false}

                                                                    upvId={res.id}
                                                                    lavel={this.state.lavel}
                                                                    userUpvInformation={(e,upvId,lavel) =>{ this.props.userUpvInformation(e,upvId,lavel)}}
                                                            />
                                                        )
                                                    }
                                                })
                                            :''
                                        }
                                        {
                                            this.state.circleData.child_list ?
                                                [...Array((this.state.circleData.max_circles_allowed - this.state.circleData.completed_circles))].map((e, i) => {
                                                    return (
                                                        <Circle key={i}
                                                                circleClass=""
                                                                nextLavelUpvFunction={false}
                                                                userInformationFunction={false}
                                                                addUpvCircleFunction={true}

                                                                upvId={this.state.circleData.upv_id}
                                                                lavel={this.state.lavel}
                                                                openAddCircleModal={(e,upvId,lavel) =>{ this.props.openAddCircleModal(e,upvId,lavel)}}
                                                        />
                                                    )
                                                })
                                            :''
                                        }
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </React.Fragment>
            )
        }
    }
}