import React from 'react';
import {Helper} from 'gg-react-utilities';
import totalShopping from '../../../core/images/stats/Group12.png';
import extraEarnings from '../../../core/images/stats/Group13.png';
import upvStatus from '../../../core/images/stats/Group14.png';
import fpvStatus from '../../../core/images/stats/Group15.png';
import totalCashback from '../../../core/images/stats/GGStates16.png';
import outline from '../../../core/images/stats/outline-shopping_basket-24px.svg'
import wallet from '../../../core/images/stats/outline-account_balance_wallet-24px.svg'
import gasDtation from '../../../core/images/stats/outline-local_gas_station-24px.svg'
import rupee from '../../../core/images/stats/₹.svg'
import {Link} from "react-router-dom";
import {Tab, Tabs} from "@blueprintjs/core";


export default class AllStatus extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        }
    }

    componentDidMount(){
        this.setState( {
            loading: this.props.loading,
         })
    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })

    }

    render(){
        const total_shoppings = {backgroundImage: `url(${totalShopping})`};
        const extra_earnings = {backgroundImage: `url(${extraEarnings})`};
        const upv_status = {backgroundImage: `url(${upvStatus})`};
        const fpv_status = {backgroundImage: `url(${fpvStatus})`};
        const total_cashback = {backgroundImage: `url(${totalCashback})`,height:"340px"};

        return(
            <React.Fragment>
                {
                    this.state.loading ?
                        <div className="col-lg-3 col-lg-3 col-sm-6 col-md-4 col-12 mb-3 totalShopping mt-s-4">
                            <Link to='/orders' style={{color: "#ffffff"}}>
                                <div className="bg-img" style={total_shoppings}>
                                    <div className="col-12 mt-3"><img src={outline} alt=""/></div>
                                    <div className="col-12 mt-4 title">My Total Shopping</div>
                                    <div className="col-12 mt-5 amount"> <img src={rupee} alt=""/> <span className="bp3-skeleton">1000000</span></div>
                                    <div className="col-12 mt-2 heading">My Online Shopping</div>
                                    <div className="col-12 mt-5 amount"> <img src={rupee} alt=""/> <span className="bp3-skeleton">1000000</span></div>
                                    <div className="col-12 mt-2 heading mb-3">My Offline Shopping</div>
                                </div>
                            </Link>
                        </div>
                        :
                        <div className="col-lg-3 col-lg-3 col-sm-6 col-12 col-md-4 mb-3 totalShopping mt-s-4">
                            <Link to='/orders' style={{color: "#ffffff"}}>
                                <div className="bg-img" style={total_shoppings}>
                                    <div className="col-12 mt-3"><img src={outline} alt=""/></div>
                                    <div className="col-12 mt-4 title">My Total Shopping</div>
                                    <div className="col-12 mt-5 amount">{Helper.formatMoney(this.props.myOnlineShopping)}</div>
                                    <div className="col-12 mt-2 heading">My Online Shopping</div>
                                    <div className="col-12 mt-5 amount">{Helper.formatMoney(this.props.myOfflineShopping)}</div>
                                    <div className="col-12 mt-2 heading mb-3">My Offline Shopping</div>
                                </div>
                            </Link>
                        </div>

                }

                {
                    this.state.loading ?
                        <div className="col-lg-3 col-lg-3 col-sm-6 col-12 col-md-4 mb-3 extraEarnings mt-s-4">
                            <Link to='/rewards' style={{color: "#ffffff"}}>
                                <div className="bg-img" style={extra_earnings}>
                                    <div className="col-12 mt-3"><img src={rupee} alt=""/></div>
                                    <div className="col-12 mt-4 title">Extra Earnings</div>
                                    <div className="col-12 mt-5 amount"> <span className="bp3-skeleton">1000000</span></div>
                                    <div className="col-12 mt-2 heading">Refer Count</div>
                                    <div className="col-12 mt-5 amount"> <img src={rupee} alt=""/> <span className="bp3-skeleton">1000000</span></div>
                                    <div className="col-12 mt-2 heading mb-3">Refer and Earn</div>
                                </div>
                            </Link>
                        </div>
                        :
                        <div className="col-lg-3 col-lg-3 col-sm-6 col-12 col-md-4 mb-3 extraEarnings mt-s-4">
                            <Link to='/rewards' style={{color: "#ffffff"}}>
                                <div className="bg-img" style={extra_earnings}>
                                    <div className="col-12 mt-3"><img src={rupee} alt=""/></div>
                                    <div className="col-12 mt-4 title">Extra Earnings</div>
                                    <div className="col-12 mt-5 amount">{this.props.referral_count}</div>
                                    <div className="col-12 mt-2 heading">Refer Count</div>
                                    <div className="col-12 mt-5 amount">{Helper.formatMoney(this.props.referral_earning)}</div>
                                    <div className="col-12 mt-2 heading mb-3">Refer and Earn</div>
                                </div>
                            </Link>
                        </div>

                }

                {
                    this.state.loading ?
                        <div className="col-lg-3 col-lg-3 col-sm-6 col-12 col-md-4 mb-3 upvStatus mt-s-4 mt-sm-4 mt-md-0 mt-lg-0 mt-xl-0">
                            <Link to='/upv' style={{color: "#ffffff"}}>
                                <div className="bg-img" style={upv_status}>
                                    <div className="col-12 mt-3"><img src={wallet} alt=""/></div>
                                    <div className="col-12 mt-4 title">UPV Status</div>
                                    <div className="col-12 mt-5 amount"> <span className="bp3-skeleton">1000000</span></div>
                                    <div className="col-12 mt-2 heading">My UPV Count</div>
                                    <div className="col-12 mt-5 amount"> <img src={rupee} alt=""/> <span className="bp3-skeleton">1000000</span></div>
                                    <div className="col-12 mt-2 heading mb-3">My Cashback Count</div>
                                </div>
                            </Link>
                        </div>
                        :
                        <div className="col-lg-3 col-lg-3 col-sm-6 col-12 col-md-4 mb-3 upvStatus mt-s-4 mt-sm-4 mt-md-0 mt-lg-0 mt-xl-0">
                            <Link to='/upv' style={{color: "#ffffff"}}>
                                <div className="bg-img" style={upv_status}>
                                    <div className="col-12 mt-3"><img src={wallet} alt=""/></div>
                                    <div className="col-12 mt-4 title">UPV Status</div>
                                    <div className="col-12 mt-5 amount">{this.props.upvstatus}</div>
                                    <div className="col-12 mt-2 heading">My UPV Count</div>
                                    <div className="col-12 mt-5 amount">{Helper.formatMoney(this.props.upvCount)}</div>
                                    <div className="col-12 mt-2 heading mb-3">My Cashback Count</div>
                                </div>
                            </Link>
                        </div>

                }

                {
                    this.state.loading ?
                        <div className="col-lg-3 col-lg-3 col-sm-6 col-12 col-md-4 mb-3 fpvStatus mt-s-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0">
                            <Link to='/fpv' style={{color: "#ffffff"}}>
                                <div className="bg-img" style={fpv_status}>
                                    <div className="col-12 mt-3"><img src={gasDtation} alt=""/></div>
                                    <div className="col-12 mt-4 title">FPV Status</div>
                                    <div className="col-12 mt-5 amount"> <span className="bp3-skeleton">1000000</span></div>
                                    <div className="col-12 mt-2 heading">MY FPV COUNT</div>
                                    <div className="col-12 mt-5 amount"> <img src={rupee} alt=""/> <span className="bp3-skeleton">1000000</span></div>
                                    <div className="col-12 mt-2 heading mb-3">My Cashback Shopping</div>
                                </div>
                            </Link>
                        </div>
                        :
                        <div className="col-lg-3 col-lg-3 col-sm-6 col-12 col-md-4 mb-3 fpvStatus mt-s-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0">
                            <Link to='/fpv' style={{color: "#ffffff"}}>
                                <div className="bg-img" style={fpv_status}>
                                    <div className="col-12 mt-3"><img src={gasDtation} alt=""/></div>
                                    <div className="col-12 mt-4 title">FPV Status</div>
                                    <div className="col-12 mt-5 amount">{this.props.fpvStatus}</div>
                                    <div className="col-12 mt-2 heading">MY FPV COUNT</div>
                                    <div className="col-12 mt-5 amount">{Helper.formatMoney(this.props.fpvCount)}</div>
                                    <div className="col-12 mt-2 heading mb-3">My Cashback Shopping</div>
                                </div>
                            </Link>
                        </div>

                }

                {
                    this.state.loading ?
                        <div className="col-lg-3 col-lg-3 col-sm-6 col-12 col-md-4 mb-3 fpvStatus mt-s-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0">
                            <Link to='/fpv' style={{color: "#ffffff"}}>
                                <div className="bg-img" style={total_cashback}>
                                    <div className="col-12 mt-3"><img src={rupee} alt=""/></div>
                                    <div className="col-12 mt-3 title">Total Cashback Earned</div>
                                    <div className="col-12 mt-4 heading">&nbsp;</div>
                                    <div className="col-12 mt-5 amount"> <img src={rupee} alt=""/> <span className="bp3-skeleton">1000000</span></div>
                                    <div className="col-12 mt-2 heading mb-3">Total Cashback</div>
                                </div>
                            </Link>
                        </div>
                        :
                        <div className="col-lg-3 col-lg-3 col-sm-6 col-12 col-md-4 mb-3 fpvStatus mt-s-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0">
                            <Link to='#' style={{color: "#ffffff"}}>
                                <div className="bg-img" style={total_cashback}>
                                    <div className="col-12 mt-3"><img src={rupee} alt=""/></div>
                                    <div className="col-12 mt-3 title">Total Cashback Earned</div>
                                    <div className="col-12 mt-4 heading">&nbsp;</div>
                                    <div className="col-12 mt-5 amount">{Helper.formatMoney(this.props.totalGgMoneyCashback)}</div>
                                    <div className="col-12 mt-2 heading mb-3">Total Cashback</div>
                                </div>
                            </Link>
                        </div>

                }



            </React.Fragment>
        );
    }
}