import {GET_PROFILE} from "../../porfile/actions/index";

let initialState = {
    profile:{}
};

const profile = (state = initialState, action) => {

    switch (action.type) {

        case GET_PROFILE:
            return {...state, profile: action.sources}
        default:
            return state
    }
}

export default profile;
