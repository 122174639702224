import {connect} from 'react-redux';
import {getStoreOrderList} from '../../../../core/actions/action';
import StoreOrder from '../Components/StoreOrder';

const mapStateToProps = state => {

    return{
        storeOrderList:state.Travel.storeOrderList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getStoreOrderList:(params) => {
            return getStoreOrderList(params);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreOrder);
