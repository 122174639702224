import {connect} from 'react-redux';
import CircleTree from '../../components/circle_components/circleTree';
import {addFpvCircle, getUserDetailByFpvId, fpvReplaceRequest} from "../../../../core/actions/action";

const mapDispatchToProps = dispatch => {
    return {
        addCircle: (fpvID, obj) => {
            return addFpvCircle(fpvID, obj)
        },
        getUserDetailByFpvId : (upvId) => {
            return getUserDetailByFpvId(upvId)
        },
        fpvReplaceRequest : (fpvId,request) => {
            return fpvReplaceRequest(fpvId,request)
        }
    }
}

export default connect(null, mapDispatchToProps)(CircleTree);
