let initialState = {
    userList:[],
    vendorUserList:[],
};

const users = (state = initialState, action) => {

    switch (action.type) {

        case 'REF_USER_LIST':
            return {...state, userList: action.sources}
        case 'REF_VENDOR_USER_LIST':
            return {...state, vendorUserList: action.sources}
        default:
            return state
    }
}

export default users;
