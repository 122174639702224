import {connect} from 'react-redux';
import {getGrangeOrderDetails} from '../../../core/actions/action';
import {getGrangeOrderDetailsAction} from '../../../core/actions';
import ViewOrders from '../Components/ViewOrders';

const mapStateToProps = state => {

    return{
        viewGrangeOrderDetails:state.Travel.viewGrangeOrderDetails,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getGrangeOrderDetails :(orderId) => {
            dispatch(getGrangeOrderDetailsAction(null));
            return getGrangeOrderDetails(orderId)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status === true){
                        dispatch(getGrangeOrderDetailsAction(sources.data[0]));
                    }
                }).catch((error)=>{
                    const sources = error.response;
                    console.log(sources);
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrders);
