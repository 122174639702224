import React, { Component } from 'react';
import {Helper} from "gg-react-utilities";
import {Button, Classes, Dialog, InputGroup, Intent, Position, Radio, RadioGroup, Toaster} from "@blueprintjs/core";
import {Link} from "react-router-dom";
import {Select} from "react-inputs-validation";
import Loader from "../../../core/components/Loader";
import {isNumber} from "@material-ui/core/es/styles/transitions";
import InsertLoader from "../../../core/images/loader.gif";

export default class IC extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isVisibleAddCircleModal: false,
            title: "",
            oldUpvType: "",
            milestones: {},
            error: false,
            hasMore: true,
            isLoading: false,
            data:[],
            counter:1,
            isSkeleton:true,
            total:0,
            totalCashback:0.00,
            last_page:1,
            scrollLoader:true,
            upvID:"",
            referralID:"",
            isVisibleProceedModal:false,
            isVisibleParentUpvModal:false,
            isVisibleParentUpvCodeModal:false,
            upvDetail:[],
            parentCircleUpvInformation:[],
            newUpvType:"FIFO",
            upvCode:'',
            upv_code:'',
            upvCodeError:true,
            hasReferralIDError:true

        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.getCircleList(
                this.state.counter,
                this.state.oldUpvType
                );
        },1000)
    }

    componentWillReceiveProps(nextProps) {
        let newData = [];
        if(this.state.counter == 1){
            newData = nextProps.circleList.data;
        }else {
            let oldData = this.state.data;
            let newDataFromApi = nextProps.circleList.data;
            newData = oldData.concat(newDataFromApi);
        }
        this.setState({
            data:newData,
            isSkeleton:false,
            totalCashback:nextProps.circleList.total_cashback,
            total:nextProps.circleList.total,
            last_page:nextProps.circleList.last_page
        });
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('circleList');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);
        if (parseInt(allBookingHeight) === 0){
            this.loadMore()
        }
    }


    loadMore = () => {
        this.setState({
            scrollLoader:false
        })
        setTimeout(() => {
            this.setState({
                counter: this.state.counter + 1,
                isLoading: true,
                scrollLoader:true
            }, () => {
                this.props.getCircleList(
                    this.state.counter,
                    this.state.oldUpvType
                );
            })
        },1000);
    }

    closeAddCircleModal(){
        this.setState({
            referralID:'',
            upv_code:'',
            isVisibleAddCircleModal:false
        })
    }

    addCircle(upvID){

        if(this.state.hasReferralIDError){

            Toaster.create({position: Position.TOP}).show({message:"Please enter referral UPV ID.",intent: Intent.DANGER});
        }else if(this.state.upvCodeError){

            Toaster.create({position: Position.TOP}).show({message:"Please enter UPV Code.",intent: Intent.DANGER});
        }else{

            let obj = {
                "referrer_upv_id": this.state.referralID,
                "upv_code": this.state.upv_code
            }

            this.props.addCircle(upvID, obj)
                .then((res) => {

                    const sources = res.data;
                    if (sources.status === true) {

                        Toaster.create({position: Position.TOP}).show({message: sources.message, intent: Intent.SUCCESS});

                        this.setState({
                            isVisibleAddCircleModal: false,
                            counter:1,
                            data:[],
                            isSkeleton:true,
                            total:0.00,
                            last_page:1
                        },()=>{

                            setTimeout(() => {
                                this.props.getCircleList(
                                    this.state.counter,
                                    this.state.oldUpvType
                                )
                            },1000)
                        })
                    } else {

                        Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                    }
                }).catch((error) => {

                    this.setState({
                        referralID:"",
                        upv_code:""
                    })
                if (error.response && error.response.status !== 401) {

                    Object.keys(error.response.data.error).forEach((key) => {
                        error.response.data.error[key].forEach((error) => {
                            Toaster.create({position: Position.TOP}).show({
                                message: error,
                                intent: Intent.DANGER
                            });
                        });
                    });

                }
            })
        }
    }

    getParentUpvInfo(upvID){
        this.setState({
            upvID:upvID,
            parentCircleUpvInformation:[],
            isVisibleParentUpvModal: true
        })
        this.props.getParentUpvInfo(upvID)
            .then((res) => {

                const sources = res.data;
                if (sources.status === true) {

                    this.setState({
                        parentCircleUpvInformation:sources.data
                    })
                }

            }).catch((error) => {

            if (error.response && error.response.status !== 401) {

                Object.keys(error.response.data.error).forEach((key) => {
                    error.response.data.error[key].forEach((error) => {
                        Toaster.create({position: Position.TOP}).show({
                            message: error,
                            intent: Intent.DANGER
                        });
                    });
                });

            }
        })
    }

    closeProceedModal(){
        this.setState({
            isVisibleProceedModal:false
        })
    }

    closeparentCircleUpvInformationModal(){
        this.setState({
            isVisibleParentUpvModal:false
        })
    }

    closeparentCircleUpvCodeInformationModal(){
        this.setState({
            isVisibleParentUpvCodeModal:false
        })
    }

    render(){
        const total = this.state.total;
        return(
            <React.Fragment>
                {this.props.circleList!==null ?
                    <div className="fifo mt-4" >
                        <div id={"fifoWrapper1"} className="fifoWrapper">
                            <div className="row">
                                <div className="col-sm-4 col-12">
                                    <div className="fifoSection w-100">
                                        <div className="icon">
                                            <img src={require('../../images/check.svg')} alt="" />
                                        </div>
                                        <div className="count">
                                            <h3 className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>{total==="" ? 0 : total }</h3>
                                            <p>Total No. of UPVs</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12">
                                    <div className="fifoSection w-100">
                                        <div className="icon">
                                            <img src={require('../../images/rupee.svg')} alt="" />
                                        </div>
                                        <div className="count">
                                            <h3 className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>{Helper.formatMoney(this.state.totalCashback)}</h3>
                                            <p>Total Circles Cashback</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12">
                                    <Link to={{ pathname: '/upv-replace' }}>
                                        <div className="fifoSection w-100">
                                            {/*<div className="icon">
                                                <img src={require('../../images/rupee.svg')} alt="" />
                                            </div>*/}
                                            <div className="count">
                                                {/*<h3 className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>{Helper.formatMoney(this.state.totalCashback)}</h3>*/}
                                                {/*<p style={{color: '#2e6aee'}}>Replace Upv Approvals</p>*/}
                                                <Button
                                                    text={"Replace Upv Approvals"}
                                                    intent={"primary"}
                                                 />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {
                            this.props.orderAggregateData.circle_message ?
                                <div className="fifoWrapper mt-3">
                                    <div className="row">
                                        <div className="col-sm-12 col-12">
                                            <div className="alert alert-success">
                                                <strong>{this.props.orderAggregateData.circle_message}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :''
                        }
                        <div className="col-md-3 col-sm-3 col-12 right mt-3 mb-3">
                            <Select
                                className="w-100"
                                tabIndex="0"
                                id={"circleType"}
                                name="circleType"
                                disabled={false}
                                value={this.state.oldUpvType}
                                onChange={(e) =>{
                                    this.setState({
                                        oldUpvType:e,
                                        isSkeleton:true,
                                        counter:1,
                                    },()=>{
                                        this.props.getCircleList(
                                            this.state.counter,
                                            this.state.oldUpvType
                                        );
                                    });
                                }}
                                optionList={[{id:'',name:'All'},{id:'1',name:'Tagged'},{id:'2',name:'Untagged'}]}
                            />
                        </div>
                        <div style={{height:'100%',maxHeight:'590px',overflowY:'scroll'}} id={"circleList"} onScroll={this.handleScroll} className="table-responsive mt-3">
                            <table className="bp3-html-table bp3-html-table-bordered">
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>UPV Number</th>
                                    <th>Levels</th>
                                    <th>Cashback Amount</th>
                                    <th>Created Date</th>
                                    <th style={{width: "200px"}}>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.isSkeleton===true ?
                                    <tr>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>1</span></td>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>122322</span></td>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>₹ 2122</span></td>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>16 Aug 2982</span></td>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>16 Aug 2982</span></td>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>16 Aug 2982</span></td>
                                    </tr>
                                    :
                                    this.state.data.length===0 ?
                                        <tr>
                                            <td colSpan="6" className="emptyState">
                                                <img src={require('../../../core/images/empty-state/UPV.svg')} alt=""/>
                                                <p className="mt-3" style={{'line-height': '25px'}}>No purchase done in your circle yet. Create Referrals to get 2% to 100% assured cashback along with huge conversion of GG Coins.</p>
                                            </td>
                                        </tr>
                                        :
                                        this.state.data.map((res, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>{index+1}</span></td>
                                                    <td>{res.id}</td>
                                                    <td>Level - {res.levels}</td>
                                                    <td>{Helper.formatMoney(res.cashback_amount)}</td>
                                                    <td>{res.formatted_created_at}</td>
                                                    <td>
                                                        {
                                                            res.upv_conversion_payment_id !== null ?
                                                                <span className="bp3-small bp3-bg-success text-white pl-1 pr-1">Converted your UPV</span>
                                                            :
                                                                <React.Fragment>
                                                                    <Link to={{pathname: '/circle/'+res.id}} className={"bp3-button bp3-small mr-1 mb-1"}>View</Link>
                                                                    {
                                                                        res.referral_upv_id === null ?
                                                                            <React.Fragment>
                                                                                <Button className="bp3-small mr-1 bp3-bg-success mb-1"
                                                                                        text={"Add in Circle"}
                                                                                        onClick={() => {
                                                                                            let title = "Add Circle in UPV Number : " + res.id;
                                                                                            this.setState({
                                                                                                title: title,
                                                                                                upvID: res.id,
                                                                                                isVisibleAddCircleModal: true
                                                                                            })
                                                                                        }}/>
                                                                                <Button className="bp3-small mr-1 mb-1"
                                                                                        text={"Convert your UPV"}
                                                                                        intent={"primary"}
                                                                                        onClick={() => {
                                                                                            console.log(res);
                                                                                            this.setState({
                                                                                                upvDetail: res,
                                                                                                isVisibleProceedModal: true
                                                                                            })
                                                                                        }}/>
                                                                            </React.Fragment>
                                                                        :
                                                                            <Button className="bp3-small mr-1 bp3-bg-success mb-1"
                                                                                    text={"View Parent UPV"}
                                                                                    onClick={() => {
                                                                                        this.getParentUpvInfo(res.referral_upv_id);
                                                                                    }}/>
                                                                    }
                                                                </React.Fragment>
                                                        }
                                                        {
                                                            <Button className="bp3-small mr-1 mb-1"
                                                                    text={"Show UPV Code"}
                                                                    intent={"primary"}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            upvCode:res.upv_code,
                                                                            isVisibleParentUpvCodeModal: true
                                                                        })
                                                                    }}/>
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                        {
                            this.state.last_page < this.state.counter ? null :
                                this.state.scrollLoader ?
                                    null :
                                    <React.Fragment>
                                        <center>
                                            <div className="my-3">
                                                <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                            </div>
                                        </center>
                                    </React.Fragment>
                        }
                    </div>
                    :null}

                <Dialog isOpen={this.state.isVisibleAddCircleModal}
                        title={this.state.title}
                        className="bidModal"
                        style={{marginTop:'250px'}}
                        onClose={this.closeAddCircleModal.bind(this)} >
                    <div className="bidForm m-3 text-center">
                        <div className="row">
                            <div className="col-12">
                                <InputGroup
                                    placeholder="Enter Referral UPV ID"
                                    small={false}
                                    required={true}
                                    type={"number"}
                                    value={this.state.referralID}
                                    onChange={(e) => {
                                        this.setState({
                                            referralID:e.target.value,
                                            hasReferralIDError:false
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-12 mt-4">
                                <InputGroup
                                    placeholder="Enter UPV Code"
                                    small={false}
                                    required={true}
                                    type={"number"}
                                    value={this.state.upv_code}
                                    onChange={(e) => {
                                        this.setState({
                                            upv_code:e.target.value,
                                            upvCodeError:false
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <div className="bidFormBtn mt-4 mb-4">
                                    <Button
                                        text={"Cancel"}
                                        intent={"default"}
                                        onClick={() => {
                                            this.setState({
                                                isVisibleAddCircleModal:false
                                            })
                                        }}
                                    />
                                    <Button
                                        text={"Submit"}
                                        intent={"primary"}
                                        onClick={() => this.addCircle(this.state.upvID)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog isOpen={this.state.isVisibleProceedModal}
                        title={"Convert your UPV"}
                        className="bidModal"
                        style={{marginTop:'250px'}}
                        onClose={this.closeProceedModal.bind(this)} >
                    <div className="bidForm m-3 text-center">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="f-16 text-left">Are you sure want to change Cashback Process?
                                </h4>
                            </div>
                            <div className="col-12 text-left">
                                <RadioGroup
                                    onChange={(e) => {
                                        this.setState({
                                            newUpvType:e.target.value
                                        })
                                    }}
                                    name="type"
                                    className="d-inline option-type col-12"
                                    selectedValue={this.state.newUpvType}>
                                    <Radio label="CIRCLE to FIFO" value={"FIFO"} />
                                    <Radio label="CIRCLE to RPP" value={"RPP"} />
                                </RadioGroup>
                            </div>
                            <div className="col-12">
                                <div className="bidFormBtn mt-4 mb-4">
                                    <Button
                                        text={"Cancel"}
                                        intent={"default"}
                                        onClick={() => {
                                            this.setState({
                                                isVisibleProceedModal:false
                                            })
                                        }}
                                    />
                                    <Link to={{pathname:'/upv-conversion',state:{upv:this.state.upvDetail,oldUpvType:"CIRCLE",newUpvType:this.state.newUpvType}}}>
                                        <Button
                                            text={"Proceed"}
                                            intent={"primary"}
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog isOpen={this.state.isVisibleParentUpvModal}
                        title={ "Parent UPV No. " + this.state.upvID}
                        className="bidModal bp3-dialog-large CircleUpvInformationModal"
                        onClose={this.closeparentCircleUpvInformationModal.bind(this)}
                >
                    <div className={Classes.DIALOG_BODY+ ' bidForm text-center mb-1'}>
                        <div className="row">
                            {
                                this.state.parentCircleUpvInformation.length !== 0 ?
                                    <React.Fragment>
                                        <div className="col-12 text-center">
                                            <img
                                                src={this.state.parentCircleUpvInformation.avatar_url}
                                                alt=""
                                                className="img-fluid img-thumbnail"
                                            />
                                            <h3>{this.state.parentCircleUpvInformation.username}</h3>
                                            <h4>{this.state.parentCircleUpvInformation.email}</h4>
                                            <h4>{this.state.parentCircleUpvInformation.mobile}</h4>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <div className="col-12 text-center">
                                        <Loader/>
                                    </div>
                            }
                        </div>
                    </div>
                </Dialog>
                <Dialog isOpen={this.state.isVisibleParentUpvCodeModal}
                        title={"Global Garner"}
                        className="bidModal bp3-dialog-small CircleUpvInformationModal"
                        onClose={this.closeparentCircleUpvCodeInformationModal.bind(this)}
                >
                    <div className={Classes.DIALOG_BODY+ ' bidForm text-center mb-1'}>
                        <div className="row">
                            {
                                this.state.upvCode != '' ?
                                    <React.Fragment>
                                        <div className="col-12 text-center">
                                            <h4>UPV cod is : {this.state.upvCode}</h4>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="col-12 text-center">
                                            <h4>UPV cod is : N/A</h4>
                                        </div>
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}