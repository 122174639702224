import React from 'react';
import Facebook from '../../../core/images/reffrals/fb.png';
import Twitter from '../../../core/images/reffrals/twitter.png';
import LinkedIN from '../../../core/images/reffrals/LinkedIN.png';
import Google from '../../../core/images/reffrals/google.png';
import Whatsapp from '../../../core/images/reffrals/whatsapp.png';


export default class AllStatus extends React.Component {
    constructor(props){
        super(props);

        this.state={
            referralLink:props.referralLink,
            loading: true,
        };
    }

    componentDidMount(){
        this.setState( {
            loading: this.props.loading,
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    render(){
        let facebookLink = "http://www.facebook.com/sharer.php?u="+ this.state.referralLink;
        let twitterLink = "http://twitter.com/share?url="+ this.state.referralLink;
        let linkedInLink = "http://www.linkedin.com/shareArticle?mini=true&url="+ this.state.referralLink;
        let googleLink = "https://plus.google.com/share?url="+ this.state.referralLink;
        let whatsappLink = "https://wa.me/whatsappphonenumber/?text="+ this.state.referralLink;
        return(
            <React.Fragment>
				<div className="col-12">
					<div className="row">
						{/*<div className="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12 socialBorder p-4 px-s-0">
							{
								this.state.loading ?
									<div className="col-12 bp3-skeleton" style={{width: "100%", height: "150px"}}></div>
									:
									<div className="social">
										<div className="title">Email<p>Invite Friends</p></div>
											<ControlGroup fill={true} vertical={false} className="brand-search">
												<InputGroup
													placeholder=""
													className="bs-input-search"
													defaultValue=""
												/>
												<Button className="bp3-button bp3-intent-primary btn-sm" intent={Intent.PRIMARY}
														text="Invite"/>
											</ControlGroup>

									</div>
							}
						</div>*/}

						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-4 px-s-0">
							{
								this.state.loading ?
									<div className="bp3-skeleton" style={{width: "100%", height: "150px"}}></div>
									:
									// ml-xl-5 ml-lg-5 ml-md-5 ml-sm-0 ml-s-0
									<div className="social">
										<div className="title">Social Media<p>Share on Facebook and whatsapp</p></div>
                                        <div className={"row"}>
                                            <div className={" col-xl-1 col-lg-1 col-2 col-sm-2"}>
                                                <a href={facebookLink} target="_blank" title="Click to share" rel="noopener noreferrer">
                                                    <img src={Facebook} className="float-left" alt=""/>
                                                </a>
                                            </div>
                                            <div className={" col-xl-1 col-lg-1 col-2 col-sm-2"}>
                                                <a href={whatsappLink} target="_blank" title="Click to share" rel="noopener noreferrer">
                                                    <img src={Whatsapp} className="float-left ml-1" alt=""/>
                                                </a>
                                            </div>
                                            <div className={" col-xl-1 col-lg-1 col-2 col-sm-2"}>
                                                <a href={twitterLink} target="_blank" title="Click to share" rel="noopener noreferrer">
                                                    <img src={Twitter} className="float-left ml-1" alt=""/>
                                                </a>
                                            </div>
                                            <div className={"col-xl-1 col-lg-1 col-2 col-sm-2"}>
                                                <a href={linkedInLink} target="_blank" title="Click to share" rel="noopener noreferrer">
                                                    <img src={LinkedIN}  className="float-left ml-1" alt=""/>
                                                </a>
                                            </div>
                                            <div className={"col-xl-1 col-lg-1 col-2 col-sm-2"}>
                                                <a href={googleLink} target="_blank" title="Click to share" rel="noopener noreferrer">
                                                    <img src={Google} className="float-left ml-1" alt=""/>
                                                </a>
                                            </div>
                                        </div>
                                        <p className="float-left mt-2">Click to copy and paste link on WhatsApp Web.</p>
									</div>
							}
						</div>
					</div>
				</div>
            </React.Fragment>
        );
    }
}