import React, {Component} from 'react';
import AllorderEmptyStates from "../../../core/images/empty-state/Allorder.svg";
import ViewOrders from '../Container/ViewOrders';
import {Button} from "@blueprintjs/core";
import Config from '../../../Config';
import moment from "moment";

export default class GrangeOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderList:[],
            page:1,
            stopLoadMore: false,
            loading:true,
            isOpen: false,
            isLoading: false,
            scrollLoader:false,
            showEmptyStats:false
        }
    }

    componentDidMount() {
        this.setState( {
            loading: this.props.loading,
        })

        let params={
            page:this.state.page
        }

        this.getGrangeOrderList(params)
    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    getGrangeOrderList=(params)=>{

        this.props.getGrangeOrderList(params)
            .then((res)=>{

                const sources = res.data;
                if(sources.status===true){
                    let oldData = this.state.orderList;
                    let newDataFromApi = sources.data;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState( {
                        orderList:newData,
                        isLoading: false,
                        scrollLoader:false
                    });
                    if(sources.data.length === 0 && this.state.orderList.length === 0){
                        this.setState( {
                            showEmptyStats:true,
                        });
                    }
                    if(sources.data.length === 0){
                        this.setState( {
                            stopLoadMore: true
                        })
                    }else{
                        this.setState( {
                            stopLoadMore: false
                        })
                    }
                }else{

                }
            }).catch((error)=>{
            const sources = error.response;
            console.log(sources);
        })
    }

    loadMore = () =>{
        let oldPageNo = this.state.page;
            this.setState({
                isLoading:true,
                page:oldPageNo+1,
                scrollLoader:true
            }, () => {
                let params={
                    page:this.state.page
                }
                this.getGrangeOrderList(params);
            })
    }



    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);
        if(this.state.isLoading === false && this.state.stopLoadMore === false){
            if (parseInt(allBookingHeight) === 0){
                this.loadMore()
            }
        }
    }

    renderStatusHtml(status){

        switch (status) {
            case 'pending':
                return <label className="label text-warning">Pending</label>;
            case 'pending_payment':
                return <label className="badge text-warning">Pending Payment</label>;
            case 'processing':
                return <label className="badge text-success">Processing</label>;
            case 'shipped':
                return <label className="badge text-info">Shipped</label>;
            case 'ready_for_pickup':
                return <label className="badge text-info">Ready for Pickup</label>;
            case 'delivered':
                return <label className="badge text-success">Delivered</label>;
            case 'completed':
                return <label className="badge text-success">Completed</label>;
            case 'closed':
                return <label className="badge text-success">Closed</label>;
            case 'canceled':
                return <label className="badge text-danger">Cancelled</label>;
            case 'fraud':
                return <label className="badge text-danger">Declined</label>;
            default:
                return <label className="label text-default">--</label>;
        }
    }

    cbType(type){

        switch (type) {
            case 1:
                return "FIFO";
            case 2:
                return "RPP";
            case 3:
                return "ICB";
            case 4:
                return "CIRCLE";
            default:
                return "-";
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="storeorder" id={"allBooking"} style={{'height':'100%','maxHeight':'590px','overflowY':'scroll'}} onScroll={this.handleScroll} >
                    <table className="bp3-html-table table-borderless">
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Order Date</th>
                                <th>Price</th>
                                <th>Cashback Type</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.loading ?
                                <tr>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                </tr>
                                :
                                this.state.orderList.length ?
                                    this.state.orderList.map((order, key) => {
                                        return <tr key={key}>
                                            <td>{order.increment_id}</td>
                                            <td>{moment(order.created_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                            <td><b>{order.formated_grand_total}</b></td>
                                            <td>
                                                {
                                                    this.cbType(order.caseback_choice)
                                                }
                                            </td>
                                            <td>
                                                <b>
                                                    {
                                                        this.renderStatusHtml(order.status)
                                                    }
                                                </b>
                                            </td>
                                            <td>
                                                <ViewOrders isOpen={this.state.isOpen} orderid={order.id} displayOrderId={order.increment_id}/>
                                                {
                                                    order.invoices.length !== 0 ?
                                                        <a href={Config.GRANGE_API_URL + 'orders/print/' + order.invoices[0].id}  title="download Invoice" className="bp3-button bp3-small bp3-intent-primary btn-view" rel="noopener noreferrer" target="_blank">
                                                            <span className="bp3-icon bp3-icon-import bp3-text-medium"></span>
                                                        </a>
                                                        :
                                                        <Button small={true} intent="inform" disabled={true} className="btn-view mr-2" title="download Invoice" icon="import" />
                                                }
                                            </td>
                                        </tr>
                                    })
                                    :
                                     this.state.showEmptyStats ?
                                        <tr>
                                            <td colSpan="7" align="center">
                                                <div className="text-center">
                                                    <img src={AllorderEmptyStates} alt=""/>
                                                    <p className="mt-3">No transactions for the selected option</p>
                                                </div>
                                            </td>
                                        </tr>
                                     :''
                        }
                        </tbody>
                    </table>
                    {
                        this.state.scrollLoader ?

                                <React.Fragment>
                                    <center>
                                        <div className="my-3">
                                            <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                            : null
                    }
                </div>
            </React.Fragment>
        )
    }
}