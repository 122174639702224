import React from "react";
import {Link} from "react-router-dom";
import {Intent, Position, Toaster} from "@blueprintjs/core";
import {Helper} from "gg-react-utilities";


export default class TransactionList extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isSkeleton:true,
            transactionList:[],
            scrollLoader:true,
            counter:1,
            isLoading:false,
            loading:false,
            platform:"WEB",
        }
    }

    componentDidMount() {
        this.transactionList(this.state.counter);
        setTimeout(() => {
            this.setState({
                isSkeleton:false
            })
        }, 1000);
        window.addEventListener('scroll', this.handleOnScroll);
    }

    transactionList(page){
        this.props.transactionList(page)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    let oldData = this.state.transactionList;
                    let newDataFromApi = sources.data.data;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState({
                        transactionList:newData,
                        last_page:sources.data.last_page,
                    })
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                }
            }).catch((error)=>{
            Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
        })
    }

    handleOnScroll=()=> {
        let scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        let scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        let clientHeight = document.documentElement.clientHeight || window.innerHeight;
        let scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

        if (scrolledToBottom) {
            this.loadMore();
        }
    }

    loadMore = () =>{
        this.setState({
            scrollLoader:false
        });
        setTimeout(() => {
            this.setState({
                counter: this.state.counter + 1,
                isLoading: true,
                scrollLoader:true
            }, () => {
                this.transactionList(this.state.counter);
            })
        },1000)
    }

    render(){
        return(
            <div className="exchangeContainer">
                <div className="bidsWrapper text-right">
                    <div className="content mt-lg-4">
                        <div className="row">
                            {this.state.transactionList.length === 0 ?
                                <div className="emptyState">
                                    <img src={require('../../../core/images/empty-state/UPV.svg')} alt=""/>
                                </div>
                                :
                                this.state.transactionList.map((res, i) => {
                                    let status;
                                    let color;
                                    if(res.upv_status==="PURCHASED"){
                                        status="Purchased";
                                        color="success"
                                    }else{
                                        status="Sold";
                                        color="warning"
                                    }
                                    return (
                                        <div className={"col-12 col-lg-6 col-md-6 col-sm-6 col-xs-12"} key={i}>
                                            <Link to={{ pathname: '/bid-transaction-detail', state: { upv: res}  }}>
                                                <div className={this.state.isSkeleton ? "bidBlock p-3 mt-3 bp3-skeleton" : "bidBlock p-3 mt-3 shadowBlock"}>
                                                    <div className="row">
                                                        <div className="col-12 col-lg-2 col-md-2 col-sm-2">
                                                            <div className="upvRight">
                                                                <h5 className={"m-0"}>UPV No</h5>
                                                                <h4 className={"m-0"}>{res.upv_id}</h4>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-10 col-md-10 col-sm-10">
                                                            <div className="upvLeft text-left">
                                                                <div className={"topbar"}>
                                                                    <div>
                                                                        <h4 className="m-0 nameText">{res.formatted_created_at}</h4>
                                                                        <h4 className="m-0 priceText">{res.bid_price ? Helper.formatMoney(res.bid_price) : Helper.formatMoney(0)}</h4>
                                                                    </div>
                                                                    <div className={"text-right"}>
                                                                        <h4 className={"text-"+color+" m-0 f-16"} style={{fontWeight:'500'}}>{status}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        {
                            this.state.last_page <= this.state.counter ? null :
                                this.state.scrollLoader ?
                                    null :
                                    <React.Fragment>
                                        <center>
                                            <div className="my-3">
                                                <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                            </div>
                                        </center>
                                    </React.Fragment>

                        }
                    </div>
                </div>
            </div>
        );
    }
}