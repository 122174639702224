import React from 'react';
import RequestGgCoinImg from "../../core/images/rewards/Request_GG-Coin.svg";

import {Classes, Dialog, Button, FormGroup, Toaster, Position, Intent,} from "@blueprintjs/core";
import {Textbox,} from 'react-inputs-validation';


export default class RewardRequest extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            isOpenRequestCoin: false,
            userName:"",
            amount:"",
            Whatisforit:"",
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.handleCloseRequestCoin = this.handleCloseRequestCoin.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.isOpenRequestCoin === true){
            this.handleCloseRequestCoin();
        }
    }

    toggleValidating(validate) {
        this.setState({ validate });
    }

    handleOpenRequestCoin = () =>{
        this.setState({ isOpenRequestCoin: true });
    }

    handleCloseRequestCoin(){
        this.setState({ isOpenRequestCoin: false, userName: "",  amount:"1",  Whatisforit:""  });
    }

    onSubmit(event){
        if(this.state.amount <= 0){
            Toaster.create({position: Position.TOP}).show({message: 'The amount must be at least 1.',intent: Intent.DANGER});
            event.preventDefault();
        }else{
            this.toggleValidating(true);
            if(!this.state.errors){
                const formData = new FormData();
                formData.append('sender_username',this.state.userName)
                formData.append('description',this.state.Whatisforit)
                formData.append('amount',this.state.amount)
                if((this.state.userName !== "") && (this.state.amount > 0) && (this.state.Whatisforit !== "") ) {
                    this.props.requestCoinRequest(formData)
                }
            }
        }
        event.preventDefault();
    }

    render(){
        return(
            <React.Fragment>
                <div className="requestCoinDiv mb-4 mt-1 mb-s-3" onClick={this.handleOpenRequestCoin}>
                    <img className="mt-5 mt-s-3" src={RequestGgCoinImg} alt=""/>
                    <p className="mt-5 mt-s-4">Request GG-Coin</p>
                </div>
                <Dialog isOpen={this.state.isOpenRequestCoin}
                        onClose={() => this.handleCloseRequestCoin()}
                        className="bp3-dialog-large"
                        title="Request GG-Coin"
                        canOutsideClickClose={true}
                        canEscapeKeyClose={true}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <form onSubmit={this.onSubmit} className="form-horizontal" encType="multipart/form-data">
                            <div className="row form-data">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-group  mb-2">
                                    <FormGroup>
                                        <Textbox
                                            tabIndex="1"
                                            id={"username"}
                                            name="username"
                                            type="text"
                                            value={this.state.userName}
                                            placeholder="User Name"
                                            onChange={(userName) => {
                                                this.setState({ userName });
                                            }}
                                            onBlur={e => {}}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({ errors:res, validate: false })
                                            }
                                            validationOption={{
                                                name: "User Name",
                                                check: true,
                                                required: true,
                                            }}/>
                                    </FormGroup>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-group  mb-2">
                                    <FormGroup>
                                        <Textbox
                                            tabIndex="2"
                                            id={"amount"}
                                            name="amount"
                                            type="number"
                                            value={this.state.amount}
                                            placeholder="Amount"
                                            onChange={(amount) => {
                                                this.setState({ amount });
                                            }}
                                            onBlur={e => {}}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({ errors:res, validate: false })
                                            }
                                            validationOption={{
                                                name: "Amount",
                                                check: true,
                                                required: true,
                                            }}/>
                                    </FormGroup>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-group">
                                    <FormGroup>
                                        <Textbox
                                            tabIndex="3"
                                            id={"Whatisforit"}
                                            name="Whatisforit"
                                            type="text"
                                            value={this.state.Whatisforit}
                                            placeholder="What is for it"
                                            onChange={(Whatisforit) => {
                                                this.setState({ Whatisforit });
                                            }}
                                            onBlur={e => {}}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({ errors:res, validate: false })
                                            }
                                            validationOption={{
                                                name: "Description",
                                                check: true,
                                                required: true,
                                            }}/>
                                    </FormGroup>
                                </div>

								<div className="col-12 text-group mt-s-4 mt-3 text-right">
									<Button type="submit" text="Cancel" className="bp3-secondary mr-3" intent={Intent.secondary} onClick={() => this.handleCloseRequestCoin()} />
									<Button type="submit" text="Submit" intent={Intent.PRIMARY} loading={this.state.loader} />
                                </div>
                            </div>
                        </form>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}