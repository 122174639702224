import React, {Component} from 'react';
import AllorderEmptyStates from "../../../../core/images/empty-state/Allorder.svg";
import VoucherDetails from  "./VoucherDetails";
import {Button} from "@blueprintjs/core";

export default class CoinVouchers extends Component{
    constructor(props) {
        super(props);

        this.state = {
            voucherOrderList:[],
            loadingData : true,
            isOpen: false,
        }
    }

    componentDidMount(){
        this.setState( {
            loading: this.props.loading,
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }


    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            if(this.props.stopLoadMore === false){
                this.props.NextPage()
            }
        }
    }


    renderStatusHtml(status){

        switch (status) {
            case 0:
                return <label className='bp3-text-warning'>Awaiting Payment</label>;
            case 1:
                return <label className='bp3-text-success'>Successful</label>;
            case 2:
                return <label className='bp3-text-danger'>Failed</label>;
            case 3:
                return <label className='bp3-text-info'>Refunded</label>;
            default:
                return <label className="label text-default">--</label>;
        }
    }

    render(){
     
        return(
            <React.Fragment>
                <div className="coin-voucher-order" id={"allBooking"} style={{'height':'100%','maxHeight':'590px','overflowY':'scroll'}} onScroll={this.handleScroll}>
                    <table className="bp3-html-table table-borderless">
                        <thead>
                            <tr>
                                <th>Product Details</th>
                                <th>Order ID</th>
                                <th>Order Date</th>
                                <th>Price</th>
                                <th>Coins</th>
                                <th>Cashback Type</th>
                                <th>Status</th>
                                <th>Action</th>
                                <th>Use Here</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.loading ?
                                    <tr>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    </tr>
                                    :
                                    this.props.voucherOrderList.length ?
                                        this.props.voucherOrderList.map((orders, key) => {
                                            return <tr key={key}>
                                                <td>
                                                    <div className="orderTable">
                                                        <div className="image hidden-xs hidden-sm hidden-md visible-xl visible-lg">
                                                            <img src={orders.voucher_image} alt=""/>
                                                        </div>
                                                        <div className="title">
                                                            <p>{orders.voucher_name}</p>
                                                            <span>Qty : {orders.quantity}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{orders.id}</td>
                                                <td>{orders.order_date}</td>
                                                <td><b>₹ {orders.display_price}</b></td>
                                                <td>{orders.used_coins ? orders.used_coins : 0}</td>
                                                <td>{orders.cb_choice_title}</td>
                                                <td>
                                                    <b>
                                                        {
                                                            this.renderStatusHtml(orders.order_status)
                                                        }
                                                    </b>
                                                </td>
                                                <td>
                                                    {
                                                        orders.order_status !== 0 ?
                                                                <VoucherDetails voucherDetail={orders} />
                                                            :
                                                                <Button small={true} intent="inform" disabled={true} className="btn-view mr-2" title="View" icon="eye-open" />
                                                    }

                                                </td>
                                                <td>
                                                    {
                                                        orders.order_status !== 0 && (orders.voucher_type_id === 19|| orders.voucher_type_id===20||orders.voucher_type_id===21||orders.voucher_type_id===22||orders.voucher_type_id===23||orders.voucher_type_id===24||orders.voucher_type_id===25||orders.voucher_type_id===26) ?
                                                   <a href="http://redeemggcoins.com">                                                         
                                                        <Button intent="inform"  className="btn-view mr-2" title="Redeem Coins"  >Redeem Coins</Button>
                                                        </a>:
                                                         <Button intent="inform" disabled={true} className="btn-view mr-2" title="Redeem Coins"  >Redeem Coins</Button>
                                                     
                                                        }

                                                </td>
                                            </tr>
                                        })
                                        :
                                        <tr>
                                            <td colSpan="7" align="center">
                                                <div className="text-center">
                                                    <img src={AllorderEmptyStates} alt=""/>
                                                    <p className="mt-3">No transactions for the selected option</p>
                                                </div>
                                            </td>
                                        </tr>

                            }
                        </tbody>
                    </table>
                    {
                        this.state.loading ?
                            null
                            :
                            this.props.stopLoadMore ?
                                ""
                                :
                                <React.Fragment>
                                    <center>
                                        <div className="my-3">
                                            <img src={require('../../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }
}