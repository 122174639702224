import React from 'react';
import {Alert, Button, Intent} from "@blueprintjs/core";

export default class AcceptCoin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenAcceptCoin: false,
        }

        this.handleCloseAcceptCoin = this.handleCloseAcceptCoin.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.isOpenAcceptCoin === true){
            this.handleCloseAcceptCoin();
        }
    }

    handleOpenAcceptCoin = () =>{
        this.setState({ isOpenAcceptCoin: true });
    }

    handleCloseAcceptCoin(){
        this.setState({ isOpenAcceptCoin: false});
    }

    acceptRequest = () => {
        this.props.acceptCoinRequest(this.props.reuqestNo)
    }

    render(){
        return(
            <React.Fragment>
                <Button intent="primary" className="bp3-button bp3-small bp3-intent-primary btn-view mr-1" text="Accept" onClick={this.handleOpenAcceptCoin}/>
                <Alert
                    className="bp3-small"
                    icon="confirm"
                    cancelButtonText="Cancel"
                    confirmButtonText="Accept"
                    intent={Intent.PRIMARY}
                    isOpen={this.state.isOpenAcceptCoin}
                    onCancel={this.handleCloseAcceptCoin}
                    onConfirm={this.acceptRequest}
                    canOutsideClickCancel="true"
                    canEscapeKeyCancel="true"
                >
                    <p className="warningHeading">Are you sure you want to accept this request?</p>
                </Alert>
            </React.Fragment>
        );
    }
}