import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import Main from '../UPV/components/main';
import { getCashbackOption, changeCashbackOption, getOrderAggregateData } from '../../core/actions/action';
import { getCashbackOptionAction, changeCashbackOptionAction, orderAggregateDataAction } from '../../core/actions';

const mapStateToProps = state => {

    return{
        cashbackOpt:state.cashbackReducer.cashbackOpt,
        changeOption:state.cashbackReducer.changeOption,
        orderAggregateData:state.cashbackReducer.orderAggregateData,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getCashbackOption :() => {
            return getCashbackOption()
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(getCashbackOptionAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(getCashbackOptionAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
        changeCashbackOption :(choice) => {
            return changeCashbackOption(choice)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(changeCashbackOptionAction(sources));
                        Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(changeCashbackOptionAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
        getOrderAggregateData :() => {
            return getOrderAggregateData()
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(orderAggregateDataAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(orderAggregateDataAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
