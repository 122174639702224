import React from "react";
import PageTitle from "../../../core/components/PageTitle";
import TopBar from "../../../core/components/TopBar";
import '../../../core/styles/_coinHistory.scss';
import { getFpvLevelOfCoin } from '../../../core/actions/action';

export default class coin_conversion extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fpvID: props.match.params.fpvId,
      data: ''
    };

  }
  componentDidMount() {
    var id = this.state.fpvID;
    getFpvLevelOfCoin(id)
        .then((res) => {
          console.log("history", res.data.data.coin_conversion_history)
          this.setState({
            data: res.data.data.coin_conversion_history
          })
        })
  }

  render() {
    let data = this.state.data
    return (
        <div>
          <div>
            <div className="container mb-5">
              <PageTitle title="FPV Exchange Info - Global Garner" />
              <div className="exchangeWrapper">
                <div className="exchangeTopBar">
                  <TopBar
                      title={"Coin Redeem History"}
                  />
                </div>
                <div className="exchangeInfoContainer text-center">
                  <div className="container py-s-4 fixed-height-coin">
                    {data && data.map((history, index) => (
                        <div className="row">

                          {history.credited_at == null ?
                              <div className="col-md-12 col-sm-12 col-xs-12 coin-converted-history mb-3">

                                <div className="row" key={index}>
                                  <div className="col-md-6 col-sm-6 col-xs-12 d-flex">
                                    {/* <div className="coin-level-card">
                                <p>Level <br /> 4</p>
                              </div> */}
                                    <div className="coin-level-name text-left">
                                      <p className="traid">Transaction ID &amp; Date</p>
                                      <span>{history.id}</span><span>{history.created_at}</span>
                                      <p className="reddemed-coins">Reddemed Coins</p>
                                      <p className="prise-coin">{history.amount}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-xs-12">
                                    <div className="float-sm-right coin-list-right">
                                      <p className="coin-redeemed">Coin Redeemed</p>
                                      <p><span>Note:</span> Amount Shown in GG Money in <br /> 3 Business Days. </p>
                                    </div>
                                  </div>
                                </div>

                              </div>
                              :
                              <div className="col-md-12 col-sm-12 col-xs-12 coin-converted-history mb-3">
                                <div className="row">
                                  <div className="col-md-6 col-sm-6 col-xs-12 d-flex">
                                    {/* <div className="coin-level-card">
                                <p>Level <br /> 4</p>
                              </div> */}
                                    <div className="coin-level-name text-left">
                                      <p className="traid">Transaction ID &amp; Date</p>
                                      <span>{history.id}</span><span>{history.created_at}</span>
                                      <p className="reddemed-coins">Reddemed Coins</p>
                                      <p className="prise-coin">{history.amount}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-xs-12">
                                    <div className="float-sm-right">
                                      <p className="coin-convert-sucess">Coin Converted Successfully</p>
                                      <p className="coin-convert-date">{history.credited_at}</p>
                                      <p className="coin-conver-view" onClick={() => this.props.history.push('/passbook')}>&gt;&gt; View Details</p>
                                    </div>
                                  </div>
                                </div>
                              </div>}
                        </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
    );

  }
}