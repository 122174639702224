import {connect} from 'react-redux';

import RPP from '../components/rpp';
import { getFPVRPPCurrentList, getFPVRPPOldList } from '../../../core/actions/action';

const mapStateToProps = state => {

    return{
        fpvRPPCurrentList:state.cashbackReducer.fpvRPPCurrentList,
        fpvRPPOldList:state.cashbackReducer.fpvRPPOldList,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getFPVRPPCurrentList :(page) => {
            return getFPVRPPCurrentList(page)
        },
        getFPVRPPOldList :(page) => {
            return getFPVRPPOldList(page)

        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RPP);
