import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import SendMoney from '../components/sendMoney';
import { getOTPRequest, saveWithdrawalRequest } from '../../core/actions/action';
import { saveWithdrawalRequestAction } from '../../core/actions';

const mapStateToProps = state => {

    return{
        otpRequest:state.cashbackReducer.otpRequest,
        saveRequest:state.cashbackReducer.saveRequest
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getOTPRequest :(obj) => {
            return getOTPRequest(obj)
               /* .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                        dispatch(getOTPRequestAction(sources));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(getOTPRequestAction([]));
                    }
                }).catch((res)=>{
                    const sources =  res.response;
                    Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                })*/
        },
        saveWithdrawalRequest :(obj) => {
            return saveWithdrawalRequest(obj)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(saveWithdrawalRequestAction(sources));
                        Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(saveWithdrawalRequestAction([]));
                    }
                }).catch((res)=>{
                    const sources =  res.response;
                    Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SendMoney);
