import {connect} from 'react-redux';
import {getAllOrderList, getDMTPDF} from '../../../core/actions/action';
import AllOrders from '../index';

const mapStateToProps = state => {

    return{
        approvedOrderList:state.AllOrders.approvedOrderList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getAllOrderList:(params) => {
            return getAllOrderList(params);
        },

        getDMTPDF :(obj) => {
            return getDMTPDF(obj)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllOrders);
