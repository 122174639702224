import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import upvReplaceInbox from '../components/upvReplaceInbox';
import {getUpvReplaceInboxList, upvReplaceInboxOption} from '../../../core/actions/action';
import { getUpvReplaceInboxListAction } from '../../../core/actions';

const mapStateToProps = state => {

    return{
        upvReplaceInboxList:state.cashbackReducer.upvReplaceInboxList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getUpvReplaceInboxList :(page) => {
            return getUpvReplaceInboxList(page)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(getUpvReplaceInboxListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(getUpvReplaceInboxListAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
        upvReplaceInboxOption:(upvID, obj, status) => {
            return upvReplaceInboxOption(upvID, obj, status)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(upvReplaceInbox);
