import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import IC from '../components/ic';
import { getFPVICList } from '../../../core/actions/action';
import { getFPVICListAction } from '../../../core/actions';

const mapStateToProps = state => {

    return{
        fpvICList:state.cashbackReducer.fpvICList,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getFPVICList :(page) => {
            return getFPVICList(page)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(getFPVICListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(getFPVICListAction(null));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IC);
