let initialState = {
    megaBrandsOrderList:[],
    voucherOrderList:[],
    viewVoucherDetails:null,
};

const MegaBrands = (state = initialState, action) => {

    switch (action.type) {

        case 'MEGA_BRANDS_ORDER':
            return {...state, megaBrandsOrderList: action.sources}
        case 'VOUCHER_ORDER':
            return {...state, voucherOrderList: action.sources}
        case 'VIEW_VOUCHER_ORDER':
            return {...state, viewVoucherDetails: action.sources}
        default:
            return state
    }
}

export default MegaBrands;
