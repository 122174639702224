import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom"; 
import config from '../../../../src/Config'
export default function HelmetMetaData(props) {
  
    var data = decodeURI(window.location.search);
    data = data.split("&");
    var image = data[0] ? ('https://tinyurl.com/' + data[0].replace("?url=", '')) : 'https://s3.ap-south-1.amazonaws.com/gg-updates/2019/02/GG+Feature+graphics.png';
    let location = useLocation();
    let currentUrl = config.APP_URL + location.pathname;
   
   return (
     <Helmet>
   
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
     
        <meta property="og:url" content={currentUrl} />
        
        <meta property="og:image"  content={image} />
        <meta content="image/*" property="og:image:type" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:site_name" content="Global Garner" />
       </Helmet>
    );

}