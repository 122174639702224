import React, { Component } from 'react';
import AllorderEmptyStates from "../../../../core/images/empty-state/Allorder.svg";
import {Button, Intent, Position, Toaster, Dialog} from "@blueprintjs/core";
import Config from '../../../../Config';
import {connect} from "react-redux";
import {getHotelList, getHotelInvoice, getHotelCancelBooking} from "../../../../core/actions/action";
import {getHotelListAction} from "../../../../core/actions";

class Booking extends Component{
    constructor(props){
        super(props);
        this.state={
            hotelList:[],
            loading:true,
            page:1,
            stopLoadMore: false,
            showEmptyStats:false,
            loadingData : true,
            hotelDetail:{},
            isVisibleCancelConfirmBox:false,
            hotelId:null
        };
    }

    componentDidMount(){
        this.setState( {
            loading: false,
        })
        this.getHotelList(this.state.page)
    }

    getHotelList(page){
        this.props.getHotelList(page)
    }

    componentWillReceiveProps(nextProps) {
        let oldData = this.state.hotelList;
        let newDataFromApi = nextProps.hotelList.data;
        let newData = oldData.concat(newDataFromApi);
        this.setState({
            hotelList:newData
        });

        if(nextProps.hotelList.current_page === nextProps.hotelList.last_page){
            this.setState( {
                stopLoadMore: true,
            })
        }else{
            this.setState( {
                stopLoadMore: false,
            })
        }
    }

    loadMore = () =>{
        this.setState({
            scrollLoader:false
        });
        setTimeout(() => {
            this.setState({
                page: this.state.page + 1,
                isLoading: true,
                scrollLoader: true
            }, () => {
                this.props.getHotelList(this.state.page);
            })
        },1000)
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            this.loadMore()
        }
    }

    renderStatusHtml(status){

        switch (status) {
            case "1":
                return <label className="label text-success">SUCCESS</label>;
            case "2":
                return <label className="badge text-danger">FAILED</label>;
            case "3":
                return <label className="badge text-warning">AWAITING</label>;
            case "4":
                return <label className="badge text-warning">PENDING</label>;
            case "0":
                return <label className="badge text-danger">CANCELED</label>;
            default:
                return <label className="label text-default">--</label>;
        }
    }

    getHotelInvoice(bookingID){
        this.props.getHotelInvoice(bookingID)
            .then((response)=>{
                const bookingURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                const bookingLink = document.createElement('a');
                bookingLink.href = bookingURL;
                var name = 'gg-booking-'+bookingID+'.pdf';
                bookingLink.setAttribute('download', name);
                document.body.appendChild(bookingLink);
                bookingLink.click();
            }).catch((error)=> {
            Toaster.create({position: Position.TOP}).show({message: "Something went wrong.", intent: Intent.DANGER});
        });
    }

    getHotelCancelBooking(bookingID){
        this.props.getHotelCancelBooking(bookingID)
            .then((res)=> {
                const sources = res.data;
                if (sources.status === true) {
                    this.setState({
                        isVisibleCancelConfirmBox:false
                    })
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                }
            }).catch((error)=>{
                this.setState({
                    isVisibleCancelConfirmBox:false
                })
                Toaster.create({position: Position.TOP}).show({message:error.response.data.message,intent: Intent.DANGER});
            })
    }

    closeCancelConfirmBox(){
        this.setState({
            isVisibleCancelConfirmBox:false
        })
    }

    render(){
        return(
            <React.Fragment>
                <div className="hotel">
                    <div className="recharge" id={"allBooking"} style={{'height':'100%','maxHeight':'590px','overflowY':'scroll'}} onScroll={this.handleScroll}>
                        <table className="bp3-html-table table-borderless">
                            <thead>
                            <tr>
                                <th>Product Details</th>
                                <th>Order ID</th>
                                <th>Order Date</th>
                                <th>Price</th>
                                <th>Cashback Type</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.loading ?
                                    <tr>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                        <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    </tr>
                                    :
                                    this.state.hotelList.length ?
                                        this.state.hotelList.map((res, key) => {
                                            return <tr key={key}>
                                                <td><div className="orderTable">
                                                    <div className="title">
                                                        <p>{res.hotel_name}</p>
                                                        <span>{res.guests}</span>
                                                    </div>
                                                </div>
                                                </td>
                                                <td>{res.id}</td>
                                                <td>{res.booking_date}</td>
                                                <td>₹ {res.booking_amount}</td>
                                                <td>{res.cb_choice_title}</td>
                                                <td><b>{res.booking_status}</b></td>
                                                <td style={{'display':'flex','width':'100%'}}>
                                                    <Button small={true}
                                                        className="btn-view mr-2 bp3-bg-info"
                                                        title="View"
                                                        disabled={res.booking_status==="CONFIRM" ? false : true }
                                                        onClick={() => {
                                                            window.open(
                                                                Config.HOTEL_URL+"booking/"+res.id+"/detail",
                                                                '_blank'
                                                            );
                                                        }}>
                                                        View
                                                    </Button>
                                                    {res.booking_status === "CONFIRM" ?
                                                        <Button small={true}
                                                                className="btn-view mr-2 bp3-bg-success"
                                                                title="Download Receipt"
                                                                onClick={() => {
                                                                    this.getHotelInvoice(res.id)
                                                                }}>
                                                            Receipt
                                                        </Button>
                                                        : ""
                                                    }
                                                    {res.booking_status === "CONFIRM" ?
                                                        <Button small={true}
                                                                className="btn-view mr-2"
                                                                style={{'background':'#ff5252'}}
                                                                title="Cancel Booking"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        isVisibleCancelConfirmBox:true,
                                                                        hotelId : res.id
                                                                    })
                                                                }}>
                                                            Cancel
                                                        </Button>
                                                        : ""
                                                    }
                                                </td>
                                            </tr>
                                        })
                                        :
                                        <tr>
                                            <td colSpan="7" align="center">
                                                <div className="text-center">
                                                    <img src={AllorderEmptyStates} alt=""/>
                                                    <p className="mt-3">No transactions for the selected option</p>
                                                </div>
                                            </td>
                                        </tr>
                            }

                            </tbody>
                        </table>
                        {
                            this.state.loading ?
                                null
                                :
                                this.state.stopLoadMore ?
                                    ""
                                    :
                                    <React.Fragment>
                                        <center>
                                            <div className="my-3">
                                                <img src={require('../../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                            </div>
                                        </center>
                                    </React.Fragment>
                        }
                    </div>
                </div>
                <Dialog isOpen={this.state.isVisibleCancelConfirmBox}
                        title={"Cancel Booking"}
                        className="bidModal"
                        style={{marginTop:'250px'}}
                        onClose={this.closeCancelConfirmBox.bind(this)} >
                    <div className="bidForm m-3 text-center">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="text-left f-16">Are you sure you want to cancel the booking ?</h4>
                            </div>
                            <div className="col-12">
                                <div className="bidFormBtn mt-4 mb-4">
                                    <Button
                                        text={"No"}
                                        intent={"default"}
                                        onClick={() => {
                                            this.setState({
                                                isVisibleCancelConfirmBox:false
                                            })
                                        }}
                                    />
                                    <Button
                                        text={"Yes"}
                                        intent={"primary"}
                                        onClick={() => this.getHotelCancelBooking(this.state.hotelId)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return{
        hotelList:state.AllOrders.hotelList,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getHotelList :(page) => {
            return getHotelList(page)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(getHotelListAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(getHotelListAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
        getHotelInvoice:(bookingID) => {
            return getHotelInvoice(bookingID)
        },
        getHotelCancelBooking:(bookingID) => {
            return getHotelCancelBooking(bookingID)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Booking);