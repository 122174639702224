import React, {Component} from 'react';
import MegaBrandsFav from './Container/MegaBrandsFav';

export default class Megabrand extends Component{
    constructor(props){
        super(props);
        this.state={
            loadingData : true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 1000);
    }

    render(){
        return(
            <React.Fragment>
                <MegaBrandsFav loading={this.state.loadingData}/>
            </React.Fragment>
        );
    }
}