import React from 'react';
import { Tab, Tabs } from "@blueprintjs/core";
import UserReferrals from './UserReferrals/Container/Users';
import VendorReferrals from './VendorReferrals/Container/VendorUsers';
import {Auth, NoLogin} from "gg-react-utilities";
import TopBar from "../core/components/TopBar";
import PageTitle from "../core/components/PageTitle";


export default class Referrals extends React.Component {
    constructor(props){
        super();
        this.state = {
            isLoggedIn :false
        }
    }
    componentDidMount() {

        this.checkAuth();

        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1);
    }


    render(){
        if (this.state.isLoggedIn === false){
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <PageTitle title="GG Referrals - Global Garner"/>
                        <TopBar title="GG Referrals" />
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );
        }

        return(
            <div className="container">
                <div className="top-bar"></div>
                <Tabs
                    animate={false}
                    id="TabsExample"
                    renderActiveTabPanelOnly={true} vertical={false}>
                    <Tab id="rx" title="User Referrals" panel={<UserReferrals/>}/>
                    <Tab id="ng" title="Vendor Referrals" panel={<VendorReferrals/>}/>
                </Tabs>
                <div className="page-content-right">
                </div>
            </div>
        );
    }
}