import React, { useState } from 'react';
import MetaTag from './MetaTag'

function ShareImage(props) {
    var data = decodeURI(props.location.search);
    data = data.split("&");
    var image = data[0] ? ('https://tinyurl.com/'+data[0].replace("?url=",'')) : 'https://s3.ap-south-1.amazonaws.com/gg-updates/2019/02/GG+Feature+graphics.png';
    var title= data[1] ? data[1].replace("title=",'') : "World's first website which gives you 100% Cashback on every purchase";
    var description = data[2] ? data[2].replace("description=",'') : 'Global Garner gives you Real 100% Cashback, which can be transferred to your bank, on anything you purchase through our application.';
    return (
        <div>
            <MetaTag image={image}></MetaTag> 
            <section className="pt-5 pb-5">
                <div className="container">
                        <h1 class="entry-title mb-3 text-left">{title}</h1>
                        <p className="share-img-discription text-left"><strong>{description}</strong> <br />
                        </p>
                    <img className="w-100" src={image} />
                </div>
            </section>
        </div>
    );
}
export default ShareImage;