import {connect} from 'react-redux';
import {getMegaBrandOrderList} from '../../../core/actions/action';
import Megabrand from '../components/MegaBrandIndex';

const mapStateToProps = state => {

    return{
        megaBrandsOrderList:state.MegaBrandsOrders.megaBrandsOrderList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getMegaBrandOrderList:(params) => {
            return getMegaBrandOrderList(params);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Megabrand);
