import React, { Component } from 'react';
import {Dialog, Button, Toaster, Position, Intent} from '@blueprintjs/core';
import config from "../../../Config";
import Loader from "../../../core/components/Loader";
import InsertLoader from "../../../core/images/loader.gif";

export default class upvReplaceInbox extends Component{
    constructor(props){
        super();
        this.state= {
            data:[],
            counter:1,
            isSkeleton:true,
            isLoading:false,
            scrollLoader:true,
            stopLoadMore: false,
            isUpvReplaceRequestOptionModal:false,
            ReplaceOption:null,
            upvId:null,
            newChildUpvId:null
        }
    }

    componentWillReceiveProps(nextProps) {
        let oldData = this.state.data;
        let newDataFromApi = nextProps.upvReplaceInboxList.data;
        let newData = oldData.concat(newDataFromApi);
        this.setState({
            data:newData
        });

        if(nextProps.upvReplaceInboxList.current_page === nextProps.upvReplaceInboxList.last_page){
            this.setState( {
                stopLoadMore: true,
            })
        }else{
            this.setState( {
                stopLoadMore: false,
            })
        }
        console.log(nextProps.upvReplaceInboxList.data);
    }


    componentDidMount() {
        this.props.getUpvReplaceInboxList(this.state.counter)
        setTimeout(() => {
            this.setState({
                isSkeleton:false
            })
        }, 1000);
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            this.loadMore()
        }
    }

    loadMore = () =>{
        this.setState({
            scrollLoader:false
        });
        setTimeout(() => {
            this.setState({
                counter: this.state.counter + 1,
                isLoading: true,
                scrollLoader: true
            }, () => {
                this.props.getupvReplaceInboxList(this.state.counter);
            })
        },1000)
    }

    upvReplaceInboxOption(upvID,newChildUpvId,status){
        this.setState({
            isLoading:true
        })
        let obj ={
            "new_child_upv_id":newChildUpvId
        }
        this.props.upvReplaceInboxOption(upvID, obj, status)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    this.setState({
                        isVisibleSellModal:false,
                    })
                    setTimeout(() => {
                        window.location.href=config.APP_URL+'/upv-replace';
                    },2000)
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                    this.setState({
                        isLoading:false
                    })
                }
            }).catch((error)=>{
                Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                this.setState({
                    isLoading:false
                })
            })
    }

    closeOptionModal(){
        this.setState({
            isUpvReplaceRequestOptionModal:false
        })
    }
    render(){
        let str, strp;
        return(
            <React.Fragment>
                <div className="upvReplace mt-4">
                    <div id={"allBooking"} onScroll={this.handleScroll} className="">
                        <div id={"fifoWrapper1"} className="upvReplaceWrapper">
                            <div className="row">
                                {
                                    !this.state.isSkeleton ?
                                        this.state.data.length ?
                                            this.state.data.map((res, index) => {
                                                return (
                                                    <div className="col-sm-6 col-12 pb-4">
                                                        <div className="upvReplaceSection">
                                                            <div className="row pb-3">
                                                                <div className="col-md-5 col-sm-12 col-12">
                                                                    <div className="replace-icon d-flex align-items-center justify-content-between">
                                                                        <div>
                                                                            <img src={require('../../images/check.svg')} alt="" className="img-fluid" />
                                                                        </div>
                                                                        <div className="pl-3 pr-s-2">
                                                                            <h4>{res.parent_username}</h4>
                                                                            <h5>UPV No. {res.upv_id}</h5>
                                                                            <h6>{res.created_at}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2 col-sm-12 col-12 px-1 py-s-3">
                                                                    <div className="replace-count d-flex align-items-baseline justify-content-center">
                                                                       <h6>Under<br/> > > > > ></h6>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-5 col-sm-12 col-12">
                                                                    <div className="replace-user d-md-flex align-items-baseline flex-column justify-content-end">
                                                                        <h4>{res.new_child_username}</h4>
                                                                        <h5>UPV No. {res.new_child_upv_id}</h5>
                                                                        {
                                                                            res.status_changed_at !== null ?
                                                                                <h6>{res.created_at}</h6>
                                                                            :''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 border-top pt-2 actions px-0">
                                                                <div className="d-sm-flex align-items-baseline justify-content-end">
                                                                    {
                                                                        res.replacement_status === 'PENDING' ?
                                                                            <React.Fragment>
                                                                            <Button
                                                                                className="bp3-outline bg-white bp3-small"
                                                                                text="Decline"
                                                                                onClick={()=>{
                                                                                    this.setState({
                                                                                        upvId:res.upv_id,
                                                                                        newChildUpvId:res.new_child_upv_id,
                                                                                        ReplaceOption:'DECLINED',
                                                                                        isUpvReplaceRequestOptionModal:true
                                                                                    })
                                                                                }}
                                                                            />
                                                                            <Button
                                                                                className="ml-3 bp3-small"
                                                                                text="Approve"
                                                                                intent={Intent.PRIMARY}
                                                                                onClick={()=>{
                                                                                    this.setState({
                                                                                        upvId:res.upv_id,
                                                                                        newChildUpvId:res.new_child_upv_id,
                                                                                        ReplaceOption:'APPROVED',
                                                                                        isUpvReplaceRequestOptionModal:true
                                                                                    })
                                                                                }}
                                                                            />
                                                                            </React.Fragment>
                                                                            :
                                                                            res.replacement_status === 'APPROVED' ?
                                                                                <span className="text-success">{res.replacement_status}</span>
                                                                                :
                                                                                <span className="text-danger">{res.replacement_status}</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        :
                                            <div className="col-md-12 pt-5">
                                                <div className={"mg-empty-state"}>
                                                    <div className="text-center">
                                                        <p className="pt-5 mb-4">no more Circle UPV replacement requests received for your UPVs.</p>
                                                    </div>
                                                </div>
                                            </div>
                                    :
                                    <div className="col-12 text-center">
                                        <Loader/>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        this.state.last_page < this.state.counter ? null :
                            this.state.scrollLoader ?
                                null :
                                <React.Fragment>
                                    <center>
                                        <div className="my-3">
                                            <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>

                    }
                </div>
                <Dialog isOpen={this.state.isUpvReplaceRequestOptionModal}
                        title={this.state.ReplaceOption === 'APPROVED' ? 'Approve UPV' : 'Decline UPV'}
                        className="bidModal"
                        style={{marginTop:'250px'}}
                        onClose={this.closeOptionModal.bind(this)} >
                    <div className="bidForm m-3 text-center">
                        <p className="text-left f-14">
                            {
                                this.state.ReplaceOption === 'APPROVED' ?
                                    'Press Confirm to add your UPV - '+this.state.upvId+' under UPV - '+this.state.newChildUpvId
                                :
                                    'Press Confirm to decline add your UPV - '+this.state.upvId+' under UPV - '+this.state.newChildUpvId
                            }
                        </p>
                        <div className="row">
                            <div className="col-12">

                            </div>
                            <div className="col-12">
                                {
                                    this.state.isLoading ?
                                        <div className="bidFormBtn mt-4 mb-4">
                                            <div className="bidFormBtn mt-2 mb-2">
                                                <img src={InsertLoader} alt={""}
                                                     style={{width: '50px', height: '50px'}}/>
                                            </div>
                                        </div>
                                    :
                                        <div className="bidFormBtn mt-4 mb-4">
                                            <Button
                                                text={"Cancel"}
                                                intent={"default"}
                                                onClick={() => {
                                                    this.setState({
                                                        isUpvReplaceRequestOptionModal: false
                                                    })
                                                }}
                                            />
                                            <Button
                                                text={"Confirm"}
                                                intent={"primary"}
                                                onClick={() => {
                                                    this.upvReplaceInboxOption(this.state.upvId, this.state.newChildUpvId, this.state.ReplaceOption)
                                                }}
                                            />
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}