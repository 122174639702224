import React, { Component } from 'react';
import {Auth} from "gg-react-utilities";
import {Classes, Dialog, Intent, Position, Toaster} from "@blueprintjs/core";
import PageTitle from "../../../core/components/PageTitle";
import TopBar from "../../../core/components/TopBar";
import CircleTree from "./../container/circleContainer/CircleTreeContainer";

export default class Circles extends Component{
    constructor(props) {
        super(props);
        this.state = {
            upv: {},
            upvID: props.match.params.upvID,
            targetsAndAcheivements: [],
            childLists: [],
            nextChildLists: [],
            isSkeleton: true,
            maxCirclesAllowed: null,
            isLoader:false,
            completedCircles: null,
            treeViewOpen: false,
            prevReferralID: null,
            circlesData: [],
            isLoading: false,
            prevUPVID: [],
            upvIndex: 0,
            openCashbackTree: false,
            CashbackTree: [],
            ownUpvsWithoutTag:[],
            circleUpvInformationStatus: false,
            circleUpvInformation:[],
            isReplaceCircleUpv:false,
            newChildUpvId:null,
            ReplaceCircleStatus:false,
            isNotOwnUpv:false,
            NotOwnUpvMsg:''
        };

    }

    componentDidMount() {

        this.checkAuth();
        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);
    }

    async checkAuth() {
        setTimeout(() => {
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn: res,
                }, () => {
                    this.getNextLevel(this.state.upvID)
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        }, 1);
    }

    getNextLevel(upvID, index) {
        this.setState({
            isLoading: true
        })
        let obj = {};
        if(index === undefined){
            obj = { is_own_upv : 1}
        }
        this.props.getUPVLevels(upvID,obj)
            .then((res) => {
                const sources = res.data;
                if (sources.status === true) {
                    this.setState({
                        treeViewOpen: true,
                        isLoading: false
                    }, () => {

                        if(this.state.circlesData && this.state.circlesData.length) {
                            this.state.prevUPVID.splice((index), ((this.state.prevUPVID.length)))
                            this.state.prevUPVID.push(upvID);
                            this.state.circlesData.splice(index, ((this.state.circlesData.length) - index))
                        }else{
                            this.setState({
                                isSkeleton: false,
                                targetsAndAcheivements: sources.data.targets_and_achievements,
                                maxCirclesAllowed: sources.data.max_circles_allowed,
                                childLists: sources.data.child_list,
                                ownUpvsWithoutTag:sources.data.own_upvs_without_tag,
                                prevUPVID: [upvID]
                            })
                        }
                        let oldData = this.state.circlesData;
                        let newDataFromApi = sources.data;
                        let newData = oldData.concat(newDataFromApi);
                        this.setState({
                            circlesData: newData,
                            ownUpvsWithoutTag:sources.data.own_upvs_without_tag,
                        })
                    })
                } else {
                    Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                }
            }).catch((error) => {
                this.setState({
                    isLoading: false
                })
                if (error.response && error.response.status !== 401) {
                    Object.keys(error.response.data.error).forEach((key) => {
                        error.response.data.error[key].forEach((error) => {
                            if(error === 'NOT_OWN_UPV'){
                                this.setState({
                                    isNotOwnUpv:true
                                })
                            }else {
                                this.setState({
                                    NotOwnUpvMsg:error
                                })
                                Toaster.create({position: Position.TOP}).show({
                                    message: error,
                                    intent: Intent.DANGER
                                });
                            }
                        });
                    });
                }
        })
    }

    openCashbackTree() {
        this.props.getCircleUPVLevelList()
            .then((res) => {
                const sources = res.data;
                if (sources.status === true) {
                    this.setState({
                        CashbackTree: sources.data,
                    })
                } else {
                    Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                }
            }).catch((error) => {
            if (error.response.status == 401) {
                Toaster.create({position: Position.TOP}).show({
                    message: "Please login to continue",
                    intent: Intent.DANGER
                });
            } else {
                if (error.response && error.response.status !== 401) {
                    Object.keys(error.response.data.error).forEach((key) => {
                        error.response.data.error[key].forEach((error) => {
                            Toaster.create({position: Position.TOP}).show({
                                message: error,
                                intent: Intent.DANGER
                            });
                        });
                    });
                }
            }
        })
        this.setState({
            openCashbackTree: true
        });
    }

    closeCashbackTree() {
        this.setState({
            openCashbackTree: false
        });
    }

    render(){
        const upv = this.props.match.params.upvID;
        return (
            <div className="container mb-5">
                <PageTitle title="UPV Exchange Info - Global Garner"/>
                <div className="exchangeWrapper">
                    <div className="exchangeTopBar">
                        <TopBar
                            title={"Circle Number : " + upv}
                            rightBar={upv}
                            openCashbackTree={()=>{this.openCashbackTree()}}
                        />
                    </div>
                    <div className="exchangeInfoContainer text-left">
                            {
                                this.state.isNotOwnUpv ?

                                        <div className="emptyState" style={{"background-color":"white"}}>
                                            <img src={require('../../../core/images/empty-state/Allorder.svg')} alt=""/>
                                            <p className="mt-3">{this.state.NotOwnUpvMsg}</p>
                                        </div>
                                    :
                                    <React.Fragment>
                                    <div className="container py-5" style={{background: '#F8FAFF'}}>
                                        <ul className="progressbar">
                                            {

                                                this.state.targetsAndAcheivements.length === 0 ?
                                                    <React.Fragment>
                                                        <li className={this.state.isSkeleton ? "bp3-skeleton" : ""}>6
                                                            upv <strong>&#8377; 500</strong></li>
                                                        <li className={this.state.isSkeleton ? "bp3-skeleton" : ""}>36
                                                            upv <strong>&#8377; 1000</strong></li>
                                                        <li className={this.state.isSkeleton ? "bp3-skeleton" : ""}>216
                                                            upv <strong>&#8377; 3500</strong></li>
                                                        <li className={this.state.isSkeleton ? "bp3-skeleton" : ""}>1296
                                                            upv <strong>&#8377; 5000</strong></li>
                                                        <li className={this.state.isSkeleton ? "bp3-skeleton" : ""}>7776
                                                            upv <strong>&#8377; 18000</strong></li>
                                                        <li className={this.state.isSkeleton ? "bp3-skeleton" : ""}>46656
                                                            upv <strong>&#8377; 50,000</strong></li>
                                                        <li className={this.state.isSkeleton ? "bp3-skeleton" : ""}>279936
                                                            upv <strong>&#8377; 1,50,000</strong></li>
                                                        <li className={this.state.isSkeleton ? "bp3-skeleton" : ""}>1679616
                                                            upv <strong>&#8377; 6,72,000</strong></li>
                                                        <li className={this.state.isSkeleton ? "bp3-skeleton" : ""}>0077696
                                                            upv <strong>&#8377; 21,00,000</strong></li>
                                                    </React.Fragment>
                                                    :
                                                    this.state.targetsAndAcheivements.map((res, i) => {
                                                        return (
                                                            <li className={res.is_target_achieved ? "active" : null}
                                                                key={i}>{res.target} upv <strong>&#8377; {res.achievement}</strong>
                                                            </li>
                                                        )
                                                    })
                                            }

                                        </ul>
                                    </div>
                                    <div className="container py-5 py-s-4">
                                        <div className="tree">
                                            <CircleTree
                                                upvID={this.state.upvID}
                                                circlesData={this.state.circlesData}
                                                ownUpvsWithoutTag={this.state.ownUpvsWithoutTag}
                                                prevUPVID={this.state.prevUPVID}
                                                getNextLevel={(referralID,Index) => { this.getNextLevel(referralID,Index)}}
                                                getNextLevelLoading={this.state.isLoading}
                                                treeViewOpen={this.state.treeViewOpen}/>
                                        </div>
                                    </div>
                                </React.Fragment>
                             }
                    </div>
                </div>
                <Dialog
                    isOpen={this.state.openCashbackTree}
                    onClose={this.closeCashbackTree.bind(this)}
                    title="Cashback tree"
                    className="bp3-dialog-large CashbackTree">
                    <div className={Classes.DIALOG_BODY+ ' mt-0'}>
                        <ul className="timeline">
                            {
                                this.state.CashbackTree ?
                                    this.state.CashbackTree.map((data,key)=>{
                                        return(
                                            <li key={key}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h4>{data.level_title}</h4>
                                                    <h4 className="float-right">{data.amount}</h4>
                                                </div>
                                                <p>{data.message}</p>
                                            </li>
                                        )
                                    })
                                    :''
                            }
                        </ul>
                    </div>
                </Dialog>
            </div>
        );
    }
}