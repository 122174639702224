import React from 'react';
import {Auth, NoLogin, Helper} from "gg-react-utilities";
import TopBar from "../../../core/components/TopBar";
import PageTitle from "../../../core/components/PageTitle";
import {Link} from "react-router-dom";
import {Button, FormGroup, Intent, Position, Toaster} from "@blueprintjs/core";
import {UPVConversionHistory} from "../../../core/actions/action";
import {connect} from "react-redux";
import {Select} from "react-inputs-validation";
import ConversionListCard from "./ConversionListCard";


class ConversionHistory extends React.Component{
    constructor(props){
        super(props);
        this.state= {
            sort:"",
            isSkeleton:true,
            scrollLoader:false,
            downloadLoader:false,
            counter:1,
            upvConversionList:[],
            oldUpvType:'',
            newUpvType:'',
            last_page:''
        }
    }

    componentDidMount() {
        this.checkAuth();
        this.upvConversionHistory(this.state.counter)
        window.addEventListener('scroll', this.handleOnScroll);
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1);
    }

    upvConversionHistory = (page,isFilter=false) => {

        if(isFilter){
            this.setState({
                upvConversionList:[]
            })
        }

        let oldUpvType = this.state.oldUpvType;
        let newUpvType = this.state.newUpvType;
        this.props.UPVConversionHistory(page,oldUpvType,newUpvType)
            .then((res) => {
                const sources = res.data;
                if(sources.status===true){
                    let oldData = this.state.upvConversionList;
                    let newDataFromApi = sources.data.data;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState({
                        upvConversionList:newData,
                        last_page:sources.data.last_page,
                        isSkeleton:false,
                        scrollLoader:false
                    })
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                    this.setState({
                        scrollLoader:false
                    })
                }
            }).catch((error) => {

                if (error.response && error.response.status !== 401) {

                    Object.keys(error.response.data.error).forEach((key) => {
                        error.response.data.error[key].forEach((error) => {
                            Toaster.create({position: Position.TOP}).show({
                                message: error,
                                intent: Intent.DANGER
                            });
                        });
                    });
                    this.setState({
                        scrollLoader:false
                    })
                }
            });
    }

    handleScroll=()=> {

        if(this.state.scrollLoader != true && this.state.last_page > this.state.counter) {
            var allConversion = document.getElementById('allConversion');
            var allConversionHeight = (allConversion.scrollHeight) - (allConversion.scrollTop + 590);

            if (parseInt(allConversionHeight) === 0) {
                this.loadMore();
            }
        }
    }

    loadMore = () =>{
        this.setState({
            counter: this.state.counter + 1,
            scrollLoader:true
        }, () => {
            this.upvConversionHistory(this.state.counter);
        })
    }

    render(){
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <PageTitle title="My UPV Conversion"/>
                        <TopBar title="My UPV Conversion" />
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }

        return(
            <div className="container mb-5">
                <PageTitle title="My UPV Conversion"/>
                <div className="exchangeWrapper">
                    <div className="exchangeTopBar">
                        <TopBar title="My UPV Conversion" />
                    </div>
                    <div className="exchangeContainer">
                        <div className="col-12">
                            <hr/>
                        </div>
                        <div className="bidsWrapper">
                            <div className="content mt-lg-4">
                                <div className="row m-1 justify-content-end">
                                    <div className="col-md-3 col-sm-6 col-12 p-0">
                                        <div className="row m-1 justify-content-between">
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <label><b>Convert</b></label>
                                            </div>
                                            <div className={this.state.isSkeleton===true ? "bp3-skeleton col-md-6 col-sm-6 col-12":"col-md-6 col-sm-6 col-12"}>
                                                <Select
                                                    className="w-100"
                                                    tabIndex="0"
                                                    id={"upvType"}
                                                    name="upvType"
                                                    disabled={false}
                                                    value={this.state.oldUpvType}
                                                    onChange={(e) =>{
                                                        this.setState({
                                                            oldUpvType:e,
                                                            isSkeleton:true,
                                                            counter:1,
                                                            upvConversionList:[],
                                                        },()=>{
                                                            this.upvConversionHistory(this.state.counter,true);
                                                        });
                                                    }}
                                                    optionList={[{id:'',name:'All'},{id:'FIFO',name:'FIFO'},{id:'RPP',name:'RPP'},{id:'CIRCLE',name:'CIRCLE'}]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-12 p-0 mr-3">
                                        <div className="row m-1 justify-content-between">
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <label><b>To</b></label>
                                            </div>
                                            <div className={this.state.isSkeleton===true ? "bp3-skeleton col-md-6 col-sm-6 col-12 pr-0":"col-md-6 col-sm-6 col-12 pr-0"}>
                                                <Select
                                                    className="w-100"
                                                    tabIndex="0"
                                                    id={"upvType"}
                                                    name="upvType"
                                                    disabled={false}
                                                    value={this.state.newUpvType}
                                                    onChange={(e) =>{
                                                        this.setState({
                                                            newUpvType:e,
                                                            isSkeleton:true,
                                                            counter:1,
                                                            upvConversionList:[],
                                                        },()=>{
                                                            this.upvConversionHistory(this.state.counter,true);
                                                        });
                                                    }}
                                                    optionList={[{id:'',name:'All'},{id:'FIFO',name:'FIFO'},{id:'RPP',name:'RPP'},{id:'CIRCLE',name:'CIRCLE'}]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row" style={{height:'100%',maxHeight:'590px',overflowY:'scroll'}} id={"allConversion"} onScroll={this.handleScroll}>
                                        {
                                            this.state.isSkeleton ?
                                                (Array.apply(0, Array(10))).map((value, key) => {
                                                    return (
                                                        <div className={"col-12 col-lg-6 col-md-6 col-sm-6 col-xs-12"}>
                                                            <div className={"bidBlock p-3 mt-3 bp3-skeleton"}>
                                                                <div className="row">
                                                                    <div className="col-12 col-lg-2 col-md-2 col-sm-2">
                                                                        <div className="upvRight mb-2">
                                                                            <h5 className={"m-0"}>UPV No</h5>
                                                                            <h4 className={"m-0"}>"0001"</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-lg-10 col-md-10 col-sm-10">
                                                                        <div className="upvLeft text-left">
                                                                            <div className={"topbar"}>
                                                                                <h4 className="m-0 nameText">"FIFO"</h4>
                                                                                <h4 className={"m-0 f-5"}
                                                                                    style={{fontWeight: '500'}}>"0000"</h4>
                                                                            </div>
                                                                            <h4 className="m-0 priceText">Conversion Date :
                                                                                03/12/2019</h4>
                                                                            <h5 className="m-0 timeText">Generated Date :
                                                                                03/12/2019}</h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                             :
                                                this.state.upvConversionList.length === 0 ?
                                                    <div className="emptyState">
                                                        <img src={require('../../../core/images/empty-state/UPV.svg')} alt=""/>
                                                    </div>
                                                :
                                                    this.state.upvConversionList.map((res, i) => {
                                                        return (
                                                            <ConversionListCard listData={res} key={i}/>
                                                        );
                                                    })
                                        }
                                        </div>
                                        {

                                            this.state.scrollLoader ?
                                                <React.Fragment>
                                                    <div className="col-12 text-center">
                                                        <div className="my-3">
                                                            <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            :null

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}


const mapDispatchToProps = dispatch => {

    return {
        UPVConversionHistory:(page,oldUpvType,newUpvType) => {
            return UPVConversionHistory(page,oldUpvType,newUpvType)
        }
    }
}

export default connect(null, mapDispatchToProps)(ConversionHistory);

