import {connect} from 'react-redux';
import {getStoreServiceBookingList} from '../../../../core/actions/action';
import ServiceBooking from '../Components/ServiceBooking';

const mapStateToProps = state => {

    return{
        serviceBookingList:state.Travel.serviceBookingList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getStoreServiceBookingList:(params) => {
            return getStoreServiceBookingList(params);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceBooking);
