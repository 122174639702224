import React from 'react';
import {Alert, Button, Intent} from "@blueprintjs/core";

export default class RejectCoin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenRejectCoin: false,
        }

        this.handleCloseRejectCoin = this.handleCloseRejectCoin.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.isOpenRejectCoin === true){
            this.handleCloseRejectCoin();
        }
    }

    handleOpenRejectCoin = () =>{
        this.setState({ isOpenRejectCoin: true });
    }

    handleCloseRejectCoin(){
        this.setState({ isOpenRejectCoin: false});
    }

    rejectRequest = () => {
        this.props.rejectCoinRequest(this.props.reuqestNo)
    }

    render(){
        return(
            <React.Fragment>
                <Button intent="danger" className="bp3-button bp3-small bp3-intent-danger ml-1" text="Decline" onClick={this.handleOpenRejectCoin}/>
                <Alert
                    className="bp3-small"
                    icon="trash"
                    cancelButtonText="Cancel"
                    confirmButtonText="Reject"
                    intent={Intent.DANGER}
                    isOpen={this.state.isOpenRejectCoin}
                    onCancel={this.handleCloseRejectCoin}
                    onConfirm={this.rejectRequest}
                    canOutsideClickCancel="true"
                    canEscapeKeyCancel="true"
                >
                    <p className="warningHeading">Are You sure you want to reject this request?</p>
                </Alert>
            </React.Fragment>
        );
    }
}