import {connect} from "react-redux";
import { advertiseImg, getRefral } from '../../../core/actions/action';
import Advertise from '../Components/Advertise';
const mapStateToProps = state =>{

    return{
    }

}

const mapDispatchToProps = dispatch =>{
    return{
        advertiseImg : (params) =>{
            return advertiseImg(params);
        },
        getRefral : (name,mobileNumber,file,email,location,iframeImg) =>{

            let formData = new FormData();
            formData.append('name', name);
            formData.append('email', email);
            formData.append('mobile_no', mobileNumber);
            formData.append('location', location);
            formData.append('image', file);
            formData.append('image_template', iframeImg);

            return getRefral(formData);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Advertise)