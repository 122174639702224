let initialState = {
    orderList:[],
    viewUserBill:null,
};

const Bsw = (state = initialState, action) => {

    switch (action.type) {

        case 'BSW_ORDER':
            return {...state, orderList: action.sources}
        case 'BSW_USER_BILLS':
            return {...state, viewUserBill: action.sources}
        default:
            return state
    }
}

export default Bsw;
