import {connect} from 'react-redux';
import {getHrInquiryList} from '../../../core/actions/action';
import HrInquiriesIndex from '../components/HrInquiriesIndex';

const mapDispatchToProps = dispatch => {

    return {
        getHrInquiryList:(params) => {
            return getHrInquiryList(params);
        },
    }
}

export default connect(null, mapDispatchToProps)(HrInquiriesIndex);
