import {connect} from "react-redux";
import {getRefVendorUserList} from '../../../core/actions/action';
import VendorReferrals from '../../VendorReferrals';
const mapStateToProps = state =>{

    return{
        userList:state.Users.userList,
    }

}

const mapDispatchToProps = dispatch =>{
    return{
        getRefVendorUserList : (params) =>{
            return getRefVendorUserList(params);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorReferrals)