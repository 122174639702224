import {connect} from 'react-redux';
import {getRailBhojOrderList} from '../../../core/actions/action';
import RailBhojOrder from '../Components/RailBhojIndex';

const mapStateToProps = state => {

    return{
        railBhojOrderList:state.Travel.railBhojOrderList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getRailBhojOrderList:(params) => {
            return getRailBhojOrderList(params);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RailBhojOrder);
