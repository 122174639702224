import {connect} from 'react-redux';
import {getBSWOrderList} from '../../../core/actions/action';
import Shopping from '../Index';

const mapStateToProps = state => {

    return{
        megaBrandsOrderList:state.MegaBrandsOrders.megaBrandsOrderList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getBSWOrderList:(params) => {
            return getBSWOrderList(params);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Shopping);
