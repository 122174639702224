import React, { Component } from 'react';
import {RadioGroup, Radio} from "@blueprintjs/core";
import OldRecharge from "./oldRecharge/Container/RechargeContainer";
import NewRecharge from "./newRecharge/Container/RechargeContainer";

export default class Megabrands extends Component{
    constructor(props){
        super(props);
        this.state={
            selectedOption:1
        };
        this.handleOption=this.handleOption.bind(this);
    }
    handleOption(e){
        this.setState({
            selectedOption:Number(e.target.value)
        });
    }
    render(){
        return(
            <React.Fragment>
                <div className="megabrand">
                    <div className="megabrandOption">
                        <RadioGroup
                            onChange={this.handleOption}
                            name="type"
                            className="d-inline option-type"
                            selectedValue={this.state.selectedOption}>
                            <Radio label="New Recharge" value={1} />
                            <Radio label="Old Recharge" value={2} />
                        </RadioGroup>
                    </div>
                    {
                        this.state.selectedOption===1 ?
                            <NewRecharge/>
                            :
                            this.state.selectedOption===2 ?
                                <OldRecharge/>
                            :
                                <NewRecharge/>
                    }
                </div>
            </React.Fragment>
        );
    }
}