import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import RewardList from '../components/rewardList';
import { getRewardList, changeVoucherStatus, setVoucherCode } from '../../core/actions/action';
import { getRewardListAction, changeVoucherStatusAction } from '../../core/actions';

const mapStateToProps = state => {

    return{
        rewardList:state.cashbackReducer.rewardList,
        voucherStatus:state.cashbackReducer.voucherStatus,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getRewardList :(obj) => {
            return getRewardList(obj)
                /*.then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(getRewardListAction(sources.data));
                    }else{
                        //Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                        dispatch(getRewardListAction([]));
                    }
                }).catch((res)=>{
                    Toaster.create({position: Position.TOP}).show({message:'something went wrong',intent: Intent.DANGER});
                    dispatch(getRewardListAction([]));
                })*/
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RewardList);
