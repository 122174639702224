import React, {Component} from 'react';
import AllorderEmptyStates from "../../../../core/images/empty-state/Allorder.svg";
import {Button} from "@blueprintjs/core";

export default class FlightOrder extends Component{
    constructor(props){
        super(props);
        this.state={
            orderList:[],
            loading:true,
        };
    }

    componentDidMount(){
        this.setState( {
            loading: this.props.loading,
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    renderStatusHtml(status){

        switch (status) {
            case 0:
                return <label className="label text-warning">Payment Pending</label>;
            case 1:
                return <label className="label text-success">Payment Success</label>;
            case 2:
                return <label className="badge text-danger">Payment Failed</label>;
            case 3:
                return <label className="badge text-warning">Refund In Process</label>;
            case 4:
                return <label className="badge text-success">Payment Refunded</label>;
            case 5:
                return <label className="badge text-success">Booking In Process</label>;
            case 6:
                return <label className="badge text-danger">Booking Failed</label>;
            case 7:
                return <label className="badge text-success">Booking Success</label>;
            case 8:
                return <label className="badge text-warning">Cancelled Requested</label>;
            case 9:
                return <label className="badge text-danger">Booking Cancelled</label>;
            case 10:
                return <label className="badge text-warning">Cancelled Requested</label>;
            case 11:
                return <label className="badge text-danger">Booking Cancelled</label>;
            case 12:
                return <label className="badge text-warning">Cancelled Requested</label>;
            case 13:
                return <label className="badge text-danger">Booking Cancelled</label>;
            case 14:
                return <label className="badge text-warning">Refund In Process</label>;
            case 15:
                return <label className="badge text-warning">Refund In Process</label>;
            case 16:
                return <label className="badge text-warning">Refund In Process</label>;
            case 17:
                return <label className="badge text-success">Refunded</label>;
            case 18:
                return <label className="badge text-success">Refunded</label>;
            case 19:
                return <label className="badge text-success">Refunded</label>;
            case 20:
                return <label className="badge text-success">Send To Upv</label>;
            case 21:
                return <label className="badge text-warning">NA</label>;
            default:
                return <label className="label text-default">--</label>;
        }
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            if(this.props.stopLoadMore === false){
                this.props.NextPage()
            }
        }
    }

    renderTripHtml(trip){

        switch (trip) {
            case "0":
                return <label className="label">(Oneway trip)</label>;
            case "1":
                return <label className="label">(Round trip)</label>;
            default:
                return <label className="label text-default">--</label>;
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="flight" id={"allBooking"} style={{'height':'100%','maxHeight':'590px','overflowY':'scroll'}} onScroll={this.handleScroll}>
                    <table className="bp3-html-table table-borderless">
                        <thead>
                            <tr>
                                <th>Booking Details</th>
                                <th>Trip Details</th>
                                <th>Booking ID</th>
                                <th>Booking Date</th>
                                <th>Price</th>
                                <th>Cashback Type</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.loading ?
                                <tr>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                </tr>
                                :
                                this.props.orderList.length ?
                                    this.props.orderList.map((booking, key) => {
                                        return <tr key={key}>
                                            <td>
                                                <div className="orderTable">
                                                    <div className="image hidden-xs hidden-sm hidden-md visible-xl visible-lg">
                                                        <img src={"https://s3.ap-south-1.amazonaws.com/gg-flight/logo/"+booking.segment[0].originFlightLogo+".png"} alt=""/>
                                                    </div>
                                                    <div className="title">
                                                        <p>{booking.segment[0].originFlightName}</p>
                                                        <span>{booking.segment[0].originFlightLogo}-{booking.segment[0].originFlightNumber}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="orderTable">
                                                    <div className="title">
                                                        <p>{booking.segment[0].origin}- {booking.segment[0].destination}</p>
                                                        <span>
                                                            {
                                                                this.renderTripHtml(booking.trip_type)
                                                            }
                                                        </span>
                                                    </div>

                                                </div>
                                            </td>
                                            <td>{booking.bookingId}</td>
                                            <td>{booking.created_at}</td>
                                            <td><b>₹ {booking.amount}</b></td>
                                            <td>{booking.cb_choice_title}</td>
                                            <td>
                                                <b>
                                                    {
                                                        this.renderStatusHtml(booking.order_status)
                                                    }
                                                </b>
                                            </td>
                                            <td>
                                                {
                                                    booking.invoice_download_link !== null ?
                                                        <a href={booking.invoice_download_link} title="Download Ticket"  className="bp3-button bp3-small bp3-intent-primary btn-view" rel="noopener noreferrer" target="_blank">
                                                            <span className="bp3-icon bp3-icon-import bp3-text-medium"></span>
                                                        </a>
                                                        :
                                                        <Button small={true} intent="inform" disabled={true} className="btn-view mr-2" icon="import" title="Download Ticket"/>
                                                }
                                            </td>
                                        </tr>
                                    })
                                    :
                                    <tr>
                                        <td colSpan="7" align="center">
                                            <div className="text-center">
                                                <img src={AllorderEmptyStates} alt=""/>
                                                <p className="mt-3">No transactions for the selected option</p>
                                            </div>
                                        </td>
                                    </tr>
                        }
                        </tbody>
                    </table>
                    {
                        this.state.loading ?
                            null
                            :
                            this.props.stopLoadMore ?
                                ""
                                :
                                <React.Fragment>
                                    <center>
                                        <div className="my-3">
                                            <img src={require('../../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                    }
                </div>
            </React.Fragment>
        )
    }
}