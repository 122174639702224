import React, { Component } from 'react';
import {Helper} from "gg-react-utilities";
import {Button, Dialog, InputGroup, Intent, Position, Radio, RadioGroup, Toaster} from "@blueprintjs/core";
import {Link} from "react-router-dom";

export default class circleList extends Component{

    constructor(props) {
        super(props);
        this.state = {
            isVisibleAddCircleModal: false,
            title: "",
            milestones: {},
            error: false,
            hasMore: true,
            isLoading: false,
            data:[],
            counter:1,
            isSkeleton:true,
            total:0,
            totalCashback:0.00,
            last_page:1,
            scrollLoader:true,
            fpvID:"",
            referralID:"",
            isVisibleProceedModal:false,
            fpvDetail:[],
            newFpvType:"FIFO"
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.getCircleList(this.state.counter)
        },1000)
    }

    componentWillReceiveProps(nextProps) {
        let oldData = this.state.data;
        let newDataFromApi = nextProps.circleList.data;
        let newData = oldData.concat(newDataFromApi);
        this.setState({
            data:newData,
            isSkeleton:false,
            totalCashback:nextProps.circleList.total_cashback,
            total:nextProps.circleList.total,
            last_page:nextProps.circleList.last_page
        });
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('circleList');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);
        if (parseInt(allBookingHeight) === 0){
            this.loadMore()
        }
    }


    loadMore = () => {
        this.setState({
            scrollLoader:false
        })
        setTimeout(() => {
            this.setState({
                counter: this.state.counter + 1,
                isLoading: true,
                scrollLoader:true
            }, () => {
                this.props.getCircleList(this.state.counter);
            })
        },1000);
    }

    closeAddCircleModal(){
        this.setState({
            isVisibleAddCircleModal:false
        })
    }

    addCircle(fpvID){
        if(this.state.hasReferralIDError){
            Toaster.create({position: Position.TOP}).show({message:"Please enter referral FPV ID.",intent: Intent.DANGER});
        }else{
            let obj = {
                "referrer_fpv_id": this.state.referralID
            }
            this.props.addCircle(fpvID, obj)
                .then((res) => {
                    const sources = res.data;
                    if (sources.status === true) {
                        Toaster.create({position: Position.TOP}).show({message: sources.message, intent: Intent.SUCCESS});
                        this.setState({
                            isVisibleAddCircleModal: false,
                            counter:1,
                            data:[],
                            isSkeleton:true,
                            total:0.00,
                            last_page:1
                        },()=>{
                            setTimeout(() => {
                                this.props.getCircleList(this.state.counter)
                            },1000)
                        })
                    } else {
                        Toaster.create({position: Position.TOP}).show({message: sources.error, intent: Intent.DANGER});
                    }
                }).catch((error) => {
                this.setState({
                    referralID:"",
                })
                if (error.response && error.response.status !== 401) {

                    Object.keys(error.response.data.error).forEach((key) => {
                        error.response.data.error[key].forEach((error) => {
                            Toaster.create({position: Position.TOP}).show({
                                message: error,
                                intent: Intent.DANGER
                            });
                        });
                    });

                }
            })
        }
    }

    closeProceedModal(){
        this.setState({
            isVisibleProceedModal:false
        })
    }

    render(){
        const total = this.state.total;
        return(
            <React.Fragment>
                {this.props.circleList!==null ?
                    <div className="fifo mt-4" >
                        <div id={"fifoWrapper1"} className="fifoWrapper">
                            <div className="row">
                                <div className="col-sm-4 col-12">
                                    <div className="fifoSection w-100">
                                        <div className="icon">
                                            <img src={require('../../images/check.svg')} alt="" />
                                        </div>
                                        <div className="count">
                                            <h3 className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>{total==="" ? 0 : total }</h3>
                                            <p>Total No. of FPVs</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12">
                                    <div className="fifoSection w-100">
                                        <div className="icon">
                                            <img src={require('../../images/rupee.svg')} alt="" />
                                        </div>
                                        <div className="count">
                                            <h3 className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>{Helper.formatMoney(this.state.totalCashback)}</h3>
                                            <p>Total Circles Cashback</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-12">
                                    <Link to={{ pathname: '/fpv-replace' }}>
                                        <div className="fifoSection w-100">
                                            {/*<div className="icon">
                                                <img src={require('../../images/rupee.svg')} alt="" />
                                            </div>*/}
                                            <div className="count">
                                                {/*<h3 className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>{Helper.formatMoney(this.state.totalCashback)}</h3>*/}
                                                {/*<p style={{color: '#2e6aee'}}>Replace Fpv Approvals</p>*/}
                                                <Button
                                                    text={"Replace Fpv Approvals"}
                                                    intent={"primary"}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div style={{height:'100%',maxHeight:'590px',overflowY:'scroll'}} id={"circleList"} onScroll={this.handleScroll} className="table-responsive mt-4">
                            <table className="bp3-html-table bp3-html-table-bordered">
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>FPV Number</th>
                                    <th>Levels</th>
                                    <th>Cashback Amount</th>
                                    <th>Created Date</th>
                                    <th style={{width: "200px"}}>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.isSkeleton===true ?
                                    <tr>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>1</span></td>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>122322</span></td>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>₹ 2122</span></td>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>16 Aug 2982</span></td>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>16 Aug 2982</span></td>
                                        <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>16 Aug 2982</span></td>
                                    </tr>
                                    :
                                    this.state.data.length===0 ?
                                        <tr>
                                            <td colSpan="6" className="emptyState">
                                                <img src={require('../../../core/images/empty-state/UPV.svg')} alt=""/>
                                                <p className="mt-3" style={{'line-height': '25px'}}>No purchase done in your circle yet. Create Referrals to get 2% to 100% assured cashback along with huge conversion of GG Coins.</p>
                                            </td>
                                        </tr>
                                        :
                                        this.state.data.map((res, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td><span className={this.state.isSkeleton===true ? "bp3-skeleton" : ""}>{index+1}</span></td>
                                                    <td>{res.id}</td>
                                                    <td>Level - {res.levels}</td>
                                                    <td>{Helper.formatMoney(res.cashback_amount)}</td>
                                                    <td>{res.formatted_created_at}</td>
                                                    <td>
                                                        {
                                                            res.fpv_conversion_payment_id !== null ?
                                                                <span className="bp3-small bp3-bg-success text-white pl-1 pr-1">Converted your FPV</span>
                                                                :
                                                                <React.Fragment>
                                                                    <Link to={{pathname: '/fpv/circle/'+res.id}} className={"bp3-button bp3-small mr-1 mb-1"}>View</Link>
                                                                    {
                                                                        res.referral_fpv_id === null ?
                                                                            <React.Fragment>
                                                                                <Button className="bp3-small mr-1 bp3-bg-success mb-1"
                                                                                        text={"Add in Circle"}
                                                                                        onClick={() => {
                                                                                            let title = "Add Circle in FPV Number : " + res.id;
                                                                                            this.setState({
                                                                                                title: title,
                                                                                                fpvID: res.id,
                                                                                                isVisibleAddCircleModal: true
                                                                                            })
                                                                                        }}/>
                                                                                {/*<Button className="bp3-small mr-1 mb-1"
                                                                                        text={"Convert your FPV"}
                                                                                        intent={"primary"}
                                                                                        onClick={() => {
                                                                                            console.log(res);
                                                                                            this.setState({
                                                                                                fpvDetail: res,
                                                                                                isVisibleProceedModal: true
                                                                                            })
                                                                                        }}/>*/}
                                                                            </React.Fragment>
                                                                            :''
                                                                    }
                                                                </React.Fragment>
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                        {
                            this.state.last_page < this.state.counter ? null :
                                this.state.scrollLoader ?
                                    null :
                                    <React.Fragment>
                                        <center>
                                            <div className="my-3">
                                                <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                            </div>
                                        </center>
                                    </React.Fragment>
                        }
                    </div>
                    :null}

                <Dialog isOpen={this.state.isVisibleAddCircleModal}
                        title={this.state.title}
                        className="bidModal"
                        style={{marginTop:'250px'}}
                        onClose={this.closeAddCircleModal.bind(this)} >
                    <div className="bidForm m-3 text-center">
                        <div className="row">
                            <div className="col-12">
                                <InputGroup
                                    placeholder="Enter Referral FPV ID"
                                    small={false}
                                    type={"number"}
                                    value={this.state.referralID}
                                    onChange={(e) => {
                                        this.setState({
                                            referralID:e.target.value,
                                            hasReferralIDError:false
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <div className="bidFormBtn mt-4 mb-4">
                                    <Button
                                        text={"Cancel"}
                                        intent={"default"}
                                        onClick={() => {
                                            this.setState({
                                                isVisibleAddCircleModal:false
                                            })
                                        }}
                                    />
                                    <Button
                                        text={"Submit"}
                                        intent={"primary"}
                                        onClick={() => this.addCircle(this.state.fpvID)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog isOpen={this.state.isVisibleProceedModal}
                        title={"Convert your FPV"}
                        className="bidModal"
                        style={{marginTop:'250px'}}
                        onClose={this.closeProceedModal.bind(this)} >
                    <div className="bidForm m-3 text-center">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="f-16 text-left">Are you sure want to change Cashback Process?
                                </h4>
                            </div>
                            <div className="col-12 text-left">
                                <RadioGroup
                                    onChange={(e) => {
                                        this.setState({
                                            newFpvType:e.target.value
                                        })
                                    }}
                                    name="type"
                                    className="d-inline option-type col-12"
                                    selectedValue={this.state.newFpvType}>
                                    <Radio label="CIRCLE to FIFO" value={"FIFO"} />
                                    <Radio label="CIRCLE to RPP" value={"RPP"} />
                                </RadioGroup>
                            </div>
                            <div className="col-12">
                                <div className="bidFormBtn mt-4 mb-4">
                                    <Button
                                        text={"Cancel"}
                                        intent={"default"}
                                        onClick={() => {
                                            this.setState({
                                                isVisibleProceedModal:false
                                            })
                                        }}
                                    />
                                    <Link to={{pathname:'/fpv-conversion',state:{fpv:this.state.fpvDetail,oldFpvType:"CIRCLE",newFpvType:this.state.newFpvType}}}>
                                        <Button
                                            text={"Proceed"}
                                            intent={"primary"}
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}