import {connect} from 'react-redux';
import {getItInquiryList} from '../../../core/actions/action';
import ItInquiriesIndex from '../components/ItInquiriesIndex';

const mapDispatchToProps = dispatch => {

    return {
        getItInquiryList:(params) => {
            return getItInquiryList(params);
        },
    }
}

export default connect(null, mapDispatchToProps)(ItInquiriesIndex);
