import React, { Component } from 'react';
import AllorderEmptyStates from "../../../core/images/empty-state/Allorder.svg";
//import {Select} from "react-inputs-validation";
//import {FormGroup} from "@blueprintjs/core";


/*const CASH_TYPE_LIST = [
    { id: "All", name: "All"},
    { id: "BUS_API", name: "Bus Upos" },
    { id: "MEGA-BRANDS", name: "Mega Brands" },
    { id: "RECHARGE", name: "Recharge" },
    { id: "FLIGHT", name: "Flight" },
    { id: "USER-VOUCHER", name: "User Voucher" },
    { id: "BSW", name: "BSW" },
    { id: "Store", name: "Store" },
    { id: "RAILBHOJ", name: "Rail Bhoj" },
    { id: "BUS", name: "BUS" },
    { id: "RESALE", name: "Resale" },
    { id: "GG_STORE_VENDOR_INVOICE", name: "GG Store Vendor Invoice" },
    { id: "GG_STORE_BILL_UPLOAD", name: "GG Store Bill Upload" },
    { id: "GG_STORE_ONLINE", name: "GG Store Online" },

]*/

export default class Approved extends Component{
    constructor(props){
        super(props);
        this.state={
            orderList:[],
            loading: true,
            webDomain:"",
        }
    }

    componentDidMount(){
        this.setState( {
            loading: this.props.loading,
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    renderStatusHtml(status){

        switch (status) {
            case 1:
                return <label className="label">SCB FIFO</label>;
            case 2:
                return <label className="badge">SCB RPP</label>;
            case 3:
                return <label className="badge">IC</label>;
            case 4:
                return <label className="badge">CIRCLE</label>;
            default:
                return <label className="label">--</label>;
        }
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            if(this.props.stopLoadMore === false){
                this.props.NextPage()
            }
        }
    }

    /*getDomainData = (domainVal) => {
        this.props.domainData(domainVal);
    }*/

    render(){
        return(
            <React.Fragment>
                <div className="approvedorder" id={"allBooking"} style={{'height':'100%','maxHeight':'590px','overflowY':'scroll'}} onScroll={this.handleScroll}>
                    <table className="bp3-html-table table-borderless">
                        <thead>
                            <tr>
                                <th>Order No.</th>
                                <th>Place From</th>
                                <th>Order Total</th>
                                <th>Cashback Type</th>
                                <th>Created Date</th>
                            </tr>
                            <tr className="d-none">
                                <td>
                                    {/*<div className="bp3-form-group">
                                        <div className="bp3-form-content">
                                            <div className="bp3-input-group">
                                                <input type="text" className="bp3-input" placeholder="Search" dir="auto"/>
                                                <span className="bp3-icon bp3-icon-cross"></span>
                                            </div>
                                        </div>
                                    </div>*/}
                                </td>
                                <td>
                                    {/*<FormGroup>
                                        <Select
                                            tabIndex="0"
                                            id={"cash"}
                                            name="cash"
                                            disabled={false}
                                            value={this.state.webDomain}
                                            onChange={(webDomain, e) =>{
                                                this.setState({ webDomain });
                                                this.getDomainData(webDomain);
                                            }}
                                            optionList={CASH_TYPE_LIST}
                                        />
                                    </FormGroup>*/}
                                </td>
                                <td>
                                    {/*<div className="bp3-form-group">
                                        <div className="bp3-form-content">
                                            <div className="bp3-input-group">
                                                <input type="text" className="bp3-input" placeholder="Search" dir="auto"/>
                                                <span className="bp3-icon bp3-icon-search"></span>
                                            </div>
                                        </div>
                                    </div>*/}
                                </td>
                                <td>
                                    {/*<div className="bp3-form-group">
                                        <div className="bp3-form-content">
                                            <div className="bp3-select-group">
                                                <select className="bp3-input">

                                                </select>
                                            </div>
                                        </div>
                                    </div>*/}
                                </td>
                                <td>
                                    {/*<div className="bp3-form-group">
                                        <div className="bp3-form-content">
                                            <div className="bp3-select-group">
                                                <select className="bp3-input">

                                                </select>
                                            </div>
                                        </div>
                                    </div>*/}
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.loading ?
                                <tr>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                </tr>
                                :
                            this.props.orderList.length ?
                                this.props.orderList.map((orders, key) => {
                                    return<tr key={key}>
                                        <td>
                                            <div className="orderTable">
                                                <strong>{orders.id}</strong>
                                                <p>{orders.order_id}</p>
                                            </div>
                                        </td>
                                        <td>{orders.domain}</td>
                                        <td><b>₹ {orders.order_total}</b></td>
                                        <td>
                                            {
                                                this.renderStatusHtml(orders.choice)
                                            }
                                        </td>
                                        <td>{orders.date_created}</td>
                                    </tr>
                                })
                                :
                                <tr>
                                    <td colSpan="5" align="center">
                                        <div className="text-center">
                                            <img src={AllorderEmptyStates} alt=""/>
                                            <p className="mt-3">No transactions for the selected option</p>
                                        </div>
                                    </td>
                                </tr>
                        }
                        </tbody>
                    </table>
                    {
                        this.state.loading ?
                            null
                            :
                            this.props.stopLoadMore ?
                                ""
                                :
                                <React.Fragment>
                                    <center>
                                        <div className="my-3">
                                            <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }
}