import React, { Component } from 'react';
import {InputGroup, Button, Toaster, Position, Intent} from "@blueprintjs/core";
import {Link} from 'react-router-dom';
import {Select} from 'react-inputs-validation';
import {getMyBidsListAction} from "../../core/actions";
import {Helper} from "gg-react-utilities";

const SORT_BY_LIST = [
    { id: "", name: "Sort By"},
]


export default class Bids extends Component{
    constructor(props){
        super();
        this.state= {
            sort:"",
            isSkeleton:true,
            scrollLoader:true,
            counter:1,
            isLoading:false,
            bidsList:[]
        }
    }

    componentDidMount() {
        this.getBidsList(this.state.counter);
        setTimeout(() => {
            this.setState({
                isSkeleton:false
            })
        }, 1000);
        window.addEventListener('scroll', this.handleOnScroll);
    }

    getBidsList(page){
        this.props.getBidsList(page)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    let oldData = this.state.bidsList;
                    let newDataFromApi = sources.data.data;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState({
                        bidsList:newData,
                        last_page:sources.data.last_page,
                    })
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                }
            }).catch((error)=>{
            Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
        })
    }

    handleOnScroll=()=> {
        let scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        let scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        let clientHeight = document.documentElement.clientHeight || window.innerHeight;
        let scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

        if (scrolledToBottom) {
            this.loadMore();
        }
    }

    loadMore = () =>{
        this.setState({
            scrollLoader:false
        });
        setTimeout(() => {
            this.setState({
                counter: this.state.counter + 1,
                isLoading: true,
                scrollLoader:true
            }, () => {
                this.getBidsList(this.state.counter);
            })
        },1000)
    }


    render(){
        const lockButton = (
            <Button
                icon={"search"}
                minimal={true}
            />
        );
        return(
            <React.Fragment>
                <div className="bidsWrapper text-right">
                    {/*<div className="row">*/}
                    {/*    <div className="col-12 col-md-8 col-sm-8 col-xs-12">*/}
                    {/*        <div className="searchbar">*/}
                    {/*            <InputGroup*/}
                    {/*                placeholder="Search"*/}
                    {/*                rightElement={lockButton}*/}
                    {/*                small={false}*/}
                    {/*                type={"text"}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-12 col-md-4 col-sm-4 col-xs-12">*/}
                    {/*        <div className="leftbar">*/}
                    {/*            <Select*/}
                    {/*                tabIndex="0"*/}
                    {/*                id={"sort"}*/}
                    {/*                name="sort"*/}
                    {/*                value={this.state.sort}*/}
                    {/*                onChange={(e) =>{*/}

                    {/*                }}*/}
                    {/*                optionList={SORT_BY_LIST}*/}
                    {/*            />*/}
                    {/*            <Button*/}
                    {/*                text={"Sell your UPV"}*/}
                    {/*                intent={"primary"}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <Link to="/sell-upv-exchange">
                        <Button
                            text={"Sell your UPV"}
                            intent={"primary"}
                            onClick={() => {
                                // window.location.href="/sell-upv-exchange"
                            }}
                        />
                    </Link>
                    <div className="content mt-lg-4">
                        <div className="row">
                            {this.state.bidsList.length === 0 ?
                                <div className="emptyState">
                                    <img src={require('../../core/images/empty-state/UPV.svg')} alt=""/>
                                </div>
                                :
                                this.state.bidsList.map((res, i) => {
                                    let color;
                                    let status;
                                    if(res.bid_status==="OPEN"){
                                        color="text-success";
                                        status="Bid Open"
                                    }else if(res.bid_status==="ON_HOLD"){
                                        color="text-warning";
                                        status="On Hold"
                                    }else if(res.bid_status==="CLOSED"){
                                        color="text-danger"
                                        status="Bid Closed"
                                    }
                                    return (
                                        <div className={"col-12 col-lg-6 col-md-6 col-sm-6 col-xs-12"} key={i}>
                                            <Link to={{ pathname: '/upv-exchange-detail', state: { upvID: res.upv_id}  }}>
                                                <div className={this.state.isSkeleton ? "bidBlock p-3 mt-3 bp3-skeleton" : "bidBlock p-3 mt-3 shadowBlock"}>
                                                    <div className="row">
                                                        <div className="col-12 col-lg-2 col-md-2 col-sm-2">
                                                            <div className="upvRight mb-2">
                                                                <h5 className={"m-0"}>UPV No</h5>
                                                                <h4 className={"m-0"}>{res.upv_id}</h4>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-10 col-md-10 col-sm-10">
                                                            <div className="upvLeft text-left">
                                                                <div className={"topbar"}>
                                                                    <h4 className="m-0 nameText">{res.sso_username}</h4>
                                                                    <h4 className={"m-0 f-16 "+color} style={{fontWeight:'500'}}>{status}</h4>
                                                                </div>
                                                                <h4 className="m-0 priceText">{res.bid_amount ? Helper.formatMoney(res.bid_amount) : Helper.formatMoney(0)}</h4>
                                                                <h5 className="m-0 timeText">{res.upv_generation_date} {res.upv_generation_time}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        {
                            this.state.last_page <= this.state.counter ? null :
                                this.state.scrollLoader ?
                                    null :
                                    <React.Fragment>
                                        <center>
                                            <div className="my-3">
                                                <img src={require('../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                            </div>
                                        </center>
                                    </React.Fragment>

                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}