export const getUPVFIFOListAction = (sources) => {
    return {
        type: 'UPV_FIFO_LIST',
        sources: sources
    }
}

export const getRPPCurrentListAction = (sources) => {
    return {
        type: 'RPP_CURRENT_LIST',
        sources: sources
    }
}

export const getRPPOldListAction = (sources) => {
    return {
        type: 'RPP_OLD_LIST',
        sources: sources
    }
}

export const getICListAction = (sources) => {
    return {
        type: 'IC_LIST',
        sources: sources
    }
}

export const getCircleListAction = (sources) => {
    return {
        type: 'CIRCLE_LIST',
        sources: sources
    }
}

export const getCashbackOptionAction = (sources) => {
    return {
        type: 'CASHBACK_OPTION',
        sources: sources
    }
}

export const changeCashbackOptionAction = (sources) => {
    return {
        type: 'CHANGE_CASHBACK_OPTION',
        sources: sources
    }
}

export const getFPVFIFOListAction = (sources) => {
    return {
        type: 'FPV_FIFO_LIST',
        sources: sources
    }
}


export const getFPVRPPCurrentListAction = (sources) => {
    return {
        type: 'FPV_CURRENT_RPP_LIST',
        sources: sources
    }
}


export const getFPVRPPOldListAction = (sources) => {
    return {
        type: 'FPV_OLD_RPP_LIST',
        sources: sources
    }
}

export const getFPVICListAction = (sources) => {
    return {
        type: 'FPV_IC_LIST',
        sources: sources
    }
}

export const getFPVCashbackOptionAction = (sources) => {
    return {
        type: 'CASHBACK_FPV_OPTION',
        sources: sources
    }
}

export const changeFPVCashbackOptionAction = (sources) => {
    return {
        type: 'CHANGE_CASHBACK_FPV_OPTION',
        sources: sources
    }
}

export const getWithdrawRequestListAction = (sources) => {
    return {
        type: 'WITHDRAW_REQUEST',
        sources: sources
    }
}

export const getOTPRequestAction = (sources) => {
    return {
        type: 'OTP_REQUEST',
        sources: sources
    }
}

export const saveWithdrawalRequestAction = (sources) => {
    return {
        type: 'SAVE_REQUEST',
        sources: sources
    }
}

export const getRewardListAction = (sources) => {
    return {
        type: 'REWARD_LIST',
        sources: sources
    }
}

export const changeVoucherStatusAction = (sources) => {
    return {
        type: 'VOUCHER_STATUS',
        sources: sources
    }
}

export const setVoucherCodeAction = (sources) => {
    return {
        type: 'SET_VOUCHER',
        sources: sources
    }
}

export const refUserListAction = (sources) => {

    return {
        type: 'REF_USER_LIST',
        sources: sources
    }
}

export const refVendorUserListAction = (sources) => {

    return {
        type: 'REF_VENDOR_USER_LIST',
        sources: sources
    }
}

export const ggStatsListAction = (sources) => {

    return {
        type: 'GG_STATS',
        sources: sources
    }
}

export const ggRewardsAction = (sources) => {

    return {
        type: 'GG_REWARDS',
        sources: sources
    }
}

export const sendGgCoinAction = (sources) => {

    return {
        type: 'SEND_GG_COIN',
        sources: sources
    }
}

export const requestGgCoinAction = (sources) => {

    return {
        type: 'REQUEST_GG_COIN',
        sources: sources
    }
}

export const myRequestGgCoinListAction = (sources) => {

    return {
        type: 'REQUEST_GG_COIN_LIST',
        sources: sources
    }
}

export const ggCoinRequestListAction = (sources) => {

    return {
        type: 'GG_COIN_REQUEST_LIST',
        sources: sources
    }
}


export const getPassbookMoneyListAction = (sources) => {

    return {
        type: 'GG_MONEY_PASSBOOK_LIST',
        sources: sources
    }
}

export const getPassbookCoinListAction = (sources) => {

    return {
        type: 'GG_COIN_PASSBOOK_LIST',
        sources: sources
    }
}

export const acceptCoinAction = (sources) => {

    return {
        type: 'ACCEPT_COIN_REQUEST',
        sources: sources
    }
}

export const rejectCoinAction = (sources) => {

    return {
        type: 'REJECT_COIN_REQUEST',
        sources: sources
    }
}


export const getMegaBrandOrderListAction = (sources) => {

    return {
        type: 'MEGA_BRANDS_ORDER',
        sources: sources
    }
}

export const getVoucherOrderListAction = (sources) => {

    return {
        type: 'VOUCHER_ORDER',
        sources: sources
    }
}

export const getVoucherOrderDetailsAction = (sources) => {

    return {
        type: 'VIEW_VOUCHER_ORDER',
        sources: sources
    }
}

export const getBSWOrderListAction = (sources) => {

    return {
        type: 'BSW_ORDER',
        sources: sources
    }
}


export const getRechargeOrderListAction = (sources) => {

    return {
        type: 'RECHARGE_ORDER',
        sources: sources
    }
}

export const getBusOrderListAction = (sources) => {

    return {
        type: 'BUS_ORDER',
        sources: sources
    }
}

export const getFlightOrderListAction = (sources) => {

    return {
        type: 'FLIGHT_ORDER',
        sources: sources
    }
}

export const getRailBhojOrderListAction = (sources) => {

    return {
        type: 'RAIL_BHOJ_ORDER',
        sources: sources
    }
}

export const getStoreOrderListtAction = (sources) => {

    return {
        type: 'STORE_ORDER',
        sources: sources
    }
}

export const getNewStoreOrderListtAction = (sources) => {

    return {
        type: 'NEW_STORE_ORDER',
        sources: sources
    }
}


export const myStatsListAction = (sources) => {

    return {
        type: 'MY_STATS',
        sources: sources
    }
}


export const getApprovedOrderListAction = (sources) => {
    return {
        type: 'APPROVED_ORDERS',
        sources: sources
    }
}

export const getFavouriteVendorlistAction = (sources) => {
    return {
        type: 'VENDOR_FAVOURITE_LIST',
        sources: sources
    }
}

export const getVendorProductWishlistAction = (sources) => {
    return {
        type: 'PRODUCT_FAVOURITE_LIST',
        sources: sources
    }
}

export const getBswFavouriteListAction = (sources) => {
    return {
        type: 'BSW_FAVOURITE_LIST',
        sources: sources
    }
}


export const getMegaBrandFavouriteListAction = (sources) => {
    return {
        type: 'MEGA_BRAND_FAVOURITE_LIST',
        sources: sources
    }
}


export const getAllOrderListAction = (sources) => {
    return {
        type: 'ALL_ORDERS',
        sources: sources
    }
}

export const removeFavouriteVendorAction = (sources) => {
    return {
        type: 'REMOVE_VENDOR_FAVOURITE',
        sources: sources
    }
}

export const removeFavouriteProductAction = (sources) => {
    return {
        type: 'REMOVE_VENDOR_PRODUCT_FAVOURITE',
        sources: sources
    }
}

export const removeFavouriteUpose=(sources)=>{
    return {
        type: 'REMOVE_UPOSE_FAVOURITE',
        sources: sources
    }
}

export const removeBswFavouriteAction = (sources) => {
    return {
        type: 'REMOVE_BSW_FAVOURITE',
        sources: sources
    }
}

export const removeMegaBrandFavouriteAction = (sources) => {
    return {
        type: 'REMOVE_MEGA_BRAND_FAVOURITE',
        sources: sources
    }
}

export const getStoreServiceBookingListAction = (sources) => {

    return {
        type: 'STORE_SERVICE_BOOKING',
        sources: sources
    }
}


export const viewUserBillAction = (sources) => {

    return {
        type: 'BSW_USER_BILLS',
        sources: sources
    }
}

export const viewStoreOrderAction = (sources) => {

    return {
        type: 'VIEW_STORE_ORDER',
        sources: sources
    }
}

export const cancelBookServiceAction = (sources) => {

    return {
        type: 'CANCEL_SERVICE',
        sources: sources
    }
}

export const getRechargeInvoiceAction = (sources) => {

    return {
        type: 'RECHARGE_INVOICE',
        sources: sources
    }
}

export const getStoreBillDetailsAction = (sources) => {

    return {
        type: 'STORE_BILL_DETAILS',
        sources: sources
    }
}

export const getMegaBrandsOrderDetailsAction = (sources) => {

    return {
        type: 'MEGABRANDS_ORDERS_DETAILS',
        sources: sources
    }
}


// Here is UPV EXCHANGE ACTIONS


export const getBidsListAction = (sources) => {

    return {
        type: 'GET_BIDS_LIST',
        sources: sources
    }
}

export const getMyBidsListAction = (sources) => {

    return {
        type: 'GET_MYBIDS_LIST',
        sources: sources
    }
}

export const getUPVDetailAction = (sources) => {

    return {
        type: 'GET_UPV_DETAIL',
        sources: sources
    }
}

export const addNewBidAction = (sources) => {

    return {
        type: 'ADD_NEW_BID',
        sources: sources
    }
}

export const getUPVSellListAction = (sources) => {

    return {
        type: 'UPV_SELL_LIST',
        sources: sources
    }
}

export const getHotelListAction = (sources) => {
    return {
        type: 'HOTEL_LIST',
        sources: sources
    }
}

export const getPackageDetailAction = (sources) => {
    return {
        type: 'PACKAGE_LIST',
        sources: sources
    }
}

export const getUpvReplaceInboxListAction = (sources) => {
    return {
        type: 'UPV_REPLACE_INBOX_LIST',
        sources: sources
    }
}

export const getUpvReplaceSentListAction = (sources) => {
    return {
        type: 'UPV_REPLACE_SENT_LIST',
        sources: sources
    }
}

export const requestGGVoucherOrderListAction = (sources) => {
    return {
        type: 'REQUEST_GG_VOUCHER_ORDER_LIST',
        sources: sources
    }
}

export const getFpvReplaceInboxListAction = (sources) => {
    return {
        type: 'FPV_REPLACE_INBOX_LIST',
        sources: sources
    }
}

export const getFpvReplaceSentListAction = (sources) => {
    return {
        type: 'FPV_REPLACE_SENT_LIST',
        sources: sources
    }
}

export const getGrangeOrderDetailsAction = (sources) => {
    return {
        type: 'GRANGE_ORDER_DETAILS',
        sources: sources
    }
}

export const orderAggregateDataAction = (sources) => {
    return {
        type: 'ORDER_AGGREGATE_DATA',
        sources: sources
    }
}