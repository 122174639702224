import React from 'react';
import {Auth, NoLogin} from "gg-react-utilities";
import WebInquiryComponent from "./WebInquiriesComponent";

export default class WebInquiryIndex extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            WebInquiryList:[],
            page:1,
            perpage:15,
            stopLoadMore:false,
            loadingData:true,
            isLoggedIn:false
        }
    }

    componentDidMount() {
        this.checkAuth();

        let params={
            page:this.state.page,
            per_page:this.state.perpage
        }
        this.getWebInquiryList(params)
    }

    getWebInquiryList=(params)=>{
        this.props.getWebInquiryList(params)
            .then((res)=>{
                const sources = res.data;
                if(sources.status === true){
                    let oldData = this.state.WebInquiryList;
                    let newDataFromApi = sources.data.data;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState( {
                        WebInquiryList:newData,
                        loadingData: false,
                    });

                    if(sources.data.current_page === sources.data.last_page){
                        this.setState( {
                            stopLoadMore: true,
                        })
                    }else{
                        this.setState( {
                            stopLoadMore: false,
                        })
                    }
                }else{
                    this.setState({
                        loadingData: false,
                    });
                }
            }).catch((error)=>{
                const sources = error.response;
            })
    }

    NextPage= () =>{
        this.setState({
            page: this.state.page + 1,
            loadingData:false
        },()=>{
            let params={
                page:this.state.page,
                per_page:this.state.perpage,
            }
            this.getWebInquiryList(params)
        })

    }

    async checkAuth(){
        Auth.isLoggedInAsync().then((res) => {
            this.setState({isLoggedIn:res})
        });
    }

    render() {
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }

        return(
            <React.Fragment>
                <WebInquiryComponent
                    loading={this.state.loadingData}
                    WebInquiryList={this.state.WebInquiryList}
                    NextPage={() =>{ this.NextPage() }}
                    stopLoadMore={this.state.stopLoadMore}
                />
            </React.Fragment>
        )
    }
}