import React from 'react';
import {Button, Classes, Dialog} from "@blueprintjs/core";
import Config from "../../../../Config";

export default class OrderHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            userBill: ""
        };
        this.handleClose = this.handleClose.bind(this)
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        //this.setState({ userBill: nextProps.viewUserBill });
    }


    handleClose() {
        this.setState({isOpen: false});
    }

    renderStatusHtml(status) {

        switch (status) {
            case 1:
                return <label className="label text-warning">Awaiting Payment</label>;
            case 2:
                return <label className="badge text-warning">Awaiting Shipment</label>;
            case 3:
                return <label className="badge text-warning">Awaiting Pickup</label>;
            case 4:
                return <label className="badge text-success">Intransit</label>;
            case 5:
                return <label className="badge text-success">Delivered</label>;
            case 6:
                return <label className="badge text-success">Completed</label>;
            case 7:
                return <label className="badge text-danger">Cancelled</label>;
            case 8:
                return <label className="badge text-danger">Declined</label>;
            case 9:
                return <label className="badge text-success">Disputed</label>;
            default:
                return <label className="label text-default">--</label>;
        }
    }


    render() {
        return (
            <React.Fragment>
                <Button small={true} intent="primary" title="View" icon="eye-open" className="btn-view mr-2"
                        onClick={() => {
                            this.setState({isOpen: true})
                            this.props.viewStoreOrder(this.props.orderid)
                        }}/>
                <Dialog isOpen={this.state.isOpen} onClose={() => this.handleClose()} className="bp3-dialog-large store-order"
                        title={"Order Detail " + this.props.orderid}>
                    {this.props.viewStoreOrderDetails !== null ?

                        <div className={Classes.DIALOG_BODY}>
                            <div className="row">
                                <div className="col-md-4 col-sm-4 col-12 orderImg text-center-xs">
                                    {this.props.viewStoreOrderDetails.product !== undefined && this.props.viewStoreOrderDetails.product.images.length === 0 ?
                                        <img src={Config.DEFAULT_IMAGE_URL} alt="product" className="img-fluid"
                                             onError={(ev) => {
                                                 ev.target.src = Config.DEFAULT_IMAGE_URL;
                                             }}/>
                                        :
                                        this.props.viewStoreOrderDetails.product !== undefined &&
                                        <img src={this.props.viewStoreOrderDetails.product.images[0].image}
                                             alt="product" className="img-fluid"
                                             onError={(ev) => {
                                                 ev.target.src = Config.DEFAULT_IMAGE_URL;
                                             }}/>
                                    }
                                </div>
                                <div className="col-md-8 col-sm-8 col-12 storeOrderDetails mt-s-4">
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <p className="heading">Product Name</p>
                                            <p className="headingTitle">{this.props.viewStoreOrderDetails.product !== undefined &&this.props.viewStoreOrderDetails.product.name}</p>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 col-sm-6 col-6">
                                            <p className="heading">Order Id</p>
                                            <p className="headingTitle">{this.props.viewStoreOrderDetails !== undefined && this.props.viewStoreOrderDetails.formatted_order_id}</p>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6">
                                            <p className="heading">Order Date</p>
                                            <p className="headingTitle">{this.props.viewStoreOrderDetails !== undefined && this.props.viewStoreOrderDetails.created_at}</p>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 col-sm-6 col-6">
                                            <p className="heading">Quantity</p>
                                            <p className="headingTitle">{this.props.viewStoreOrderDetails.product !== undefined && this.props.viewStoreOrderDetails.product.quantity}</p>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6">
                                            <p className="heading">SKU</p>
                                            <p className="headingTitle">{this.props.viewStoreOrderDetails.product !== undefined && this.props.viewStoreOrderDetails.product.sku}</p>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 col-sm-6 col-6">
                                            <p className="heading">Payment Method</p>
                                            <p className="headingTitle">{this.props.viewStoreOrderDetails !== undefined && this.props.viewStoreOrderDetails.payment_method}</p>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6">
                                            <p className="heading">Order Status</p>
                                            <p className="headingTitle text-nowrap">
                                                {
                                                    this.renderStatusHtml(this.props.viewStoreOrderDetails.status !== undefined && this.props.viewStoreOrderDetails.status.order_status_id)
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2 mb-4">
                                <div className="col-md-12 col-12">
                                    {this.props.viewStoreOrderDetails != undefined &&
									<div className="OrderShippingDetails">
										<p className="userInfo">{this.props.viewStoreOrderDetails.firstname} {this.props.viewStoreOrderDetails.lastname}</p>
										<p className="">{this.props.viewStoreOrderDetails.address_street}, {this.props.viewStoreOrderDetails.address_landmark}</p>
										<p>{this.props.viewStoreOrderDetails.address_area}</p>
										<p>{this.props.viewStoreOrderDetails.address_city}</p>
										<p>{this.props.viewStoreOrderDetails.address_state}, {this.props.viewStoreOrderDetails.address_country}, {this.props.viewStoreOrderDetails.address_pincode}</p>
									</div>}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12 col-12 orderTotal">
                                    <p><b>Payment summary</b></p>
                                    {
                                        this.props.viewStoreOrderDetails.totals !=undefined && this.props.viewStoreOrderDetails.totals.length ?
                                            this.props.viewStoreOrderDetails.totals.map((orderTotal, key) => {
                                                if (orderTotal.code !== 'gg_commision') {
                                                    if (orderTotal.code !== 'grand_total') {
                                                        return (
															<div className="table-items">
																<div className="row tr-item">
																	<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
																		<p className="d1 totalTitle" key={key}>
																			{orderTotal.title}
																		</p>
																	</div>
																	<div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-2 px-s-0">
																		<div className="d2">&nbsp;</div>
																	</div>
																	<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
																		<p className="d3 t-sub-title">
																			₹ {orderTotal.value}
																		</p>
																	</div>
																</div>
															</div>
                                                        )
                                                    }else{
                                                        return (
															<div className="table-items">
																<hr/>
																<div className="row tr-item">
																	<div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
																		<div className="d1 totalTitle" key={key}>
																			{orderTotal.title}
																		</div>
																	</div>
																	<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
																		<div className="d3 t-sub-title">
																			₹ {orderTotal.value}
																		</div>
																	</div>
																</div>
															</div>
                                                        )
                                                    }

                                                }

                                            })
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className={Classes.DIALOG_BODY}>
                            <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}></div>
                        </div>
                    }
                </Dialog>
            </React.Fragment>
        );
    }
}