import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';

import TopContent from '../components/TopContent';
import { getUPVDetail } from '../../../core/actions/action';
import { getUPVDetailAction } from '../../../core/actions';

const mapStateToProps = state => {

    return{
        upvDetail:state.upvExchange.upvDetail,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUPVDetail :(upvID) => {
            return getUPVDetail(upvID)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(getUPVDetailAction(sources.data));
                    }else{
                        Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                        dispatch(getUPVDetailAction([]));
                    }
                }).catch((error)=>{
                    Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopContent);
