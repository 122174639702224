import {connect} from 'react-redux';
import MegaBrandDetails from '../Component/MegaBrandDetails';
import {getMegaBrandsOrderDetails} from "../../../core/actions/action";
import {getMegaBrandsOrderDetailsAction} from "../../../core/actions";

const mapStateToProps = state => {

    return{
        megaBrandOrderDetails:state.AllOrders.megaBrandOrderDetails,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getMegaBrandsOrderDetails :(receiptNo) => {
            dispatch(getMegaBrandsOrderDetailsAction(null));
            return getMegaBrandsOrderDetails(receiptNo)
                .then((res)=>{
                    const sources = res.data.data;
                    dispatch(getMegaBrandsOrderDetailsAction(sources));
                }).catch((error)=>{
                    const sources = error.response;
                    console.log(sources);
                })
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MegaBrandDetails);