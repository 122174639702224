let initialState = {
    approvedOrderList:[],
    allOrderList:[],
    storeBillDetails:[],
    megaBrandOrderDetails:[],
    hotelList:[],
    packageList:[]
};

const AllOrders = (state = initialState, action) => {

    switch (action.type) {

        case 'APPROVED_ORDERS':
            return {...state, approvedOrderList: action.sources}
        case 'ALL_ORDERS':
            return {...state, allOrderList: action.sources}
        case 'STORE_BILL_DETAILS':
            return {...state, storeBillDetails: action.sources}
        case 'MEGABRANDS_ORDERS_DETAILS':
            return {...state, megaBrandOrderDetails: action.sources}
        case 'HOTEL_LIST':
            return {...state, hotelList: action.sources}
        case 'PACKAGE_LIST':
            return {...state, packageList: action.sources}
        default:
            return state
    }
}

export default AllOrders;
