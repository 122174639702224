import React from 'react';
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import {Classes, Dialog} from "@blueprintjs/core";
import {Link, withRouter} from 'react-router-dom';

class TopBar extends React.Component {

    render() {
        return (
            <div className="top-bar">
                <div className="align-items-center justify-content-between row w-100">
                    <div className="float-left d-flex align-items-center">
                        {
                            this.props.isDashboard ?
                                <span className="back-icon d-none text-dark"><KeyboardArrowLeftRounded/></span>
                                :
                                <span className="back-icon text-dark"
                                      onClick={() => this.props.history.goBack()}><KeyboardArrowLeftRounded/></span>
                        }
                        <h1 className="title my-0">{this.props.title}</h1>
                    </div>
                    {
                        this.props.rightBar ?
                            <div className="float-right col-md-5 col-12 row pr-0">
                                <div className="col-md-6 col-6 pr-0">
                                    <Link to={{
                                            pathname: (this.props.type && this.props.type == 'fpv') ? '/fpv/circle/coinconversion/'+this.props.rightBar : '/circle/coinconversion/'+this.props.rightBar
                                        }}
                                        className="title my-0 text-right font-20 text-primary" >
                                        Coin Conversion
                                    </Link>
                                </div>
                                <div className="col-md-6 col-6 pr-0">
                                    <a onClick={()=>{this.props.openCashbackTree()}}
                                       className="title my-0 text-right font-20 text-primary">Cashback Tree</a>
                                </div>
                            </div>
                        : ''
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(TopBar);