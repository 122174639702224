import React from 'react'
import { Route } from 'react-router-dom'
import { Header,Footer, ScrollTop } from 'gg-react-utilities';
//import ScrollTOTop from "./components/ScrollTOTop";
const PrivateRoute = ({ component: Component, ...rest }) => (

    <Route {...rest} render={(props) => {
        return(
            <React.Fragment>
                {props.location.pathname !== "/upv-exchange-info" ?
                    <div className="dashboard">
                        {props.location.pathname !== "/referrals/image" ?
                        <Header/>:""}
                        <Component {...props} />
                        {props.location.pathname !== "/referrals/image"?
                        <Footer/>:""}
                        <ScrollTop/>
                    </div>
                    :
                    <div className="dashboard">
                        <Component {...props} />
                        <ScrollTop/>
                    </div>
                }
            </React.Fragment>
        )
    }}/>
);

export default PrivateRoute;
