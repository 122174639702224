import React, { Component } from 'react';
import Defaultbrands from '../../../../core/images/order/Defaultbrands.png'
import AllorderEmptyStates from "../../../../core/images/empty-state/Allorder.svg";
import Invoice from '../Container/Invoice';
import {Button} from "@blueprintjs/core";

export default class Recharge extends Component{
    constructor(props){
        super(props);
        this.state={
            orderList:[],
            loading:true,
        };
    }

    componentDidMount(){
        this.setState( {
            loading: this.props.loading,
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading
        })
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            if(this.props.stopLoadMore === false){
                this.props.NextPage()
            }
        }
    }

    renderStatusHtml(status){

        switch (status) {
            case "1":
                return <label className="label text-success">SUCCESS</label>;
            case "2":
                return <label className="badge text-danger">FAILED</label>;
            case "3":
                return <label className="badge text-warning">AWAITING</label>;
            case "4":
                return <label className="badge text-warning">PENDING</label>;
            case "0":
                return <label className="badge text-danger">CANCELED</label>;
            default:
                return <label className="label text-default">--</label>;
        }
    }

    getInvoice = (orderId) => {
        alert(orderId);
    }

    render(){
        return(
            <React.Fragment>
				<div className="megabrand">
					<div className="recharge" id={"allBooking"} style={{'height':'100%','maxHeight':'590px','overflowY':'scroll'}} onScroll={this.handleScroll}>
						<table className="bp3-html-table table-borderless">
							<thead>
								<tr>
									<th>Product Details</th>
									<th>Order ID</th>
									<th>Order Date</th>
									<th>Price</th>
                                    <th>Cashback Type</th>
									<th>Status</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
							{
								this.state.loading ?
									<tr>
										<td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
										<td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
										<td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
										<td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
										<td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
										<td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
										<td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
									</tr>
									:
									this.props.orderList.length ?
										this.props.orderList.map((orders, key) => {
											return<tr key={key}>
												<td>
													<div className="orderTable">
														<div className="image hidden-xs hidden-sm hidden-md visible-xl visible-lg">
															{
																orders.service_provider.icon === null ?
																	<img src={Defaultbrands} alt=""/>
																	:
																	<img src={orders.service_provider.icon} alt=""/>
															}
															<div className="title">
																<p className="pt-2">{orders.service_provider.service_provider_name}</p>
																<span>{orders.bill_no}</span>
															</div>
														</div>
													</div>
												</td>
												<td>{orders.order_id}</td>
												<td>{orders.created_date}</td>
												<td><b>₹ {orders.amount}</b></td>
                                                <td>{orders.cb_choice_title}</td>
												<td>
													<b>
														{
															this.renderStatusHtml(orders.full_payment_status)
														}
													</b>
												</td>
												<td>
                                                    {
                                                        orders.full_payment_status === "1" ?
                                                            <Invoice orderId={orders.order_id}/>
                                                            :
                                                            <Button small={true} intent="inform" disabled={true} className="btn-view mr-2" title="Download" icon="download" />
                                                    }

												</td>
											</tr>
										})
										:
										<tr>
											<td colSpan="7" align="center">
												<div className="text-center">
													<img src={AllorderEmptyStates} alt=""/>
													<p className="mt-3">No transactions for the selected option</p>
												</div>
											</td>
										</tr>
							}
							</tbody>
						</table>
                        {
                            this.state.loading ?
                                null
                                :
                                this.props.stopLoadMore ?
                                    ""
                                    :
                                    <React.Fragment>
                                        <center>
                                            <div className="my-3">
                                                <img src={require('../../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                            </div>
                                        </center>
                                    </React.Fragment>
                        }
					</div>
				</div>
            </React.Fragment>
        );
    }
}