import React from 'react';
import {Auth, NoLogin} from "gg-react-utilities";
import TopBar from "../../core/components/TopBar";
import PageTitle from "../../core/components/PageTitle";
import {Link} from "react-router-dom";
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import {Button, Dialog, Intent, Position, Tab, Tabs, Toaster} from "@blueprintjs/core";
import {declineBid, getTransactionID, getUPVDetail} from "../../core/actions/action";
import {connect} from "react-redux";


class BidPayment extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isLoggedIn:false,
            platform:"WEB",
            upvID:"",
            bidID:"",
            loading:false,
            isVisibleMilestoneModel:false,
            milestones:[]
        }
    }

    componentDidMount() {
        this.checkAuth();
        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);
        this.getUPVDetail(this.props.location.state.upv.upv_id);
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1);
    }

    acceptPayment = (upv) => {
        if(upv.upv_id===""){
            Toaster.create({position: Position.TOP}).show({message:'Invalid UPV ID',intent: Intent.DANGER});
            return false;
        }
        if(upv.id!==""){

            let bidRequest = {
                "bid_id":upv.id,
                "upv_id":upv.upv_id,
                "platform":this.state.platform
            }

            this.setState({loading: true});

            this.props.getTransactionID(bidRequest).then((res) => {

                this.setState({loading: false});

                const resdata = res.data;

                if (resdata.status === true) {

                    const PayUBiz = resdata.data.pg;
                    let form = document.createElement("form");
                    form.setAttribute("method", "POST");
                    form.setAttribute("action", PayUBiz.url);

                    for (let key in PayUBiz) {
                        let hiddenField = document.createElement("input");
                        hiddenField.setAttribute("type", "hidden");
                        hiddenField.setAttribute("name", key);
                        hiddenField.setAttribute("value", PayUBiz[key]);
                        form.appendChild(hiddenField);
                    }
                    document.body.appendChild(form);
                    form.submit();
                } else {
                    Toaster.create({position: Position.TOP,}).show({message: resdata.message, intent: Intent.DANGER});
                }

            }).catch((error) => {
                this.setState({loading: false});
                const sources = error.response
                if (sources.data.message === "Validation Error") {
                    sources.data.error.map((res) => {
                        Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                        return res
                    })
                } else {
                    Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                }
            });
        }
    }


    declineBid(upv){
        this.props.declineBid(upv.upv_id, upv.id)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.SUCCESS});
                    this.setState({
                        isVisibleAcceptBidModal:false
                    })
                    setTimeout(() => {
                        window.location.href="/upv-exchange"
                    },3000)
                }else{
                    Toaster.create({position: Position.TOP}).show({message:"You are not allowed to Bid on this UPV.",intent: Intent.DANGER});
                }
            }).catch((error)=>{
            Toaster.create({position: Position.TOP}).show({message:"You are not allowed to Bid on this UPV.",intent: Intent.DANGER});
        })

    }

    getUPVDetail(upvID){
        this.props.getUPVDetail(upvID)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    this.setState({
                        milestones:sources.data.milestones
                    })
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.error,intent: Intent.DANGER});
                }
            }).catch((error)=>{
            Toaster.create({position: Position.TOP}).show({message:"Something went wrong.",intent: Intent.DANGER});
        })
    }

    closeModal(){
        this.setState({
            isVisibleMilestoneModel:false
        })
    }

    render(){
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <PageTitle title="UPV Exchange - Global Garner"/>
                        <TopBar title="UPV Exchange" />
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }
        const upv = this.props.location.state.upv;
        return(
            <div className="container mb-5">
                <PageTitle title="UPV Exchange - Global Garner"/>
                <div className="exchangeWrapper">
                    <div className="exchangeTopBar">
                        <div className="top-bar">
                            <Link to='/upv-exchange'>
                                <span className="back-icon"><KeyboardArrowLeftRounded /></span>
                                <h1 className="title m-0">UPV Exchange / My Bids / <small><b>UPV Number {upv.upv_id}</b></small></h1>
                            </Link>
                        </div>
                        <div className="leftTopBar">
                            <a href="#" target="_blank">UPV Exchange info</a>
                        </div>
                    </div>
                    <div className="paymentContainer">
                        <div className="UPVblock">
                            <div className="rupeeBlock">
                                <h3 className="m-0">₹ {upv.bid_amount}</h3>
                            </div>
                            <div className="bidCenterBlock">
                                <div className="row">
                                    <div className="col-12 col-lg-3 col-md-3 col-sm-3">
                                        <div className="upvRight">
                                            <h5 className={"m-0"}>UPV No</h5>
                                            <h4 className={"m-0"}>{upv.upv_id}</h4>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-9 col-md-9 col-sm-9">
                                        <div className="upvLeft text-left">
                                            <div className={"topbar"}>
                                                <div>
                                                    <h4 className="m-0 nameText">{upv.sso_username}</h4>
                                                    <h5 className="m-0 timeText">{upv.created_at}</h5>
                                                    <Button
                                                        className={"btn-link"}
                                                        text={"View Milestones"}
                                                        onClick={()=>{
                                                            this.setState({
                                                                isVisibleMilestoneModel:true
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footerBlock p-4">
                                <Button className={"m-0 f-16 "}
                                        text={"Not Interested"}
                                        intent={"default"}
                                        style={{
                                            height: 25,
                                            padding: '0px 10px',
                                            boxShadow:'none',
                                            fontSize:'14px'
                                        }}
                                        onClick={() =>
                                            this.declineBid(upv)
                                        }
                                />
                                <Button className={"m-0 f-16"}
                                        text={"Pay"}
                                        intent={"primary"}
                                        style={{
                                            height: 25,
                                            padding: '0px 10px',
                                            boxShadow:'none',
                                            fontSize:'14px'
                                        }}
                                        loading={this.state.loading}
                                        onClick={() =>
                                            this.acceptPayment(upv)
                                        }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog isOpen={this.state.isVisibleMilestoneModel}
                        title={"UPV No. :"+ upv.upv_id}
                        className="milestoneWrapper"
                        style={{marginTop:'250px'}}
                        onClose={this.closeModal.bind(this)} >
                    <div className="milestone m-3 text-center">
                        <div className="row">
                            { this.state.milestones.length > 0 ?
                                this.state.milestones.map((resp, i)=>{
                                    let str, strp, color, opacity
                                    i = i + 1;
                                    if(i===1){
                                        str = "1st";
                                        strp= "Cashback";
                                    }else if(i===2){
                                        str = "2nd";
                                        strp= "Cashback";
                                    }else if(i===3){
                                        str = "3rd";
                                        strp= "Cashback";
                                    }else if(i===4){
                                        str = "4th";
                                        strp= "GG Coin Conversion";
                                    }

                                    if(resp.cashback_given){
                                        color="#FE5A23";
                                        opacity="#B53206";
                                    }else{
                                        color="#40BE65";
                                        opacity="#164A25";
                                    }
                                    return(
                                        <div className="col-xl-3 col-lg-3 col-sm-6 col-md-6 col-12 my-2" key={i}>
                                            <div className="section">
                                                <div className="topSection pt-3 pb-3" style={{backgroundColor:color}}>
                                                    <h3>{str}</h3>
                                                    <p>{strp}</p>
                                                </div>
                                                <div className="bottomSection pt-3 pb-3" style={{opacity:0.8,backgroundColor:opacity}} >
                                                    <p>of {resp.milestone_description} <br></br><strong>{resp.upv_id}</strong><br></br> no. of UPV Generation</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }):
                                <td colSpan="3" className="emptyState">
                                    <img src={require('../../core/images/empty-state/UPV.svg')} alt=""/>
                                </td>
                            }
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {

    return {
        declineBid:(upvID, bidID) => {
            return declineBid(upvID, bidID)
        },
        getTransactionID:(obj) => {
            return getTransactionID(obj)
        },
        getUPVDetail:(upvID) => {
            return getUPVDetail(upvID)
        }
    }
}

export default connect(null, mapDispatchToProps)(BidPayment);