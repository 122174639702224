import React, { Component } from 'react';
import AllorderEmptyStates from "../../../core/images/empty-state/Allorder.svg";
import {Button, FormGroup, Intent, Position, Toaster} from "@blueprintjs/core";
import ViewCoinVoucher from "../Component/ViewCoinVoucher";
//import Invoice from '../../Recharge/Container/Invoice';
import ViewOrders from '../../Store/StoreOrder/Container/ViewOrders';
import Config from "../../../Config";
import ViewBswBill from '../../BSW/Container/ViewBill';
//import StoreBill from '../Container/StoreBill';
import MegaBrandDetails from '../Container/MegaBrandDetails';
import {Select} from 'react-inputs-validation';
import {connect} from 'react-redux';
import {getAllOrderList, getDMTPDF} from "../../../core/actions/action";
import {Auth, NoLogin} from "gg-react-utilities";
import ViewPackage from "./ViewPackage";

const CASH_TYPE_LIST = [
    { id: "", name: "All"},
    { id: "BUS_Upos", name: "Bus Upos" },
    { id: "MEGA-BRANDS", name: "Mega Brands" },
    { id: "RECHARGE", name: "Recharge" },
    { id: "NEW_RECHARGE", name: "New Recharge" },
    { id: "FLIGHT", name: "Flight" },
    { id: "USER-VOUCHER", name: "Coin Vouchers" },
    { id: "BSW", name: "BSW" },
    { id: "Store", name: "Store" },
    /*{ id: "RAILBHOJ", name: "Rail Bhoj" },*/
    { id: "BUS", name: "BUS" },
    { id: "Resale", name: "Resale" },
    { id: "DMT", name: "DMT" },
    { id: "PACKAGE", name: "PACKAGE" },
    { id: "HOTEL", name: "HOTEL" },
]

class AllOrders extends Component{
    constructor(props) {
        super(props);

        this.state = {
            orderList:[],
            loading: true,
            isOpen: false,
            isBswOpen: false,
            isStoreBillOpen: false,
            isMegaBrandsOpen: false,
            webDomain:"",
            isLoggedIn:false,
          
        }
    }

    componentDidMount(){
        this.checkAuth();

        this.setState( {
            loading: this.props.loading,            
            orderList:this.props.orderList
        })
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1);
    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading,
            orderList: nextProps.orderList
           })
    }

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+590);

        if (parseInt(allBookingHeight) === 0){
            if(this.props.stopLoadMore === false){
                this.props.NextPage()
            }
        }
    }

    getDomainData = (domainVal) => {
        this.props.domainData(domainVal);
    }

    getReceipt(id){
        let obj = {
            'order_id': id
        };
        this.props.getDMTPDF(obj)
            .then((response)=>{
                const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                const link = document.createElement('a');
                link.href = url;
                var name = 'gg-receipt-'+obj.order_id+'.pdf';
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
            }).catch((error)=> {
                Toaster.create({position: Position.TOP}).show({message: "Something went wrong.", intent: Intent.DANGER});
            });
    }

    render(){
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }

        return(
            <React.Fragment>
                <div className="order table-responsive" id={"allBooking"} style={{'height':'100%','maxHeight':'590px','overflowY':'scroll'}} onScroll={this.handleScroll}>
                    <table className="bp3-html-table table-borderless">
                        <thead>
                        <tr>
                            <th>Order Details</th>
                            <th>Status</th>
                            <th>Order No.</th>
                            <th>Module</th>
                            <th>Cashback Type</th>
                            <th>Order Total</th>
                        </tr>
                        <tr>
                            <td>
                                {/*<div className="bp3-form-group">
                                    <div className="bp3-form-content">
                                        <div className="bp3-input-group">
                                            <input type="text" className="bp3-input" placeholder="Search" dir="auto"/>
                                            <span className="bp3-icon bp3-icon-cross"></span>
                                        </div>
                                    </div>
                                </div>*/}
                            </td>
                            <td>
                                {/*<div className="bp3-form-group">
                                    <div className="bp3-form-content">
                                        <div className="bp3-input-group">
                                            <input type="text" className="bp3-input" placeholder="Search" dir="auto"/>
                                            <span className="bp3-icon bp3-icon-cross"></span>
                                        </div>
                                    </div>
                                </div>*/}
                            </td>
                            <td>
                                {/*<div className="bp3-form-group">
                                    <div className="bp3-form-content">
                                        <div className="bp3-select-group">
                                            <select className="bp3-input">

                                            </select>
                                        </div>
                                    </div>
                                </div>*/}
                            </td>
                            <td>
                                <FormGroup>
                                    <Select
                                        tabIndex="0"
                                        id={"cash"}
                                        name="cash"
                                        disabled={false}
                                        value={this.state.webDomain}
                                        onChange={(webDomain, e) =>{
                                            this.setState({ webDomain });
                                            this.getDomainData(webDomain);
                                        }}
                                        optionList={CASH_TYPE_LIST}
                                        />
                                </FormGroup>
                            </td>
                            <td>
                                {/*<div className="bp3-form-group">
                                    <div className="bp3-form-content">
                                        <div className="bp3-input-group">
                                            <input type="text" className="bp3-input" placeholder="Search" dir="auto"/>
                                            <span className="bp3-icon bp3-icon-search"></span>
                                        </div>
                                    </div>
                                </div>*/}
                            </td>
                           <td>
                                {/*<div className="bp3-form-group">
                                    <div className="bp3-form-content">
                                        <div className="bp3-select-group">
                                            <select className="bp3-input">

                                            </select>
                                        </div>
                                    </div>
                                </div>*/}
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.loading ?
                                <tr>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                    <td><div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div></td>
                                </tr>
                                :
                                    this.state.orderList && this.state.orderList.length ?
                                        this.state.orderList.map((orders, key) => {
                                        return<tr key={key}>
                                            <td>
                                                {
                                                    orders.domain === 'BSW' ?
                                                        <div className="title">
                                                            <p>{orders.order_details}</p>
                                                            <p>{orders.date_of_purchase}</p>
                                                            <p className="hidden-xs hidden-sm hidden-md visible-lg visible-xl">
                                                                <ViewBswBill isOpen={this.state.isBswOpen} billNo={orders.order_id}/>
                                                            </p>
                                                        </div>
                                                        :
                                                        orders.domain === 'MEGA-BRANDS' ?
                                                            <div className="title">
                                                            
                                                                <p>Receipt No : {orders.receipt_no ? orders.receipt_no : "---------------"}</p>
                                                                <p>{orders.order_date}</p>
                                                                <p className="hidden-xs hidden-sm hidden-md visible-lg visible-xl">
                                                                    <MegaBrandDetails isOpen={this.state.isMegaBrandsOpen} invoiceDownloadLink={orders.invoice_download_link} voucherDetailView={orders.voucher_detail_view} receiptNo={orders.receipt_no}/>
                                                                </p>
                                                            </div>
                                                            :
                                                            orders.domain === 'RECHARGE' ?
                                                                <div className="title">
                                                                    <p>{orders.order_details}</p>
                                                                    <p>{orders.create_at}</p>
                                                                    <p className="hidden-xs hidden-sm hidden-md visible-lg visible-xl">
                                                                        {/*<Invoice orderId={orders.order_id}/>*/}
                                                                    </p>
                                                                </div>
                                                                :
                                                                orders.domain === 'NEW_RECHARGE' ?
                                                                <div className="title">
                                                                    <p>{orders.order_details}</p>
                                                                    <p>{orders.create_at}</p>
                                                                    <p className="hidden-xs hidden-sm hidden-md visible-lg visible-xl">
                                                                        {/*<Invoice orderId={orders.order_id}/>*/}
                                                                    </p>
                                                                </div>
                                                                :
                                                                orders.domain === 'USER-VOUCHER' || orders.domain === 'UPOS-VOUCHER' ?
                                                                    <div className="title">
                                                                        <p>{orders.order_details}</p>
                                                                        <p>{orders.create_at}</p>
                                                                        <p className="hidden-xs hidden-sm hidden-md visible-lg visible-xl">
                                                                            {
                                                                                orders.order_status === 'Successful'?
                                                                                    <ViewCoinVoucher voucherId={orders.order_id} />
                                                                                    :
                                                                                    ""
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                    :
                                                                    orders.domain === 'FLIGHT' ?
                                                                        <div className="title">
                                                                            <p>{orders.order_details}</p>
                                                                            <p>{orders.create_at}</p>
                                                                        </div>
                                                                        :
                                                                        orders.domain === "Store" ?
                                                                            orders.subDomain === "StoreBill" ?
                                                                                <div className="orderTable">
                                                                                    <div className="hidden-xs hidden-sm hidden-md visible-lg visible-xl image">
                                                                                        <img src={orders.order_details} alt="" width="70px"/>
                                                                                    </div>
                                                                                    <div className="title">
                                                                                        <span>{orders.create_at}</span>
                                                                                        {/*<p className="hidden-xs hidden-sm hidden-md visible-lg visible-xl">
                                                                                            <StoreBill isOpen={this.state.isStoreBillOpen} vendorId={orders.vendor_id} billNumber={orders.order_id}/>
                                                                                        </p>*/}
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="orderTable">
                                                                                    <div className="title">
                                                                                        <p>{orders.order_details}</p>
                                                                                        <span>{orders.create_at}</span>
                                                                                        <p className="hidden-xs hidden-sm hidden-md visible-lg visible-xl">
                                                                                            <ViewOrders isOpen={this.state.isOpen} orderid={orders.order_id}/>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>

                                                                            :
                                                                            orders.domain === 'RAILBHOJ' ?
                                                                                <div className="title">
                                                                                    <p>{orders.order_details}</p>
                                                                                    <p>{orders.create_at}</p>

                                                                                        {
                                                                                            orders.ticket_link !== '' ?
                                                                                                <p className="hidden-xs hidden-sm hidden-md visible-lg visible-xl">
                                                                                                    <a href={Config.RAILBHOJ_API_URL+orders.ticket_link} className="bp3-button bp3-small bp3-intent-primary btn-view" rel="noopener noreferrer" target="_blank" title="download Invoice">
                                                                                                        <span className="bp3-icon bp3-icon-import bp3-text-medium"></span>
                                                                                                    </a>
                                                                                                </p>
                                                                                                :
                                                                                                ""
                                                                                        }

                                                                                </div>
                                                                                :
                                                                                orders.domain === 'BUS_Upos' ?
                                                                                    <div className="title">
                                                                                        <p>{orders.order_details}</p>
                                                                                        <p>{orders.create_at}</p>
                                                                                        {
                                                                                            orders.order_status === 'Successful' ?
                                                                                                <p className="hidden-xs hidden-sm hidden-md visible-lg visible-xl">
                                                                                                    <a href={Config.BUS_API_URL+ "api/v3/ticket-download/" + orders.order_id} title="Download Ticket" className="bp3-button bp3-small bp3-intent-primary btn-view" rel="noopener noreferrer" target="_blank">
                                                                                                        <span className="bp3-icon bp3-icon-import bp3-text-medium"></span>
                                                                                                    </a>
                                                                                                </p>
                                                                                                :
                                                                                                <p className="hidden-xs hidden-sm hidden-md visible-lg visible-xl">
                                                                                                    <Button small={true} intent="inform" disabled={true} className="btn-view mr-2" icon="import" title="Download Ticket" />
                                                                                                </p>
                                                                                        }
                                                                                    </div>
                                                                                    :
                                                                                    orders.domain === 'BUS' ?
                                                                                        <div className="title">
                                                                                            <p>{orders.order_details}</p>
                                                                                            <p>{orders.create_at}</p>
                                                                                            {
                                                                                                orders.order_status === 'Successful' ?
                                                                                                    <p className="hidden-xs hidden-sm hidden-md visible-lg visible-xl">
                                                                                                        <a href={Config.BUS_API_URL+ "api/v3/ticket-download/" + orders.order_id} title="Download Ticket" className="bp3-button bp3-small bp3-intent-primary btn-view" rel="noopener noreferrer" target="_blank">
                                                                                                            <span className="bp3-icon bp3-icon-import bp3-text-medium"></span>
                                                                                                        </a>
                                                                                                    </p>
                                                                                                    :
                                                                                                    <p className="hidden-xs hidden-sm hidden-md visible-lg visible-xl">
                                                                                                        <Button small={true} intent="inform" disabled={true} className="btn-view mr-2" icon="import" title="Download Ticket"/>
                                                                                                    </p>
                                                                                            }
                                                                                        </div>
                                                                                        :
                                                                                        orders.domain === "PACKAGE" ?
                                                                                            <div className="orderTable">
                                                                                                <div className="title">
                                                                                                    <p>{orders.package_name+" - "+orders.city_name}</p>
                                                                                                    <span>{orders.create_at}</span>
                                                                                                    <p className="hidden-xs hidden-sm hidden-md visible-lg visible-xl">
                                                                                                        <ViewPackage isOpen={this.state.isOpen} orderID={orders.order_id}/>
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        :
                                                                                        orders.domain === "HOTEL" ?
                                                                                            <div className="orderTable">
                                                                                                <div className="title">
                                                                                                    <p>{orders.order_details}</p>
                                                                                                    <span>{orders.order_date}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            orders.domain === "DMT" ?
                                                                                                <div className="orderTable">
                                                                                                    <div className="title">
                                                                                                        <p>{orders.order_details}</p>
                                                                                                        <span>{orders.order_date}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                <div className="orderTable">
                                                                                                    <div className="hidden-xs hidden-sm hidden-md visible-lg visible-xl image">
                                                                                                        <img src={require('../../../core/images/reffrals/insta.png')} alt=""/>
                                                                                                    </div>
                                                                                                    <div className="title">
                                                                                                        <p>{orders.order_details}</p>
                                                                                                        <span>{orders.create_at}</span>
                                                                                                    </div>
                                                                                                </div>
                                                }


                                            </td>
                                            <td>
                                                {orders.domain === 'DMT' ?
                                                    <label className="label" style={{color: orders.order_status_color}}><b>{orders.order_status} </b>
                                                        {orders.order_status === "Fulfilled" || orders.order_status === "Partial Success" ?
                                                            <button type="submit" onClick={() => {
                                                                this.getReceipt(orders.order_id)
                                                            }}
                                                               className="bp3-button bp3-small bp3-intent-primary btn-view"
                                                               rel="noopener noreferrer" target="_blank"
                                                               title="download Receipt">
                                                                <span
                                                                    className="bp3-icon bp3-icon-import bp3-text-medium"></span>
                                                            </button>
                                                            : ""
                                                        }
                                                    </label>
                                                    :
                                                    orders.domain === 'HOTEL' ?
                                                            <label className="label"><b>{orders.order_status}</b>
                                                            </label>
                                                        :
                                                    <label className="label" style={{color: orders.order_status_color}}><b>{orders.order_status}</b>
                                                    </label>
                                                }
                                            </td>
                                            <td>{orders.domain==='HOTEL' ? orders.id : orders.order_id}</td>
                                            <td>{orders.domain}</td>
                                            <td>{orders.cb_choice_title}</td>
                                            <td><b>₹ {orders.domain==='HOTEL' ? orders.amount : orders.amount}</b>
                                                {
                                                    orders.domain === 'BSW' ?
                                                        <p className="hidden-xl hidden-lg visible-md visible-sm visible-xs">
                                                            <ViewBswBill isOpen={this.state.isBswOpen} billNo={orders.order_id} />
                                                        </p>
                                                        :
                                                       /* orders.domain === 'MEGA-BRANDS' ?
                                                            orders.receipt_no ?
                                                                <p className="hidden-xl hidden-lg visible-md visible-sm visible-xs">
                                                                    <MegaBrandDetails isOpen={this.state.isMegaBrandsOpen} receiptNo={orders.receipt_no}/>
                                                                </p>
                                                                :
                                                                ""
                                                            :*/
                                                            orders.domain === 'RECHARGE' ?
                                                                <p className="hidden-xl hidden-lg visible-md visible-sm visible-xs">
                                                                    {/*<Invoice orderId={orders.order_id}/>*/}
                                                                </p>
                                                                :
                                                                orders.domain === 'USER-VOUCHER' || orders.domain === 'UPOS-VOUCHER' ?
                                                                    orders.order_status === 'Successful'?
                                                                        <p className="hidden-xl hidden-lg visible-md visible-sm visible-xs">
                                                                            <ViewCoinVoucher voucherId={orders.order_id} />
                                                                        </p>
                                                                        :
                                                                        ""
                                                                    :
                                                                    orders.domain === 'FLIGHT' ?
                                                                        <p className="hidden-xl hidden-lg visible-md visible-sm visible-xs"></p>
                                                                        :
                                                                        orders.domain === 'Store' ?
                                                                            orders.subDomain === "StoreBill" ?
                                                                                <p className="hidden-xl hidden-lg visible-md visible-sm visible-xs">
                                                                                    {/*<StoreBill isOpen={this.state.isStoreBillOpen} vendorId={orders.vendor_id} billNumber={orders.order_id}/>*/}
                                                                                </p>
                                                                                :
                                                                                <p className="hidden-xl hidden-lg visible-md visible-sm visible-xs">
                                                                                    <ViewOrders isOpen={this.state.isOpen} orderid={orders.order_id}/>
                                                                                </p>

                                                                            :
                                                                            orders.domain === 'RAILBHOJ' ?
                                                                                orders.ticket_link !== '' ?
                                                                                    <p className="hidden-xl hidden-lg visible-md visible-sm visible-xs">
                                                                                        <a href={Config.RAILBHOJ_API_URL+orders.ticket_link} className="bp3-button bp3-small bp3-intent-primary btn-view" rel="noopener noreferrer" target="_blank" title="download Invoice">
                                                                                            <span className="bp3-icon bp3-icon-import bp3-text-medium"></span>
                                                                                        </a>
                                                                                    </p>
                                                                                    :
                                                                                    ""
                                                                                :
                                                                                orders.domain === 'BUS_Upos' ?
                                                                                    orders.order_status === 'Successful' ?
                                                                                        <p className="hidden-xl hidden-lg visible-md visible-sm visible-xs">
                                                                                            <a href={Config.BUS_API_URL+ "api/v3/ticket-download/" + orders.order_id} title="Download Ticket" className="bp3-button bp3-small bp3-intent-primary btn-view" rel="noopener noreferrer" target="_blank">
                                                                                                <span className="bp3-icon bp3-icon-import bp3-text-medium"></span>
                                                                                            </a>
                                                                                        </p>
                                                                                        :
                                                                                        <p className="hidden-xl hidden-lg visible-md visible-sm visible-xs">
                                                                                            <Button small={true} intent="inform" disabled={true} className="btn-view mr-2" icon="import" title="Download Ticket"/>
                                                                                        </p>
                                                                                    :
                                                                                    orders.domain === 'BUS' ?
                                                                                        orders.order_status === 'Successful' ?
                                                                                            <p className="hidden-xl hidden-lg visible-md visible-sm visible-xs">
                                                                                                <a href={Config.BUS_API_URL+ "api/v3/ticket-download/" + orders.order_id} title="Download Ticket" className="bp3-button bp3-small bp3-intent-primary btn-view" rel="noopener noreferrer" target="_blank">
                                                                                                    <span className="bp3-icon bp3-icon-import bp3-text-medium"></span>
                                                                                                </a>
                                                                                            </p>
                                                                                            :
                                                                                            <p className="hidden-xl hidden-lg visible-md visible-sm visible-xs">
                                                                                                <Button small={true} intent="inform" disabled={true} className="btn-view mr-2" icon="import" title="Download Ticket" />
                                                                                            </p>
                                                                                        :
                                                                                        ""
                                                }
                                            </td>
                                        </tr>
                                    })
                                    :
                                    <tr>
                                        <td colSpan="7" align="center">
                                            <div className="text-center">
                                                <img src={AllorderEmptyStates} alt=""/>
                                                <p className="mt-3">No transactions for the selected option</p>
                                            </div>
                                        </td>
                                    </tr>
                        }
                        </tbody>
                    </table>
                    {
                        this.state.loading ?
                            null
                            :
                            this.props.stopLoadMore ?
                                ""
                                :
                                <React.Fragment>
                                    <center>
                                        <div className="my-3">
                                            <img src={require('../../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {

    return{
        approvedOrderList:state.AllOrders.approvedOrderList,
    }
}


const mapDispatchToProps = dispatch => {


    return {
        getAllOrderList:(params) => {
            return getAllOrderList(params);
        },
        getDMTPDF:(obj) => {

            return getDMTPDF(obj)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(AllOrders);